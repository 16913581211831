/* Akshat Jain -- 1002172488
Koppolu Leela Krishna -- 1002119295
Hansika Kotha -- 1002163191
Hruthvik Reddy Podduturi -- 1002139310
Sudarshan Potdar -- 1002138300 */

@import url(http://fonts.googleapis.com/css?family=Lato:300,400,700);


*, *:after, *:before { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }
body, html { font-size: 100%; padding: 0; margin: 0;}

/* Clearfix hack by Nicolas Gallagher: http://nicolasgallagher.com/micro-clearfix-hack/ */
.clearfix:before, .clearfix:after { content: " "; display: table; }
.clearfix:after { clear: both; }

body {
	font-family: 'Lato', Calibri, Arial, sans-serif;
	color: #b3b9bf;
	background: #f9f9f9;
}

a {
	color: #888;
	text-decoration: none;
}

a:hover,
a:active {
	color: #333;
}

/* Header Style */
.container > header {
	margin: 0 auto;
	padding: 2em;
	text-align: center;
	background: rgba(0,0,0,0.01);
}

.container > header h1 {
	font-size: 2.625em;
	line-height: 1.3;
	margin: 0;
	font-weight: 300;
}

/* To Navigation Style */
.codrops-top {
	background: #fff;
	background: rgba(255, 255, 255, 0.6);
	text-transform: uppercase;
	width: 100%;
	font-size: 0.69em;
	line-height: 2.2;
}

.codrops-top a {
	padding: 0 1em;
	letter-spacing: 0.1em;
	color: #888;
	display: inline-block;
}

.codrops-top a:hover {
	background: rgba(255,255,255,0.95);
	color: #333;
}

.codrops-top span.right {
	float: right;
}

.codrops-top span.right a {
	float: left;
	display: block;
}

.codrops-icon:before {
	font-family: 'codropsicons';
	margin: 0 4px;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
}

.codrops-icon-drop:before {
	content: "\e001";
}

.codrops-icon-prev:before {
	content: "\e004";
}

.codrops-icon-archive:before {
	content: "\e002";
}

.codrops-icon-next:before {
	content: "\e000";
}

.codrops-icon-about:before {
	content: "\e003";
}

/* Demo Buttons Style */
.codrops-demos {
	padding-top: 1em;
	font-size: 0.9em;
}

.codrops-demos a {
	display: inline-block;
	margin: 0.5em;
	padding: 0.7em 1.1em;
	border: 3px solid #b3b9bf;
	color: #b3b9bf;
	font-weight: 700;
}

.codrops-demos a:hover,
.codrops-demos a.current-demo,
.codrops-demos a.current-demo:hover {
	border-color: #2c3f52;
	color: #2c3f52;
}

@media screen and (max-width: 25em) {

	.codrops-icon span {
		display: none;
	}

}