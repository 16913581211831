/* Akshat Jain -- 1002172488
Koppolu Leela Krishna -- 1002119295
Hansika Kotha -- 1002163191
Hruthvik Reddy Podduturi -- 1002139310
Sudarshan Potdar -- 1002138300 */

@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap");

@-webkit-keyframes flash {
  0% {
    opacity: 1; }
  25% {
    opacity: 0; }
  50% {
    opacity: 1; }
  75% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes flash {
  0% {
    opacity: 1; }
  25% {
    opacity: 0; }
  50% {
    opacity: 1; }
  75% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  20% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px); }
  50% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px); }
  80% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  20% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px); }
  50% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px); }
  80% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes shake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  10% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  20% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px); }
  30% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  40% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px); }
  50% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px); }
  70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  80% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px); }
  90% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes shake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  10% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  20% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px); }
  30% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  40% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px); }
  50% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px); }
  70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  80% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px); }
  90% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@-webkit-keyframes tada {
  0% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); }
  10% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg); }
  20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg); }
  30% {
    -webkit-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg); }
  40% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg); }
  50% {
    -webkit-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg); }
  60% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg); }
  70% {
    -webkit-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg); }
  80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg); }
  90% {
    -webkit-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg); }
  100% {
    -webkit-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0); } }

@keyframes tada {
  0% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); }
  10% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg); }
  20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg); }
  30% {
    -webkit-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg); }
  40% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg); }
  50% {
    -webkit-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg); }
  60% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg); }
  70% {
    -webkit-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg); }
  80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg); }
  90% {
    -webkit-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg); }
  100% {
    -webkit-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0); } }

@-webkit-keyframes swing {
  20%, 40%, 60%, 80%, 100% {
    -webkit-transform-origin: top center 50%;
    transform-origin: top center 50%; }
  20% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg); }
  40% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg); }
  60% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg); }
  80% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg); }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); } }

@keyframes swing {
  20%, 40%, 60%, 80%, 100% {
    -webkit-transform-origin: top center 50%;
    transform-origin: top center 50%; }
  20% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg); }
  40% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg); }
  60% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg); }
  80% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg); }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); } }

@-webkit-keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%); }
  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
    transform: translateX(-25%) rotate(-5deg); }
  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
    transform: translateX(20%) rotate(3deg); }
  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
    transform: translateX(-15%) rotate(-3deg); }
  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
    transform: translateX(10%) rotate(2deg); }
  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
    transform: translateX(-5%) rotate(-1deg); }
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%); } }

@keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%); }
  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
    transform: translateX(-25%) rotate(-5deg); }
  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
    transform: translateX(20%) rotate(3deg); }
  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
    transform: translateX(-15%) rotate(-3deg); }
  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
    transform: translateX(10%) rotate(2deg); }
  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
    transform: translateX(-5%) rotate(-1deg); }
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%); } }

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); }
  50% {
    -webkit-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1); }
  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); } }

@keyframes pulse {
  0% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); }
  50% {
    -webkit-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1); }
  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); } }

@-webkit-keyframes wiggle {
  0% {
    -webkit-transform: skewX(9deg);
    transform: skewX(9deg); }
  10% {
    -webkit-transform: skewX(-8deg);
    transform: skewX(-8deg); }
  20% {
    -webkit-transform: skewX(7deg);
    transform: skewX(7deg); }
  30% {
    -webkit-transform: skewX(-6deg);
    transform: skewX(-6deg); }
  40% {
    -webkit-transform: skewX(5deg);
    transform: skewX(5deg); }
  50% {
    -webkit-transform: skewX(-4deg);
    transform: skewX(-4deg); }
  60% {
    -webkit-transform: skewX(3deg);
    transform: skewX(3deg); }
  70% {
    -webkit-transform: skewX(-2deg);
    transform: skewX(-2deg); }
  80% {
    -webkit-transform: skewX(1deg);
    transform: skewX(1deg); }
  90% {
    -webkit-transform: skewX(0deg);
    transform: skewX(0deg); }
  100% {
    -webkit-transform: skewX(0deg);
    transform: skewX(0deg); } }

@keyframes wiggle {
  0% {
    -webkit-transform: skewX(9deg);
    transform: skewX(9deg); }
  10% {
    -webkit-transform: skewX(-8deg);
    transform: skewX(-8deg); }
  20% {
    -webkit-transform: skewX(7deg);
    transform: skewX(7deg); }
  30% {
    -webkit-transform: skewX(-6deg);
    transform: skewX(-6deg); }
  40% {
    -webkit-transform: skewX(5deg);
    transform: skewX(5deg); }
  50% {
    -webkit-transform: skewX(-4deg);
    transform: skewX(-4deg); }
  60% {
    -webkit-transform: skewX(3deg);
    transform: skewX(3deg); }
  70% {
    -webkit-transform: skewX(-2deg);
    transform: skewX(-2deg); }
  80% {
    -webkit-transform: skewX(1deg);
    transform: skewX(1deg); }
  90% {
    -webkit-transform: skewX(0deg);
    transform: skewX(0deg); }
  100% {
    -webkit-transform: skewX(0deg);
    transform: skewX(0deg); } }

@-webkit-keyframes bounceOut {
  0% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); }
  25% {
    -webkit-transform: scale(0.95, 0.95);
    transform: scale(0.95, 0.95); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3, 0.3);
    transform: scale(0.3, 0.3); } }

@keyframes bounceOut {
  0% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); }
  25% {
    -webkit-transform: scale(0.95, 0.95);
    transform: scale(0.95, 0.95); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3, 0.3);
    transform: scale(0.3, 0.3); } }

@-webkit-keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); } }

@keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); } }

@-webkit-keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); } }

@keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); } }

@-webkit-keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); } }

@keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); } }

@-webkit-keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); } }

@keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); } }

@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3, 0.3);
    transform: scale(0.3, 0.3); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05, 1.05);
    transform: scale(1.05, 1.05); }
  70% {
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9); }
  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); } }

@keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3, 0.3);
    transform: scale(0.3, 0.3); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05, 1.05);
    transform: scale(1.05, 1.05); }
  70% {
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9); }
  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); } }

@-webkit-keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
    transform: translateY(30px); }
  80% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
    transform: translateY(30px); }
  80% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px); }
  80% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px); }
  80% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px); }
  80% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px); }
  80% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@-webkit-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
    transform: translateX(30px); }
  80% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
    transform: translateX(30px); }
  80% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes fadeOutUp {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; }
  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0; } }

@keyframes fadeOutUp {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; }
  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0; } }

@-webkit-keyframes fadeOutDown {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; }
  100% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0; } }

@keyframes fadeOutDown {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; }
  100% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0; } }

@-webkit-keyframes fadeOutRight {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
    opacity: 0; } }

@keyframes fadeOutRight {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
    opacity: 0; } }

@-webkit-keyframes fadeOutLeft {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 0; } }

@keyframes fadeOutLeft {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 0; } }

@-webkit-keyframes fadeOutUpBig {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; }
  100% {
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px);
    opacity: 0; } }

@keyframes fadeOutUpBig {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; }
  100% {
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px);
    opacity: 0; } }

@-webkit-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); } }

@keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); } }

@-webkit-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); } }

@keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); } }

@-webkit-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); } }

@keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); } }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeInUp {
  0% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@keyframes fadeInUp {
  0% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@-webkit-keyframes fadeInDown {
  0% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@keyframes fadeInDown {
  0% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@-webkit-keyframes fadeInRight {
  0% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }

@keyframes fadeInRight {
  0% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }

@-webkit-keyframes fadeInLeft {
  0% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }

@keyframes fadeInLeft {
  0% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }

@-webkit-keyframes fadeInUpBig {
  0% {
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@keyframes fadeInUpBig {
  0% {
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@-webkit-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@-webkit-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@-webkit-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotateY(0);
    transform: perspective(400px) rotateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  80% {
    -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
    transform: perspective(400px) rotateY(360deg) scale(0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  100% {
    -webkit-transform: perspective(400px) scale(1);
    transform: perspective(400px) scale(1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; } }

@keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotateY(0);
    transform: perspective(400px) rotateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  80% {
    -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
    transform: perspective(400px) rotateY(360deg) scale(0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  100% {
    -webkit-transform: perspective(400px) scale(1);
    transform: perspective(400px) scale(1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; } }

@-webkit-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
    transform: perspective(400px) rotateX(-10deg); }
  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg); }
  100% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; } }

@keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
    transform: perspective(400px) rotateX(-10deg); }
  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg); }
  100% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; } }

@-webkit-keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; }
  100% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0; } }

@keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; }
  100% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0; } }

@-webkit-keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
    transform: perspective(400px) rotateY(-10deg); }
  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg); }
  100% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; } }

@keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
    transform: perspective(400px) rotateY(-10deg); }
  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg); }
  100% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; } }

@-webkit-keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; }
  100% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0; } }

@keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; }
  100% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0; } }

@-webkit-keyframes lightSpeedIn {
  0% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0; }
  60% {
    -webkit-transform: translateX(-20%) skewX(30deg);
    transform: translateX(-20%) skewX(30deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; }
  80% {
    -webkit-transform: translateX(0%) skewX(-15deg);
    transform: translateX(0%) skewX(-15deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; } }

@keyframes lightSpeedIn {
  0% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0; }
  60% {
    -webkit-transform: translateX(-20%) skewX(30deg);
    transform: translateX(-20%) skewX(30deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; }
  80% {
    -webkit-transform: translateX(0%) skewX(-15deg);
    transform: translateX(0%) skewX(-15deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; } }

@-webkit-keyframes lightSpeedOut {
  0% {
    -webkit-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0; } }

@keyframes lightSpeedOut {
  0% {
    -webkit-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0; } }

@-webkit-keyframes rotateOut {
  0% {
    -webkit-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(200deg);
    transform: rotate(200deg);
    opacity: 0; } }

@keyframes rotateOut {
  0% {
    -webkit-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(200deg);
    transform: rotate(200deg);
    opacity: 0; } }

@-webkit-keyframes rotateOutDownLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0; } }

@keyframes rotateOutDownLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0; } }

@-webkit-keyframes rotateOutUpLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0; } }

@keyframes rotateOutUpLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0; } }

@-webkit-keyframes rotateOutDownRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0; } }

@keyframes rotateOutDownRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0; } }

@-webkit-keyframes rotateOutUpRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0; } }

@keyframes rotateOutUpRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0; } }

@-webkit-keyframes rotateIn {
  0% {
    -webkit-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(-200deg);
    transform: rotate(-200deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; } }

@keyframes rotateIn {
  0% {
    -webkit-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(-200deg);
    transform: rotate(-200deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; } }

@-webkit-keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; } }

@keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; } }

@-webkit-keyframes rotateInUpLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; } }

@keyframes rotateInUpLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; } }

@-webkit-keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; } }

@keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; } }

@-webkit-keyframes rotateInDownRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; } }

@keyframes rotateInDownRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; } }

@-webkit-keyframes hinge {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  20%, 60% {
    -webkit-transform: rotate(80deg);
    transform: rotate(80deg);
    -webkit-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  40% {
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  80% {
    -webkit-transform: rotate(60deg) translateY(0);
    transform: rotate(60deg) translateY(0);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  100% {
    -webkit-transform: translateY(700px);
    transform: translateY(700px);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0; } }

@keyframes hinge {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  20%, 60% {
    -webkit-transform: rotate(80deg);
    transform: rotate(80deg);
    -webkit-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  40% {
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  80% {
    -webkit-transform: rotate(60deg) translateY(0);
    transform: rotate(60deg) translateY(0);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  100% {
    -webkit-transform: translateY(700px);
    transform: translateY(700px);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0; } }

@-webkit-keyframes rollIn {
  0% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg); }
  100% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg); } }

@keyframes rollIn {
  0% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg); }
  100% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg); } }

@-webkit-keyframes rollOut {
  0% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg); }
  100% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg); } }

@keyframes rollOut {
  0% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg); }
  100% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg); } }

:root {
  --blue: #5E50F9;
  --indigo: #6610f2;
  --purple: #6a008a;
  --pink: #E91E63;
  --red: #f96868;
  --orange: #f2a654;
  --yellow: #f6e84e;
  --green: #46c35f;
  --teal: #58d8a3;
  --cyan: #57c7d4;
  --white: #ffffff;
  --gray: #6c757d;
  --gray-dark: #0f1531;
  --blue: #5E50F9;
  --indigo: #6610f2;
  --purple: #6a008a;
  --pink: #E91E63;
  --red: #f96868;
  --orange: #f2a654;
  --yellow: #f6e84e;
  --green: #46c35f;
  --teal: #58d8a3;
  --cyan: #57c7d4;
  --white: #ffffff;
  --gray: #434a54;
  --gray-light: #aab2bd;
  --gray-lighter: #e8eff4;
  --gray-lightest: #e6e9ed;
  --black: #000000;
  --primary: #1bdbe0;
  --secondary: #d8d8d8;
  --success: #38ce3c;
  --info: #8e32e9;
  --warning: #ffde73;
  --danger: #ff4d6b;
  --light: #f8f9fa;
  --dark: #3e4b5b;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #343a40;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px; }

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.33333%;
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.66667%;
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.33333%;
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.66667%;
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.33333%;
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.66667%;
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1; }

.order-last {
  -webkit-box-ordinal-group: 14;
  -ms-flex-order: 13;
  order: 13; }

.order-0 {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0; }

.order-1 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1; }

.order-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2; }

.order-3 {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3; }

.order-4 {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4; }

.order-5 {
  -webkit-box-ordinal-group: 6;
  -ms-flex-order: 5;
  order: 5; }

.order-6 {
  -webkit-box-ordinal-group: 7;
  -ms-flex-order: 6;
  order: 6; }

.order-7 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 7;
  order: 7; }

.order-8 {
  -webkit-box-ordinal-group: 9;
  -ms-flex-order: 8;
  order: 8; }

.order-9 {
  -webkit-box-ordinal-group: 10;
  -ms-flex-order: 9;
  order: 9; }

.order-10 {
  -webkit-box-ordinal-group: 11;
  -ms-flex-order: 10;
  order: 10; }

.order-11 {
  -webkit-box-ordinal-group: 12;
  -ms-flex-order: 11;
  order: 11; }

.order-12 {
  -webkit-box-ordinal-group: 13;
  -ms-flex-order: 12;
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1; }
  .order-sm-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13; }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0; }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2; }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3; }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4; }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5; }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6; }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7; }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8; }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9; }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10; }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11; }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1; }
  .order-md-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13; }
  .order-md-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0; }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2; }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3; }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4; }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5; }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6; }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7; }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8; }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9; }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10; }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11; }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1; }
  .order-lg-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13; }
  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0; }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2; }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3; }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4; }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5; }
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6; }
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7; }
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8; }
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9; }
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10; }
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11; }
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1; }
  .order-xl-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13; }
  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0; }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2; }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3; }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4; }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5; }
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6; }
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7; }
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8; }
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9; }
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10; }
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11; }
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.9375rem;
    vertical-align: top;
    border-top: 1px solid #e8ecf1; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #e8ecf1; }
  .table tbody + tbody {
    border-top: 2px solid #e8ecf1; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #e8ecf1; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #e8ecf1; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #ecf0f4; }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: #ecf0f4; }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #bff5f6; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #88ecef; }

.table-hover .table-primary:hover {
  background-color: #a9f2f3; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #a9f2f3; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #f4f4f4; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #ebebeb; }

.table-hover .table-secondary:hover {
  background-color: #e7e7e7; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #e7e7e7; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c7f1c8; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #98e69a; }

.table-hover .table-success:hover {
  background-color: #b3ecb4; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b3ecb4; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #dfc6f9; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #c494f4; }

.table-hover .table-info:hover {
  background-color: #d2aff7; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #d2aff7; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fff6d8; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffeeb6; }

.table-hover .table-warning:hover {
  background-color: #fff0bf; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fff0bf; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #ffcdd6; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ffa2b2; }

.table-hover .table-danger:hover {
  background-color: #ffb4c1; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #ffb4c1; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c9cdd1; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #9ba1aa; }

.table-hover .table-dark:hover {
  background-color: #bbc0c5; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #bbc0c5; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #e8ecf1; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control, .select2-container--default .select2-selection--single, .select2-container--default .select2-selection--single .select2-search__field, .typeahead,
.tt-query,
.tt-hint {
  display: block;
  width: 100%;
  height: 2.875rem;
  padding: 0.94rem 1.375rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1;
  color: #495057;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 2px;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control, .select2-container--default .select2-selection--single, .select2-container--default .select2-selection--single .select2-search__field, .typeahead,
    .tt-query,
    .tt-hint {
      -webkit-transition: none;
      transition: none; } }
  .form-control::-ms-expand, .select2-container--default .select2-selection--single::-ms-expand, .select2-container--default .select2-selection--single .select2-search__field::-ms-expand, .typeahead::-ms-expand,
  .tt-query::-ms-expand,
  .tt-hint::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring, .select2-container--default .select2-selection--single:-moz-focusring, .select2-container--default .select2-selection--single .select2-search__field:-moz-focusring, .typeahead:-moz-focusring,
  .tt-query:-moz-focusring,
  .tt-hint:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus, .select2-container--default .select2-selection--single:focus, .select2-container--default .select2-selection--single .select2-search__field:focus, .typeahead:focus,
  .tt-query:focus,
  .tt-hint:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::-webkit-input-placeholder, .select2-container--default .select2-selection--single::-webkit-input-placeholder, .select2-container--default .select2-selection--single .select2-search__field::-webkit-input-placeholder, .typeahead::-webkit-input-placeholder, .tt-query::-webkit-input-placeholder, .tt-hint::-webkit-input-placeholder {
    color: #c9c8c8;
    opacity: 1; }
  .form-control::-moz-placeholder, .select2-container--default .select2-selection--single::-moz-placeholder, .select2-container--default .select2-selection--single .select2-search__field::-moz-placeholder, .typeahead::-moz-placeholder, .tt-query::-moz-placeholder, .tt-hint::-moz-placeholder {
    color: #c9c8c8;
    opacity: 1; }
  .form-control:-ms-input-placeholder, .select2-container--default .select2-selection--single:-ms-input-placeholder, .select2-container--default .select2-selection--single .select2-search__field:-ms-input-placeholder, .typeahead:-ms-input-placeholder, .tt-query:-ms-input-placeholder, .tt-hint:-ms-input-placeholder {
    color: #c9c8c8;
    opacity: 1; }
  .form-control::-ms-input-placeholder, .select2-container--default .select2-selection--single::-ms-input-placeholder, .select2-container--default .select2-selection--single .select2-search__field::-ms-input-placeholder, .typeahead::-ms-input-placeholder, .tt-query::-ms-input-placeholder, .tt-hint::-ms-input-placeholder {
    color: #c9c8c8;
    opacity: 1; }
  .form-control::placeholder, .select2-container--default .select2-selection--single::placeholder, .select2-container--default .select2-selection--single .select2-search__field::placeholder, .typeahead::placeholder,
  .tt-query::placeholder,
  .tt-hint::placeholder {
    color: #c9c8c8;
    opacity: 1; }
  .form-control:disabled, .select2-container--default .select2-selection--single:disabled, .select2-container--default .select2-selection--single .select2-search__field:disabled, .typeahead:disabled,
  .tt-query:disabled,
  .tt-hint:disabled, .form-control[readonly], .select2-container--default .select2-selection--single[readonly], .select2-container--default .select2-selection--single .select2-search__field[readonly], .typeahead[readonly],
  .tt-query[readonly],
  .tt-hint[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control, .select2-container--default input.select2-selection--single[type="date"], .select2-container--default .select2-selection--single input.select2-search__field[type="date"], input.typeahead[type="date"],
input.tt-query[type="date"],
input.tt-hint[type="date"],
input[type="time"].form-control,
.select2-container--default input.select2-selection--single[type="time"],
.select2-container--default .select2-selection--single input.select2-search__field[type="time"],
input.typeahead[type="time"],
input.tt-query[type="time"],
input.tt-hint[type="time"],
input[type="datetime-local"].form-control,
.select2-container--default input.select2-selection--single[type="datetime-local"],
.select2-container--default .select2-selection--single input.select2-search__field[type="datetime-local"],
input.typeahead[type="datetime-local"],
input.tt-query[type="datetime-local"],
input.tt-hint[type="datetime-local"],
input[type="month"].form-control,
.select2-container--default input.select2-selection--single[type="month"],
.select2-container--default .select2-selection--single input.select2-search__field[type="month"],
input.typeahead[type="month"],
input.tt-query[type="month"],
input.tt-hint[type="month"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

select.form-control:focus::-ms-value, .select2-container--default select.select2-selection--single:focus::-ms-value, .select2-container--default .select2-selection--single select.select2-search__field:focus::-ms-value, select.typeahead:focus::-ms-value,
select.tt-query:focus::-ms-value,
select.tt-hint:focus::-ms-value {
  color: #495057;
  background-color: #ffffff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.94rem + 1px);
  padding-bottom: calc(0.94rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1; }

.col-form-label-lg {
  padding-top: calc(0.94rem + 1px);
  padding-bottom: calc(0.94rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.94rem 0;
  margin-bottom: 0;
  font-size: 0.8125rem;
  line-height: 1;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: 2.575rem;
  padding: 0.5rem 0.81rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: 3.175rem;
  padding: 0.94rem 1.94rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], .select2-container--default select.select2-selection--single[size], .select2-container--default .select2-selection--single select.select2-search__field[size], select.typeahead[size],
select.tt-query[size],
select.tt-hint[size], select.form-control[multiple], .select2-container--default select.select2-selection--single[multiple], .select2-container--default .select2-selection--single select.select2-search__field[multiple], select.typeahead[multiple],
select.tt-query[multiple],
select.tt-hint[multiple] {
  height: auto; }

textarea.form-control, .select2-container--default textarea.select2-selection--single, .select2-container--default .select2-selection--single textarea.select2-search__field, textarea.typeahead,
textarea.tt-query,
textarea.tt-hint {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #9c9fa6; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .was-validated .select2-container--default .select2-selection--single:valid, .select2-container--default .was-validated .select2-selection--single:valid, .was-validated .select2-container--default .select2-selection--single .select2-search__field:valid, .select2-container--default .select2-selection--single .was-validated .select2-search__field:valid, .was-validated .typeahead:valid,
.was-validated .tt-query:valid,
.was-validated .tt-hint:valid, .form-control.is-valid, .select2-container--default .is-valid.select2-selection--single, .select2-container--default .select2-selection--single .is-valid.select2-search__field, .is-valid.typeahead,
.is-valid.tt-query,
.is-valid.tt-hint {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .was-validated .select2-container--default .select2-selection--single:valid:focus, .select2-container--default .was-validated .select2-selection--single:valid:focus, .was-validated .select2-container--default .select2-selection--single .select2-search__field:valid:focus, .select2-container--default .select2-selection--single .was-validated .select2-search__field:valid:focus, .was-validated .typeahead:valid:focus,
  .was-validated .tt-query:valid:focus,
  .was-validated .tt-hint:valid:focus, .form-control.is-valid:focus, .select2-container--default .is-valid.select2-selection--single:focus, .select2-container--default .select2-selection--single .is-valid.select2-search__field:focus, .is-valid.typeahead:focus,
  .is-valid.tt-query:focus,
  .is-valid.tt-hint:focus {
    border-color: #28a745;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated textarea.form-control:valid, .was-validated .select2-container--default textarea.select2-selection--single:valid, .select2-container--default .was-validated textarea.select2-selection--single:valid, .was-validated .select2-container--default .select2-selection--single textarea.select2-search__field:valid, .select2-container--default .select2-selection--single .was-validated textarea.select2-search__field:valid, .was-validated textarea.typeahead:valid,
.was-validated textarea.tt-query:valid,
.was-validated textarea.tt-hint:valid, textarea.form-control.is-valid, .select2-container--default textarea.is-valid.select2-selection--single, .select2-container--default .select2-selection--single textarea.is-valid.select2-search__field, textarea.is-valid.typeahead,
textarea.is-valid.tt-query,
textarea.is-valid.tt-hint {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .was-validated .select2-container--default .select2-selection--single:invalid, .select2-container--default .was-validated .select2-selection--single:invalid, .was-validated .select2-container--default .select2-selection--single .select2-search__field:invalid, .select2-container--default .select2-selection--single .was-validated .select2-search__field:invalid, .was-validated .typeahead:invalid,
.was-validated .tt-query:invalid,
.was-validated .tt-hint:invalid, .form-control.is-invalid, .select2-container--default .is-invalid.select2-selection--single, .select2-container--default .select2-selection--single .is-invalid.select2-search__field, .is-invalid.typeahead,
.is-invalid.tt-query,
.is-invalid.tt-hint {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .was-validated .select2-container--default .select2-selection--single:invalid:focus, .select2-container--default .was-validated .select2-selection--single:invalid:focus, .was-validated .select2-container--default .select2-selection--single .select2-search__field:invalid:focus, .select2-container--default .select2-selection--single .was-validated .select2-search__field:invalid:focus, .was-validated .typeahead:invalid:focus,
  .was-validated .tt-query:invalid:focus,
  .was-validated .tt-hint:invalid:focus, .form-control.is-invalid:focus, .select2-container--default .is-invalid.select2-selection--single:focus, .select2-container--default .select2-selection--single .is-invalid.select2-search__field:focus, .is-invalid.typeahead:focus,
  .is-invalid.tt-query:focus,
  .is-invalid.tt-hint:focus {
    border-color: #dc3545;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, .was-validated .select2-container--default textarea.select2-selection--single:invalid, .select2-container--default .was-validated textarea.select2-selection--single:invalid, .was-validated .select2-container--default .select2-selection--single textarea.select2-search__field:invalid, .select2-container--default .select2-selection--single .was-validated textarea.select2-search__field:invalid, .was-validated textarea.typeahead:invalid,
.was-validated textarea.tt-query:invalid,
.was-validated textarea.tt-hint:invalid, textarea.form-control.is-invalid, .select2-container--default textarea.is-invalid.select2-selection--single, .select2-container--default .select2-selection--single textarea.is-invalid.select2-search__field, textarea.is-invalid.typeahead,
textarea.is-invalid.tt-query,
textarea.is-invalid.tt-hint {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control, .form-inline .select2-container--default .select2-selection--single, .select2-container--default .form-inline .select2-selection--single, .form-inline .select2-container--default .select2-selection--single .select2-search__field, .select2-container--default .select2-selection--single .form-inline .select2-search__field, .form-inline .typeahead,
    .form-inline .tt-query,
    .form-inline .tt-hint {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #343a40;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.875rem 2.5rem;
  font-size: 0.875rem;
  line-height: 1;
  border-radius: 0.1875rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      -webkit-transition: none;
      transition: none; } }
  .btn:hover {
    color: #343a40;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #212529;
  background-color: #1bdbe0;
  border-color: #1bdbe0; }
  .btn-primary:hover {
    color: #fff;
    background-color: #17babe;
    border-color: #16afb2; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #17babe;
    border-color: #16afb2;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(28, 192, 197, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(28, 192, 197, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #212529;
    background-color: #1bdbe0;
    border-color: #1bdbe0; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #16afb2;
    border-color: #14a3a7; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(28, 192, 197, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(28, 192, 197, 0.5); }

.btn-secondary {
  color: #212529;
  background-color: #d8d8d8;
  border-color: #d8d8d8; }
  .btn-secondary:hover {
    color: #212529;
    background-color: #c5c5c5;
    border-color: #bfbfbf; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #212529;
    background-color: #c5c5c5;
    border-color: #bfbfbf;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(189, 189, 190, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(189, 189, 190, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #212529;
    background-color: #d8d8d8;
    border-color: #d8d8d8; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #212529;
    background-color: #bfbfbf;
    border-color: #b8b8b8; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(189, 189, 190, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(189, 189, 190, 0.5); }

.btn-success {
  color: #fff;
  background-color: #38ce3c;
  border-color: #38ce3c; }
  .btn-success:hover {
    color: #fff;
    background-color: #2cb430;
    border-color: #2aa92d; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #2cb430;
    border-color: #2aa92d;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(86, 213, 89, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(86, 213, 89, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #38ce3c;
    border-color: #38ce3c; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #2aa92d;
    border-color: #279f2a; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(86, 213, 89, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(86, 213, 89, 0.5); }

.btn-info {
  color: #fff;
  background-color: #8e32e9;
  border-color: #8e32e9; }
  .btn-info:hover {
    color: #fff;
    background-color: #7b18dd;
    border-color: #7516d2; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #7b18dd;
    border-color: #7516d2;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(159, 81, 236, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(159, 81, 236, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #8e32e9;
    border-color: #8e32e9; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #7516d2;
    border-color: #6e15c6; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(159, 81, 236, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(159, 81, 236, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffde73;
  border-color: #ffde73; }
  .btn-warning:hover {
    color: #212529;
    background-color: #ffd54d;
    border-color: #ffd240; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #ffd54d;
    border-color: #ffd240;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 194, 104, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(222, 194, 104, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffde73;
    border-color: #ffde73; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffd240;
    border-color: #ffcf33; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 194, 104, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(222, 194, 104, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #ff4d6b;
  border-color: #ff4d6b; }
  .btn-danger:hover {
    color: #fff;
    background-color: #ff274b;
    border-color: #ff1a41; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #ff274b;
    border-color: #ff1a41;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 104, 129, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(255, 104, 129, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #ff4d6b;
    border-color: #ff4d6b; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ff1a41;
    border-color: #ff0d36; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 104, 129, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(255, 104, 129, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #3e4b5b;
  border-color: #3e4b5b; }
  .btn-dark:hover {
    color: #fff;
    background-color: #2f3844;
    border-color: #29323d; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #2f3844;
    border-color: #29323d;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(91, 102, 116, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(91, 102, 116, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #3e4b5b;
    border-color: #3e4b5b; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #29323d;
    border-color: #242c35; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(91, 102, 116, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(91, 102, 116, 0.5); }

.btn-outline-primary {
  color: #1bdbe0;
  border-color: #1bdbe0; }
  .btn-outline-primary:hover {
    color: #212529;
    background-color: #1bdbe0;
    border-color: #1bdbe0; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(27, 219, 224, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(27, 219, 224, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #1bdbe0;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #212529;
    background-color: #1bdbe0;
    border-color: #1bdbe0; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(27, 219, 224, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(27, 219, 224, 0.5); }

.btn-outline-secondary {
  color: #d8d8d8;
  border-color: #d8d8d8; }
  .btn-outline-secondary:hover {
    color: #212529;
    background-color: #d8d8d8;
    border-color: #d8d8d8; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(216, 216, 216, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(216, 216, 216, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #d8d8d8;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #212529;
    background-color: #d8d8d8;
    border-color: #d8d8d8; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(216, 216, 216, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(216, 216, 216, 0.5); }

.btn-outline-success {
  color: #38ce3c;
  border-color: #38ce3c; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #38ce3c;
    border-color: #38ce3c; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(56, 206, 60, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(56, 206, 60, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #38ce3c;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #38ce3c;
    border-color: #38ce3c; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(56, 206, 60, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(56, 206, 60, 0.5); }

.btn-outline-info {
  color: #8e32e9;
  border-color: #8e32e9; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #8e32e9;
    border-color: #8e32e9; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(142, 50, 233, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(142, 50, 233, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #8e32e9;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #8e32e9;
    border-color: #8e32e9; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(142, 50, 233, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(142, 50, 233, 0.5); }

.btn-outline-warning {
  color: #ffde73;
  border-color: #ffde73; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffde73;
    border-color: #ffde73; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 222, 115, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(255, 222, 115, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffde73;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffde73;
    border-color: #ffde73; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 222, 115, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(255, 222, 115, 0.5); }

.btn-outline-danger {
  color: #ff4d6b;
  border-color: #ff4d6b; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #ff4d6b;
    border-color: #ff4d6b; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 77, 107, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(255, 77, 107, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #ff4d6b;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #ff4d6b;
    border-color: #ff4d6b; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 77, 107, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(255, 77, 107, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #3e4b5b;
  border-color: #3e4b5b; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #3e4b5b;
    border-color: #3e4b5b; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(62, 75, 91, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(62, 75, 91, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #3e4b5b;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #3e4b5b;
    border-color: #3e4b5b; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(62, 75, 91, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(62, 75, 91, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 1rem 3rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.1875rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.5rem 0.81rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.1875rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      -webkit-transition: none;
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      -webkit-transition: none;
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e8ecf1;
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e8ecf1; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #343a40;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #343a40;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #343a40; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 1.875rem;
  padding-left: 1.875rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.6075rem;
  padding-left: 0.6075rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 2.25rem;
  padding-left: 2.25rem; }

.btn-group-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control, .select2-container--default .input-group > .select2-selection--single, .select2-container--default .select2-selection--single .input-group > .select2-search__field, .input-group > .typeahead,
  .input-group > .tt-query,
  .input-group > .tt-hint,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control, .select2-container--default .input-group > .select2-selection--single + .form-control, .select2-container--default .select2-selection--single .input-group > .select2-search__field + .form-control, .input-group > .typeahead + .form-control, .input-group > .tt-query + .form-control, .input-group > .tt-hint + .form-control, .select2-container--default .input-group > .form-control + .select2-selection--single, .select2-container--default .input-group > .select2-selection--single + .select2-selection--single, .select2-container--default .select2-selection--single .input-group > .select2-search__field + .select2-selection--single, .select2-container--default .input-group > .typeahead + .select2-selection--single, .select2-container--default .input-group > .tt-query + .select2-selection--single, .select2-container--default .input-group > .tt-hint + .select2-selection--single, .select2-container--default .select2-selection--single .input-group > .form-control + .select2-search__field, .select2-container--default .select2-selection--single .input-group > .select2-selection--single + .select2-search__field, .select2-container--default .select2-selection--single .input-group > .select2-search__field + .select2-search__field, .select2-container--default .select2-selection--single .input-group > .typeahead + .select2-search__field, .select2-container--default .select2-selection--single .input-group > .tt-query + .select2-search__field, .select2-container--default .select2-selection--single .input-group > .tt-hint + .select2-search__field, .input-group > .form-control + .typeahead, .select2-container--default .input-group > .select2-selection--single + .typeahead, .select2-container--default .select2-selection--single .input-group > .select2-search__field + .typeahead, .input-group > .typeahead + .typeahead, .input-group > .tt-query + .typeahead, .input-group > .tt-hint + .typeahead,
    .input-group > .form-control + .tt-query,
    .select2-container--default .input-group > .select2-selection--single + .tt-query,
    .select2-container--default .select2-selection--single .input-group > .select2-search__field + .tt-query,
    .input-group > .typeahead + .tt-query,
    .input-group > .tt-query + .tt-query,
    .input-group > .tt-hint + .tt-query,
    .input-group > .form-control + .tt-hint,
    .select2-container--default .input-group > .select2-selection--single + .tt-hint,
    .select2-container--default .select2-selection--single .input-group > .select2-search__field + .tt-hint,
    .input-group > .typeahead + .tt-hint,
    .input-group > .tt-query + .tt-hint,
    .input-group > .tt-hint + .tt-hint,
    .input-group > .form-control + .custom-select,
    .select2-container--default .input-group > .select2-selection--single + .custom-select,
    .select2-container--default .select2-selection--single .input-group > .select2-search__field + .custom-select,
    .input-group > .typeahead + .custom-select,
    .input-group > .tt-query + .custom-select,
    .input-group > .tt-hint + .custom-select,
    .input-group > .form-control + .custom-file,
    .select2-container--default .input-group > .select2-selection--single + .custom-file,
    .select2-container--default .select2-selection--single .input-group > .select2-search__field + .custom-file,
    .input-group > .typeahead + .custom-file,
    .input-group > .tt-query + .custom-file,
    .input-group > .tt-hint + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .select2-container--default .input-group > .form-control-plaintext + .select2-selection--single,
    .select2-container--default .select2-selection--single .input-group > .form-control-plaintext + .select2-search__field,
    .input-group > .form-control-plaintext + .typeahead,
    .input-group > .form-control-plaintext + .tt-query,
    .input-group > .form-control-plaintext + .tt-hint,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .select2-container--default .input-group > .custom-select + .select2-selection--single,
    .select2-container--default .select2-selection--single .input-group > .custom-select + .select2-search__field,
    .input-group > .custom-select + .typeahead,
    .input-group > .custom-select + .tt-query,
    .input-group > .custom-select + .tt-hint,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .select2-container--default .input-group > .custom-file + .select2-selection--single,
    .select2-container--default .select2-selection--single .input-group > .custom-file + .select2-search__field,
    .input-group > .custom-file + .typeahead,
    .input-group > .custom-file + .tt-query,
    .input-group > .custom-file + .tt-hint,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus, .select2-container--default .input-group > .select2-selection--single:focus, .select2-container--default .select2-selection--single .input-group > .select2-search__field:focus, .input-group > .typeahead:focus,
  .input-group > .tt-query:focus,
  .input-group > .tt-hint:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child), .select2-container--default .input-group > .select2-selection--single:not(:last-child), .select2-container--default .select2-selection--single .input-group > .select2-search__field:not(:last-child), .input-group > .typeahead:not(:last-child),
  .input-group > .tt-query:not(:last-child),
  .input-group > .tt-hint:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child), .select2-container--default .input-group > .select2-selection--single:not(:first-child), .select2-container--default .select2-selection--single .input-group > .select2-search__field:not(:first-child), .input-group > .typeahead:not(:first-child),
  .input-group > .tt-query:not(:first-child),
  .input-group > .tt-hint:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.94rem 1.375rem;
  margin-bottom: 0;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 2px; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea), .select2-container--default .input-group-lg > .select2-selection--single:not(textarea), .select2-container--default .select2-selection--single .input-group-lg > .select2-search__field:not(textarea), .input-group-lg > .typeahead:not(textarea),
.input-group-lg > .tt-query:not(textarea),
.input-group-lg > .tt-hint:not(textarea),
.input-group-lg > .custom-select {
  height: 3.175rem; }

.input-group-lg > .form-control, .select2-container--default .input-group-lg > .select2-selection--single, .select2-container--default .select2-selection--single .input-group-lg > .select2-search__field, .input-group-lg > .typeahead,
.input-group-lg > .tt-query,
.input-group-lg > .tt-hint,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.94rem 1.94rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea), .select2-container--default .input-group-sm > .select2-selection--single:not(textarea), .select2-container--default .select2-selection--single .input-group-sm > .select2-search__field:not(textarea), .input-group-sm > .typeahead:not(textarea),
.input-group-sm > .tt-query:not(textarea),
.input-group-sm > .tt-hint:not(textarea),
.input-group-sm > .custom-select {
  height: 2.575rem; }

.input-group-sm > .form-control, .select2-container--default .input-group-sm > .select2-selection--single, .select2-container--default .select2-selection--single .input-group-sm > .select2-search__field, .input-group-sm > .typeahead,
.input-group-sm > .tt-query,
.input-group-sm > .tt-hint,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.5rem 0.81rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact; }

.custom-control-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #80bdff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        -webkit-transition: none;
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(0.75rem);
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #ffffff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        -ms-transition: none;
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      -webkit-transition: none;
      transition: none; } }



.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }


.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap; }
    .card-group > .card {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.56rem 1.13rem;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0.25rem; }

.breadcrumb-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem; }
    .breadcrumb-item + .breadcrumb-item::before {
      display: inline-block;
      padding-right: 0.5rem;
      color: #6c757d;
      content: "/"; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none; }
  .breadcrumb-item.active {
    color: #495057; }

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      -webkit-transition: none;
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #212529;
  background-color: #1bdbe0; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #212529;
    background-color: #16afb2; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(27, 219, 224, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(27, 219, 224, 0.5); }

.badge-secondary {
  color: #212529;
  background-color: #d8d8d8; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #212529;
    background-color: #bfbfbf; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(216, 216, 216, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(216, 216, 216, 0.5); }

.badge-success, .preview-list .preview-item .preview-thumbnail .badge.badge-online {
  color: #fff;
  background-color: #38ce3c; }
  a.badge-success:hover, .preview-list .preview-item .preview-thumbnail a.badge.badge-online:hover, a.badge-success:focus, .preview-list .preview-item .preview-thumbnail a.badge.badge-online:focus {
    color: #fff;
    background-color: #2aa92d; }
  a.badge-success:focus, .preview-list .preview-item .preview-thumbnail a.badge.badge-online:focus, a.badge-success.focus, .preview-list .preview-item .preview-thumbnail a.focus.badge.badge-online {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(56, 206, 60, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(56, 206, 60, 0.5); }

.badge-info, .preview-list .preview-item .preview-thumbnail .badge.badge-offline {
  color: #fff;
  background-color: #8e32e9; }
  a.badge-info:hover, .preview-list .preview-item .preview-thumbnail a.badge.badge-offline:hover, a.badge-info:focus, .preview-list .preview-item .preview-thumbnail a.badge.badge-offline:focus {
    color: #fff;
    background-color: #7516d2; }
  a.badge-info:focus, .preview-list .preview-item .preview-thumbnail a.badge.badge-offline:focus, a.badge-info.focus, .preview-list .preview-item .preview-thumbnail a.focus.badge.badge-offline {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(142, 50, 233, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(142, 50, 233, 0.5); }

.badge-warning, .preview-list .preview-item .preview-thumbnail .badge.badge-busy {
  color: #212529;
  background-color: #ffde73; }
  a.badge-warning:hover, .preview-list .preview-item .preview-thumbnail a.badge.badge-busy:hover, a.badge-warning:focus, .preview-list .preview-item .preview-thumbnail a.badge.badge-busy:focus {
    color: #212529;
    background-color: #ffd240; }
  a.badge-warning:focus, .preview-list .preview-item .preview-thumbnail a.badge.badge-busy:focus, a.badge-warning.focus, .preview-list .preview-item .preview-thumbnail a.focus.badge.badge-busy {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 222, 115, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(255, 222, 115, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #ff4d6b; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #ff1a41; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 77, 107, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(255, 77, 107, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #3e4b5b; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #29323d; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(62, 75, 91, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(62, 75, 91, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #0e7274;
  background-color: #d1f8f9;
  border-color: #bff5f6; }
  .alert-primary hr {
    border-top-color: #a9f2f3; }
  .alert-primary .alert-link {
    color: #094546; }

.alert-secondary {
  color: #707070;
  background-color: #f7f7f7;
  border-color: #f4f4f4; }
  .alert-secondary hr {
    border-top-color: #e7e7e7; }
  .alert-secondary .alert-link {
    color: #575757; }

.alert-success {
  color: #1d6b1f;
  background-color: #d7f5d8;
  border-color: #c7f1c8; }
  .alert-success hr {
    border-top-color: #b3ecb4; }
  .alert-success .alert-link {
    color: #124313; }

.alert-info {
  color: #4a1a79;
  background-color: #e8d6fb;
  border-color: #dfc6f9; }
  .alert-info hr {
    border-top-color: #d2aff7; }
  .alert-info .alert-link {
    color: #30114f; }

.alert-warning {
  color: #85733c;
  background-color: #fff8e3;
  border-color: #fff6d8; }
  .alert-warning hr {
    border-top-color: #fff0bf; }
  .alert-warning .alert-link {
    color: #62552c; }

.alert-danger {
  color: #852838;
  background-color: #ffdbe1;
  border-color: #ffcdd6; }
  .alert-danger hr {
    border-top-color: #ffb4c1; }
  .alert-danger .alert-link {
    color: #5e1c27; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #20272f;
  background-color: #d8dbde;
  border-color: #c9cdd1; }
  .alert-dark hr {
    border-top-color: #bbc0c5; }
  .alert-dark .alert-link {
    color: #0b0e11; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      -webkit-transition: none;
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
      animation: none; } }

.media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start; }

.media-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1; }

.list-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #0e7274;
  background-color: #bff5f6; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #0e7274;
    background-color: #a9f2f3; }
  .list-group-item-primary.list-group-item-action.active {
    color: #ffffff;
    background-color: #0e7274;
    border-color: #0e7274; }

.list-group-item-secondary {
  color: #707070;
  background-color: #f4f4f4; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #707070;
    background-color: #e7e7e7; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #ffffff;
    background-color: #707070;
    border-color: #707070; }

.list-group-item-success {
  color: #1d6b1f;
  background-color: #c7f1c8; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #1d6b1f;
    background-color: #b3ecb4; }
  .list-group-item-success.list-group-item-action.active {
    color: #ffffff;
    background-color: #1d6b1f;
    border-color: #1d6b1f; }

.list-group-item-info {
  color: #4a1a79;
  background-color: #dfc6f9; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #4a1a79;
    background-color: #d2aff7; }
  .list-group-item-info.list-group-item-action.active {
    color: #ffffff;
    background-color: #4a1a79;
    border-color: #4a1a79; }

.list-group-item-warning {
  color: #85733c;
  background-color: #fff6d8; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #85733c;
    background-color: #fff0bf; }
  .list-group-item-warning.list-group-item-action.active {
    color: #ffffff;
    background-color: #85733c;
    border-color: #85733c; }

.list-group-item-danger {
  color: #852838;
  background-color: #ffcdd6; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #852838;
    background-color: #ffb4c1; }
  .list-group-item-danger.list-group-item-action.active {
    color: #ffffff;
    background-color: #852838;
    border-color: #852838; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #ffffff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #20272f;
  background-color: #c9cdd1; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #20272f;
    background-color: #bbc0c5; }
  .list-group-item-dark.list-group-item-action.active {
    color: #ffffff;
    background-color: #20272f;
    border-color: #20272f; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  -ms-flex-preferred-size: 350px;
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform 0.4s ease;
    transition: -webkit-transform 0.4s ease;
    transition: transform 0.4s ease;
    transition: transform 0.4s ease, -webkit-transform 0.4s ease;
    -webkit-transform: translate(0, -50px);
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        -webkit-transition: none;
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none; }
  .modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 20px); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 20px);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    -ms-flex-negative: 0;
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 20px); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 20px);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ecf0f4;
  background-clip: padding-box;
  border: 1px solid #e8ecf1;
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e8ecf1;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -25px -26px -25px auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0.9375rem; }

.modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0.6875rem;
  border-top: 1px solid #e8ecf1;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 30px auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 60px); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 60px); }
  .modal-dialog-centered {
    min-height: calc(100% - 60px); }
    .modal-dialog-centered::before {
      height: calc(100vh - 60px);
      height: -webkit-min-content;
      height: -moz-min-content;
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 90%; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.75rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.4rem 0.75rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.375rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      -webkit-transition: none;
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  -webkit-transition: opacity 0s 0.6s;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      -webkit-transition: none;
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  -webkit-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      -webkit-transition: none;
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    -webkit-transition: opacity 0.6s ease;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        -webkit-transition: none;
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #1bdbe0 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #16afb2 !important; }

.bg-secondary {
  background-color: #d8d8d8 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #bfbfbf !important; }

.bg-success {
  background-color: #38ce3c !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #2aa92d !important; }

.bg-info {
  background-color: #8e32e9 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #7516d2 !important; }

.bg-warning {
  background-color: #ffde73 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ffd240 !important; }

.bg-danger {
  background-color: #ff4d6b !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ff1a41 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #3e4b5b !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #29323d !important; }

.bg-white {
  background-color: #ffffff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border, .loader-demo-box {
  border: 1px solid #e8ecf1 !important; }

.border-top {
  border-top: 1px solid #e8ecf1 !important; }

.border-right {
  border-right: 1px solid #e8ecf1 !important; }

.border-bottom {
  border-bottom: 1px solid #e8ecf1 !important; }

.border-left {
  border-left: 1px solid #e8ecf1 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #1bdbe0 !important; }

.border-secondary, .loader-demo-box {
  border-color: #d8d8d8 !important; }

.border-success {
  border-color: #38ce3c !important; }

.border-info {
  border-color: #8e32e9 !important; }

.border-warning {
  border-color: #ffde73 !important; }

.border-danger {
  border-color: #ff4d6b !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #3e4b5b !important; }

.border-white {
  border-color: #ffffff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded, .loader-demo-box {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }


.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important; }

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important; }

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important; }

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important; }

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important; }

.flex-grow-0 {
  -webkit-box-flex: 0 !important;
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important; }

.flex-grow-1 {
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important; }

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important; }

.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important; }

.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important; }

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important; }

.justify-content-between, .page-header {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important; }

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important; }

.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important; }

.align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important; }


.align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important; }

.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important; }

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important; }

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important; }

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important; }

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important; }

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important; }

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important; }

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important; }

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important; }

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important; }

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important; }

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important; }

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-sm-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-sm-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-sm-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-md-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-md-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-md-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-md-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-md-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-lg-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-lg-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-lg-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-xl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-xl-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-xl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  -ms-user-select: all !important;
  user-select: all !important; }

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important; }

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0, .demo-modal .modal-dialog,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0, .rtl .preview-list .preview-item .preview-thumbnail .preview-icon i,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2, .template-demo > .btn-group,
.template-demo > .btn-group-vertical, .template-demo .circle-progress,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2, .template-demo > .btn-group,
.template-demo > .btn-group-vertical, .template-demo > .dropdown,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2, .btn-toolbar .btn-group + .btn-group,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3, .template-demo > .btn, .template-demo > .btn-toolbar,
.my-3 {
  margin-top: 1rem !important; }

.mr-3, .template-demo > .btn, .template-demo > .btn-toolbar,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3, .template-demo .circle-progress-block,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2,
.template-demo .circle-progress-block {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2,
.template-demo .circle-progress-block {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #ffffff !important; }

.text-primary {
  color: #1bdbe0 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #13989c !important; }

.text-secondary {
  color: #d8d8d8 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #b2b2b2 !important; }

.text-success {
  color: #38ce3c !important; }

a.text-success:hover, a.text-success:focus {
  color: #259528 !important; }

.text-info {
  color: #8e32e9 !important; }

a.text-info:hover, a.text-info:focus {
  color: #6814ba !important; }

.text-warning {
  color: #ffde73 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ffcc27 !important; }

.text-danger {
  color: #ff4d6b !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #ff012b !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #3e4b5b !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1f262e !important; }

.text-body {
  color: #343a40 !important; }

.text-muted, .preview-list .preview-item .preview-item-content p .content-category {
  color: #9c9fa6 !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }

  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #ffffff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #e8ecf1; }
  .table .thead-dark th {
    color: inherit;
    border-color: #e8ecf1; } }

/*-------------------------------------------------------------------*/
/* === Template mixins === */
/* Miscellaneous Mixins */
/* Animation Mixins */
@-webkit-keyframes dropdownAnimation {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    -webkit-transform: translate3d(0, 0px, 0);
    transform: translate3d(0, 0px, 0); } }
@keyframes dropdownAnimation {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    -webkit-transform: translate3d(0, 0px, 0);
    transform: translate3d(0, 0px, 0); } }


@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut; }

.infinite-spin {
  -webkit-animation-name: spin;
  animation-name: spin;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear; }

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }

/*-------------------------------------------------------------------*/
/* === Core Styles === */
/* Reset Styles */
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden; }

.form-control, .select2-container--default .select2-selection--single, .select2-container--default .select2-selection--single .select2-search__field, .typeahead,
.tt-query,
.tt-hint, .form-control:focus, .select2-container--default .select2-selection--single:focus, .select2-container--default .select2-selection--single .select2-search__field:focus, .typeahead:focus,
.tt-query:focus,
.tt-hint:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

.form-control, .select2-container--default .select2-selection--single, .select2-container--default .select2-selection--single .select2-search__field, .typeahead,
.tt-query,
.tt-hint {
  -webkit-box-shadow: none;
  box-shadow: none; }

.form-control:focus, .select2-container--default .select2-selection--single:focus, .select2-container--default .select2-selection--single .select2-search__field:focus, .typeahead:focus,
.tt-query:focus,
.tt-hint:focus {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none; }

a,
div,
h1,
h2,
h3,
h4,
h5,
p,
span {
  text-shadow: none; }

[type=button]:focus,
a:active,
a:focus,
a:visited,
button::-moz-focus-inner,
input[type=reset]::-moz-focus-inner,
input[type=button]::-moz-focus-inner,
input[type=submit]::-moz-focus-inner,
input[type=file] > input[type=button]::-moz-focus-inner,
select::-moz-focus-inner {
  outline: 0; }

input, .form-control:focus, .select2-container--default .select2-selection--single:focus, .select2-container--default .select2-selection--single .select2-search__field:focus, .typeahead:focus,
.tt-query:focus,
.tt-hint:focus,
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
  outline-width: 0;
  outline-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline-style: none; }

textarea {
  resize: none;
  overflow-x: hidden; }

.btn,
.btn-group.open .dropdown-toggle,
.btn:active,
.btn:focus,
.btn:hover,
.btn:visited,
a,
a:active,
a:checked,
a:focus,
a:hover,
a:visited,
body,
button,
button:active,
button:hover,
button:visited,
div,
input,
input:active,
input:focus,
input:hover,
input:visited,
select,
select:active,
select:focus,
select:visited,
textarea,
textarea:active,
textarea:focus,
textarea:hover,
textarea:visited {
  -webkit-box-shadow: none;
  box-shadow: none; }

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus,
button,
button:active,
button:checked,
button:focus,
button:hover,
button:visited {
  outline: 0;
  outline-offset: 0; }

.dropdown-menu > li > a:active,
.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:visited {
  outline: 0; }

a:focus,
input:focus {
  border-color: transparent;
  outline: none; }

/* Typography */
body {
  font-size: 1rem;
  font-family: "Open Sans", sans-serif;
  font-weight: initial;
  line-height: normal;
  -webkit-font-smoothing: antialiased; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600; }

p {
  font-size: 0.875rem; }

h1, .h1 {
  font-size: 2.19rem; }

h2, .h2 {
  font-size: 1.88rem; }

h3, .h3 {
  font-size: 1.56rem; }

h4, .h4 {
  font-size: 1.13rem; }

h5, .h5 {
  font-size: 1rem; }

h6, .h6 {
  font-size: .9375rem; }

p {
  font-size: .9375rem;
  line-height: 1.5; }

.display-1 {
  font-size: 3.75rem; }
  @media (max-width: 991px) {
    .display-1 {
      font-size: 3rem; } }

.display-2 {
  font-size: 3.125rem; }
  @media (max-width: 991px) {
    .display-2 {
      font-size: 2.5rem; } }

.display-3 {
  font-size: 2.5rem; }
  @media (max-width: 991px) {
    .display-3 {
      font-size: 2rem; } }

.display-4 {
  font-size: 1.875rem; }
  @media (max-width: 991px) {
    .display-4 {
      font-size: 1.5rem; } }

.display-5 {
  font-size: 1.25rem; }
  @media (max-width: 991px) {
    .display-5 {
      font-size: 1rem; } }

.blockquote {
  padding: 1.25rem;
  border: 1px solid #e8ecf1; }

address p {
  margin-bottom: 0; }

.blockquote-primary {
  border-color: #1bdbe0; }
  .blockquote-primary .blockquote-footer {
    color: #1bdbe0; }

.blockquote-secondary {
  border-color: #d8d8d8; }
  .blockquote-secondary .blockquote-footer {
    color: #d8d8d8; }

.blockquote-success {
  border-color: #38ce3c; }
  .blockquote-success .blockquote-footer {
    color: #38ce3c; }

.blockquote-info {
  border-color: #8e32e9; }
  .blockquote-info .blockquote-footer {
    color: #8e32e9; }

.blockquote-warning {
  border-color: #ffde73; }
  .blockquote-warning .blockquote-footer {
    color: #ffde73; }

.blockquote-danger {
  border-color: #ff4d6b; }
  .blockquote-danger .blockquote-footer {
    color: #ff4d6b; }

.blockquote-light {
  border-color: #f8f9fa; }
  .blockquote-light .blockquote-footer {
    color: #f8f9fa; }

.blockquote-dark {
  border-color: #3e4b5b; }
  .blockquote-dark .blockquote-footer {
    color: #3e4b5b; }

.error-page h1 {
  font-size: 12rem; }
  @media (max-width: 991px) {
    .error-page h1 {
      font-size: 8rem; } }

.icon-lg {
  font-size: 3.438rem; }

.icon-md {
  font-size: 1.875rem; }

.icon-sm {
  font-size: 1rem; }

/* Miscellanoeous */
body,
html {
  overflow-x: hidden;
  padding-right: 0 !important; }

*:-moz-full-screen,
*:-webkit-full-screen,
*:fullscreen *:-ms-fullscreen {
  overflow: auto; }

.container-scroller {
  overflow: hidden; }

pre {
  background: #e8eff4;
  padding: 15px;
  font-size: 14px; }

code {
  padding: 5px;
  color: #ff4d6b;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 0.875rem;
  border-radius: 4px; }

.page-header {
  margin: 0 0 1.5rem 0; }
  .page-header .breadcrumb {
    border: 0;
    margin-bottom: 0; }

.page-title {
  color: #343a40;
  font-size: 1.125rem;
  margin-bottom: 0; }
  .page-title .page-title-icon {
    display: inline-block;
    width: 36px;
    height: 36px;
    border-radius: 4px;
    text-align: center;
    -webkit-box-shadow: 0px 3px 8.3px 0.7px rgba(163, 93, 255, 0.35);
    box-shadow: 0px 3px 8.3px 0.7px rgba(163, 93, 255, 0.35); }
    .page-title .page-title-icon i {
      font-size: .9375rem;
      line-height: 36px; }

/* Utilities */
.grid-margin {
  margin-bottom: 25px; }

@media (min-width: 576px) {
  .grid-margin-sm-0 {
    margin-bottom: 0; } }

@media (min-width: 768px) {
  .grid-margin-md-0 {
    margin-bottom: 0; } }

@media (min-width: 992px) {
  .grid-margin-lg-0 {
    margin-bottom: 0; } }

@media (min-width: 1200px) {
  .grid-margin-xl-0 {
    margin-bottom: 0; } }

.img-lg {
  width: 92px;
  height: 92px; }

.img-sm {
  width: 43px;
  height: 43px; }

.img-xs {
  width: 37px;
  height: 37px; }

.img-ss {
  width: 26px;
  height: 26px; }

.stretch-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-pack: stretch;
  -ms-flex-pack: stretch;
  justify-content: stretch; }
  .stretch-card > .card {
    width: 100%;
    min-width: 100%; }

@media (min-width: 576px) {
  .border-right-sm {
    border-right: 1px solid #e8ecf1; } }

@media (min-width: 768px) {
  .border-right-md {
    border-right: 1px solid #e8ecf1; } }

@media (min-width: 992px) {
  .border-right-lg {
    border-right: 1px solid #e8ecf1; } }

@media (min-width: 576px) {
  .border-left-sm {
    border-left: 1px solid #e8ecf1; } }

@media (min-width: 768px) {
  .border-left-md {
    border-left: 1px solid #e8ecf1; } }

@media (min-width: 992px) {
  .border-left-lg {
    border-left: 1px solid #e8ecf1; } }

.text-gray, .card .card-subtitle {
  color: #8c8c8c; }

.text-black {
  color: #000; }

.text-small {
  font-size: 12px; }

.flex-grow {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1; }

.font-weight-light {
  font-family: "Open Sans", sans-serif;
  font-weight: 300; }

.font-weight-medium {
  font-family: "Open Sans", sans-serif;
  font-weight: 600; }

.font-weight-semibold {
  font-family: "Open Sans", sans-serif;
  font-weight: 700; }

.font-weight-bold {
  font-family: "Open Sans", sans-serif;
  font-weight: bold; }

.font-weight-normal {
  font-family: "Open Sans", sans-serif;
  font-weight: 400; }

/* Demo Styles */
.template-demo .slider-wrap {
  height: 100px; }

.template-demo .progress {
  margin-top: 1.5rem; }

.template-demo > h2,
.template-demo > h3,
.template-demo > h4,
.template-demo > h5,
.template-demo > h6,
.template-demo > h1 {
  border-top: 1px solid #e8ecf1;
  padding: 0.5rem 0 0; }

.template-demo .ul-slider.noUi-horizontal {
  margin-top: 2rem; }

.template-demo .ul-slider.noUi-vertical {
  margin-right: 2rem; }

.template-demo > .dropdown {
  display: inline-block;
  margin-bottom: 0.5rem; }

.template-demo nav .breadcrumb {
  margin-bottom: 1.375rem; }

.template-demo nav:last-child .breadcrumb {
  margin-bottom: 0; }

.template-demo .editable-form > .form-group {
  border-bottom: 1px solid #e8ecf1;
  padding-bottom: 0.8rem;
  margin-bottom: 0.8rem; }

.template-demo .circle-progress {
  padding: 15px; }

.demo-modal {
  position: static;
  display: block; }
  .demo-modal .modal-dialog.modal-lg {
    max-width: 100%; }

.loader-demo-box {
  width: 100%;
  height: 200px; }

.dropdown-menu-static-demo {
  height: 250px;
  margin-bottom: 20px; }

.rounded-legend ul li {
  list-style-type: none;
  color: #9c9fa6;
  font-size: .75rem; }
  .rounded-legend ul li .legend-dots {
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    display: inline-block;
    vertical-align: text-bottom;
    margin-right: .5rem; }
    .rtl .rounded-legend ul li .legend-dots {
      margin-left: .5rem; }

.rounded-legend.legend-horizontal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .rounded-legend.legend-horizontal ul li {
    display: inline-block;
    margin-right: 1.5rem; }
    .rtl .rounded-legend.legend-horizontal ul li {
      margin-right: auto;
      margin-left: 1.5rem; }

.rounded-legend.legend-top-right ul {
  float: right; }
  .rtl .rounded-legend.legend-top-right ul {
    float: left; }

.rounded-legend.legend-vertical ul li {
  margin-top: 1rem; }

/*-------------------------------------------------------------------*/
/* === Components === */
/* Buttons */
.btn {
  font-size: 0.875rem;
  line-height: 1;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  /* Buttons with only icons */
  /* Buttons with icon and text */ }
  .btn i {
    font-size: 1rem; }
  .btn.btn-rounded {
    border-radius: 50px; }
  .btn.btn-fw {
    min-width: 150px; }
  .btn.btn-sm, .btn-group-sm > .btn {
    font-size: 0.875rem; }
  .btn.btn-lg, .btn-group-lg > .btn {
    font-size: 0.875rem; }
  .btn.btn-xs {
    padding: 0.5rem 0.75rem;
    font-size: 0.625rem; }
  .btn.btn-icon {
    width: 42px;
    height: 42px;
    padding: 0; }
  .btn.btn-icon-text .btn-icon-prepend {
    margin-right: .5rem; }
  .btn.btn-icon-text .btn-icon-append {
    margin-left: .5rem; }
  .btn.btn-social-icon {
    width: 50px;
    height: 50px;
    padding: 0; }
  .btn.btn-no-hover-bg:hover, .btn.btn-no-hover-bg:focus, .btn.btn-no-hover-bg:active {
    background: transparent;
    color: currentColor; }

.btn-group .btn + .btn {
  border-left: 0; }

/*social buttons*/
.btn-twitter {
  background: #2caae1;
  color: #ffffff; }
  .btn-twitter:hover, .btn-twitter:focus {
    background: #1b8dbf;
    color: #ffffff; }
  .btn-twitter.btn-social-icon-text {
    padding: 0 1.5rem 0 0;
    background: #59bce7; }
    .btn-twitter.btn-social-icon-text i {
      background: #2caae1;
      padding: .75rem;
      display: inline-block;
      margin-right: 1.5rem; }

.btn-outline-twitter {
  border: 1px solid #2caae1;
  color: #2caae1; }
  .btn-outline-twitter:hover {
    background: #2caae1;
    color: #ffffff; }

.btn-facebook {
  background: #3b579d;
  color: #ffffff; }
  .btn-facebook:hover, .btn-facebook:focus {
    background: #2d4278;
    color: #ffffff; }
  .btn-facebook.btn-social-icon-text {
    padding: 0 1.5rem 0 0;
    background: #4e6ebd; }
    .btn-facebook.btn-social-icon-text i {
      background: #3b579d;
      padding: .75rem;
      display: inline-block;
      margin-right: 1.5rem; }

.btn-outline-facebook {
  border: 1px solid #3b579d;
  color: #3b579d; }
  .btn-outline-facebook:hover {
    background: #3b579d;
    color: #ffffff; }

.btn-google {
  background: #dc4a38;
  color: #ffffff; }
  .btn-google:hover, .btn-google:focus {
    background: #bf3322;
    color: #ffffff; }
  .btn-google.btn-social-icon-text {
    padding: 0 1.5rem 0 0;
    background: #e47163; }
    .btn-google.btn-social-icon-text i {
      background: #dc4a38;
      padding: .75rem;
      display: inline-block;
      margin-right: 1.5rem; }

.btn-outline-google {
  border: 1px solid #dc4a38;
  color: #dc4a38; }
  .btn-outline-google:hover {
    background: #dc4a38;
    color: #ffffff; }

.btn-linkedin {
  background: #0177b5;
  color: #ffffff; }
  .btn-linkedin:hover, .btn-linkedin:focus {
    background: #015682;
    color: #ffffff; }
  .btn-linkedin.btn-social-icon-text {
    padding: 0 1.5rem 0 0;
    background: #0198e8; }
    .btn-linkedin.btn-social-icon-text i {
      background: #0177b5;
      padding: .75rem;
      display: inline-block;
      margin-right: 1.5rem; }

.btn-outline-linkedin {
  border: 1px solid #0177b5;
  color: #0177b5; }
  .btn-outline-linkedin:hover {
    background: #0177b5;
    color: #ffffff; }

.btn-pinterest {
  background: #cc2127;
  color: #ffffff; }
  .btn-pinterest:hover, .btn-pinterest:focus {
    background: #a01a1f;
    color: #ffffff; }
  .btn-pinterest.btn-social-icon-text {
    padding: 0 1.5rem 0 0;
    background: #e04046; }
    .btn-pinterest.btn-social-icon-text i {
      background: #cc2127;
      padding: .75rem;
      display: inline-block;
      margin-right: 1.5rem; }

.btn-outline-pinterest {
  border: 1px solid #cc2127;
  color: #cc2127; }
  .btn-outline-pinterest:hover {
    background: #cc2127;
    color: #ffffff; }

.btn-youtube {
  background: #e52d27;
  color: #ffffff; }
  .btn-youtube:hover, .btn-youtube:focus {
    background: #c21d17;
    color: #ffffff; }
  .btn-youtube.btn-social-icon-text {
    padding: 0 1.5rem 0 0;
    background: #ea5955; }
    .btn-youtube.btn-social-icon-text i {
      background: #e52d27;
      padding: .75rem;
      display: inline-block;
      margin-right: 1.5rem; }

.btn-outline-youtube {
  border: 1px solid #e52d27;
  color: #e52d27; }
  .btn-outline-youtube:hover {
    background: #e52d27;
    color: #ffffff; }

.btn-github {
  background: #333333;
  color: #ffffff; }
  .btn-github:hover, .btn-github:focus {
    background: #1a1a1a;
    color: #ffffff; }
  .btn-github.btn-social-icon-text {
    padding: 0 1.5rem 0 0;
    background: #4d4d4d; }
    .btn-github.btn-social-icon-text i {
      background: #333333;
      padding: .75rem;
      display: inline-block;
      margin-right: 1.5rem; }

.btn-outline-github {
  border: 1px solid #333333;
  color: #333333; }
  .btn-outline-github:hover {
    background: #333333;
    color: #ffffff; }

.btn-behance {
  background: #1769ff;
  color: #ffffff; }
  .btn-behance:hover, .btn-behance:focus {
    background: #0050e3;
    color: #ffffff; }
  .btn-behance.btn-social-icon-text {
    padding: 0 1.5rem 0 0;
    background: #4a8aff; }
    .btn-behance.btn-social-icon-text i {
      background: #1769ff;
      padding: .75rem;
      display: inline-block;
      margin-right: 1.5rem; }

.btn-outline-behance {
  border: 1px solid #1769ff;
  color: #1769ff; }
  .btn-outline-behance:hover {
    background: #1769ff;
    color: #ffffff; }

.btn-dribbble {
  background: #ea4c89;
  color: #ffffff; }
  .btn-dribbble:hover, .btn-dribbble:focus {
    background: #e51e6b;
    color: #ffffff; }
  .btn-dribbble.btn-social-icon-text {
    padding: 0 1.5rem 0 0;
    background: #ef7aa7; }
    .btn-dribbble.btn-social-icon-text i {
      background: #ea4c89;
      padding: .75rem;
      display: inline-block;
      margin-right: 1.5rem; }

.btn-outline-dribbble {
  border: 1px solid #ea4c89;
  color: #ea4c89; }
  .btn-outline-dribbble:hover {
    background: #ea4c89;
    color: #ffffff; }

.btn-reddit {
  background: #ff4500;
  color: #ffffff; }
  .btn-reddit:hover, .btn-reddit:focus {
    background: #cc3700;
    color: #ffffff; }
  .btn-reddit.btn-social-icon-text {
    padding: 0 1.5rem 0 0;
    background: #ff6a33; }
    .btn-reddit.btn-social-icon-text i {
      background: #ff4500;
      padding: .75rem;
      display: inline-block;
      margin-right: 1.5rem; }

.btn-outline-reddit {
  border: 1px solid #ff4500;
  color: #ff4500; }
  .btn-outline-reddit:hover {
    background: #ff4500;
    color: #ffffff; }

/* inverse buttons */
.btn-inverse-primary {
  background-color: rgba(27, 219, 224, 0.2);
  background-image: none;
  border-color: rgba(27, 219, 224, 0); }
  .btn-inverse-primary:not(.btn-inverse-light) {
    color: #1bdbe0; }
  .btn-inverse-primary:hover {
    color: #ffffff;
    background-color: #1bdbe0;
    border-color: #1bdbe0; }
  .btn-inverse-primary.focus, .btn-inverse-primary:focus {
    -webkit-box-shadow: 0 0 0 3px rgba(27, 219, 224, 0.5);
    box-shadow: 0 0 0 3px rgba(27, 219, 224, 0.5); }
  .btn-inverse-primary.disabled, .btn-inverse-primary:disabled {
    color: #1bdbe0;
    background-color: transparent; }
  .btn-inverse-primary.active, .btn-inverse-primary:active,
  .show > .btn-inverse-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #1bdbe0;
    border-color: #1bdbe0; }

.btn-primary:not(.btn-light) {
  color: #ffffff; }
  .btn-primary:not(.btn-light):hover, .btn-primary:not(.btn-light):focus, .btn-primary:not(.btn-light):active {
    color: #ffffff; }
  .btn-primary:not(.btn-light):focus, .btn-primary:not(.btn-light):active {
    background: #1bdbe0;
    border-color: #1bdbe0; }

.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active {
  background: #1bdbe0;
  color: #ffffff; }

.btn-inverse-secondary {
  background-color: rgba(216, 216, 216, 0.2);
  background-image: none;
  border-color: rgba(216, 216, 216, 0); }
  .btn-inverse-secondary:not(.btn-inverse-light) {
    color: #d8d8d8; }
  .btn-inverse-secondary:hover {
    color: #ffffff;
    background-color: #d8d8d8;
    border-color: #d8d8d8; }
  .btn-inverse-secondary.focus, .btn-inverse-secondary:focus {
    -webkit-box-shadow: 0 0 0 3px rgba(216, 216, 216, 0.5);
    box-shadow: 0 0 0 3px rgba(216, 216, 216, 0.5); }
  .btn-inverse-secondary.disabled, .btn-inverse-secondary:disabled {
    color: #d8d8d8;
    background-color: transparent; }
  .btn-inverse-secondary.active, .btn-inverse-secondary:active,
  .show > .btn-inverse-secondary.dropdown-toggle {
    color: #ffffff;
    background-color: #d8d8d8;
    border-color: #d8d8d8; }

.btn-secondary:not(.btn-light) {
  color: #ffffff; }
  .btn-secondary:not(.btn-light):hover, .btn-secondary:not(.btn-light):focus, .btn-secondary:not(.btn-light):active {
    color: #ffffff; }
  .btn-secondary:not(.btn-light):focus, .btn-secondary:not(.btn-light):active {
    background: #d8d8d8;
    border-color: #d8d8d8; }

.btn-outline-secondary:hover, .btn-outline-secondary:focus, .btn-outline-secondary:active {
  background: #d8d8d8;
  color: #ffffff; }

.btn-inverse-success {
  background-color: rgba(56, 206, 60, 0.2);
  background-image: none;
  border-color: rgba(56, 206, 60, 0); }
  .btn-inverse-success:not(.btn-inverse-light) {
    color: #38ce3c; }
  .btn-inverse-success:hover {
    color: #ffffff;
    background-color: #38ce3c;
    border-color: #38ce3c; }
  .btn-inverse-success.focus, .btn-inverse-success:focus {
    -webkit-box-shadow: 0 0 0 3px rgba(56, 206, 60, 0.5);
    box-shadow: 0 0 0 3px rgba(56, 206, 60, 0.5); }
  .btn-inverse-success.disabled, .btn-inverse-success:disabled {
    color: #38ce3c;
    background-color: transparent; }
  .btn-inverse-success.active, .btn-inverse-success:active,
  .show > .btn-inverse-success.dropdown-toggle {
    color: #ffffff;
    background-color: #38ce3c;
    border-color: #38ce3c; }

.btn-success:not(.btn-light) {
  color: #ffffff; }
  .btn-success:not(.btn-light):hover, .btn-success:not(.btn-light):focus, .btn-success:not(.btn-light):active {
    color: #ffffff; }
  .btn-success:not(.btn-light):focus, .btn-success:not(.btn-light):active {
    background: #38ce3c;
    border-color: #38ce3c; }

.btn-outline-success:hover, .btn-outline-success:focus, .btn-outline-success:active {
  background: #38ce3c;
  color: #ffffff; }

.btn-inverse-info {
  background-color: rgba(142, 50, 233, 0.2);
  background-image: none;
  border-color: rgba(142, 50, 233, 0); }
  .btn-inverse-info:not(.btn-inverse-light) {
    color: #8e32e9; }
  .btn-inverse-info:hover {
    color: #ffffff;
    background-color: #8e32e9;
    border-color: #8e32e9; }
  .btn-inverse-info.focus, .btn-inverse-info:focus {
    -webkit-box-shadow: 0 0 0 3px rgba(142, 50, 233, 0.5);
    box-shadow: 0 0 0 3px rgba(142, 50, 233, 0.5); }
  .btn-inverse-info.disabled, .btn-inverse-info:disabled {
    color: #8e32e9;
    background-color: transparent; }
  .btn-inverse-info.active, .btn-inverse-info:active,
  .show > .btn-inverse-info.dropdown-toggle {
    color: #ffffff;
    background-color: #8e32e9;
    border-color: #8e32e9; }

.btn-info:not(.btn-light) {
  color: #ffffff; }
  .btn-info:not(.btn-light):hover, .btn-info:not(.btn-light):focus, .btn-info:not(.btn-light):active {
    color: #ffffff; }
  .btn-info:not(.btn-light):focus, .btn-info:not(.btn-light):active {
    background: #8e32e9;
    border-color: #8e32e9; }

.btn-outline-info:hover, .btn-outline-info:focus, .btn-outline-info:active {
  background: #8e32e9;
  color: #ffffff; }

.btn-inverse-warning {
  background-color: rgba(255, 222, 115, 0.2);
  background-image: none;
  border-color: rgba(255, 222, 115, 0); }
  .btn-inverse-warning:not(.btn-inverse-light) {
    color: #ffde73; }
  .btn-inverse-warning:hover {
    color: #ffffff;
    background-color: #ffde73;
    border-color: #ffde73; }
  .btn-inverse-warning.focus, .btn-inverse-warning:focus {
    -webkit-box-shadow: 0 0 0 3px rgba(255, 222, 115, 0.5);
    box-shadow: 0 0 0 3px rgba(255, 222, 115, 0.5); }
  .btn-inverse-warning.disabled, .btn-inverse-warning:disabled {
    color: #ffde73;
    background-color: transparent; }
  .btn-inverse-warning.active, .btn-inverse-warning:active,
  .show > .btn-inverse-warning.dropdown-toggle {
    color: #ffffff;
    background-color: #ffde73;
    border-color: #ffde73; }

.btn-warning:not(.btn-light) {
  color: #ffffff; }
  .btn-warning:not(.btn-light):hover, .btn-warning:not(.btn-light):focus, .btn-warning:not(.btn-light):active {
    color: #ffffff; }
  .btn-warning:not(.btn-light):focus, .btn-warning:not(.btn-light):active {
    background: #ffde73;
    border-color: #ffde73; }

.btn-outline-warning:hover, .btn-outline-warning:focus, .btn-outline-warning:active {
  background: #ffde73;
  color: #ffffff; }

.btn-inverse-danger {
  background-color: rgba(255, 77, 107, 0.2);
  background-image: none;
  border-color: rgba(255, 77, 107, 0); }
  .btn-inverse-danger:not(.btn-inverse-light) {
    color: #ff4d6b; }
  .btn-inverse-danger:hover {
    color: #ffffff;
    background-color: #ff4d6b;
    border-color: #ff4d6b; }
  .btn-inverse-danger.focus, .btn-inverse-danger:focus {
    -webkit-box-shadow: 0 0 0 3px rgba(255, 77, 107, 0.5);
    box-shadow: 0 0 0 3px rgba(255, 77, 107, 0.5); }
  .btn-inverse-danger.disabled, .btn-inverse-danger:disabled {
    color: #ff4d6b;
    background-color: transparent; }
  .btn-inverse-danger.active, .btn-inverse-danger:active,
  .show > .btn-inverse-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #ff4d6b;
    border-color: #ff4d6b; }

.btn-danger:not(.btn-light) {
  color: #ffffff; }
  .btn-danger:not(.btn-light):hover, .btn-danger:not(.btn-light):focus, .btn-danger:not(.btn-light):active {
    color: #ffffff; }
  .btn-danger:not(.btn-light):focus, .btn-danger:not(.btn-light):active {
    background: #ff4d6b;
    border-color: #ff4d6b; }

.btn-outline-danger:hover, .btn-outline-danger:focus, .btn-outline-danger:active {
  background: #ff4d6b;
  color: #ffffff; }

.btn-inverse-light {
  background-color: rgba(248, 249, 250, 0.2);
  background-image: none;
  border-color: rgba(248, 249, 250, 0); }
  .btn-inverse-light:not(.btn-inverse-light) {
    color: #f8f9fa; }
  .btn-inverse-light:hover {
    color: #ffffff;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-inverse-light.focus, .btn-inverse-light:focus {
    -webkit-box-shadow: 0 0 0 3px rgba(248, 249, 250, 0.5);
    box-shadow: 0 0 0 3px rgba(248, 249, 250, 0.5); }
  .btn-inverse-light.disabled, .btn-inverse-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-inverse-light.active, .btn-inverse-light:active,
  .show > .btn-inverse-light.dropdown-toggle {
    color: #ffffff;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }

.btn-light:not(.btn-light) {
  color: #ffffff; }
  .btn-light:not(.btn-light):hover, .btn-light:not(.btn-light):focus, .btn-light:not(.btn-light):active {
    color: #ffffff; }
  .btn-light:not(.btn-light):focus, .btn-light:not(.btn-light):active {
    background: #f8f9fa;
    border-color: #f8f9fa; }

.btn-outline-light:hover, .btn-outline-light:focus, .btn-outline-light:active {
  background: #f8f9fa;
  color: #ffffff; }

.btn-inverse-dark {
  background-color: rgba(62, 75, 91, 0.2);
  background-image: none;
  border-color: rgba(62, 75, 91, 0); }
  .btn-inverse-dark:not(.btn-inverse-light) {
    color: #3e4b5b; }
  .btn-inverse-dark:hover {
    color: #ffffff;
    background-color: #3e4b5b;
    border-color: #3e4b5b; }
  .btn-inverse-dark.focus, .btn-inverse-dark:focus {
    -webkit-box-shadow: 0 0 0 3px rgba(62, 75, 91, 0.5);
    box-shadow: 0 0 0 3px rgba(62, 75, 91, 0.5); }
  .btn-inverse-dark.disabled, .btn-inverse-dark:disabled {
    color: #3e4b5b;
    background-color: transparent; }
  .btn-inverse-dark.active, .btn-inverse-dark:active,
  .show > .btn-inverse-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #3e4b5b;
    border-color: #3e4b5b; }

.btn-dark:not(.btn-light) {
  color: #ffffff; }
  .btn-dark:not(.btn-light):hover, .btn-dark:not(.btn-light):focus, .btn-dark:not(.btn-light):active {
    color: #ffffff; }
  .btn-dark:not(.btn-light):focus, .btn-dark:not(.btn-light):active {
    background: #3e4b5b;
    border-color: #3e4b5b; }

.btn-outline-dark:hover, .btn-outline-dark:focus, .btn-outline-dark:active {
  background: #3e4b5b;
  color: #ffffff; }

/* Cards */
.card {
  border: 0; }
  .card .card-body {
    padding: 1.875rem 1.875rem; }
    .card .card-body + .card-body {
      padding-top: 1rem; }
  .card .card-title {
    color: #343a40;
    margin-bottom: .75rem;
    text-transform: capitalize;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 1.125rem; }
  .card .card-subtitle {
    font-family: "Open Sans", sans-serif;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem; }
  .card .card-description {
    color: #76838f;
    margin-bottom: 1.5rem;
    font-family: "Open Sans", sans-serif; }
  .card.card-outline-success {
    border: 1px solid #38ce3c; }
  .card.card-outline-primary {
    border: 1px solid #1bdbe0; }
  .card.card-outline-warning {
    border: 1px solid #ffde73; }
  .card.card-outline-danger {
    border: 1px solid #ff4d6b; }
  .card.card-rounded {
    border-radius: 5px; }
  .card.card-faded {
    background: #b5b0b2;
    border-color: #b5b0b2; }
  .card.card-circle-progress {
    color: #ffffff;
    text-align: center; }
  .card.card-img-holder {
    position: relative; }
    .card.card-img-holder .card-img-absolute {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%; }

.card-inverse-primary {
  background: rgba(27, 219, 224, 0.2);
  border: 1px solid #19c9ce;
  color: #15a6aa; }

.card-inverse-secondary {
  background: rgba(216, 216, 216, 0.2);
  border: 1px solid #c7c7c7;
  color: #a4a4a4; }

.card-inverse-success {
  background: rgba(56, 206, 60, 0.2);
  border: 1px solid #34be37;
  color: #2b9d2e; }

.card-inverse-info {
  background: rgba(142, 50, 233, 0.2);
  border: 1px solid #832ed6;
  color: #6c26b1; }

.card-inverse-warning {
  background: rgba(255, 222, 115, 0.2);
  border: 1px solid #ebcc6a;
  color: #c2a957; }

.card-inverse-danger {
  background: rgba(255, 77, 107, 0.2);
  border: 1px solid #eb4762;
  color: #c23b51; }

.card-inverse-light {
  background: rgba(248, 249, 250, 0.2);
  border: 1px solid #e4e5e6;
  color: #bcbdbe; }

.card-inverse-dark {
  background: rgba(62, 75, 91, 0.2);
  border: 1px solid #394554;
  color: #2f3945; }

/* Checkboxes and Radios */
.form-check {
  position: relative;
  display: block;
  margin-top: 15px;
  margin-bottom: 10px;
  padding-left: 0; }
  .form-check .form-check-label {
    display: block;
    margin-left: 1.75rem;
    font-size: 0.875rem;
    line-height: 1.5; }
    .rtl .form-check .form-check-label {
      margin-left: 0;
      margin-right: 1.75rem; }
    .form-check .form-check-label input {
      position: absolute;
      top: 0;
      left: 0;
      margin-left: 0;
      margin-top: 0;
      z-index: 1;
      cursor: pointer;
      opacity: 0;
      filter: alpha(opacity=0); }
      .rtl .form-check .form-check-label input {
        left: auto;
        right: 0; }
    .form-check .form-check-label input[type="checkbox"] + .input-helper:before, .form-check .form-check-label input[type="checkbox"] + .input-helper:after {
      position: absolute;
      top: 0;
      left: 0; }
      .rtl .form-check .form-check-label input[type="checkbox"] + .input-helper:before, .rtl .form-check .form-check-label input[type="checkbox"] + .input-helper:after {
        left: auto;
        right: 0; }
    .form-check .form-check-label input[type="checkbox"] + .input-helper:before {
      content: "";
      width: 18px;
      height: 18px;
      border-radius: 2px;
      border: solid #1bdbe0;
      border-width: 2px;
      -webkit-transition: all;
      transition: all;
      transition-duration: 0s;
      -webkit-transition-duration: 250ms;
      transition-duration: 250ms; }
    .form-check .form-check-label input[type="checkbox"] + .input-helper:after {
      -webkit-transition: all;
      transition: all;
      transition-duration: 0s;
      -webkit-transition-duration: 250ms;
      transition-duration: 250ms;
      opacity: 0;
      filter: alpha(opacity=0);
      content: "";
      height: 12px;
      width: 5px;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      border-style: solid;
      border-width: 2px;
      border-color: transparent #ffffff #ffffff transparent;
      left: 7px;
      top: 1px;
      font-weight: bold;
      color: #ffffff; }
    .form-check .form-check-label input[type="checkbox"]:checked + .input-helper:before {
      background: #1bdbe0;
      border-width: 0; }
    .form-check .form-check-label input[type="checkbox"]:checked + .input-helper:after {
      opacity: 1;
      line-height: 18px;
      filter: alpha(opacity=100); }
    .form-check .form-check-label input[type="checkbox"]:disabled + .input-helper:before {
      border-color: #e8ecf1; }
    .form-check .form-check-label input[type="checkbox"]:disabled:checked + .input-helper:after {
      border-color: transparent #e8ecf1 #e8ecf1 transparent; }
    .form-check .form-check-label input[type="radio"] + .input-helper:before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      border: solid #1bdbe0;
      border-width: 2px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      -webkit-transition: all;
      transition: all;
      transition-duration: 0s;
      -webkit-transition-duration: 250ms;
      transition-duration: 250ms; }
      .rtl .form-check .form-check-label input[type="radio"] + .input-helper:before {
        left: auto;
        right: 0; }
    .form-check .form-check-label input[type="radio"] + .input-helper:after {
      content: "";
      width: 8px;
      height: 8px;
      background: #ffffff;
      border-radius: 50%;
      top: 6px;
      left: 6px;
      -webkit-transition: all;
      transition: all;
      transition-duration: 0s;
      -webkit-transition-duration: 250ms;
      transition-duration: 250ms;
      opacity: 0;
      filter: alpha(opacity=0);
      -webkit-transform: scale(0);
      transform: scale(0);
      position: absolute; }
      .rtl .form-check .form-check-label input[type="radio"] + .input-helper:after {
        left: auto;
        right: 6px; }
    .form-check .form-check-label input[type="radio"]:checked + .input-helper:before {
      background: #1bdbe0;
      border-width: 0; }
    .form-check .form-check-label input[type="radio"]:checked + .input-helper:after {
      opacity: 1;
      line-height: 1.5;
      filter: alpha(opacity=100);
      -webkit-transform: scale(1);
      transform: scale(1); }
    .form-check .form-check-label input[type="radio"]:disabled + .input-helper:before {
      border-color: #e8ecf1; }
    .form-check .form-check-label input[type="radio"]:disabled:checked + .input-helper:before {
      background: #e8ecf1; }
    .form-check .form-check-label input[type="radio"]:disabled:checked + .input-helper:after {
      background: #ffffff; }

.form-check-primary.form-check label input[type="checkbox"] + .input-helper:before,
.form-check-primary.form-check label input[type="radio"] + .input-helper:before {
  border-color: #1bdbe0; }

.form-check-primary.form-check label input[type="checkbox"]:checked + .input-helper:before,
.form-check-primary.form-check label input[type="radio"]:checked + .input-helper:before {
  background: #1bdbe0; }

.form-check-secondary.form-check label input[type="checkbox"] + .input-helper:before,
.form-check-secondary.form-check label input[type="radio"] + .input-helper:before {
  border-color: #d8d8d8; }

.form-check-secondary.form-check label input[type="checkbox"]:checked + .input-helper:before,
.form-check-secondary.form-check label input[type="radio"]:checked + .input-helper:before {
  background: #d8d8d8; }

.form-check-success.form-check label input[type="checkbox"] + .input-helper:before,
.form-check-success.form-check label input[type="radio"] + .input-helper:before {
  border-color: #38ce3c; }

.form-check-success.form-check label input[type="checkbox"]:checked + .input-helper:before,
.form-check-success.form-check label input[type="radio"]:checked + .input-helper:before {
  background: #38ce3c; }

.form-check-info.form-check label input[type="checkbox"] + .input-helper:before,
.form-check-info.form-check label input[type="radio"] + .input-helper:before {
  border-color: #8e32e9; }

.form-check-info.form-check label input[type="checkbox"]:checked + .input-helper:before,
.form-check-info.form-check label input[type="radio"]:checked + .input-helper:before {
  background: #8e32e9; }

.form-check-warning.form-check label input[type="checkbox"] + .input-helper:before,
.form-check-warning.form-check label input[type="radio"] + .input-helper:before {
  border-color: #ffde73; }

.form-check-warning.form-check label input[type="checkbox"]:checked + .input-helper:before,
.form-check-warning.form-check label input[type="radio"]:checked + .input-helper:before {
  background: #ffde73; }

.form-check-danger.form-check label input[type="checkbox"] + .input-helper:before,
.form-check-danger.form-check label input[type="radio"] + .input-helper:before {
  border-color: #ff4d6b; }

.form-check-danger.form-check label input[type="checkbox"]:checked + .input-helper:before,
.form-check-danger.form-check label input[type="radio"]:checked + .input-helper:before {
  background: #ff4d6b; }

.form-check-light.form-check label input[type="checkbox"] + .input-helper:before,
.form-check-light.form-check label input[type="radio"] + .input-helper:before {
  border-color: #f8f9fa; }

.form-check-light.form-check label input[type="checkbox"]:checked + .input-helper:before,
.form-check-light.form-check label input[type="radio"]:checked + .input-helper:before {
  background: #f8f9fa; }

.form-check-dark.form-check label input[type="checkbox"] + .input-helper:before,
.form-check-dark.form-check label input[type="radio"] + .input-helper:before {
  border-color: #3e4b5b; }

.form-check-dark.form-check label input[type="checkbox"]:checked + .input-helper:before,
.form-check-dark.form-check label input[type="radio"]:checked + .input-helper:before {
  background: #3e4b5b; }

/* Dropdowns */
.dropdown .dropdown-toggle:after {
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 0;
  font-family: "simple-line-icons";
  content: "\e604";
  width: auto;
  height: auto;
  vertical-align: baseline;
  font-size: .75rem; }

.dropdown .dropdown-menu {
  margin-top: .75rem;
  font-size: 0.875rem;
  -webkit-box-shadow: 0px 1px 15px 1px rgba(230, 234, 236, 0.35);
  box-shadow: 0px 1px 15px 1px rgba(230, 234, 236, 0.35); }
  .dropdown .dropdown-menu .dropdown-item {
    font-size: 1rem;
    padding: .25rem 1.5rem; }
    .dropdown .dropdown-menu .dropdown-item:active {
      background: initial; }

/* Forms */
.form-group {
  margin-bottom: 1.5rem; }

.input-group-append,
.input-group-prepend {
  color: #c9c8c8;
  width: auto;
  border: none; }
  .input-group-append .input-group-text,
  .input-group-prepend .input-group-text {
    border-color: #e8ecf1;
    padding: 0.94rem 0.75rem;
    color: #c9c8c8; }

.form-control, .select2-container--default .select2-selection--single, .select2-container--default .select2-selection--single .select2-search__field, .typeahead,
.tt-query,
.tt-hint {
  border: 1px solid #e8ecf1;
  font-family: "Open Sans", sans-serif;
  font-size: 0.8125rem; }

select.form-control, .select2-container--default select.select2-selection--single, .select2-container--default .select2-selection--single select.select2-search__field, select.typeahead,
select.tt-query,
select.tt-hint {
  padding: .4375rem .75rem;
  border: 0;
  outline: 1px solid #e8ecf1;
  color: #c9c8c8; }
  select.form-control:focus, .select2-container--default select.select2-selection--single:focus, .select2-container--default .select2-selection--single select.select2-search__field:focus, select.typeahead:focus,
  select.tt-query:focus,
  select.tt-hint:focus {
    outline: 1px solid #e8ecf1; }
  select.form-control.border-primary, .select2-container--default select.border-primary.select2-selection--single, .select2-container--default .select2-selection--single select.border-primary.select2-search__field, select.border-primary.typeahead,
  select.border-primary.tt-query,
  select.border-primary.tt-hint {
    outline: 1px solid #1bdbe0; }
    select.form-control.border-primary:focus, .select2-container--default select.border-primary.select2-selection--single:focus, .select2-container--default .select2-selection--single select.border-primary.select2-search__field:focus, select.border-primary.typeahead:focus,
    select.border-primary.tt-query:focus,
    select.border-primary.tt-hint:focus {
      outline: 1px solid #1bdbe0; }
  select.form-control.border-secondary, select.form-control.loader-demo-box, .select2-container--default select.loader-demo-box.select2-selection--single, .select2-container--default .select2-selection--single select.loader-demo-box.select2-search__field, select.loader-demo-box.typeahead,
  select.loader-demo-box.tt-query,
  select.loader-demo-box.tt-hint, .select2-container--default select.border-secondary.select2-selection--single, .select2-container--default .select2-selection--single select.border-secondary.select2-search__field, select.border-secondary.typeahead,
  select.border-secondary.tt-query,
  select.border-secondary.tt-hint {
    outline: 1px solid #d8d8d8; }
    select.form-control.border-secondary:focus, select.form-control.loader-demo-box:focus, .select2-container--default select.loader-demo-box.select2-selection--single:focus, .select2-container--default .select2-selection--single select.loader-demo-box.select2-search__field:focus, select.loader-demo-box.typeahead:focus,
    select.loader-demo-box.tt-query:focus,
    select.loader-demo-box.tt-hint:focus, .select2-container--default select.border-secondary.select2-selection--single:focus, .select2-container--default .select2-selection--single select.border-secondary.select2-search__field:focus, select.border-secondary.typeahead:focus,
    select.border-secondary.tt-query:focus,
    select.border-secondary.tt-hint:focus {
      outline: 1px solid #d8d8d8; }
  select.form-control.border-success, .select2-container--default select.border-success.select2-selection--single, .select2-container--default .select2-selection--single select.border-success.select2-search__field, select.border-success.typeahead,
  select.border-success.tt-query,
  select.border-success.tt-hint {
    outline: 1px solid #38ce3c; }
    select.form-control.border-success:focus, .select2-container--default select.border-success.select2-selection--single:focus, .select2-container--default .select2-selection--single select.border-success.select2-search__field:focus, select.border-success.typeahead:focus,
    select.border-success.tt-query:focus,
    select.border-success.tt-hint:focus {
      outline: 1px solid #38ce3c; }
  select.form-control.border-info, .select2-container--default select.border-info.select2-selection--single, .select2-container--default .select2-selection--single select.border-info.select2-search__field, select.border-info.typeahead,
  select.border-info.tt-query,
  select.border-info.tt-hint {
    outline: 1px solid #8e32e9; }
    select.form-control.border-info:focus, .select2-container--default select.border-info.select2-selection--single:focus, .select2-container--default .select2-selection--single select.border-info.select2-search__field:focus, select.border-info.typeahead:focus,
    select.border-info.tt-query:focus,
    select.border-info.tt-hint:focus {
      outline: 1px solid #8e32e9; }
  select.form-control.border-warning, .select2-container--default select.border-warning.select2-selection--single, .select2-container--default .select2-selection--single select.border-warning.select2-search__field, select.border-warning.typeahead,
  select.border-warning.tt-query,
  select.border-warning.tt-hint {
    outline: 1px solid #ffde73; }
    select.form-control.border-warning:focus, .select2-container--default select.border-warning.select2-selection--single:focus, .select2-container--default .select2-selection--single select.border-warning.select2-search__field:focus, select.border-warning.typeahead:focus,
    select.border-warning.tt-query:focus,
    select.border-warning.tt-hint:focus {
      outline: 1px solid #ffde73; }
  select.form-control.border-danger, .select2-container--default select.border-danger.select2-selection--single, .select2-container--default .select2-selection--single select.border-danger.select2-search__field, select.border-danger.typeahead,
  select.border-danger.tt-query,
  select.border-danger.tt-hint {
    outline: 1px solid #ff4d6b; }
    select.form-control.border-danger:focus, .select2-container--default select.border-danger.select2-selection--single:focus, .select2-container--default .select2-selection--single select.border-danger.select2-search__field:focus, select.border-danger.typeahead:focus,
    select.border-danger.tt-query:focus,
    select.border-danger.tt-hint:focus {
      outline: 1px solid #ff4d6b; }
  select.form-control.border-light, .select2-container--default select.border-light.select2-selection--single, .select2-container--default .select2-selection--single select.border-light.select2-search__field, select.border-light.typeahead,
  select.border-light.tt-query,
  select.border-light.tt-hint {
    outline: 1px solid #f8f9fa; }
    select.form-control.border-light:focus, .select2-container--default select.border-light.select2-selection--single:focus, .select2-container--default .select2-selection--single select.border-light.select2-search__field:focus, select.border-light.typeahead:focus,
    select.border-light.tt-query:focus,
    select.border-light.tt-hint:focus {
      outline: 1px solid #f8f9fa; }
  select.form-control.border-dark, .select2-container--default select.border-dark.select2-selection--single, .select2-container--default .select2-selection--single select.border-dark.select2-search__field, select.border-dark.typeahead,
  select.border-dark.tt-query,
  select.border-dark.tt-hint {
    outline: 1px solid #3e4b5b; }
    select.form-control.border-dark:focus, .select2-container--default select.border-dark.select2-selection--single:focus, .select2-container--default .select2-selection--single select.border-dark.select2-search__field:focus, select.border-dark.typeahead:focus,
    select.border-dark.tt-query:focus,
    select.border-dark.tt-hint:focus {
      outline: 1px solid #3e4b5b; }

.form-group label {
  font-size: 0.875rem;
  line-height: 1;
  vertical-align: top;
  margin-bottom: .5rem; }

.form-group.has-danger .form-control, .form-group.has-danger .select2-container--default .select2-selection--single, .select2-container--default .form-group.has-danger .select2-selection--single, .form-group.has-danger .select2-container--default .select2-selection--single .select2-search__field, .select2-container--default .select2-selection--single .form-group.has-danger .select2-search__field, .form-group.has-danger .typeahead,
.form-group.has-danger .tt-query,
.form-group.has-danger .tt-hint {
  border-color: #ff4d6b; }

.form-group .file-upload-default {
  visibility: hidden;
  position: absolute; }

.form-group .file-upload-info {
  background: transparent; }

/* Icons */
.icons-list {
  border-left: 1px solid #e8ecf1;
  border-top: 1px solid #e8ecf1; }
  .icons-list > div {
    background: #fff;
    border-bottom: 1px solid #e8ecf1;
    border-right: 1px solid #e8ecf1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 15px 15px;
    font-family: "Open Sans", sans-serif;
    font-size: 0.875rem; }
    .icons-list > div i {
      display: inline-block;
      font-size: 20px;
      width: 40px;
      text-align: left;
      color: #1bdbe0; }

/* Lists */
ul,
ol,
dl {
  padding-left: 1rem;
  font-size: 0.875rem; }
  ul li,
  ol li,
  dl li {
    line-height: 1.8; }

.list-ticked,
.list-arrow,
.list-star {
  list-style: none;
  padding: 0; }
  .list-ticked li,
  .list-arrow li,
  .list-star li {
    padding-left: 1.5rem; }
    .list-ticked li:before,
    .list-arrow li:before,
    .list-star li:before {
      font-family: "simple-line-icons";
      margin-left: -1.5rem;
      width: 1.5rem;
      margin-right: .5rem; }

.list-ticked li:before {
  content: '\e080';
  color: #ff4d6b; }

.list-arrow li:before {
  content: '\e606';
  color: #38ce3c; }

.list-star li:before {
  content: '\e09b';
  color: #ffde73; }

.gradient-bullet-list {
  padding-left: 0; }
  .rtl .gradient-bullet-list {
    padding-right: 0; }
  .gradient-bullet-list li {
    position: relative;
    list-style-type: none;
    padding-left: 25px;
    line-height: 1;
    padding-bottom: 25px; }
    .gradient-bullet-list li:before, .gradient-bullet-list li:after {
      content: "";
      position: absolute; }
    .gradient-bullet-list li:before {
      top: 0;
      left: 0;
      width: 15px;
      height: 15px;
      border-radius: 100%; }
    .gradient-bullet-list li:after {
      width: 11px;
      height: 11px;
      top: 2px;
      left: 2px;
      background: #fff;
      border-radius: 100%; }
    .gradient-bullet-list li:nth-child(1):before {
      background: #ff4d6b; }
    .gradient-bullet-list li:nth-child(2):before {
      background: #8e32e9; }
    .gradient-bullet-list li:nth-child(3):before {
      background: #ffde73; }
    .gradient-bullet-list li:nth-child(4):before {
      background: #38ce3c; }
    .gradient-bullet-list li:nth-child(5):before {
      background: #1bdbe0; }

/* Modals */
.modal .modal-dialog {
  margin-top: 100px; }
  .modal .modal-dialog .modal-content .modal-header {
    padding: 25px 26px; }
    .modal .modal-dialog .modal-content .modal-header .close span {
      font-size: 20px;
      font-weight: 400;
      color: #6a6a6a; }
  .modal .modal-dialog .modal-content .modal-body {
    padding: 35px 26px; }
  .modal .modal-dialog .modal-content .modal-footer {
    padding: 15px 31px; }

/* Pagination */
.pagination .page-item .page-link {
  border-color: #e8ecf1;
  color: #343a40;
  font-size: .875rem;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s; }
  .pagination .page-item .page-link:focus {
    background: inherit; }
  .pagination .page-item .page-link i:before {
    font-size: inherit;
    line-height: 1;
    vertical-align: middle; }

.pagination .page-item.active .page-link, .pagination .page-item:hover .page-link, .pagination .page-item:focus .page-link, .pagination .page-item:active .page-link {
  background: #1bdbe0;
  border-color: #1bdbe0;
  color: #ffffff; }

.pagination.flat .page-item .page-link {
  border: none;
  border-radius: 2px; }

.pagination.separated .page-item {
  margin-left: 2px;
  margin-right: 2px; }
  .pagination.separated .page-item:first-child {
    margin-left: 0; }
  .pagination.separated .page-item:last-child {
    margin-right: 0; }
  .pagination.separated .page-item .page-link {
    border-radius: 2px; }

.pagination.rounded .page-item:first-child .page-link, .pagination.loader-demo-box .page-item:first-child .page-link {
  border-radius: 25px 0 0 25px; }

.pagination.rounded .page-item:last-child .page-link, .pagination.loader-demo-box .page-item:last-child .page-link {
  border-radius: 0 25px 25px 0; }

.pagination.rounded-flat .page-item {
  margin-right: 3px;
  margin-left: 3px; }
  .pagination.rounded-flat .page-item .page-link {
    border: none;
    border-radius: 50px; }

.pagination.rounded-separated .page-item {
  margin-left: 2px;
  margin-right: 2px; }
  .pagination.rounded-separated .page-item:first-child {
    margin-left: 0; }
    .pagination.rounded-separated .page-item:first-child .page-link {
      border-radius: 10px 0 0 10px; }
  .pagination.rounded-separated .page-item:last-child {
    margin-right: 0; }
    .pagination.rounded-separated .page-item:last-child .page-link {
      border-radius: 0 10px 10px 0; }
  .pagination.rounded-separated .page-item .page-link {
    border-radius: 2px; }

/* pagination variations */
.pagination-primary .page-item.active .page-link {
  background: #1bdbe0;
  border-color: #1bdbe0; }

.pagination-primary .page-item .page-link:hover {
  background: #2fe1e6;
  border-color: #1bdbe0;
  color: #ffffff; }

.pagination-secondary .page-item.active .page-link {
  background: #d8d8d8;
  border-color: #d8d8d8; }

.pagination-secondary .page-item .page-link:hover {
  background: #e5e5e5;
  border-color: #d8d8d8;
  color: #ffffff; }

.pagination-success .page-item.active .page-link {
  background: #38ce3c;
  border-color: #38ce3c; }

.pagination-success .page-item .page-link:hover {
  background: #4cd350;
  border-color: #38ce3c;
  color: #ffffff; }

.pagination-info .page-item.active .page-link {
  background: #8e32e9;
  border-color: #8e32e9; }

.pagination-info .page-item .page-link:hover {
  background: #9b49eb;
  border-color: #8e32e9;
  color: #ffffff; }

.pagination-warning .page-item.active .page-link {
  background: #ffde73;
  border-color: #ffde73; }

.pagination-warning .page-item .page-link:hover {
  background: #ffe48d;
  border-color: #ffde73;
  color: #ffffff; }

.pagination-danger .page-item.active .page-link {
  background: #ff4d6b;
  border-color: #ff4d6b; }

.pagination-danger .page-item .page-link:hover {
  background: #ff6780;
  border-color: #ff4d6b;
  color: #ffffff; }

.pagination-light .page-item.active .page-link {
  background: #f8f9fa;
  border-color: #f8f9fa; }

.pagination-light .page-item .page-link:hover {
  background: white;
  border-color: #f8f9fa;
  color: #ffffff; }

.pagination-dark .page-item.active .page-link {
  background: #3e4b5b;
  border-color: #3e4b5b; }

.pagination-dark .page-item .page-link:hover {
  background: #48586a;
  border-color: #3e4b5b;
  color: #ffffff; }

/* Tables */
.table {
  margin-bottom: 0; }
  .table thead th {
    border-top: 0;
    border-bottom-width: 1px;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-weight: initial; }
    .table thead th i {
      margin-left: 0.325rem; }
  .table th,
  .table td {
    vertical-align: middle;
    font-size: 0.875rem;
    line-height: 1;
    white-space: nowrap; }
    .table th img,
    .table td img {
      width: 36px;
      height: 36px;
      border-radius: 100%; }
    .table th .badge,
    .table td .badge {
      margin-bottom: 0; }
  .table.table-borderless {
    border: none; }
    .table.table-borderless tr,
    .table.table-borderless td,
    .table.table-borderless th {
      border: none; }

/* Preview */
.preview-list .preview-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: .75rem 1.5rem;
  font-size: .875rem; }
  .preview-list .preview-item:last-child {
    border-bottom: 0; }
  .preview-list .preview-item:hover {
    background: #f8f9fa; }
  .preview-list .preview-item .form-check {
    margin-top: 8px;
    margin-right: 1rem; }
  .preview-list .preview-item .preview-thumbnail {
    color: #ffffff;
    position: relative; }
    .preview-list .preview-item .preview-thumbnail img,
    .preview-list .preview-item .preview-thumbnail .preview-icon {
      width: 36px;
      height: 36px;
      border-radius: 100%; }
    .preview-list .preview-item .preview-thumbnail .preview-icon {
      padding: 6px;
      text-align: center;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center; }
      .preview-list .preview-item .preview-thumbnail .preview-icon i {
        font-size: 1.125rem;
        margin: 0; }
    .preview-list .preview-item .preview-thumbnail .badge {
      border: 2px solid #ffffff;
      border-radius: 100%;
      bottom: 5px;
      display: block;
      height: 14px;
      left: -5px;
      padding: 0;
      position: absolute;
      width: 14px; }
  .preview-list .preview-item .preview-item-content {
    line-height: 1;
    padding-left: 15px; }
    .preview-list .preview-item .preview-item-content:first-child {
      padding-left: 0; }
    .preview-list .preview-item .preview-item-content p {
      margin-bottom: 10px; }
      .preview-list .preview-item .preview-item-content p .content-category {
        font-family: 'source-sans-pro-semibold', sans-serif;
        padding-right: 15px;
        border-right: 1px solid #e8ecf1; }
    .rtl .preview-list .preview-item .preview-item-content {
      padding-left: 0;
      padding-right: 1rem;
      margin-right: 0;
      margin-left: auto; }
  .preview-list .preview-item .preview-actions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
    .preview-list .preview-item .preview-actions i {
      width: 29px;
      color: #e6e9ed;
      height: 29px;
      border: 2px solid #e6e9ed;
      border-radius: 100%;
      padding: 3px 6px;
      display: inline-block; }
      .preview-list .preview-item .preview-actions i:first-child {
        margin-right: 10px; }

.preview-list.comment-preview .preview-item {
  padding: .87rem 0; }
  .preview-list.comment-preview .preview-item:first-child {
    padding-top: 0; }
  .preview-list.comment-preview .preview-item p {
    line-height: 27px; }

.preview-list.bordered .preview-item {
  border-bottom: 1px solid #e8ecf1; }
  .preview-list.bordered .preview-item:last-child {
    border-bottom: 0; }

/*-------------------------------------------------------------------*/
/* === Plugin overrides === */
/* Chartist */
.ct-series-a .ct-line {
  stroke: #a29afc;
  stroke-width: 3px;
  stroke-dasharray: 10px 20px; }

.ct-series-a .ct-point {
  stroke: #6355f9;
  stroke-width: 10px;
  stroke-linecap: round; }

.ct-series-b .ct-line {
  stroke: #80d691;
  stroke-width: 3px;
  stroke-dasharray: 10px 20px; }

.ct-series-b .ct-point {
  stroke: #4ac462;
  stroke-width: 10px;
  stroke-linecap: round; }

.ct-series-c .ct-line {
  stroke: #f7cb9b;
  stroke-width: 3px;
  stroke-dasharray: 10px 20px; }

.ct-series-c .ct-point {
  stroke: #f2a859;
  stroke-width: 10px;
  stroke-linecap: round; }

.ct-series-a .ct-bar {
  stroke: #a29afc;
  stroke-width: 20px;
  stroke-dasharray: 0;
  stroke-linecap: squre; }

.ct-series-b .ct-bar {
  stroke: #80d691;
  stroke-width: 20px;
  stroke-dasharray: 0;
  stroke-linecap: squre; }

.ct-series-c .ct-bar {
  stroke: #f7cb9b;
  stroke-width: 20px;
  stroke-dasharray: 0;
  stroke-linecap: squre; }

.ct-series-d .ct-bar {
  stroke: #fcb2b2;
  stroke-width: 20px;
  stroke-dasharray: 0;
  stroke-linecap: squre; }

.ct-series-a .ct-slice-pie {
  fill: #a29afc;
  stroke-width: 4px; }

.ct-series-b .ct-slice-pie {
  fill: #80d691;
  stroke-width: 4px; }

.ct-series-c .ct-slice-pie {
  fill: #fcb2b2;
  stroke-width: 4px; }

.ct-series-d .ct-slice-pie {
  fill: #f7cb9b;
  stroke-width: 4px; }

.ct-series-a .ct-slice-donut-solid {
  fill: #a29afc; }

.ct-series-b .ct-slice-donut-solid {
  fill: #80d691; }

.ct-series-c .ct-slice-donut-solid {
  fill: #fcb2b2; }

.ct-series-a .ct-slice-donut-solid {
  fill: #f7cb9b; }

#ct-chart-dash-barChart .ct-series-a .ct-bar {
  stroke: #fb9999;
  stroke-width: 20px;
  stroke-dasharray: 0;
  stroke-linecap: squre; }

#ct-chart-dash-barChart .ct-series-b .ct-bar {
  stroke: #8b81fb;
  stroke-width: 20px;
  stroke-dasharray: 0;
  stroke-linecap: squre; }

#ct-chart-dash-barChart .ct-series-c .ct-bar {
  stroke: #a29afc;
  stroke-width: 20px;
  stroke-dasharray: 0;
  stroke-linecap: squre; }

#ct-chart-dash-barChart .ct-series-d .ct-bar {
  stroke: #b9b3fc;
  stroke-width: 20px;
  stroke-dasharray: 0;
  stroke-linecap: squre; }

/* Select2 */
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background: #1bdbe0; }

.select2-container--default .select2-selection--single,
.select2-container--default .select2-dropdown,
.select2-container--default .select2-selection--multiple {
  border-color: #e8ecf1; }
  .select2-container--default .select2-selection--single .select2-search__field,
  .select2-container--default .select2-dropdown .select2-search__field,
  .select2-container--default .select2-selection--multiple .select2-search__field {
    border-color: #e8ecf1; }

.select2-container--default .select2-selection--single {
  height: auto; }
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 14px; }

.select2-container--default .select2-dropdown {
  font-size: .8125rem; }

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #e8ecf1; }

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  color: #ffffff;
  border: 0;
  border-radius: 3px;
  padding: 6px;
  font-size: .625rem;
  font-family: inherit;
  line-height: 1; }
  .select2-container--default .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove {
    color: #ffffff; }
  .select2-container--default .select2-selection--multiple .select2-selection__choice:nth-child(5n+1) {
    background: #1bdbe0; }
  .select2-container--default .select2-selection--multiple .select2-selection__choice:nth-child(5n+2) {
    background: #38ce3c; }
  .select2-container--default .select2-selection--multiple .select2-selection__choice:nth-child(5n+3) {
    background: #8e32e9; }
  .select2-container--default .select2-selection--multiple .select2-selection__choice:nth-child(5n+4) {
    background: #ff4d6b; }
  .select2-container--default .select2-selection--multiple .select2-selection__choice:nth-child(5n+5) {
    background: #ffde73; }

/* Typeahead */
.tt-menu,
.gist {
  text-align: left; }

.twitter-typeahead {
  max-width: 100%; }

.typeahead {
  background-color: #ffffff; }
  .typeahead:focus {
    border-color: #e8ecf1; }

.tt-query {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }

.tt-hint {
  color: #434a54; }

.tt-menu {
  width: 100%;
  margin: 12px 0;
  padding: 8px 0;
  background-color: #ffffff;
  border: 1px solid #e8ecf1;
  border-radius: 8px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2); }

.tt-suggestion {
  padding: 3px 20px;
  font-size: inherit; }
  .tt-suggestion:hover {
    cursor: pointer;
    color: #ffffff;
    background-color: #1bdbe0; }
  .tt-suggestion .tt-cursor {
    color: #ffffff;
    background-color: #1bdbe0; }
  .tt-suggestion p {
    margin: 0; }

/*-------------------------------------------------------------------*/
/* === Landing screens === */
/* Auth */
.auth .login-half-bg {
  background: url("../images/loginBg.jpg");
  background-size: cover; }

.auth .register-half-bg {
  background: url("../images/auth/register-bg.jpg");
  background-size: cover; }

.auth.lock-full-bg {
  background: url("../images/auth/lockscreen-bg.jpg");
  background-size: cover; }

.auth .lock-profile-img {
  width: 90px;
  height: 90px;
  border-radius: 100%; }

.auth .auth-form-dark {
  background: rgba(0, 0, 0, 0.6);
  color: #ffffff; }
  .auth .auth-form-dark .form-control, .auth .auth-form-dark .select2-container--default .select2-selection--single, .select2-container--default .auth .auth-form-dark .select2-selection--single, .auth .auth-form-dark .select2-container--default .select2-selection--single .select2-search__field, .select2-container--default .select2-selection--single .auth .auth-form-dark .select2-search__field, .auth .auth-form-dark .typeahead,
  .auth .auth-form-dark .tt-query,
  .auth .auth-form-dark .tt-hint {
    border-color: rgba(255, 255, 255, 0.2);
    color: #ffffff; }
    .auth .auth-form-dark .form-control:-ms-input-placeholder, .auth .auth-form-dark .select2-container--default .select2-selection--single:-ms-input-placeholder, .select2-container--default .auth .auth-form-dark .select2-selection--single:-ms-input-placeholder, .auth .auth-form-dark .select2-container--default .select2-selection--single .select2-search__field:-ms-input-placeholder, .select2-container--default .select2-selection--single .auth .auth-form-dark .select2-search__field:-ms-input-placeholder, .auth .auth-form-dark .typeahead:-ms-input-placeholder,
    .auth .auth-form-dark .tt-query:-ms-input-placeholder,
    .auth .auth-form-dark .tt-hint:-ms-input-placeholder {
      color: #ffffff; }
    .auth .auth-form-dark .form-control:-moz-placeholder, .auth .auth-form-dark .select2-container--default .select2-selection--single:-moz-placeholder, .select2-container--default .auth .auth-form-dark .select2-selection--single:-moz-placeholder, .auth .auth-form-dark .select2-container--default .select2-selection--single .select2-search__field:-moz-placeholder, .select2-container--default .select2-selection--single .auth .auth-form-dark .select2-search__field:-moz-placeholder, .auth .auth-form-dark .typeahead:-moz-placeholder,
    .auth .auth-form-dark .tt-query:-moz-placeholder,
    .auth .auth-form-dark .tt-hint:-moz-placeholder {
      color: #ffffff; }
    .auth .auth-form-dark .form-control::-moz-placeholder, .auth .auth-form-dark .select2-container--default .select2-selection--single::-moz-placeholder, .select2-container--default .auth .auth-form-dark .select2-selection--single::-moz-placeholder, .auth .auth-form-dark .select2-container--default .select2-selection--single .select2-search__field::-moz-placeholder, .select2-container--default .select2-selection--single .auth .auth-form-dark .select2-search__field::-moz-placeholder, .auth .auth-form-dark .typeahead::-moz-placeholder,
    .auth .auth-form-dark .tt-query::-moz-placeholder,
    .auth .auth-form-dark .tt-hint::-moz-placeholder {
      color: #ffffff; }
    .auth .auth-form-dark .form-control::-webkit-input-placeholder, .auth .auth-form-dark .select2-container--default .select2-selection--single::-webkit-input-placeholder, .select2-container--default .auth .auth-form-dark .select2-selection--single::-webkit-input-placeholder, .auth .auth-form-dark .select2-container--default .select2-selection--single .select2-search__field::-webkit-input-placeholder, .select2-container--default .select2-selection--single .auth .auth-form-dark .select2-search__field::-webkit-input-placeholder, .auth .auth-form-dark .typeahead::-webkit-input-placeholder,
    .auth .auth-form-dark .tt-query::-webkit-input-placeholder,
    .auth .auth-form-dark .tt-hint::-webkit-input-placeholder {
      color: #ffffff; }

.auth .auth-form-light {
  background: #ffffff; }
  .auth .auth-form-light select {
    color: #c9c8c8; }
  .auth .auth-form-light .input-group .form-control:focus, .auth .auth-form-light .input-group .select2-container--default .select2-selection--single:focus, .select2-container--default .auth .auth-form-light .input-group .select2-selection--single:focus, .auth .auth-form-light .input-group .select2-container--default .select2-selection--single .select2-search__field:focus, .select2-container--default .select2-selection--single .auth .auth-form-light .input-group .select2-search__field:focus, .auth .auth-form-light .input-group .typeahead:focus,
  .auth .auth-form-light .input-group .tt-query:focus,
  .auth .auth-form-light .input-group .tt-hint:focus, .auth .auth-form-light .input-group .form-control:active, .auth .auth-form-light .input-group .select2-container--default .select2-selection--single:active, .select2-container--default .auth .auth-form-light .input-group .select2-selection--single:active, .auth .auth-form-light .input-group .select2-container--default .select2-selection--single .select2-search__field:active, .select2-container--default .select2-selection--single .auth .auth-form-light .input-group .select2-search__field:active, .auth .auth-form-light .input-group .typeahead:active,
  .auth .auth-form-light .input-group .tt-query:active,
  .auth .auth-form-light .input-group .tt-hint:active {
    border-color: #e8ecf1; }

.auth .auth-form-transparent {
  background: transparent; }
  .auth .auth-form-transparent .form-control, .auth .auth-form-transparent .select2-container--default .select2-selection--single, .select2-container--default .auth .auth-form-transparent .select2-selection--single, .auth .auth-form-transparent .select2-container--default .select2-selection--single .select2-search__field, .select2-container--default .select2-selection--single .auth .auth-form-transparent .select2-search__field, .auth .auth-form-transparent .typeahead,
  .auth .auth-form-transparent .tt-query,
  .auth .auth-form-transparent .tt-hint,
  .auth .auth-form-transparent .input-group-text {
    border-color: #d8d8d8; }
    .auth .auth-form-transparent .form-control:focus, .auth .auth-form-transparent .select2-container--default .select2-selection--single:focus, .select2-container--default .auth .auth-form-transparent .select2-selection--single:focus, .auth .auth-form-transparent .select2-container--default .select2-selection--single .select2-search__field:focus, .select2-container--default .select2-selection--single .auth .auth-form-transparent .select2-search__field:focus, .auth .auth-form-transparent .typeahead:focus,
    .auth .auth-form-transparent .tt-query:focus,
    .auth .auth-form-transparent .tt-hint:focus, .auth .auth-form-transparent .form-control:active, .auth .auth-form-transparent .select2-container--default .select2-selection--single:active, .select2-container--default .auth .auth-form-transparent .select2-selection--single:active, .auth .auth-form-transparent .select2-container--default .select2-selection--single .select2-search__field:active, .select2-container--default .select2-selection--single .auth .auth-form-transparent .select2-search__field:active, .auth .auth-form-transparent .typeahead:active,
    .auth .auth-form-transparent .tt-query:active,
    .auth .auth-form-transparent .tt-hint:active,
    .auth .auth-form-transparent .input-group-text:focus,
    .auth .auth-form-transparent .input-group-text:active {
      border-color: #d8d8d8; }
  .auth .auth-form-transparent select {
    outline-color: #d8d8d8; }

.auth.auth-img-bg {
  padding: 0; }
  @media (min-width: 768px) {
    .auth.auth-img-bg .auth-form-transparent {
      width: 55%;
      margin: auto; } }

.auth .brand-logo {
  margin-bottom: 2rem; }
  .auth .brand-logo img {
    width: 150px; }

.auth form .form-group {
  margin-bottom: 1.5rem; }
  .auth form .form-group label {
    font-size: .8125rem; }
  .auth form .form-group .form-control, .auth form .form-group .select2-container--default .select2-selection--single, .select2-container--default .auth form .form-group .select2-selection--single, .auth form .form-group .select2-container--default .select2-selection--single .select2-search__field, .select2-container--default .select2-selection--single .auth form .form-group .select2-search__field, .auth form .form-group .typeahead,
  .auth form .form-group .tt-query,
  .auth form .form-group .tt-hint {
    background: transparent;
    border-radius: 0;
    font-size: .9375rem; }

.auth form .auth-form-btn {
  height: 50px;
  line-height: 1.5; }

.auth form .auth-link {
  font-size: 0.875rem; }
  .auth form .auth-link:hover {
    color: initial; }
    
    @import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap");
    
    @-webkit-keyframes flash {
      0% {
        opacity: 1; }
      25% {
        opacity: 0; }
      50% {
        opacity: 1; }
      75% {
        opacity: 0; }
      100% {
        opacity: 1; } }
    
    @keyframes flash {
      0% {
        opacity: 1; }
      25% {
        opacity: 0; }
      50% {
        opacity: 1; }
      75% {
        opacity: 0; }
      100% {
        opacity: 1; } }
    
    @-webkit-keyframes bounce {
      0% {
        -webkit-transform: translateY(0);
        transform: translateY(0); }
      20% {
        -webkit-transform: translateY(0);
        transform: translateY(0); }
      40% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px); }
      50% {
        -webkit-transform: translateY(0);
        transform: translateY(0); }
      60% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px); }
      80% {
        -webkit-transform: translateY(0);
        transform: translateY(0); }
      100% {
        -webkit-transform: translateY(0);
        transform: translateY(0); } }
    
    @keyframes bounce {
      0% {
        -webkit-transform: translateY(0);
        transform: translateY(0); }
      20% {
        -webkit-transform: translateY(0);
        transform: translateY(0); }
      40% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px); }
      50% {
        -webkit-transform: translateY(0);
        transform: translateY(0); }
      60% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px); }
      80% {
        -webkit-transform: translateY(0);
        transform: translateY(0); }
      100% {
        -webkit-transform: translateY(0);
        transform: translateY(0); } }
    
    @-webkit-keyframes shake {
      0% {
        -webkit-transform: translateX(0);
        transform: translateX(0); }
      10% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px); }
      20% {
        -webkit-transform: translateX(10px);
        transform: translateX(10px); }
      30% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px); }
      40% {
        -webkit-transform: translateX(10px);
        transform: translateX(10px); }
      50% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px); }
      60% {
        -webkit-transform: translateX(10px);
        transform: translateX(10px); }
      70% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px); }
      80% {
        -webkit-transform: translateX(10px);
        transform: translateX(10px); }
      90% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px); }
      100% {
        -webkit-transform: translateX(0);
        transform: translateX(0); } }
    
    @keyframes shake {
      0% {
        -webkit-transform: translateX(0);
        transform: translateX(0); }
      10% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px); }
      20% {
        -webkit-transform: translateX(10px);
        transform: translateX(10px); }
      30% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px); }
      40% {
        -webkit-transform: translateX(10px);
        transform: translateX(10px); }
      50% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px); }
      60% {
        -webkit-transform: translateX(10px);
        transform: translateX(10px); }
      70% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px); }
      80% {
        -webkit-transform: translateX(10px);
        transform: translateX(10px); }
      90% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px); }
      100% {
        -webkit-transform: translateX(0);
        transform: translateX(0); } }
    
    @-webkit-keyframes tada {
      0% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1); }
      10% {
        -webkit-transform: scale(0.9) rotate(-3deg);
        transform: scale(0.9) rotate(-3deg); }
      20% {
        -webkit-transform: scale(0.9) rotate(-3deg);
        transform: scale(0.9) rotate(-3deg); }
      30% {
        -webkit-transform: scale(1.1) rotate(3deg);
        transform: scale(1.1) rotate(3deg); }
      40% {
        -webkit-transform: scale(1.1) rotate(-3deg);
        transform: scale(1.1) rotate(-3deg); }
      50% {
        -webkit-transform: scale(1.1) rotate(3deg);
        transform: scale(1.1) rotate(3deg); }
      60% {
        -webkit-transform: scale(1.1) rotate(-3deg);
        transform: scale(1.1) rotate(-3deg); }
      70% {
        -webkit-transform: scale(1.1) rotate(3deg);
        transform: scale(1.1) rotate(3deg); }
      80% {
        -webkit-transform: scale(1.1) rotate(-3deg);
        transform: scale(1.1) rotate(-3deg); }
      90% {
        -webkit-transform: scale(1.1) rotate(3deg);
        transform: scale(1.1) rotate(3deg); }
      100% {
        -webkit-transform: scale(1) rotate(0);
        transform: scale(1) rotate(0); } }
    
    @keyframes tada {
      0% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1); }
      10% {
        -webkit-transform: scale(0.9) rotate(-3deg);
        transform: scale(0.9) rotate(-3deg); }
      20% {
        -webkit-transform: scale(0.9) rotate(-3deg);
        transform: scale(0.9) rotate(-3deg); }
      30% {
        -webkit-transform: scale(1.1) rotate(3deg);
        transform: scale(1.1) rotate(3deg); }
      40% {
        -webkit-transform: scale(1.1) rotate(-3deg);
        transform: scale(1.1) rotate(-3deg); }
      50% {
        -webkit-transform: scale(1.1) rotate(3deg);
        transform: scale(1.1) rotate(3deg); }
      60% {
        -webkit-transform: scale(1.1) rotate(-3deg);
        transform: scale(1.1) rotate(-3deg); }
      70% {
        -webkit-transform: scale(1.1) rotate(3deg);
        transform: scale(1.1) rotate(3deg); }
      80% {
        -webkit-transform: scale(1.1) rotate(-3deg);
        transform: scale(1.1) rotate(-3deg); }
      90% {
        -webkit-transform: scale(1.1) rotate(3deg);
        transform: scale(1.1) rotate(3deg); }
      100% {
        -webkit-transform: scale(1) rotate(0);
        transform: scale(1) rotate(0); } }
    
    @-webkit-keyframes swing {
      20%, 40%, 60%, 80%, 100% {
        -webkit-transform-origin: top center 50%;
        transform-origin: top center 50%; }
      20% {
        -webkit-transform: rotate(15deg);
        transform: rotate(15deg); }
      40% {
        -webkit-transform: rotate(-10deg);
        transform: rotate(-10deg); }
      60% {
        -webkit-transform: rotate(5deg);
        transform: rotate(5deg); }
      80% {
        -webkit-transform: rotate(-5deg);
        transform: rotate(-5deg); }
      100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg); } }
    
    @keyframes swing {
      20%, 40%, 60%, 80%, 100% {
        -webkit-transform-origin: top center 50%;
        transform-origin: top center 50%; }
      20% {
        -webkit-transform: rotate(15deg);
        transform: rotate(15deg); }
      40% {
        -webkit-transform: rotate(-10deg);
        transform: rotate(-10deg); }
      60% {
        -webkit-transform: rotate(5deg);
        transform: rotate(5deg); }
      80% {
        -webkit-transform: rotate(-5deg);
        transform: rotate(-5deg); }
      100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg); } }
    
    @-webkit-keyframes wobble {
      0% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%); }
      15% {
        -webkit-transform: translateX(-25%) rotate(-5deg);
        transform: translateX(-25%) rotate(-5deg); }
      30% {
        -webkit-transform: translateX(20%) rotate(3deg);
        transform: translateX(20%) rotate(3deg); }
      45% {
        -webkit-transform: translateX(-15%) rotate(-3deg);
        transform: translateX(-15%) rotate(-3deg); }
      60% {
        -webkit-transform: translateX(10%) rotate(2deg);
        transform: translateX(10%) rotate(2deg); }
      75% {
        -webkit-transform: translateX(-5%) rotate(-1deg);
        transform: translateX(-5%) rotate(-1deg); }
      100% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%); } }
    
    @keyframes wobble {
      0% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%); }
      15% {
        -webkit-transform: translateX(-25%) rotate(-5deg);
        transform: translateX(-25%) rotate(-5deg); }
      30% {
        -webkit-transform: translateX(20%) rotate(3deg);
        transform: translateX(20%) rotate(3deg); }
      45% {
        -webkit-transform: translateX(-15%) rotate(-3deg);
        transform: translateX(-15%) rotate(-3deg); }
      60% {
        -webkit-transform: translateX(10%) rotate(2deg);
        transform: translateX(10%) rotate(2deg); }
      75% {
        -webkit-transform: translateX(-5%) rotate(-1deg);
        transform: translateX(-5%) rotate(-1deg); }
      100% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%); } }
    
    @-webkit-keyframes pulse {
      0% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1); }
      50% {
        -webkit-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1); }
      100% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1); } }
    
    @keyframes pulse {
      0% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1); }
      50% {
        -webkit-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1); }
      100% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1); } }
    
    @-webkit-keyframes wiggle {
      0% {
        -webkit-transform: skewX(9deg);
        transform: skewX(9deg); }
      10% {
        -webkit-transform: skewX(-8deg);
        transform: skewX(-8deg); }
      20% {
        -webkit-transform: skewX(7deg);
        transform: skewX(7deg); }
      30% {
        -webkit-transform: skewX(-6deg);
        transform: skewX(-6deg); }
      40% {
        -webkit-transform: skewX(5deg);
        transform: skewX(5deg); }
      50% {
        -webkit-transform: skewX(-4deg);
        transform: skewX(-4deg); }
      60% {
        -webkit-transform: skewX(3deg);
        transform: skewX(3deg); }
      70% {
        -webkit-transform: skewX(-2deg);
        transform: skewX(-2deg); }
      80% {
        -webkit-transform: skewX(1deg);
        transform: skewX(1deg); }
      90% {
        -webkit-transform: skewX(0deg);
        transform: skewX(0deg); }
      100% {
        -webkit-transform: skewX(0deg);
        transform: skewX(0deg); } }
    
    @keyframes wiggle {
      0% {
        -webkit-transform: skewX(9deg);
        transform: skewX(9deg); }
      10% {
        -webkit-transform: skewX(-8deg);
        transform: skewX(-8deg); }
      20% {
        -webkit-transform: skewX(7deg);
        transform: skewX(7deg); }
      30% {
        -webkit-transform: skewX(-6deg);
        transform: skewX(-6deg); }
      40% {
        -webkit-transform: skewX(5deg);
        transform: skewX(5deg); }
      50% {
        -webkit-transform: skewX(-4deg);
        transform: skewX(-4deg); }
      60% {
        -webkit-transform: skewX(3deg);
        transform: skewX(3deg); }
      70% {
        -webkit-transform: skewX(-2deg);
        transform: skewX(-2deg); }
      80% {
        -webkit-transform: skewX(1deg);
        transform: skewX(1deg); }
      90% {
        -webkit-transform: skewX(0deg);
        transform: skewX(0deg); }
      100% {
        -webkit-transform: skewX(0deg);
        transform: skewX(0deg); } }
    
    @-webkit-keyframes bounceOut {
      0% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1); }
      25% {
        -webkit-transform: scale(0.95, 0.95);
        transform: scale(0.95, 0.95); }
      50% {
        opacity: 1;
        -webkit-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1); }
      100% {
        opacity: 0;
        -webkit-transform: scale(0.3, 0.3);
        transform: scale(0.3, 0.3); } }
    
    @keyframes bounceOut {
      0% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1); }
      25% {
        -webkit-transform: scale(0.95, 0.95);
        transform: scale(0.95, 0.95); }
      50% {
        opacity: 1;
        -webkit-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1); }
      100% {
        opacity: 0;
        -webkit-transform: scale(0.3, 0.3);
        transform: scale(0.3, 0.3); } }
    
    @-webkit-keyframes bounceOutUp {
      0% {
        -webkit-transform: translateY(0);
        transform: translateY(0); }
      20% {
        opacity: 1;
        -webkit-transform: translateY(20px);
        transform: translateY(20px); }
      100% {
        opacity: 0;
        -webkit-transform: translateY(-2000px);
        transform: translateY(-2000px); } }
    
    @keyframes bounceOutUp {
      0% {
        -webkit-transform: translateY(0);
        transform: translateY(0); }
      20% {
        opacity: 1;
        -webkit-transform: translateY(20px);
        transform: translateY(20px); }
      100% {
        opacity: 0;
        -webkit-transform: translateY(-2000px);
        transform: translateY(-2000px); } }
    
    @-webkit-keyframes bounceOutDown {
      0% {
        -webkit-transform: translateY(0);
        transform: translateY(0); }
      20% {
        opacity: 1;
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px); }
      100% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
        transform: translateY(2000px); } }
    
    @keyframes bounceOutDown {
      0% {
        -webkit-transform: translateY(0);
        transform: translateY(0); }
      20% {
        opacity: 1;
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px); }
      100% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
        transform: translateY(2000px); } }
    
    @-webkit-keyframes bounceOutLeft {
      0% {
        -webkit-transform: translateX(0);
        transform: translateX(0); }
      20% {
        opacity: 1;
        -webkit-transform: translateX(20px);
        transform: translateX(20px); }
      100% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        transform: translateX(-2000px); } }
    
    @keyframes bounceOutLeft {
      0% {
        -webkit-transform: translateX(0);
        transform: translateX(0); }
      20% {
        opacity: 1;
        -webkit-transform: translateX(20px);
        transform: translateX(20px); }
      100% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        transform: translateX(-2000px); } }
    
    @-webkit-keyframes bounceOutRight {
      0% {
        -webkit-transform: translateX(0);
        transform: translateX(0); }
      20% {
        opacity: 1;
        -webkit-transform: translateX(-20px);
        transform: translateX(-20px); }
      100% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        transform: translateX(2000px); } }
    
    @keyframes bounceOutRight {
      0% {
        -webkit-transform: translateX(0);
        transform: translateX(0); }
      20% {
        opacity: 1;
        -webkit-transform: translateX(-20px);
        transform: translateX(-20px); }
      100% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        transform: translateX(2000px); } }
    
    @-webkit-keyframes bounceIn {
      0% {
        opacity: 0;
        -webkit-transform: scale(0.3, 0.3);
        transform: scale(0.3, 0.3); }
      50% {
        opacity: 1;
        -webkit-transform: scale(1.05, 1.05);
        transform: scale(1.05, 1.05); }
      70% {
        -webkit-transform: scale(0.9, 0.9);
        transform: scale(0.9, 0.9); }
      100% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1); } }
    
    @keyframes bounceIn {
      0% {
        opacity: 0;
        -webkit-transform: scale(0.3, 0.3);
        transform: scale(0.3, 0.3); }
      50% {
        opacity: 1;
        -webkit-transform: scale(1.05, 1.05);
        transform: scale(1.05, 1.05); }
      70% {
        -webkit-transform: scale(0.9, 0.9);
        transform: scale(0.9, 0.9); }
      100% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1); } }
    
    @-webkit-keyframes bounceInDown {
      0% {
        opacity: 0;
        -webkit-transform: translateY(-2000px);
        transform: translateY(-2000px); }
      60% {
        opacity: 1;
        -webkit-transform: translateY(30px);
        transform: translateY(30px); }
      80% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px); }
      100% {
        -webkit-transform: translateY(0);
        transform: translateY(0); } }
    
    @keyframes bounceInDown {
      0% {
        opacity: 0;
        -webkit-transform: translateY(-2000px);
        transform: translateY(-2000px); }
      60% {
        opacity: 1;
        -webkit-transform: translateY(30px);
        transform: translateY(30px); }
      80% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px); }
      100% {
        -webkit-transform: translateY(0);
        transform: translateY(0); } }
    
    @-webkit-keyframes bounceInUp {
      0% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
        transform: translateY(2000px); }
      60% {
        opacity: 1;
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px); }
      80% {
        -webkit-transform: translateY(10px);
        transform: translateY(10px); }
      100% {
        -webkit-transform: translateY(0);
        transform: translateY(0); } }
    
    @keyframes bounceInUp {
      0% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
        transform: translateY(2000px); }
      60% {
        opacity: 1;
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px); }
      80% {
        -webkit-transform: translateY(10px);
        transform: translateY(10px); }
      100% {
        -webkit-transform: translateY(0);
        transform: translateY(0); } }
    
    @-webkit-keyframes bounceInRight {
      0% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        transform: translateX(2000px); }
      60% {
        opacity: 1;
        -webkit-transform: translateX(-30px);
        transform: translateX(-30px); }
      80% {
        -webkit-transform: translateX(10px);
        transform: translateX(10px); }
      100% {
        -webkit-transform: translateX(0);
        transform: translateX(0); } }
    
    @keyframes bounceInRight {
      0% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        transform: translateX(2000px); }
      60% {
        opacity: 1;
        -webkit-transform: translateX(-30px);
        transform: translateX(-30px); }
      80% {
        -webkit-transform: translateX(10px);
        transform: translateX(10px); }
      100% {
        -webkit-transform: translateX(0);
        transform: translateX(0); } }
    
    @-webkit-keyframes bounceInLeft {
      0% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        transform: translateX(-2000px); }
      60% {
        opacity: 1;
        -webkit-transform: translateX(30px);
        transform: translateX(30px); }
      80% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px); }
      100% {
        -webkit-transform: translateX(0);
        transform: translateX(0); } }
    
    @keyframes bounceInLeft {
      0% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        transform: translateX(-2000px); }
      60% {
        opacity: 1;
        -webkit-transform: translateX(30px);
        transform: translateX(30px); }
      80% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px); }
      100% {
        -webkit-transform: translateX(0);
        transform: translateX(0); } }
    
    @-webkit-keyframes fadeOut {
      0% {
        opacity: 1; }
      100% {
        opacity: 0; } }
    
    @keyframes fadeOut {
      0% {
        opacity: 1; }
      100% {
        opacity: 0; } }
    
    @-webkit-keyframes fadeOutUp {
      0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1; }
      100% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
        opacity: 0; } }
    
    @keyframes fadeOutUp {
      0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1; }
      100% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
        opacity: 0; } }
    
    @-webkit-keyframes fadeOutDown {
      0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1; }
      100% {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 0; } }
    
    @keyframes fadeOutDown {
      0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1; }
      100% {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 0; } }
    
    @-webkit-keyframes fadeOutRight {
      0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1; }
      100% {
        -webkit-transform: translateX(20px);
        transform: translateX(20px);
        opacity: 0; } }
    
    @keyframes fadeOutRight {
      0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1; }
      100% {
        -webkit-transform: translateX(20px);
        transform: translateX(20px);
        opacity: 0; } }
    
    @-webkit-keyframes fadeOutLeft {
      0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1; }
      100% {
        -webkit-transform: translateX(-20px);
        transform: translateX(-20px);
        opacity: 0; } }
    
    @keyframes fadeOutLeft {
      0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1; }
      100% {
        -webkit-transform: translateX(-20px);
        transform: translateX(-20px);
        opacity: 0; } }
    
    @-webkit-keyframes fadeOutUpBig {
      0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1; }
      100% {
        -webkit-transform: translateY(-2000px);
        transform: translateY(-2000px);
        opacity: 0; } }
    
    @keyframes fadeOutUpBig {
      0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1; }
      100% {
        -webkit-transform: translateY(-2000px);
        transform: translateY(-2000px);
        opacity: 0; } }
    
    @-webkit-keyframes fadeOutDownBig {
      0% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0); }
      100% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
        transform: translateY(2000px); } }
    
    @keyframes fadeOutDownBig {
      0% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0); }
      100% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
        transform: translateY(2000px); } }
    
    @-webkit-keyframes fadeOutRightBig {
      0% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0); }
      100% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        transform: translateX(2000px); } }
    
    @keyframes fadeOutRightBig {
      0% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0); }
      100% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        transform: translateX(2000px); } }
    
    @-webkit-keyframes fadeOutLeftBig {
      0% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0); }
      100% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        transform: translateX(-2000px); } }
    
    @keyframes fadeOutLeftBig {
      0% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0); }
      100% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        transform: translateX(-2000px); } }
    
    @-webkit-keyframes fadeIn {
      0% {
        opacity: 0; }
      100% {
        opacity: 1; } }
    
    @keyframes fadeIn {
      0% {
        opacity: 0; }
      100% {
        opacity: 1; } }
    
    @-webkit-keyframes fadeInUp {
      0% {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 0; }
      100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1; } }
    
    @keyframes fadeInUp {
      0% {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 0; }
      100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1; } }
    
    @-webkit-keyframes fadeInDown {
      0% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
        opacity: 0; }
      100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1; } }
    
    @keyframes fadeInDown {
      0% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
        opacity: 0; }
      100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1; } }
    
    @-webkit-keyframes fadeInRight {
      0% {
        -webkit-transform: translateX(20px);
        transform: translateX(20px);
        opacity: 0; }
      100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1; } }
    
    @keyframes fadeInRight {
      0% {
        -webkit-transform: translateX(20px);
        transform: translateX(20px);
        opacity: 0; }
      100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1; } }
    
    @-webkit-keyframes fadeInLeft {
      0% {
        -webkit-transform: translateX(-20px);
        transform: translateX(-20px);
        opacity: 0; }
      100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1; } }
    
    @keyframes fadeInLeft {
      0% {
        -webkit-transform: translateX(-20px);
        transform: translateX(-20px);
        opacity: 0; }
      100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1; } }
    
    @-webkit-keyframes fadeInUpBig {
      0% {
        -webkit-transform: translateY(2000px);
        transform: translateY(2000px);
        opacity: 0; }
      100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1; } }
    
    @keyframes fadeInUpBig {
      0% {
        -webkit-transform: translateY(2000px);
        transform: translateY(2000px);
        opacity: 0; }
      100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1; } }
    
    @-webkit-keyframes fadeInDownBig {
      0% {
        opacity: 0;
        -webkit-transform: translateY(-2000px);
        transform: translateY(-2000px); }
      100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0); } }
    
    @keyframes fadeInDownBig {
      0% {
        opacity: 0;
        -webkit-transform: translateY(-2000px);
        transform: translateY(-2000px); }
      100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0); } }
    
    @-webkit-keyframes fadeInRightBig {
      0% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        transform: translateX(2000px); }
      100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0); } }
    
    @keyframes fadeInRightBig {
      0% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        transform: translateX(2000px); }
      100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0); } }
    
    @-webkit-keyframes fadeInLeftBig {
      0% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        transform: translateX(-2000px); }
      100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0); } }
    
    @keyframes fadeInLeftBig {
      0% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        transform: translateX(-2000px); }
      100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0); } }
    
    @-webkit-keyframes flip {
      0% {
        -webkit-transform: perspective(400px) rotateY(0);
        transform: perspective(400px) rotateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out; }
      40% {
        -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
        transform: perspective(400px) translateZ(150px) rotateY(170deg);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out; }
      50% {
        -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in; }
      80% {
        -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
        transform: perspective(400px) rotateY(360deg) scale(0.95);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in; }
      100% {
        -webkit-transform: perspective(400px) scale(1);
        transform: perspective(400px) scale(1);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in; } }
    
    @keyframes flip {
      0% {
        -webkit-transform: perspective(400px) rotateY(0);
        transform: perspective(400px) rotateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out; }
      40% {
        -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
        transform: perspective(400px) translateZ(150px) rotateY(170deg);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out; }
      50% {
        -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in; }
      80% {
        -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
        transform: perspective(400px) rotateY(360deg) scale(0.95);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in; }
      100% {
        -webkit-transform: perspective(400px) scale(1);
        transform: perspective(400px) scale(1);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in; } }
    
    @-webkit-keyframes flipInX {
      0% {
        -webkit-transform: perspective(400px) rotateX(90deg);
        transform: perspective(400px) rotateX(90deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0; }
      40% {
        -webkit-transform: perspective(400px) rotateX(-10deg);
        transform: perspective(400px) rotateX(-10deg); }
      70% {
        -webkit-transform: perspective(400px) rotateX(10deg);
        transform: perspective(400px) rotateX(10deg); }
      100% {
        -webkit-transform: perspective(400px) rotateX(0deg);
        transform: perspective(400px) rotateX(0deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1; } }
    
    @keyframes flipInX {
      0% {
        -webkit-transform: perspective(400px) rotateX(90deg);
        transform: perspective(400px) rotateX(90deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0; }
      40% {
        -webkit-transform: perspective(400px) rotateX(-10deg);
        transform: perspective(400px) rotateX(-10deg); }
      70% {
        -webkit-transform: perspective(400px) rotateX(10deg);
        transform: perspective(400px) rotateX(10deg); }
      100% {
        -webkit-transform: perspective(400px) rotateX(0deg);
        transform: perspective(400px) rotateX(0deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1; } }
    
    @-webkit-keyframes flipOutX {
      0% {
        -webkit-transform: perspective(400px) rotateX(0deg);
        transform: perspective(400px) rotateX(0deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1; }
      100% {
        -webkit-transform: perspective(400px) rotateX(90deg);
        transform: perspective(400px) rotateX(90deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0; } }
    
    @keyframes flipOutX {
      0% {
        -webkit-transform: perspective(400px) rotateX(0deg);
        transform: perspective(400px) rotateX(0deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1; }
      100% {
        -webkit-transform: perspective(400px) rotateX(90deg);
        transform: perspective(400px) rotateX(90deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0; } }
    
    @-webkit-keyframes flipInY {
      0% {
        -webkit-transform: perspective(400px) rotateY(90deg);
        transform: perspective(400px) rotateY(90deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0; }
      40% {
        -webkit-transform: perspective(400px) rotateY(-10deg);
        transform: perspective(400px) rotateY(-10deg); }
      70% {
        -webkit-transform: perspective(400px) rotateY(10deg);
        transform: perspective(400px) rotateY(10deg); }
      100% {
        -webkit-transform: perspective(400px) rotateY(0deg);
        transform: perspective(400px) rotateY(0deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1; } }
    
    @keyframes flipInY {
      0% {
        -webkit-transform: perspective(400px) rotateY(90deg);
        transform: perspective(400px) rotateY(90deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0; }
      40% {
        -webkit-transform: perspective(400px) rotateY(-10deg);
        transform: perspective(400px) rotateY(-10deg); }
      70% {
        -webkit-transform: perspective(400px) rotateY(10deg);
        transform: perspective(400px) rotateY(10deg); }
      100% {
        -webkit-transform: perspective(400px) rotateY(0deg);
        transform: perspective(400px) rotateY(0deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1; } }
    
    @-webkit-keyframes flipOutY {
      0% {
        -webkit-transform: perspective(400px) rotateY(0deg);
        transform: perspective(400px) rotateY(0deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1; }
      100% {
        -webkit-transform: perspective(400px) rotateY(90deg);
        transform: perspective(400px) rotateY(90deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0; } }
    
    @keyframes flipOutY {
      0% {
        -webkit-transform: perspective(400px) rotateY(0deg);
        transform: perspective(400px) rotateY(0deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1; }
      100% {
        -webkit-transform: perspective(400px) rotateY(90deg);
        transform: perspective(400px) rotateY(90deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0; } }
    
    @-webkit-keyframes lightSpeedIn {
      0% {
        -webkit-transform: translateX(100%) skewX(-30deg);
        transform: translateX(100%) skewX(-30deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0; }
      60% {
        -webkit-transform: translateX(-20%) skewX(30deg);
        transform: translateX(-20%) skewX(30deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1; }
      80% {
        -webkit-transform: translateX(0%) skewX(-15deg);
        transform: translateX(0%) skewX(-15deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1; }
      100% {
        -webkit-transform: translateX(0%) skewX(0deg);
        transform: translateX(0%) skewX(0deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1; } }
    
    @keyframes lightSpeedIn {
      0% {
        -webkit-transform: translateX(100%) skewX(-30deg);
        transform: translateX(100%) skewX(-30deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0; }
      60% {
        -webkit-transform: translateX(-20%) skewX(30deg);
        transform: translateX(-20%) skewX(30deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1; }
      80% {
        -webkit-transform: translateX(0%) skewX(-15deg);
        transform: translateX(0%) skewX(-15deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1; }
      100% {
        -webkit-transform: translateX(0%) skewX(0deg);
        transform: translateX(0%) skewX(0deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1; } }
    
    @-webkit-keyframes lightSpeedOut {
      0% {
        -webkit-transform: translateX(0%) skewX(0deg);
        transform: translateX(0%) skewX(0deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1; }
      100% {
        -webkit-transform: translateX(100%) skewX(-30deg);
        transform: translateX(100%) skewX(-30deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0; } }
    
    @keyframes lightSpeedOut {
      0% {
        -webkit-transform: translateX(0%) skewX(0deg);
        transform: translateX(0%) skewX(0deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1; }
      100% {
        -webkit-transform: translateX(100%) skewX(-30deg);
        transform: translateX(100%) skewX(-30deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0; } }
    
    @-webkit-keyframes rotateOut {
      0% {
        -webkit-transform-origin: center center 50%;
        transform-origin: center center 50%;
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1; }
      100% {
        -webkit-transform-origin: center center 50%;
        transform-origin: center center 50%;
        -webkit-transform: rotate(200deg);
        transform: rotate(200deg);
        opacity: 0; } }
    
    @keyframes rotateOut {
      0% {
        -webkit-transform-origin: center center 50%;
        transform-origin: center center 50%;
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1; }
      100% {
        -webkit-transform-origin: center center 50%;
        transform-origin: center center 50%;
        -webkit-transform: rotate(200deg);
        transform: rotate(200deg);
        opacity: 0; } }
    
    @-webkit-keyframes rotateOutDownLeft {
      0% {
        -webkit-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1; }
      100% {
        -webkit-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0; } }
    
    @keyframes rotateOutDownLeft {
      0% {
        -webkit-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1; }
      100% {
        -webkit-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0; } }
    
    @-webkit-keyframes rotateOutUpLeft {
      0% {
        -webkit-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1; }
      100% {
        -webkit-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0; } }
    
    @keyframes rotateOutUpLeft {
      0% {
        -webkit-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1; }
      100% {
        -webkit-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0; } }
    
    @-webkit-keyframes rotateOutDownRight {
      0% {
        -webkit-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1; }
      100% {
        -webkit-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0; } }
    
    @keyframes rotateOutDownRight {
      0% {
        -webkit-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1; }
      100% {
        -webkit-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0; } }
    
    @-webkit-keyframes rotateOutUpRight {
      0% {
        -webkit-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1; }
      100% {
        -webkit-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0; } }
    
    @keyframes rotateOutUpRight {
      0% {
        -webkit-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1; }
      100% {
        -webkit-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0; } }
    
    @-webkit-keyframes rotateIn {
      0% {
        -webkit-transform-origin: center center 50%;
        transform-origin: center center 50%;
        -webkit-transform: rotate(-200deg);
        transform: rotate(-200deg);
        opacity: 0; }
      100% {
        -webkit-transform-origin: center center 50%;
        transform-origin: center center 50%;
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1; } }
    
    @keyframes rotateIn {
      0% {
        -webkit-transform-origin: center center 50%;
        transform-origin: center center 50%;
        -webkit-transform: rotate(-200deg);
        transform: rotate(-200deg);
        opacity: 0; }
      100% {
        -webkit-transform-origin: center center 50%;
        transform-origin: center center 50%;
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1; } }
    
    @-webkit-keyframes rotateInDownLeft {
      0% {
        -webkit-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0; }
      100% {
        -webkit-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1; } }
    
    @keyframes rotateInDownLeft {
      0% {
        -webkit-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0; }
      100% {
        -webkit-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1; } }
    
    @-webkit-keyframes rotateInUpLeft {
      0% {
        -webkit-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0; }
      100% {
        -webkit-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1; } }
    
    @keyframes rotateInUpLeft {
      0% {
        -webkit-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0; }
      100% {
        -webkit-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1; } }
    
    @-webkit-keyframes rotateInUpRight {
      0% {
        -webkit-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0; }
      100% {
        -webkit-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1; } }
    
    @keyframes rotateInUpRight {
      0% {
        -webkit-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0; }
      100% {
        -webkit-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1; } }
    
    @-webkit-keyframes rotateInDownRight {
      0% {
        -webkit-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0; }
      100% {
        -webkit-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1; } }
    
    @keyframes rotateInDownRight {
      0% {
        -webkit-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0; }
      100% {
        -webkit-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1; } }
    
    @-webkit-keyframes hinge {
      0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        -webkit-transform-origin: top left 50%;
        transform-origin: top left 50%;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out; }
      20%, 60% {
        -webkit-transform: rotate(80deg);
        transform: rotate(80deg);
        -webkit-transform-origin: top left 50%;
        transform-origin: top left 50%;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out; }
      40% {
        -webkit-transform: rotate(60deg);
        transform: rotate(60deg);
        -webkit-transform-origin: top left 50%;
        transform-origin: top left 50%;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out; }
      80% {
        -webkit-transform: rotate(60deg) translateY(0);
        transform: rotate(60deg) translateY(0);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
        -webkit-transform-origin: top left 50%;
        transform-origin: top left 50%;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out; }
      100% {
        -webkit-transform: translateY(700px);
        transform: translateY(700px);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0; } }
    
    @keyframes hinge {
      0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        -webkit-transform-origin: top left 50%;
        transform-origin: top left 50%;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out; }
      20%, 60% {
        -webkit-transform: rotate(80deg);
        transform: rotate(80deg);
        -webkit-transform-origin: top left 50%;
        transform-origin: top left 50%;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out; }
      40% {
        -webkit-transform: rotate(60deg);
        transform: rotate(60deg);
        -webkit-transform-origin: top left 50%;
        transform-origin: top left 50%;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out; }
      80% {
        -webkit-transform: rotate(60deg) translateY(0);
        transform: rotate(60deg) translateY(0);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
        -webkit-transform-origin: top left 50%;
        transform-origin: top left 50%;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out; }
      100% {
        -webkit-transform: translateY(700px);
        transform: translateY(700px);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0; } }
    
    @-webkit-keyframes rollIn {
      0% {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
        -webkit-transform: translateX(-100%) rotate(-120deg);
        transform: translateX(-100%) rotate(-120deg); }
      100% {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
        -webkit-transform: translateX(0px) rotate(0deg);
        transform: translateX(0px) rotate(0deg); } }
    
    @keyframes rollIn {
      0% {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
        -webkit-transform: translateX(-100%) rotate(-120deg);
        transform: translateX(-100%) rotate(-120deg); }
      100% {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
        -webkit-transform: translateX(0px) rotate(0deg);
        transform: translateX(0px) rotate(0deg); } }
    
    @-webkit-keyframes rollOut {
      0% {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
        -webkit-transform: translateX(0px) rotate(0deg);
        transform: translateX(0px) rotate(0deg); }
      100% {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
        -webkit-transform: translateX(-100%) rotate(-120deg);
        transform: translateX(-100%) rotate(-120deg); } }
    
    @keyframes rollOut {
      0% {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
        -webkit-transform: translateX(0px) rotate(0deg);
        transform: translateX(0px) rotate(0deg); }
      100% {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
        -webkit-transform: translateX(-100%) rotate(-120deg);
        transform: translateX(-100%) rotate(-120deg); } }
    
    :root {
      --blue: #5E50F9;
      --indigo: #6610f2;
      --purple: #6a008a;
      --pink: #E91E63;
      --red: #f96868;
      --orange: #f2a654;
      --yellow: #f6e84e;
      --green: #46c35f;
      --teal: #58d8a3;
      --cyan: #57c7d4;
      --white: #ffffff;
      --gray: #6c757d;
      --gray-dark: #0f1531;
      --blue: #5E50F9;
      --indigo: #6610f2;
      --purple: #6a008a;
      --pink: #E91E63;
      --red: #f96868;
      --orange: #f2a654;
      --yellow: #f6e84e;
      --green: #46c35f;
      --teal: #58d8a3;
      --cyan: #57c7d4;
      --white: #ffffff;
      --gray: #434a54;
      --gray-light: #aab2bd;
      --gray-lighter: #e8eff4;
      --gray-lightest: #e6e9ed;
      --black: #000000;
      --primary: #1bdbe0;
      --secondary: #d8d8d8;
      --success: #38ce3c;
      --info: #8e32e9;
      --warning: #ffde73;
      --danger: #ff4d6b;
      --light: #f8f9fa;
      --dark: #3e4b5b;
      --breakpoint-xs: 0;
      --breakpoint-sm: 576px;
      --breakpoint-md: 768px;
      --breakpoint-lg: 992px;
      --breakpoint-xl: 1200px;
      --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }
    
    *,
    *::before,
    *::after {
      -webkit-box-sizing: border-box;
      box-sizing: border-box; }
    
    html {
      font-family: sans-serif;
      line-height: 1.15;
      -webkit-text-size-adjust: 100%;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
    
    article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
      display: block; }
    
    body {
      margin: 0;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #343a40;
      text-align: left;
      background-color: #fff; }
    
    [tabindex="-1"]:focus:not(:focus-visible) {
      outline: 0 !important; }
    
    hr {
      -webkit-box-sizing: content-box;
      box-sizing: content-box;
      height: 0;
      overflow: visible; }
    
    h1, h2, h3, h4, h5, h6 {
      margin-top: 0;
      margin-bottom: 0.5rem; }
    
    p {
      margin-top: 0;
      margin-bottom: 1rem; }
    
    abbr[title],
    abbr[data-original-title] {
      text-decoration: underline;
      -webkit-text-decoration: underline dotted;
      text-decoration: underline dotted;
      cursor: help;
      border-bottom: 0;
      -webkit-text-decoration-skip-ink: none;
      text-decoration-skip-ink: none; }
    
    address {
      margin-bottom: 1rem;
      font-style: normal;
      line-height: inherit; }
    
    ol,
    ul,
    dl {
      margin-top: 0;
      margin-bottom: 1rem; }
    
    ol ol,
    ul ul,
    ol ul,
    ul ol {
      margin-bottom: 0; }
    
    dt {
      font-weight: 700; }
    
    dd {
      margin-bottom: .5rem;
      margin-left: 0; }
    
    blockquote {
      margin: 0 0 1rem; }
    
    b,
    strong {
      font-weight: bolder; }
    
    small {
      font-size: 80%; }
    
    sub,
    sup {
      position: relative;
      font-size: 75%;
      line-height: 0;
      vertical-align: baseline; }
    
    sub {
      bottom: -.25em; }
    
    sup {
      top: -.5em; }
    
    a {
      color: #007bff;
      text-decoration: none;
      background-color: transparent; }
      a:hover {
        color: #0056b3;
        text-decoration: underline; }
    
    a:not([href]):not([class]) {
      color: inherit;
      text-decoration: none; }
      a:not([href]):not([class]):hover {
        color: inherit;
        text-decoration: none; }
    
    pre,
    code,
    kbd,
    samp {
      font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
      font-size: 1em; }
    
    pre {
      margin-top: 0;
      margin-bottom: 1rem;
      overflow: auto;
      -ms-overflow-style: scrollbar; }
    
    figure {
      margin: 0 0 1rem; }
    
    img {
      vertical-align: middle;
      border-style: none; }
    
    svg {
      overflow: hidden;
      vertical-align: middle; }
    
    table {
      border-collapse: collapse; }
    
    caption {
      padding-top: 0.9375rem;
      padding-bottom: 0.9375rem;
      color: #6c757d;
      text-align: left;
      caption-side: bottom; }
    
    th {
      text-align: inherit;
      text-align: -webkit-match-parent; }
    
    label {
      display: inline-block;
      margin-bottom: 0.5rem; }
    
    button {
      border-radius: 0; }
    
    button:focus {
      outline: 1px dotted;
      outline: 5px auto -webkit-focus-ring-color; }
    
    input,
    button,
    select,
    optgroup,
    textarea {
      margin: 0;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit; }
    
    button,
    input {
      overflow: visible; }
    
    button,
    select {
      text-transform: none; }
    
    [role="button"] {
      cursor: pointer; }
    
    select {
      word-wrap: normal; }
    
    button,
    [type="button"],
    [type="reset"],
    [type="submit"] {
      -webkit-appearance: button; }
    
    button:not(:disabled),
    [type="button"]:not(:disabled),
    [type="reset"]:not(:disabled),
    [type="submit"]:not(:disabled) {
      cursor: pointer; }
    
    button::-moz-focus-inner,
    [type="button"]::-moz-focus-inner,
    [type="reset"]::-moz-focus-inner,
    [type="submit"]::-moz-focus-inner {
      padding: 0;
      border-style: none; }
    
    input[type="radio"],
    input[type="checkbox"] {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      padding: 0; }
    
    textarea {
      overflow: auto;
      resize: vertical; }
    
    fieldset {
      min-width: 0;
      padding: 0;
      margin: 0;
      border: 0; }
    
    legend {
      display: block;
      width: 100%;
      max-width: 100%;
      padding: 0;
      margin-bottom: .5rem;
      font-size: 1.5rem;
      line-height: inherit;
      color: inherit;
      white-space: normal; }
    
    progress {
      vertical-align: baseline; }
    
    [type="number"]::-webkit-inner-spin-button,
    [type="number"]::-webkit-outer-spin-button {
      height: auto; }
    
    [type="search"] {
      outline-offset: -2px;
      -webkit-appearance: none; }
    
    [type="search"]::-webkit-search-decoration {
      -webkit-appearance: none; }
    
    ::-webkit-file-upload-button {
      font: inherit;
      -webkit-appearance: button; }
    
    output {
      display: inline-block; }
    
    summary {
      display: list-item;
      cursor: pointer; }
    
    template {
      display: none; }
    
    [hidden] {
      display: none !important; }
    
    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
      margin-bottom: 0.5rem;
      font-weight: 500;
      line-height: 1.2; }
    
    h1, .h1 {
      font-size: 2.5rem; }
    
    h2, .h2 {
      font-size: 2rem; }
    
    h3, .h3 {
      font-size: 1.75rem; }
    
    h4, .h4 {
      font-size: 1.5rem; }
    
    h5, .h5 {
      font-size: 1.25rem; }
    
    h6, .h6 {
      font-size: 1rem; }
    
    .lead {
      font-size: 1.25rem;
      font-weight: 300; }
    
    .display-1 {
      font-size: 6rem;
      font-weight: 300;
      line-height: 1.2; }
    
    .display-2 {
      font-size: 5.5rem;
      font-weight: 300;
      line-height: 1.2; }
    
    .display-3 {
      font-size: 4.5rem;
      font-weight: 300;
      line-height: 1.2; }
    
    .display-4 {
      font-size: 3.5rem;
      font-weight: 300;
      line-height: 1.2; }
    
    hr {
      margin-top: 1rem;
      margin-bottom: 1rem;
      border: 0;
      border-top: 1px solid rgba(0, 0, 0, 0.1); }
    
    small,
    .small {
      font-size: 80%;
      font-weight: 400; }
    
    mark,
    .mark {
      padding: 0.2em;
      background-color: #fcf8e3; }
    
    .list-unstyled {
      padding-left: 0;
      list-style: none; }
    
    .list-inline {
      padding-left: 0;
      list-style: none; }
    
    .list-inline-item {
      display: inline-block; }
      .list-inline-item:not(:last-child) {
        margin-right: 0.5rem; }
    
    .initialism {
      font-size: 90%;
      text-transform: uppercase; }
    
    .blockquote {
      margin-bottom: 1rem;
      font-size: 1.25rem; }
    
    .blockquote-footer {
      display: block;
      font-size: 80%;
      color: #6c757d; }
      .blockquote-footer::before {
        content: "\2014\00A0"; }
    
    .img-fluid {
      max-width: 100%;
      height: auto; }
    
    .img-thumbnail {
      padding: 0.25rem;
      background-color: #fff;
      border: 1px solid #dee2e6;
      border-radius: 0.25rem;
      max-width: 100%;
      height: auto; }
    
    .figure {
      display: inline-block; }
    
    .figure-img {
      margin-bottom: 0.5rem;
      line-height: 1; }
    
    .figure-caption {
      font-size: 90%;
      color: #6c757d; }
    
    code {
      font-size: 87.5%;
      color: #e83e8c;
      word-wrap: break-word; }
      a > code {
        color: inherit; }
    
    kbd {
      padding: 0.2rem 0.4rem;
      font-size: 87.5%;
      color: #fff;
      background-color: #212529;
      border-radius: 0.2rem; }
      kbd kbd {
        padding: 0;
        font-size: 100%;
        font-weight: 700; }
    
    pre {
      display: block;
      font-size: 87.5%;
      color: #212529; }
      pre code {
        font-size: inherit;
        color: inherit;
        word-break: normal; }
    
    .pre-scrollable {
      max-height: 340px;
      overflow-y: scroll; }
    
    .container,
    .container-fluid,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
      margin-right: auto;
      margin-left: auto; }
    
    @media (min-width: 576px) {
      .container, .container-sm {
        max-width: 540px; } }
    
    @media (min-width: 768px) {
      .container, .container-sm, .container-md {
        max-width: 720px; } }
    
    @media (min-width: 992px) {
      .container, .container-sm, .container-md, .container-lg {
        max-width: 960px; } }
    
    @media (min-width: 1200px) {
      .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: 1140px; } }
    
    .row {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-right: -10px;
      margin-left: -10px; }
    
    .no-gutters {
      margin-right: 0;
      margin-left: 0; }
      .no-gutters > .col,
      .no-gutters > [class*="col-"] {
        padding-right: 0;
        padding-left: 0; }
    
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
    .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
    .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
    .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
    .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
    .col-xl-auto {
      position: relative;
      width: 100%;
      padding-right: 10px;
      padding-left: 10px; }
    
    .col {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%; }
    
    .row-cols-1 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%; }
    
    .row-cols-2 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%; }
    
    .row-cols-3 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    
    .row-cols-4 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%; }
    
    .row-cols-5 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
      max-width: 20%; }
    
    .row-cols-6 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    
    .col-auto {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: 100%; }
    
    .col-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 8.33333%;
      flex: 0 0 8.33333%;
      max-width: 8.33333%; }
    
    .col-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    
    .col-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%; }
    
    .col-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    
    .col-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 41.66667%;
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    
    .col-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%; }
    
    .col-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 58.33333%;
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    
    .col-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 66.66667%;
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    
    .col-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%; }
    
    .col-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 83.33333%;
      flex: 0 0 83.33333%;
      max-width: 83.33333%; }
    
    .col-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 91.66667%;
      flex: 0 0 91.66667%;
      max-width: 91.66667%; }
    
    .col-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%; }
    
    .order-first {
      -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
      order: -1; }
    
    .order-last {
      -webkit-box-ordinal-group: 14;
      -ms-flex-order: 13;
      order: 13; }
    
    .order-0 {
      -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
      order: 0; }
    
    .order-1 {
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1; }
    
    .order-2 {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2; }
    
    .order-3 {
      -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
      order: 3; }
    
    .order-4 {
      -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
      order: 4; }
    
    .order-5 {
      -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
      order: 5; }
    
    .order-6 {
      -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
      order: 6; }
    
    .order-7 {
      -webkit-box-ordinal-group: 8;
      -ms-flex-order: 7;
      order: 7; }
    
    .order-8 {
      -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
      order: 8; }
    
    .order-9 {
      -webkit-box-ordinal-group: 10;
      -ms-flex-order: 9;
      order: 9; }
    
    .order-10 {
      -webkit-box-ordinal-group: 11;
      -ms-flex-order: 10;
      order: 10; }
    
    .order-11 {
      -webkit-box-ordinal-group: 12;
      -ms-flex-order: 11;
      order: 11; }
    
    .order-12 {
      -webkit-box-ordinal-group: 13;
      -ms-flex-order: 12;
      order: 12; }
    
    .offset-1 {
      margin-left: 8.33333%; }
    
    .offset-2 {
      margin-left: 16.66667%; }
    
    .offset-3 {
      margin-left: 25%; }
    
    .offset-4 {
      margin-left: 33.33333%; }
    
    .offset-5 {
      margin-left: 41.66667%; }
    
    .offset-6 {
      margin-left: 50%; }
    
    .offset-7 {
      margin-left: 58.33333%; }
    
    .offset-8 {
      margin-left: 66.66667%; }
    
    .offset-9 {
      margin-left: 75%; }
    
    .offset-10 {
      margin-left: 83.33333%; }
    
    .offset-11 {
      margin-left: 91.66667%; }
    
    @media (min-width: 576px) {
      .col-sm {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%; }
      .row-cols-sm-1 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%; }
      .row-cols-sm-2 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%; }
      .row-cols-sm-3 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%; }
      .row-cols-sm-4 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%; }
      .row-cols-sm-5 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%; }
      .row-cols-sm-6 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%; }
      .col-sm-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%; }
      .col-sm-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%; }
      .col-sm-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%; }
      .col-sm-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%; }
      .col-sm-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%; }
      .col-sm-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%; }
      .col-sm-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%; }
      .col-sm-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%; }
      .col-sm-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%; }
      .col-sm-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%; }
      .col-sm-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%; }
      .col-sm-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%; }
      .col-sm-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%; }
      .order-sm-first {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1; }
      .order-sm-last {
        -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
        order: 13; }
      .order-sm-0 {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0; }
      .order-sm-1 {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1; }
      .order-sm-2 {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2; }
      .order-sm-3 {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3; }
      .order-sm-4 {
        -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4; }
      .order-sm-5 {
        -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5; }
      .order-sm-6 {
        -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
        order: 6; }
      .order-sm-7 {
        -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
        order: 7; }
      .order-sm-8 {
        -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8; }
      .order-sm-9 {
        -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
        order: 9; }
      .order-sm-10 {
        -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10; }
      .order-sm-11 {
        -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
        order: 11; }
      .order-sm-12 {
        -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
        order: 12; }
      .offset-sm-0 {
        margin-left: 0; }
      .offset-sm-1 {
        margin-left: 8.33333%; }
      .offset-sm-2 {
        margin-left: 16.66667%; }
      .offset-sm-3 {
        margin-left: 25%; }
      .offset-sm-4 {
        margin-left: 33.33333%; }
      .offset-sm-5 {
        margin-left: 41.66667%; }
      .offset-sm-6 {
        margin-left: 50%; }
      .offset-sm-7 {
        margin-left: 58.33333%; }
      .offset-sm-8 {
        margin-left: 66.66667%; }
      .offset-sm-9 {
        margin-left: 75%; }
      .offset-sm-10 {
        margin-left: 83.33333%; }
      .offset-sm-11 {
        margin-left: 91.66667%; } }
    
    @media (min-width: 768px) {
      .col-md {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%; }
      .row-cols-md-1 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%; }
      .row-cols-md-2 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%; }
      .row-cols-md-3 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%; }
      .row-cols-md-4 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%; }
      .row-cols-md-5 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%; }
      .row-cols-md-6 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%; }
      .col-md-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%; }
      .col-md-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%; }
      .col-md-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%; }
      .col-md-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%; }
      .col-md-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%; }
      .col-md-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%; }
      .col-md-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%; }
      .col-md-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%; }
      .col-md-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%; }
      .col-md-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%; }
      .col-md-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%; }
      .col-md-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%; }
      .col-md-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%; }
      .order-md-first {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1; }
      .order-md-last {
        -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
        order: 13; }
      .order-md-0 {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0; }
      .order-md-1 {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1; }
      .order-md-2 {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2; }
      .order-md-3 {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3; }
      .order-md-4 {
        -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4; }
      .order-md-5 {
        -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5; }
      .order-md-6 {
        -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
        order: 6; }
      .order-md-7 {
        -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
        order: 7; }
      .order-md-8 {
        -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8; }
      .order-md-9 {
        -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
        order: 9; }
      .order-md-10 {
        -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10; }
      .order-md-11 {
        -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
        order: 11; }
      .order-md-12 {
        -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
        order: 12; }
      .offset-md-0 {
        margin-left: 0; }
      .offset-md-1 {
        margin-left: 8.33333%; }
      .offset-md-2 {
        margin-left: 16.66667%; }
      .offset-md-3 {
        margin-left: 25%; }
      .offset-md-4 {
        margin-left: 33.33333%; }
      .offset-md-5 {
        margin-left: 41.66667%; }
      .offset-md-6 {
        margin-left: 50%; }
      .offset-md-7 {
        margin-left: 58.33333%; }
      .offset-md-8 {
        margin-left: 66.66667%; }
      .offset-md-9 {
        margin-left: 75%; }
      .offset-md-10 {
        margin-left: 83.33333%; }
      .offset-md-11 {
        margin-left: 91.66667%; } }
    
    @media (min-width: 992px) {
      .col-lg {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%; }
      .row-cols-lg-1 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%; }
      .row-cols-lg-2 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%; }
      .row-cols-lg-3 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%; }
      .row-cols-lg-4 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%; }
      .row-cols-lg-5 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%; }
      .row-cols-lg-6 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%; }
      .col-lg-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%; }
      .col-lg-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%; }
      .col-lg-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%; }
      .col-lg-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%; }
      .col-lg-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%; }
      .col-lg-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%; }
      .col-lg-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%; }
      .col-lg-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%; }
      .col-lg-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%; }
      .col-lg-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%; }
      .col-lg-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%; }
      .col-lg-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%; }
      .col-lg-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%; }
      .order-lg-first {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1; }
      .order-lg-last {
        -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
        order: 13; }
      .order-lg-0 {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0; }
      .order-lg-1 {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1; }
      .order-lg-2 {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2; }
      .order-lg-3 {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3; }
      .order-lg-4 {
        -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4; }
      .order-lg-5 {
        -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5; }
      .order-lg-6 {
        -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
        order: 6; }
      .order-lg-7 {
        -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
        order: 7; }
      .order-lg-8 {
        -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8; }
      .order-lg-9 {
        -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
        order: 9; }
      .order-lg-10 {
        -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10; }
      .order-lg-11 {
        -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
        order: 11; }
      .order-lg-12 {
        -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
        order: 12; }
      .offset-lg-0 {
        margin-left: 0; }
      .offset-lg-1 {
        margin-left: 8.33333%; }
      .offset-lg-2 {
        margin-left: 16.66667%; }
      .offset-lg-3 {
        margin-left: 25%; }
      .offset-lg-4 {
        margin-left: 33.33333%; }
      .offset-lg-5 {
        margin-left: 41.66667%; }
      .offset-lg-6 {
        margin-left: 50%; }
      .offset-lg-7 {
        margin-left: 58.33333%; }
      .offset-lg-8 {
        margin-left: 66.66667%; }
      .offset-lg-9 {
        margin-left: 75%; }
      .offset-lg-10 {
        margin-left: 83.33333%; }
      .offset-lg-11 {
        margin-left: 91.66667%; } }
    
    @media (min-width: 1200px) {
      .col-xl {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%; }
      .row-cols-xl-1 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%; }
      .row-cols-xl-2 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%; }
      .row-cols-xl-3 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%; }
      .row-cols-xl-4 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%; }
      .row-cols-xl-5 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%; }
      .row-cols-xl-6 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%; }
      .col-xl-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%; }
      .col-xl-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%; }
      .col-xl-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%; }
      .col-xl-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%; }
      .col-xl-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%; }
      .col-xl-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%; }
      .col-xl-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%; }
      .col-xl-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%; }
      .col-xl-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%; }
      .col-xl-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%; }
      .col-xl-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%; }
      .col-xl-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%; }
      .col-xl-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%; }
      .order-xl-first {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1; }
      .order-xl-last {
        -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
        order: 13; }
      .order-xl-0 {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0; }
      .order-xl-1 {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1; }
      .order-xl-2 {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2; }
      .order-xl-3 {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3; }
      .order-xl-4 {
        -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4; }
      .order-xl-5 {
        -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5; }
      .order-xl-6 {
        -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
        order: 6; }
      .order-xl-7 {
        -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
        order: 7; }
      .order-xl-8 {
        -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8; }
      .order-xl-9 {
        -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
        order: 9; }
      .order-xl-10 {
        -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10; }
      .order-xl-11 {
        -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
        order: 11; }
      .order-xl-12 {
        -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
        order: 12; }
      .offset-xl-0 {
        margin-left: 0; }
      .offset-xl-1 {
        margin-left: 8.33333%; }
      .offset-xl-2 {
        margin-left: 16.66667%; }
      .offset-xl-3 {
        margin-left: 25%; }
      .offset-xl-4 {
        margin-left: 33.33333%; }
      .offset-xl-5 {
        margin-left: 41.66667%; }
      .offset-xl-6 {
        margin-left: 50%; }
      .offset-xl-7 {
        margin-left: 58.33333%; }
      .offset-xl-8 {
        margin-left: 66.66667%; }
      .offset-xl-9 {
        margin-left: 75%; }
      .offset-xl-10 {
        margin-left: 83.33333%; }
      .offset-xl-11 {
        margin-left: 91.66667%; } }
    
    .table {
      width: 100%;
      margin-bottom: 1rem;
      color: #212529; }
      .table th,
      .table td {
        padding: 0.9375rem;
        vertical-align: top;
        border-top: 1px solid #e8ecf1; }
      .table thead th {
        vertical-align: bottom;
        border-bottom: 2px solid #e8ecf1; }
      .table tbody + tbody {
        border-top: 2px solid #e8ecf1; }
    
    .table-sm th,
    .table-sm td {
      padding: 0.3rem; }
    
    .table-bordered {
      border: 1px solid #e8ecf1; }
      .table-bordered th,
      .table-bordered td {
        border: 1px solid #e8ecf1; }
      .table-bordered thead th,
      .table-bordered thead td {
        border-bottom-width: 2px; }
    
    .table-borderless th,
    .table-borderless td,
    .table-borderless thead th,
    .table-borderless tbody + tbody {
      border: 0; }
    
    .table-striped tbody tr:nth-of-type(odd) {
      background-color: #ecf0f4; }
    
    .table-hover tbody tr:hover {
      color: #212529;
      background-color: #ecf0f4; }
    
    .table-primary,
    .table-primary > th,
    .table-primary > td {
      background-color: #bff5f6; }
    
    .table-primary th,
    .table-primary td,
    .table-primary thead th,
    .table-primary tbody + tbody {
      border-color: #88ecef; }
    
    .table-hover .table-primary:hover {
      background-color: #a9f2f3; }
      .table-hover .table-primary:hover > td,
      .table-hover .table-primary:hover > th {
        background-color: #a9f2f3; }
    
    .table-secondary,
    .table-secondary > th,
    .table-secondary > td {
      background-color: #f4f4f4; }
    
    .table-secondary th,
    .table-secondary td,
    .table-secondary thead th,
    .table-secondary tbody + tbody {
      border-color: #ebebeb; }
    
    .table-hover .table-secondary:hover {
      background-color: #e7e7e7; }
      .table-hover .table-secondary:hover > td,
      .table-hover .table-secondary:hover > th {
        background-color: #e7e7e7; }
    
    .table-success,
    .table-success > th,
    .table-success > td {
      background-color: #c7f1c8; }
    
    .table-success th,
    .table-success td,
    .table-success thead th,
    .table-success tbody + tbody {
      border-color: #98e69a; }
    
    .table-hover .table-success:hover {
      background-color: #b3ecb4; }
      .table-hover .table-success:hover > td,
      .table-hover .table-success:hover > th {
        background-color: #b3ecb4; }
    
    .table-info,
    .table-info > th,
    .table-info > td {
      background-color: #dfc6f9; }
    
    .table-info th,
    .table-info td,
    .table-info thead th,
    .table-info tbody + tbody {
      border-color: #c494f4; }
    
    .table-hover .table-info:hover {
      background-color: #d2aff7; }
      .table-hover .table-info:hover > td,
      .table-hover .table-info:hover > th {
        background-color: #d2aff7; }
    
    .table-warning,
    .table-warning > th,
    .table-warning > td {
      background-color: #fff6d8; }
    
    .table-warning th,
    .table-warning td,
    .table-warning thead th,
    .table-warning tbody + tbody {
      border-color: #ffeeb6; }
    
    .table-hover .table-warning:hover {
      background-color: #fff0bf; }
      .table-hover .table-warning:hover > td,
      .table-hover .table-warning:hover > th {
        background-color: #fff0bf; }
    
    .table-danger,
    .table-danger > th,
    .table-danger > td {
      background-color: #ffcdd6; }
    
    .table-danger th,
    .table-danger td,
    .table-danger thead th,
    .table-danger tbody + tbody {
      border-color: #ffa2b2; }
    
    .table-hover .table-danger:hover {
      background-color: #ffb4c1; }
      .table-hover .table-danger:hover > td,
      .table-hover .table-danger:hover > th {
        background-color: #ffb4c1; }
    
    .table-light,
    .table-light > th,
    .table-light > td {
      background-color: #fdfdfe; }
    
    .table-light th,
    .table-light td,
    .table-light thead th,
    .table-light tbody + tbody {
      border-color: #fbfcfc; }
    
    .table-hover .table-light:hover {
      background-color: #ececf6; }
      .table-hover .table-light:hover > td,
      .table-hover .table-light:hover > th {
        background-color: #ececf6; }
    
    .table-dark,
    .table-dark > th,
    .table-dark > td {
      background-color: #c9cdd1; }
    
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #9ba1aa; }
    
    .table-hover .table-dark:hover {
      background-color: #bbc0c5; }
      .table-hover .table-dark:hover > td,
      .table-hover .table-dark:hover > th {
        background-color: #bbc0c5; }
    
    .table-active,
    .table-active > th,
    .table-active > td {
      background-color: rgba(0, 0, 0, 0.075); }
    
    .table-hover .table-active:hover {
      background-color: rgba(0, 0, 0, 0.075); }
      .table-hover .table-active:hover > td,
      .table-hover .table-active:hover > th {
        background-color: rgba(0, 0, 0, 0.075); }
    
    .table .thead-dark th {
      color: #fff;
      background-color: #343a40;
      border-color: #454d55; }
    
    .table .thead-light th {
      color: #495057;
      background-color: #e9ecef;
      border-color: #e8ecf1; }
    
    .table-dark {
      color: #fff;
      background-color: #343a40; }
      .table-dark th,
      .table-dark td,
      .table-dark thead th {
        border-color: #454d55; }
      .table-dark.table-bordered {
        border: 0; }
      .table-dark.table-striped tbody tr:nth-of-type(odd) {
        background-color: rgba(255, 255, 255, 0.05); }
      .table-dark.table-hover tbody tr:hover {
        color: #fff;
        background-color: rgba(255, 255, 255, 0.075); }
    
    @media (max-width: 575.98px) {
      .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch; }
        .table-responsive-sm > .table-bordered {
          border: 0; } }
    
    @media (max-width: 767.98px) {
      .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch; }
        .table-responsive-md > .table-bordered {
          border: 0; } }
    
    @media (max-width: 991.98px) {
      .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch; }
        .table-responsive-lg > .table-bordered {
          border: 0; } }
    
    @media (max-width: 1199.98px) {
      .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch; }
        .table-responsive-xl > .table-bordered {
          border: 0; } }
    
    .table-responsive {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; }
      .table-responsive > .table-bordered {
        border: 0; }
    
    .form-control, .select2-container--default .select2-selection--single, .select2-container--default .select2-selection--single .select2-search__field, .typeahead,
    .tt-query,
    .tt-hint {
      display: block;
      width: 100%;
      height: 2.875rem;
      padding: 0.94rem 1.375rem;
      font-size: 0.8125rem;
      font-weight: 400;
      line-height: 1;
      color: #495057;
      background-color: #ffffff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 2px;
      -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
      transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
      @media (prefers-reduced-motion: reduce) {
        .form-control, .select2-container--default .select2-selection--single, .select2-container--default .select2-selection--single .select2-search__field, .typeahead,
        .tt-query,
        .tt-hint {
          -webkit-transition: none;
          transition: none; } }
      .form-control::-ms-expand, .select2-container--default .select2-selection--single::-ms-expand, .select2-container--default .select2-selection--single .select2-search__field::-ms-expand, .typeahead::-ms-expand,
      .tt-query::-ms-expand,
      .tt-hint::-ms-expand {
        background-color: transparent;
        border: 0; }
      .form-control:-moz-focusring, .select2-container--default .select2-selection--single:-moz-focusring, .select2-container--default .select2-selection--single .select2-search__field:-moz-focusring, .typeahead:-moz-focusring,
      .tt-query:-moz-focusring,
      .tt-hint:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 #495057; }
      .form-control:focus, .select2-container--default .select2-selection--single:focus, .select2-container--default .select2-selection--single .select2-search__field:focus, .typeahead:focus,
      .tt-query:focus,
      .tt-hint:focus {
        color: #495057;
        background-color: #fff;
        border-color: #80bdff;
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
      .form-control::-webkit-input-placeholder, .select2-container--default .select2-selection--single::-webkit-input-placeholder, .select2-container--default .select2-selection--single .select2-search__field::-webkit-input-placeholder, .typeahead::-webkit-input-placeholder, .tt-query::-webkit-input-placeholder, .tt-hint::-webkit-input-placeholder {
        color: #c9c8c8;
        opacity: 1; }
      .form-control::-moz-placeholder, .select2-container--default .select2-selection--single::-moz-placeholder, .select2-container--default .select2-selection--single .select2-search__field::-moz-placeholder, .typeahead::-moz-placeholder, .tt-query::-moz-placeholder, .tt-hint::-moz-placeholder {
        color: #c9c8c8;
        opacity: 1; }
      .form-control:-ms-input-placeholder, .select2-container--default .select2-selection--single:-ms-input-placeholder, .select2-container--default .select2-selection--single .select2-search__field:-ms-input-placeholder, .typeahead:-ms-input-placeholder, .tt-query:-ms-input-placeholder, .tt-hint:-ms-input-placeholder {
        color: #c9c8c8;
        opacity: 1; }
      .form-control::-ms-input-placeholder, .select2-container--default .select2-selection--single::-ms-input-placeholder, .select2-container--default .select2-selection--single .select2-search__field::-ms-input-placeholder, .typeahead::-ms-input-placeholder, .tt-query::-ms-input-placeholder, .tt-hint::-ms-input-placeholder {
        color: #c9c8c8;
        opacity: 1; }
      .form-control::placeholder, .select2-container--default .select2-selection--single::placeholder, .select2-container--default .select2-selection--single .select2-search__field::placeholder, .typeahead::placeholder,
      .tt-query::placeholder,
      .tt-hint::placeholder {
        color: #c9c8c8;
        opacity: 1; }
      .form-control:disabled, .select2-container--default .select2-selection--single:disabled, .select2-container--default .select2-selection--single .select2-search__field:disabled, .typeahead:disabled,
      .tt-query:disabled,
      .tt-hint:disabled, .form-control[readonly], .select2-container--default .select2-selection--single[readonly], .select2-container--default .select2-selection--single .select2-search__field[readonly], .typeahead[readonly],
      .tt-query[readonly],
      .tt-hint[readonly] {
        background-color: #e9ecef;
        opacity: 1; }
    
    input[type="date"].form-control, .select2-container--default input.select2-selection--single[type="date"], .select2-container--default .select2-selection--single input.select2-search__field[type="date"], input.typeahead[type="date"],
    input.tt-query[type="date"],
    input.tt-hint[type="date"],
    input[type="time"].form-control,
    .select2-container--default input.select2-selection--single[type="time"],
    .select2-container--default .select2-selection--single input.select2-search__field[type="time"],
    input.typeahead[type="time"],
    input.tt-query[type="time"],
    input.tt-hint[type="time"],
    input[type="datetime-local"].form-control,
    .select2-container--default input.select2-selection--single[type="datetime-local"],
    .select2-container--default .select2-selection--single input.select2-search__field[type="datetime-local"],
    input.typeahead[type="datetime-local"],
    input.tt-query[type="datetime-local"],
    input.tt-hint[type="datetime-local"],
    input[type="month"].form-control,
    .select2-container--default input.select2-selection--single[type="month"],
    .select2-container--default .select2-selection--single input.select2-search__field[type="month"],
    input.typeahead[type="month"],
    input.tt-query[type="month"],
    input.tt-hint[type="month"] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none; }
    
    select.form-control:focus::-ms-value, .select2-container--default select.select2-selection--single:focus::-ms-value, .select2-container--default .select2-selection--single select.select2-search__field:focus::-ms-value, select.typeahead:focus::-ms-value,
    select.tt-query:focus::-ms-value,
    select.tt-hint:focus::-ms-value {
      color: #495057;
      background-color: #ffffff; }
    
    .form-control-file,
    .form-control-range {
      display: block;
      width: 100%; }
    
    .col-form-label {
      padding-top: calc(0.94rem + 1px);
      padding-bottom: calc(0.94rem + 1px);
      margin-bottom: 0;
      font-size: inherit;
      line-height: 1; }
    
    .col-form-label-lg {
      padding-top: calc(0.94rem + 1px);
      padding-bottom: calc(0.94rem + 1px);
      font-size: 1.25rem;
      line-height: 1.5; }
    
    .col-form-label-sm {
      padding-top: calc(0.5rem + 1px);
      padding-bottom: calc(0.5rem + 1px);
      font-size: 0.875rem;
      line-height: 1.5; }
    
    .form-control-plaintext {
      display: block;
      width: 100%;
      padding: 0.94rem 0;
      margin-bottom: 0;
      font-size: 0.8125rem;
      line-height: 1;
      color: #212529;
      background-color: transparent;
      border: solid transparent;
      border-width: 1px 0; }
      .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
        padding-right: 0;
        padding-left: 0; }
    
    .form-control-sm {
      height: 2.575rem;
      padding: 0.5rem 0.81rem;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 0.2rem; }
    
    .form-control-lg {
      height: 3.175rem;
      padding: 0.94rem 1.94rem;
      font-size: 1.25rem;
      line-height: 1.5;
      border-radius: 0.3rem; }
    
    select.form-control[size], .select2-container--default select.select2-selection--single[size], .select2-container--default .select2-selection--single select.select2-search__field[size], select.typeahead[size],
    select.tt-query[size],
    select.tt-hint[size], select.form-control[multiple], .select2-container--default select.select2-selection--single[multiple], .select2-container--default .select2-selection--single select.select2-search__field[multiple], select.typeahead[multiple],
    select.tt-query[multiple],
    select.tt-hint[multiple] {
      height: auto; }
    
    textarea.form-control, .select2-container--default textarea.select2-selection--single, .select2-container--default .select2-selection--single textarea.select2-search__field, textarea.typeahead,
    textarea.tt-query,
    textarea.tt-hint {
      height: auto; }
    
    .form-group {
      margin-bottom: 1rem; }
    
    .form-text {
      display: block;
      margin-top: 0.25rem; }
    
    .form-row {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-right: -5px;
      margin-left: -5px; }
      .form-row > .col,
      .form-row > [class*="col-"] {
        padding-right: 5px;
        padding-left: 5px; }
    
    .form-check {
      position: relative;
      display: block;
      padding-left: 1.25rem; }
    
    .form-check-input {
      position: absolute;
      margin-top: 0.3rem;
      margin-left: -1.25rem; }
      .form-check-input[disabled] ~ .form-check-label,
      .form-check-input:disabled ~ .form-check-label {
        color: #9c9fa6; }
    
    .form-check-label {
      margin-bottom: 0; }
    
    .form-check-inline {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding-left: 0;
      margin-right: 0.75rem; }
      .form-check-inline .form-check-input {
        position: static;
        margin-top: 0;
        margin-right: 0.3125rem;
        margin-left: 0; }
    
    .valid-feedback {
      display: none;
      width: 100%;
      margin-top: 0.25rem;
      font-size: 80%;
      color: #28a745; }
    
    .valid-tooltip {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 5;
      display: none;
      max-width: 100%;
      padding: 0.25rem 0.5rem;
      margin-top: .1rem;
      font-size: 0.875rem;
      line-height: 1.5;
      color: #fff;
      background-color: rgba(40, 167, 69, 0.9);
      border-radius: 0.25rem; }
    
    .was-validated :valid ~ .valid-feedback,
    .was-validated :valid ~ .valid-tooltip,
    .is-valid ~ .valid-feedback,
    .is-valid ~ .valid-tooltip {
      display: block; }
    
    .was-validated .form-control:valid, .was-validated .select2-container--default .select2-selection--single:valid, .select2-container--default .was-validated .select2-selection--single:valid, .was-validated .select2-container--default .select2-selection--single .select2-search__field:valid, .select2-container--default .select2-selection--single .was-validated .select2-search__field:valid, .was-validated .typeahead:valid,
    .was-validated .tt-query:valid,
    .was-validated .tt-hint:valid, .form-control.is-valid, .select2-container--default .is-valid.select2-selection--single, .select2-container--default .select2-selection--single .is-valid.select2-search__field, .is-valid.typeahead,
    .is-valid.tt-query,
    .is-valid.tt-hint {
      border-color: #28a745;
      padding-right: calc(1.5em + 0.75rem);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.1875rem) center;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
      .was-validated .form-control:valid:focus, .was-validated .select2-container--default .select2-selection--single:valid:focus, .select2-container--default .was-validated .select2-selection--single:valid:focus, .was-validated .select2-container--default .select2-selection--single .select2-search__field:valid:focus, .select2-container--default .select2-selection--single .was-validated .select2-search__field:valid:focus, .was-validated .typeahead:valid:focus,
      .was-validated .tt-query:valid:focus,
      .was-validated .tt-hint:valid:focus, .form-control.is-valid:focus, .select2-container--default .is-valid.select2-selection--single:focus, .select2-container--default .select2-selection--single .is-valid.select2-search__field:focus, .is-valid.typeahead:focus,
      .is-valid.tt-query:focus,
      .is-valid.tt-hint:focus {
        border-color: #28a745;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
    
    .was-validated textarea.form-control:valid, .was-validated .select2-container--default textarea.select2-selection--single:valid, .select2-container--default .was-validated textarea.select2-selection--single:valid, .was-validated .select2-container--default .select2-selection--single textarea.select2-search__field:valid, .select2-container--default .select2-selection--single .was-validated textarea.select2-search__field:valid, .was-validated textarea.typeahead:valid,
    .was-validated textarea.tt-query:valid,
    .was-validated textarea.tt-hint:valid, textarea.form-control.is-valid, .select2-container--default textarea.is-valid.select2-selection--single, .select2-container--default .select2-selection--single textarea.is-valid.select2-search__field, textarea.is-valid.typeahead,
    textarea.is-valid.tt-query,
    textarea.is-valid.tt-hint {
      padding-right: calc(1.5em + 0.75rem);
      background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }
    
    .was-validated .custom-select:valid, .custom-select.is-valid {
      border-color: #28a745;
      padding-right: calc(0.75em + 2.3125rem);
      background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
      .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
        border-color: #28a745;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
    
    .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
      color: #28a745; }
    
    .was-validated .form-check-input:valid ~ .valid-feedback,
    .was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
    .form-check-input.is-valid ~ .valid-tooltip {
      display: block; }
    
    .was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
      color: #28a745; }
      .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
        border-color: #28a745; }
    
    .was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
      border-color: #34ce57;
      background-color: #34ce57; }
    
    .was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
    
    .was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
      border-color: #28a745; }
    
    .was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
      border-color: #28a745; }
    
    .was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
      border-color: #28a745;
      -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
    
    .invalid-feedback {
      display: none;
      width: 100%;
      margin-top: 0.25rem;
      font-size: 80%;
      color: #dc3545; }
    
    .invalid-tooltip {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 5;
      display: none;
      max-width: 100%;
      padding: 0.25rem 0.5rem;
      margin-top: .1rem;
      font-size: 0.875rem;
      line-height: 1.5;
      color: #fff;
      background-color: rgba(220, 53, 69, 0.9);
      border-radius: 0.25rem; }
    
    .was-validated :invalid ~ .invalid-feedback,
    .was-validated :invalid ~ .invalid-tooltip,
    .is-invalid ~ .invalid-feedback,
    .is-invalid ~ .invalid-tooltip {
      display: block; }
    
    .was-validated .form-control:invalid, .was-validated .select2-container--default .select2-selection--single:invalid, .select2-container--default .was-validated .select2-selection--single:invalid, .was-validated .select2-container--default .select2-selection--single .select2-search__field:invalid, .select2-container--default .select2-selection--single .was-validated .select2-search__field:invalid, .was-validated .typeahead:invalid,
    .was-validated .tt-query:invalid,
    .was-validated .tt-hint:invalid, .form-control.is-invalid, .select2-container--default .is-invalid.select2-selection--single, .select2-container--default .select2-selection--single .is-invalid.select2-search__field, .is-invalid.typeahead,
    .is-invalid.tt-query,
    .is-invalid.tt-hint {
      border-color: #dc3545;
      padding-right: calc(1.5em + 0.75rem);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.1875rem) center;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
      .was-validated .form-control:invalid:focus, .was-validated .select2-container--default .select2-selection--single:invalid:focus, .select2-container--default .was-validated .select2-selection--single:invalid:focus, .was-validated .select2-container--default .select2-selection--single .select2-search__field:invalid:focus, .select2-container--default .select2-selection--single .was-validated .select2-search__field:invalid:focus, .was-validated .typeahead:invalid:focus,
      .was-validated .tt-query:invalid:focus,
      .was-validated .tt-hint:invalid:focus, .form-control.is-invalid:focus, .select2-container--default .is-invalid.select2-selection--single:focus, .select2-container--default .select2-selection--single .is-invalid.select2-search__field:focus, .is-invalid.typeahead:focus,
      .is-invalid.tt-query:focus,
      .is-invalid.tt-hint:focus {
        border-color: #dc3545;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
    
    .was-validated textarea.form-control:invalid, .was-validated .select2-container--default textarea.select2-selection--single:invalid, .select2-container--default .was-validated textarea.select2-selection--single:invalid, .was-validated .select2-container--default .select2-selection--single textarea.select2-search__field:invalid, .select2-container--default .select2-selection--single .was-validated textarea.select2-search__field:invalid, .was-validated textarea.typeahead:invalid,
    .was-validated textarea.tt-query:invalid,
    .was-validated textarea.tt-hint:invalid, textarea.form-control.is-invalid, .select2-container--default textarea.is-invalid.select2-selection--single, .select2-container--default .select2-selection--single textarea.is-invalid.select2-search__field, textarea.is-invalid.typeahead,
    textarea.is-invalid.tt-query,
    textarea.is-invalid.tt-hint {
      padding-right: calc(1.5em + 0.75rem);
      background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }
    
    .was-validated .custom-select:invalid, .custom-select.is-invalid {
      border-color: #dc3545;
      padding-right: calc(0.75em + 2.3125rem);
      background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
      .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
        border-color: #dc3545;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
    
    .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
      color: #dc3545; }
    
    .was-validated .form-check-input:invalid ~ .invalid-feedback,
    .was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
    .form-check-input.is-invalid ~ .invalid-tooltip {
      display: block; }
    
    .was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
      color: #dc3545; }
      .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
        border-color: #dc3545; }
    
    .was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
      border-color: #e4606d;
      background-color: #e4606d; }
    
    .was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
    
    .was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
      border-color: #dc3545; }
    
    .was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
      border-color: #dc3545; }
    
    .was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
      border-color: #dc3545;
      -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
    
    .form-inline {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      .form-inline .form-check {
        width: 100%; }
      @media (min-width: 576px) {
        .form-inline label {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          margin-bottom: 0; }
        .form-inline .form-group {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
          flex: 0 0 auto;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-flow: row wrap;
          flex-flow: row wrap;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          margin-bottom: 0; }
        .form-inline .form-control, .form-inline .select2-container--default .select2-selection--single, .select2-container--default .form-inline .select2-selection--single, .form-inline .select2-container--default .select2-selection--single .select2-search__field, .select2-container--default .select2-selection--single .form-inline .select2-search__field, .form-inline .typeahead,
        .form-inline .tt-query,
        .form-inline .tt-hint {
          display: inline-block;
          width: auto;
          vertical-align: middle; }
        .form-inline .form-control-plaintext {
          display: inline-block; }
        .form-inline .input-group,
        .form-inline .custom-select {
          width: auto; }
        .form-inline .form-check {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          width: auto;
          padding-left: 0; }
        .form-inline .form-check-input {
          position: relative;
          -ms-flex-negative: 0;
          flex-shrink: 0;
          margin-top: 0;
          margin-right: 0.25rem;
          margin-left: 0; }
        .form-inline .custom-control {
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center; }
        .form-inline .custom-control-label {
          margin-bottom: 0; } }
    
    .btn {
      display: inline-block;
      font-weight: 400;
      color: #343a40;
      text-align: center;
      vertical-align: middle;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      background-color: transparent;
      border: 1px solid transparent;
      padding: 0.875rem 2.5rem;
      font-size: 0.875rem;
      line-height: 1;
      border-radius: 0.1875rem;
      -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
      @media (prefers-reduced-motion: reduce) {
        .btn {
          -webkit-transition: none;
          transition: none; } }
      .btn:hover {
        color: #343a40;
        text-decoration: none; }
      .btn:focus, .btn.focus {
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
      .btn.disabled, .btn:disabled {
        opacity: 0.65; }
      .btn:not(:disabled):not(.disabled) {
        cursor: pointer; }
    
    a.btn.disabled,
    fieldset:disabled a.btn {
      pointer-events: none; }
    
    .btn-primary {
      color: #212529;
      background-color: #1bdbe0;
      border-color: #1bdbe0; }
      .btn-primary:hover {
        color: #fff;
        background-color: #17babe;
        border-color: #16afb2; }
      .btn-primary:focus, .btn-primary.focus {
        color: #fff;
        background-color: #17babe;
        border-color: #16afb2;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(28, 192, 197, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(28, 192, 197, 0.5); }
      .btn-primary.disabled, .btn-primary:disabled {
        color: #212529;
        background-color: #1bdbe0;
        border-color: #1bdbe0; }
      .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
      .show > .btn-primary.dropdown-toggle {
        color: #fff;
        background-color: #16afb2;
        border-color: #14a3a7; }
        .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
        .show > .btn-primary.dropdown-toggle:focus {
          -webkit-box-shadow: 0 0 0 0.2rem rgba(28, 192, 197, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(28, 192, 197, 0.5); }
    
    .btn-secondary {
      color: #212529;
      background-color: #d8d8d8;
      border-color: #d8d8d8; }
      .btn-secondary:hover {
        color: #212529;
        background-color: #c5c5c5;
        border-color: #bfbfbf; }
      .btn-secondary:focus, .btn-secondary.focus {
        color: #212529;
        background-color: #c5c5c5;
        border-color: #bfbfbf;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(189, 189, 190, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(189, 189, 190, 0.5); }
      .btn-secondary.disabled, .btn-secondary:disabled {
        color: #212529;
        background-color: #d8d8d8;
        border-color: #d8d8d8; }
      .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
      .show > .btn-secondary.dropdown-toggle {
        color: #212529;
        background-color: #bfbfbf;
        border-color: #b8b8b8; }
        .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
        .show > .btn-secondary.dropdown-toggle:focus {
          -webkit-box-shadow: 0 0 0 0.2rem rgba(189, 189, 190, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(189, 189, 190, 0.5); }
    
    .btn-success {
      color: #fff;
      background-color: #38ce3c;
      border-color: #38ce3c; }
      .btn-success:hover {
        color: #fff;
        background-color: #2cb430;
        border-color: #2aa92d; }
      .btn-success:focus, .btn-success.focus {
        color: #fff;
        background-color: #2cb430;
        border-color: #2aa92d;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(86, 213, 89, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(86, 213, 89, 0.5); }
      .btn-success.disabled, .btn-success:disabled {
        color: #fff;
        background-color: #38ce3c;
        border-color: #38ce3c; }
      .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
      .show > .btn-success.dropdown-toggle {
        color: #fff;
        background-color: #2aa92d;
        border-color: #279f2a; }
        .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
        .show > .btn-success.dropdown-toggle:focus {
          -webkit-box-shadow: 0 0 0 0.2rem rgba(86, 213, 89, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(86, 213, 89, 0.5); }
    
    .btn-info {
      color: #fff;
      background-color: #8e32e9;
      border-color: #8e32e9; }
      .btn-info:hover {
        color: #fff;
        background-color: #7b18dd;
        border-color: #7516d2; }
      .btn-info:focus, .btn-info.focus {
        color: #fff;
        background-color: #7b18dd;
        border-color: #7516d2;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(159, 81, 236, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(159, 81, 236, 0.5); }
      .btn-info.disabled, .btn-info:disabled {
        color: #fff;
        background-color: #8e32e9;
        border-color: #8e32e9; }
      .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
      .show > .btn-info.dropdown-toggle {
        color: #fff;
        background-color: #7516d2;
        border-color: #6e15c6; }
        .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
        .show > .btn-info.dropdown-toggle:focus {
          -webkit-box-shadow: 0 0 0 0.2rem rgba(159, 81, 236, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(159, 81, 236, 0.5); }
    
    .btn-warning {
      color: #212529;
      background-color: #ffde73;
      border-color: #ffde73; }
      .btn-warning:hover {
        color: #212529;
        background-color: #ffd54d;
        border-color: #ffd240; }
      .btn-warning:focus, .btn-warning.focus {
        color: #212529;
        background-color: #ffd54d;
        border-color: #ffd240;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 194, 104, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(222, 194, 104, 0.5); }
      .btn-warning.disabled, .btn-warning:disabled {
        color: #212529;
        background-color: #ffde73;
        border-color: #ffde73; }
      .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
      .show > .btn-warning.dropdown-toggle {
        color: #212529;
        background-color: #ffd240;
        border-color: #ffcf33; }
        .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
        .show > .btn-warning.dropdown-toggle:focus {
          -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 194, 104, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(222, 194, 104, 0.5); }
    
    .btn-danger {
      color: #fff;
      background-color: #ff4d6b;
      border-color: #ff4d6b; }
      .btn-danger:hover {
        color: #fff;
        background-color: #ff274b;
        border-color: #ff1a41; }
      .btn-danger:focus, .btn-danger.focus {
        color: #fff;
        background-color: #ff274b;
        border-color: #ff1a41;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 104, 129, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(255, 104, 129, 0.5); }
      .btn-danger.disabled, .btn-danger:disabled {
        color: #fff;
        background-color: #ff4d6b;
        border-color: #ff4d6b; }
      .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
      .show > .btn-danger.dropdown-toggle {
        color: #fff;
        background-color: #ff1a41;
        border-color: #ff0d36; }
        .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
        .show > .btn-danger.dropdown-toggle:focus {
          -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 104, 129, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(255, 104, 129, 0.5); }
    
    .btn-light {
      color: #212529;
      background-color: #f8f9fa;
      border-color: #f8f9fa; }
      .btn-light:hover {
        color: #212529;
        background-color: #e2e6ea;
        border-color: #dae0e5; }
      .btn-light:focus, .btn-light.focus {
        color: #212529;
        background-color: #e2e6ea;
        border-color: #dae0e5;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
      .btn-light.disabled, .btn-light:disabled {
        color: #212529;
        background-color: #f8f9fa;
        border-color: #f8f9fa; }
      .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
      .show > .btn-light.dropdown-toggle {
        color: #212529;
        background-color: #dae0e5;
        border-color: #d3d9df; }
        .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
        .show > .btn-light.dropdown-toggle:focus {
          -webkit-box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
    
    .btn-dark {
      color: #fff;
      background-color: #3e4b5b;
      border-color: #3e4b5b; }
      .btn-dark:hover {
        color: #fff;
        background-color: #2f3844;
        border-color: #29323d; }
      .btn-dark:focus, .btn-dark.focus {
        color: #fff;
        background-color: #2f3844;
        border-color: #29323d;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(91, 102, 116, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(91, 102, 116, 0.5); }
      .btn-dark.disabled, .btn-dark:disabled {
        color: #fff;
        background-color: #3e4b5b;
        border-color: #3e4b5b; }
      .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
      .show > .btn-dark.dropdown-toggle {
        color: #fff;
        background-color: #29323d;
        border-color: #242c35; }
        .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
        .show > .btn-dark.dropdown-toggle:focus {
          -webkit-box-shadow: 0 0 0 0.2rem rgba(91, 102, 116, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(91, 102, 116, 0.5); }
    
    .btn-outline-primary {
      color: #1bdbe0;
      border-color: #1bdbe0; }
      .btn-outline-primary:hover {
        color: #212529;
        background-color: #1bdbe0;
        border-color: #1bdbe0; }
      .btn-outline-primary:focus, .btn-outline-primary.focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(27, 219, 224, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(27, 219, 224, 0.5); }
      .btn-outline-primary.disabled, .btn-outline-primary:disabled {
        color: #1bdbe0;
        background-color: transparent; }
      .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
      .show > .btn-outline-primary.dropdown-toggle {
        color: #212529;
        background-color: #1bdbe0;
        border-color: #1bdbe0; }
        .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
        .show > .btn-outline-primary.dropdown-toggle:focus {
          -webkit-box-shadow: 0 0 0 0.2rem rgba(27, 219, 224, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(27, 219, 224, 0.5); }
    
    .btn-outline-secondary {
      color: #d8d8d8;
      border-color: #d8d8d8; }
      .btn-outline-secondary:hover {
        color: #212529;
        background-color: #d8d8d8;
        border-color: #d8d8d8; }
      .btn-outline-secondary:focus, .btn-outline-secondary.focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(216, 216, 216, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(216, 216, 216, 0.5); }
      .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
        color: #d8d8d8;
        background-color: transparent; }
      .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
      .show > .btn-outline-secondary.dropdown-toggle {
        color: #212529;
        background-color: #d8d8d8;
        border-color: #d8d8d8; }
        .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
        .show > .btn-outline-secondary.dropdown-toggle:focus {
          -webkit-box-shadow: 0 0 0 0.2rem rgba(216, 216, 216, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(216, 216, 216, 0.5); }
    
    .btn-outline-success {
      color: #38ce3c;
      border-color: #38ce3c; }
      .btn-outline-success:hover {
        color: #fff;
        background-color: #38ce3c;
        border-color: #38ce3c; }
      .btn-outline-success:focus, .btn-outline-success.focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(56, 206, 60, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(56, 206, 60, 0.5); }
      .btn-outline-success.disabled, .btn-outline-success:disabled {
        color: #38ce3c;
        background-color: transparent; }
      .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
      .show > .btn-outline-success.dropdown-toggle {
        color: #fff;
        background-color: #38ce3c;
        border-color: #38ce3c; }
        .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
        .show > .btn-outline-success.dropdown-toggle:focus {
          -webkit-box-shadow: 0 0 0 0.2rem rgba(56, 206, 60, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(56, 206, 60, 0.5); }
    
    .btn-outline-info {
      color: #8e32e9;
      border-color: #8e32e9; }
      .btn-outline-info:hover {
        color: #fff;
        background-color: #8e32e9;
        border-color: #8e32e9; }
      .btn-outline-info:focus, .btn-outline-info.focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(142, 50, 233, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(142, 50, 233, 0.5); }
      .btn-outline-info.disabled, .btn-outline-info:disabled {
        color: #8e32e9;
        background-color: transparent; }
      .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
      .show > .btn-outline-info.dropdown-toggle {
        color: #fff;
        background-color: #8e32e9;
        border-color: #8e32e9; }
        .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
        .show > .btn-outline-info.dropdown-toggle:focus {
          -webkit-box-shadow: 0 0 0 0.2rem rgba(142, 50, 233, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(142, 50, 233, 0.5); }
    
    .btn-outline-warning {
      color: #ffde73;
      border-color: #ffde73; }
      .btn-outline-warning:hover {
        color: #212529;
        background-color: #ffde73;
        border-color: #ffde73; }
      .btn-outline-warning:focus, .btn-outline-warning.focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 222, 115, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(255, 222, 115, 0.5); }
      .btn-outline-warning.disabled, .btn-outline-warning:disabled {
        color: #ffde73;
        background-color: transparent; }
      .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
      .show > .btn-outline-warning.dropdown-toggle {
        color: #212529;
        background-color: #ffde73;
        border-color: #ffde73; }
        .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
        .show > .btn-outline-warning.dropdown-toggle:focus {
          -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 222, 115, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(255, 222, 115, 0.5); }
    
    .btn-outline-danger {
      color: #ff4d6b;
      border-color: #ff4d6b; }
      .btn-outline-danger:hover {
        color: #fff;
        background-color: #ff4d6b;
        border-color: #ff4d6b; }
      .btn-outline-danger:focus, .btn-outline-danger.focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 77, 107, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(255, 77, 107, 0.5); }
      .btn-outline-danger.disabled, .btn-outline-danger:disabled {
        color: #ff4d6b;
        background-color: transparent; }
      .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
      .show > .btn-outline-danger.dropdown-toggle {
        color: #fff;
        background-color: #ff4d6b;
        border-color: #ff4d6b; }
        .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
        .show > .btn-outline-danger.dropdown-toggle:focus {
          -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 77, 107, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(255, 77, 107, 0.5); }
    
    .btn-outline-light {
      color: #f8f9fa;
      border-color: #f8f9fa; }
      .btn-outline-light:hover {
        color: #212529;
        background-color: #f8f9fa;
        border-color: #f8f9fa; }
      .btn-outline-light:focus, .btn-outline-light.focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
      .btn-outline-light.disabled, .btn-outline-light:disabled {
        color: #f8f9fa;
        background-color: transparent; }
      .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
      .show > .btn-outline-light.dropdown-toggle {
        color: #212529;
        background-color: #f8f9fa;
        border-color: #f8f9fa; }
        .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
        .show > .btn-outline-light.dropdown-toggle:focus {
          -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
    
    .btn-outline-dark {
      color: #3e4b5b;
      border-color: #3e4b5b; }
      .btn-outline-dark:hover {
        color: #fff;
        background-color: #3e4b5b;
        border-color: #3e4b5b; }
      .btn-outline-dark:focus, .btn-outline-dark.focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(62, 75, 91, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(62, 75, 91, 0.5); }
      .btn-outline-dark.disabled, .btn-outline-dark:disabled {
        color: #3e4b5b;
        background-color: transparent; }
      .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
      .show > .btn-outline-dark.dropdown-toggle {
        color: #fff;
        background-color: #3e4b5b;
        border-color: #3e4b5b; }
        .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
        .show > .btn-outline-dark.dropdown-toggle:focus {
          -webkit-box-shadow: 0 0 0 0.2rem rgba(62, 75, 91, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(62, 75, 91, 0.5); }
    
    .btn-link {
      font-weight: 400;
      color: #007bff;
      text-decoration: none; }
      .btn-link:hover {
        color: #0056b3;
        text-decoration: underline; }
      .btn-link:focus, .btn-link.focus {
        text-decoration: underline; }
      .btn-link:disabled, .btn-link.disabled {
        color: #6c757d;
        pointer-events: none; }
    
    .btn-lg, .btn-group-lg > .btn {
      padding: 1rem 3rem;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 0.1875rem; }
    
    .btn-sm, .btn-group-sm > .btn {
      padding: 0.5rem 0.81rem;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 0.1875rem; }
    
    .btn-block {
      display: block;
      width: 100%; }
      .btn-block + .btn-block {
        margin-top: 0.5rem; }
    
    input[type="submit"].btn-block,
    input[type="reset"].btn-block,
    input[type="button"].btn-block {
      width: 100%; }
    
    .fade {
      -webkit-transition: opacity 0.15s linear;
      transition: opacity 0.15s linear; }
      @media (prefers-reduced-motion: reduce) {
        .fade {
          -webkit-transition: none;
          transition: none; } }
      .fade:not(.show) {
        opacity: 0; }
    
    
    
    .collapsing {
      position: relative;
      height: 0;
      overflow: hidden;
      -webkit-transition: height 0.35s ease;
      transition: height 0.35s ease; }
      @media (prefers-reduced-motion: reduce) {
        .collapsing {
          -webkit-transition: none;
          transition: none; } }
    
    .dropup,
    .dropright,
    .dropdown,
    .dropleft {
      position: relative; }
    
    .dropdown-toggle {
      white-space: nowrap; }
      .dropdown-toggle::after {
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent; }
      .dropdown-toggle:empty::after {
        margin-left: 0; }
    
    .dropdown-menu {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1000;
      display: none;
      float: left;
      min-width: 10rem;
      padding: 0.5rem 0;
      margin: 0.125rem 0 0;
      font-size: 1rem;
      color: #212529;
      text-align: left;
      list-style: none;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #e8ecf1;
      border-radius: 0.25rem; }
    
    .dropdown-menu-left {
      right: auto;
      left: 0; }
    
    .dropdown-menu-right {
      right: 0;
      left: auto; }
    
    @media (min-width: 576px) {
      .dropdown-menu-sm-left {
        right: auto;
        left: 0; }
      .dropdown-menu-sm-right {
        right: 0;
        left: auto; } }
    
    @media (min-width: 768px) {
      .dropdown-menu-md-left {
        right: auto;
        left: 0; }
      .dropdown-menu-md-right {
        right: 0;
        left: auto; } }
    
    @media (min-width: 992px) {
      .dropdown-menu-lg-left {
        right: auto;
        left: 0; }
      .dropdown-menu-lg-right {
        right: 0;
        left: auto; } }
    
    @media (min-width: 1200px) {
      .dropdown-menu-xl-left {
        right: auto;
        left: 0; }
      .dropdown-menu-xl-right {
        right: 0;
        left: auto; } }
    
    .dropup .dropdown-menu {
      top: auto;
      bottom: 100%;
      margin-top: 0;
      margin-bottom: 0.125rem; }
    
    .dropup .dropdown-toggle::after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0;
      border-right: 0.3em solid transparent;
      border-bottom: 0.3em solid;
      border-left: 0.3em solid transparent; }
    
    .dropup .dropdown-toggle:empty::after {
      margin-left: 0; }
    
    .dropright .dropdown-menu {
      top: 0;
      right: auto;
      left: 100%;
      margin-top: 0;
      margin-left: 0.125rem; }
    
    .dropright .dropdown-toggle::after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid transparent;
      border-right: 0;
      border-bottom: 0.3em solid transparent;
      border-left: 0.3em solid; }
    
    .dropright .dropdown-toggle:empty::after {
      margin-left: 0; }
    
    .dropright .dropdown-toggle::after {
      vertical-align: 0; }
    
    .dropleft .dropdown-menu {
      top: 0;
      right: 100%;
      left: auto;
      margin-top: 0;
      margin-right: 0.125rem; }
    
    .dropleft .dropdown-toggle::after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: ""; }
    
    .dropleft .dropdown-toggle::after {
      display: none; }
    
    .dropleft .dropdown-toggle::before {
      display: inline-block;
      margin-right: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid transparent;
      border-right: 0.3em solid;
      border-bottom: 0.3em solid transparent; }
    
    .dropleft .dropdown-toggle:empty::after {
      margin-left: 0; }
    
    .dropleft .dropdown-toggle::before {
      vertical-align: 0; }
    
    .dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
      right: auto;
      bottom: auto; }
    
    .dropdown-divider {
      height: 0;
      margin: 0.5rem 0;
      overflow: hidden;
      border-top: 1px solid #e8ecf1; }
    
    .dropdown-item {
      display: block;
      width: 100%;
      padding: 0.25rem 1.5rem;
      clear: both;
      font-weight: 400;
      color: #343a40;
      text-align: inherit;
      white-space: nowrap;
      background-color: transparent;
      border: 0; }
      .dropdown-item:hover, .dropdown-item:focus {
        color: #16181b;
        text-decoration: none;
        background-color: #f8f9fa; }
      .dropdown-item.active, .dropdown-item:active {
        color: #fff;
        text-decoration: none;
        background-color: #007bff; }
      .dropdown-item.disabled, .dropdown-item:disabled {
        color: #6c757d;
        pointer-events: none;
        background-color: transparent; }
    
    .dropdown-menu.show {
      display: block; }
    
    .dropdown-header {
      display: block;
      padding: 0.5rem 1.5rem;
      margin-bottom: 0;
      font-size: 0.875rem;
      color: #343a40;
      white-space: nowrap; }
    
    .dropdown-item-text {
      display: block;
      padding: 0.25rem 1.5rem;
      color: #343a40; }
    
    .btn-group,
    .btn-group-vertical {
      position: relative;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      vertical-align: middle; }
      .btn-group > .btn,
      .btn-group-vertical > .btn {
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto; }
        .btn-group > .btn:hover,
        .btn-group-vertical > .btn:hover {
          z-index: 1; }
        .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
        .btn-group-vertical > .btn:focus,
        .btn-group-vertical > .btn:active,
        .btn-group-vertical > .btn.active {
          z-index: 1; }
    
    .btn-toolbar {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start; }
      .btn-toolbar .input-group {
        width: auto; }
    
    .btn-group > .btn:not(:first-child),
    .btn-group > .btn-group:not(:first-child) {
      margin-left: -1px; }
    
    .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
    .btn-group > .btn-group:not(:last-child) > .btn {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    
    .btn-group > .btn:not(:first-child),
    .btn-group > .btn-group:not(:first-child) > .btn {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
    
    .dropdown-toggle-split {
      padding-right: 1.875rem;
      padding-left: 1.875rem; }
      .dropdown-toggle-split::after,
      .dropup .dropdown-toggle-split::after,
      .dropright .dropdown-toggle-split::after {
        margin-left: 0; }
      .dropleft .dropdown-toggle-split::before {
        margin-right: 0; }
    
    .btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
      padding-right: 0.6075rem;
      padding-left: 0.6075rem; }
    
    .btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
      padding-right: 2.25rem;
      padding-left: 2.25rem; }
    
    .btn-group-vertical {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center; }
      .btn-group-vertical > .btn,
      .btn-group-vertical > .btn-group {
        width: 100%; }
      .btn-group-vertical > .btn:not(:first-child),
      .btn-group-vertical > .btn-group:not(:first-child) {
        margin-top: -1px; }
      .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
      .btn-group-vertical > .btn-group:not(:last-child) > .btn {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0; }
      .btn-group-vertical > .btn:not(:first-child),
      .btn-group-vertical > .btn-group:not(:first-child) > .btn {
        border-top-left-radius: 0;
        border-top-right-radius: 0; }
    
    .btn-group-toggle > .btn,
    .btn-group-toggle > .btn-group > .btn {
      margin-bottom: 0; }
      .btn-group-toggle > .btn input[type="radio"],
      .btn-group-toggle > .btn input[type="checkbox"],
      .btn-group-toggle > .btn-group > .btn input[type="radio"],
      .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
        position: absolute;
        clip: rect(0, 0, 0, 0);
        pointer-events: none; }
    
    .input-group {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
      width: 100%; }
      .input-group > .form-control, .select2-container--default .input-group > .select2-selection--single, .select2-container--default .select2-selection--single .input-group > .select2-search__field, .input-group > .typeahead,
      .input-group > .tt-query,
      .input-group > .tt-hint,
      .input-group > .form-control-plaintext,
      .input-group > .custom-select,
      .input-group > .custom-file {
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        width: 1%;
        min-width: 0;
        margin-bottom: 0; }
        .input-group > .form-control + .form-control, .select2-container--default .input-group > .select2-selection--single + .form-control, .select2-container--default .select2-selection--single .input-group > .select2-search__field + .form-control, .input-group > .typeahead + .form-control, .input-group > .tt-query + .form-control, .input-group > .tt-hint + .form-control, .select2-container--default .input-group > .form-control + .select2-selection--single, .select2-container--default .input-group > .select2-selection--single + .select2-selection--single, .select2-container--default .select2-selection--single .input-group > .select2-search__field + .select2-selection--single, .select2-container--default .input-group > .typeahead + .select2-selection--single, .select2-container--default .input-group > .tt-query + .select2-selection--single, .select2-container--default .input-group > .tt-hint + .select2-selection--single, .select2-container--default .select2-selection--single .input-group > .form-control + .select2-search__field, .select2-container--default .select2-selection--single .input-group > .select2-selection--single + .select2-search__field, .select2-container--default .select2-selection--single .input-group > .select2-search__field + .select2-search__field, .select2-container--default .select2-selection--single .input-group > .typeahead + .select2-search__field, .select2-container--default .select2-selection--single .input-group > .tt-query + .select2-search__field, .select2-container--default .select2-selection--single .input-group > .tt-hint + .select2-search__field, .input-group > .form-control + .typeahead, .select2-container--default .input-group > .select2-selection--single + .typeahead, .select2-container--default .select2-selection--single .input-group > .select2-search__field + .typeahead, .input-group > .typeahead + .typeahead, .input-group > .tt-query + .typeahead, .input-group > .tt-hint + .typeahead,
        .input-group > .form-control + .tt-query,
        .select2-container--default .input-group > .select2-selection--single + .tt-query,
        .select2-container--default .select2-selection--single .input-group > .select2-search__field + .tt-query,
        .input-group > .typeahead + .tt-query,
        .input-group > .tt-query + .tt-query,
        .input-group > .tt-hint + .tt-query,
        .input-group > .form-control + .tt-hint,
        .select2-container--default .input-group > .select2-selection--single + .tt-hint,
        .select2-container--default .select2-selection--single .input-group > .select2-search__field + .tt-hint,
        .input-group > .typeahead + .tt-hint,
        .input-group > .tt-query + .tt-hint,
        .input-group > .tt-hint + .tt-hint,
        .input-group > .form-control + .custom-select,
        .select2-container--default .input-group > .select2-selection--single + .custom-select,
        .select2-container--default .select2-selection--single .input-group > .select2-search__field + .custom-select,
        .input-group > .typeahead + .custom-select,
        .input-group > .tt-query + .custom-select,
        .input-group > .tt-hint + .custom-select,
        .input-group > .form-control + .custom-file,
        .select2-container--default .input-group > .select2-selection--single + .custom-file,
        .select2-container--default .select2-selection--single .input-group > .select2-search__field + .custom-file,
        .input-group > .typeahead + .custom-file,
        .input-group > .tt-query + .custom-file,
        .input-group > .tt-hint + .custom-file,
        .input-group > .form-control-plaintext + .form-control,
        .select2-container--default .input-group > .form-control-plaintext + .select2-selection--single,
        .select2-container--default .select2-selection--single .input-group > .form-control-plaintext + .select2-search__field,
        .input-group > .form-control-plaintext + .typeahead,
        .input-group > .form-control-plaintext + .tt-query,
        .input-group > .form-control-plaintext + .tt-hint,
        .input-group > .form-control-plaintext + .custom-select,
        .input-group > .form-control-plaintext + .custom-file,
        .input-group > .custom-select + .form-control,
        .select2-container--default .input-group > .custom-select + .select2-selection--single,
        .select2-container--default .select2-selection--single .input-group > .custom-select + .select2-search__field,
        .input-group > .custom-select + .typeahead,
        .input-group > .custom-select + .tt-query,
        .input-group > .custom-select + .tt-hint,
        .input-group > .custom-select + .custom-select,
        .input-group > .custom-select + .custom-file,
        .input-group > .custom-file + .form-control,
        .select2-container--default .input-group > .custom-file + .select2-selection--single,
        .select2-container--default .select2-selection--single .input-group > .custom-file + .select2-search__field,
        .input-group > .custom-file + .typeahead,
        .input-group > .custom-file + .tt-query,
        .input-group > .custom-file + .tt-hint,
        .input-group > .custom-file + .custom-select,
        .input-group > .custom-file + .custom-file {
          margin-left: -1px; }
      .input-group > .form-control:focus, .select2-container--default .input-group > .select2-selection--single:focus, .select2-container--default .select2-selection--single .input-group > .select2-search__field:focus, .input-group > .typeahead:focus,
      .input-group > .tt-query:focus,
      .input-group > .tt-hint:focus,
      .input-group > .custom-select:focus,
      .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
        z-index: 3; }
      .input-group > .custom-file .custom-file-input:focus {
        z-index: 4; }
      .input-group > .form-control:not(:last-child), .select2-container--default .input-group > .select2-selection--single:not(:last-child), .select2-container--default .select2-selection--single .input-group > .select2-search__field:not(:last-child), .input-group > .typeahead:not(:last-child),
      .input-group > .tt-query:not(:last-child),
      .input-group > .tt-hint:not(:last-child),
      .input-group > .custom-select:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
      .input-group > .form-control:not(:first-child), .select2-container--default .input-group > .select2-selection--single:not(:first-child), .select2-container--default .select2-selection--single .input-group > .select2-search__field:not(:first-child), .input-group > .typeahead:not(:first-child),
      .input-group > .tt-query:not(:first-child),
      .input-group > .tt-hint:not(:first-child),
      .input-group > .custom-select:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
      .input-group > .custom-file {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center; }
        .input-group > .custom-file:not(:last-child) .custom-file-label,
        .input-group > .custom-file:not(:last-child) .custom-file-label::after {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
        .input-group > .custom-file:not(:first-child) .custom-file-label {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
    
    .input-group-prepend,
    .input-group-append {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      .input-group-prepend .btn,
      .input-group-append .btn {
        position: relative;
        z-index: 2; }
        .input-group-prepend .btn:focus,
        .input-group-append .btn:focus {
          z-index: 3; }
      .input-group-prepend .btn + .btn,
      .input-group-prepend .btn + .input-group-text,
      .input-group-prepend .input-group-text + .input-group-text,
      .input-group-prepend .input-group-text + .btn,
      .input-group-append .btn + .btn,
      .input-group-append .btn + .input-group-text,
      .input-group-append .input-group-text + .input-group-text,
      .input-group-append .input-group-text + .btn {
        margin-left: -1px; }
    
    .input-group-prepend {
      margin-right: -1px; }
    
    .input-group-append {
      margin-left: -1px; }
    
    .input-group-text {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.94rem 1.375rem;
      margin-bottom: 0;
      font-size: 0.8125rem;
      font-weight: 400;
      line-height: 1;
      color: #495057;
      text-align: center;
      white-space: nowrap;
      background-color: #e9ecef;
      border: 1px solid #ced4da;
      border-radius: 2px; }
      .input-group-text input[type="radio"],
      .input-group-text input[type="checkbox"] {
        margin-top: 0; }
    
    .input-group-lg > .form-control:not(textarea), .select2-container--default .input-group-lg > .select2-selection--single:not(textarea), .select2-container--default .select2-selection--single .input-group-lg > .select2-search__field:not(textarea), .input-group-lg > .typeahead:not(textarea),
    .input-group-lg > .tt-query:not(textarea),
    .input-group-lg > .tt-hint:not(textarea),
    .input-group-lg > .custom-select {
      height: 3.175rem; }
    
    .input-group-lg > .form-control, .select2-container--default .input-group-lg > .select2-selection--single, .select2-container--default .select2-selection--single .input-group-lg > .select2-search__field, .input-group-lg > .typeahead,
    .input-group-lg > .tt-query,
    .input-group-lg > .tt-hint,
    .input-group-lg > .custom-select,
    .input-group-lg > .input-group-prepend > .input-group-text,
    .input-group-lg > .input-group-append > .input-group-text,
    .input-group-lg > .input-group-prepend > .btn,
    .input-group-lg > .input-group-append > .btn {
      padding: 0.94rem 1.94rem;
      font-size: 1.25rem;
      line-height: 1.5;
      border-radius: 0.3rem; }
    
    .input-group-sm > .form-control:not(textarea), .select2-container--default .input-group-sm > .select2-selection--single:not(textarea), .select2-container--default .select2-selection--single .input-group-sm > .select2-search__field:not(textarea), .input-group-sm > .typeahead:not(textarea),
    .input-group-sm > .tt-query:not(textarea),
    .input-group-sm > .tt-hint:not(textarea),
    .input-group-sm > .custom-select {
      height: 2.575rem; }
    
    .input-group-sm > .form-control, .select2-container--default .input-group-sm > .select2-selection--single, .select2-container--default .select2-selection--single .input-group-sm > .select2-search__field, .input-group-sm > .typeahead,
    .input-group-sm > .tt-query,
    .input-group-sm > .tt-hint,
    .input-group-sm > .custom-select,
    .input-group-sm > .input-group-prepend > .input-group-text,
    .input-group-sm > .input-group-append > .input-group-text,
    .input-group-sm > .input-group-prepend > .btn,
    .input-group-sm > .input-group-append > .btn {
      padding: 0.5rem 0.81rem;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 0.2rem; }
    
    .input-group-lg > .custom-select,
    .input-group-sm > .custom-select {
      padding-right: 1.75rem; }
    
    .input-group > .input-group-prepend > .btn,
    .input-group > .input-group-prepend > .input-group-text,
    .input-group > .input-group-append:not(:last-child) > .btn,
    .input-group > .input-group-append:not(:last-child) > .input-group-text,
    .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
    .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    
    .input-group > .input-group-append > .btn,
    .input-group > .input-group-append > .input-group-text,
    .input-group > .input-group-prepend:not(:first-child) > .btn,
    .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
    .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
    .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
    
    .custom-control {
      position: relative;
      z-index: 1;
      display: block;
      min-height: 1.5rem;
      padding-left: 1.5rem;
      -webkit-print-color-adjust: exact;
      color-adjust: exact; }
    
    .custom-control-inline {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      margin-right: 1rem; }
    
    .custom-control-input {
      position: absolute;
      left: 0;
      z-index: -1;
      width: 1rem;
      height: 1.25rem;
      opacity: 0; }
      .custom-control-input:checked ~ .custom-control-label::before {
        color: #fff;
        border-color: #007bff;
        background-color: #007bff; }
      .custom-control-input:focus ~ .custom-control-label::before {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
      .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
        border-color: #80bdff; }
      .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
        color: #fff;
        background-color: #b3d7ff;
        border-color: #b3d7ff; }
      .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
        color: #6c757d; }
        .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
          background-color: #e9ecef; }
    
    .custom-control-label {
      position: relative;
      margin-bottom: 0;
      vertical-align: top; }
      .custom-control-label::before {
        position: absolute;
        top: 0.25rem;
        left: -1.5rem;
        display: block;
        width: 1rem;
        height: 1rem;
        pointer-events: none;
        content: "";
        background-color: #fff;
        border: #adb5bd solid 1px; }
      .custom-control-label::after {
        position: absolute;
        top: 0.25rem;
        left: -1.5rem;
        display: block;
        width: 1rem;
        height: 1rem;
        content: "";
        background: no-repeat 50% / 50% 50%; }
    
    .custom-checkbox .custom-control-label::before {
      border-radius: 0.25rem; }
    
    .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }
    
    .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
      border-color: #007bff;
      background-color: #007bff; }
    
    .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }
    
    .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
      background-color: rgba(0, 123, 255, 0.5); }
    
    .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
      background-color: rgba(0, 123, 255, 0.5); }
    
    .custom-radio .custom-control-label::before {
      border-radius: 50%; }
    
    .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }
    
    .custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
      background-color: rgba(0, 123, 255, 0.5); }
    
    .custom-switch {
      padding-left: 2.25rem; }
      .custom-switch .custom-control-label::before {
        left: -2.25rem;
        width: 1.75rem;
        pointer-events: all;
        border-radius: 0.5rem; }
      .custom-switch .custom-control-label::after {
        top: calc(0.25rem + 2px);
        left: calc(-2.25rem + 2px);
        width: calc(1rem - 4px);
        height: calc(1rem - 4px);
        background-color: #adb5bd;
        border-radius: 0.5rem;
        -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
        @media (prefers-reduced-motion: reduce) {
          .custom-switch .custom-control-label::after {
            -webkit-transition: none;
            transition: none; } }
      .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
        background-color: #fff;
        -webkit-transform: translateX(0.75rem);
        transform: translateX(0.75rem); }
      .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
        background-color: rgba(0, 123, 255, 0.5); }
    
    .custom-select {
      display: inline-block;
      width: 100%;
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 1.75rem 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      vertical-align: middle;
      background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none; }
      .custom-select:focus {
        border-color: #80bdff;
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
        .custom-select:focus::-ms-value {
          color: #495057;
          background-color: #ffffff; }
      .custom-select[multiple], .custom-select[size]:not([size="1"]) {
        height: auto;
        padding-right: 0.75rem;
        background-image: none; }
      .custom-select:disabled {
        color: #6c757d;
        background-color: #e9ecef; }
      .custom-select::-ms-expand {
        display: none; }
      .custom-select:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 #495057; }
    
    .custom-select-sm {
      height: calc(1.5em + 0.5rem + 2px);
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      padding-left: 0.5rem;
      font-size: 0.875rem; }
    
    .custom-select-lg {
      height: calc(1.5em + 1rem + 2px);
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      padding-left: 1rem;
      font-size: 1.25rem; }
    
    .custom-file {
      position: relative;
      display: inline-block;
      width: 100%;
      height: calc(1.5em + 0.75rem + 2px);
      margin-bottom: 0; }
    
    .custom-file-input {
      position: relative;
      z-index: 2;
      width: 100%;
      height: calc(1.5em + 0.75rem + 2px);
      margin: 0;
      opacity: 0; }
      .custom-file-input:focus ~ .custom-file-label {
        border-color: #80bdff;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
      .custom-file-input[disabled] ~ .custom-file-label,
      .custom-file-input:disabled ~ .custom-file-label {
        background-color: #e9ecef; }
      .custom-file-input:lang(en) ~ .custom-file-label::after {
        content: "Browse"; }
      .custom-file-input ~ .custom-file-label[data-browse]::after {
        content: attr(data-browse); }
    
    .custom-file-label {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1;
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 0.75rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      border: 1px solid #ced4da;
      border-radius: 0.25rem; }
      .custom-file-label::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 3;
        display: block;
        height: calc(1.5em + 0.75rem);
        padding: 0.375rem 0.75rem;
        line-height: 1.5;
        color: #495057;
        content: "Browse";
        background-color: #e9ecef;
        border-left: inherit;
        border-radius: 0 0.25rem 0.25rem 0; }
    
    .custom-range {
      width: 100%;
      height: 1.4rem;
      padding: 0;
      background-color: transparent;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none; }
      .custom-range:focus {
        outline: none; }
        .custom-range:focus::-webkit-slider-thumb {
          -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
          box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
        .custom-range:focus::-moz-range-thumb {
          box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
        .custom-range:focus::-ms-thumb {
          box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
      .custom-range::-moz-focus-outer {
        border: 0; }
      .custom-range::-webkit-slider-thumb {
        width: 1rem;
        height: 1rem;
        margin-top: -0.25rem;
        background-color: #007bff;
        border: 0;
        border-radius: 1rem;
        -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        -webkit-appearance: none;
        appearance: none; }
        @media (prefers-reduced-motion: reduce) {
          .custom-range::-webkit-slider-thumb {
            -webkit-transition: none;
            transition: none; } }
        .custom-range::-webkit-slider-thumb:active {
          background-color: #b3d7ff; }
      .custom-range::-webkit-slider-runnable-track {
        width: 100%;
        height: 0.5rem;
        color: transparent;
        cursor: pointer;
        background-color: #dee2e6;
        border-color: transparent;
        border-radius: 1rem; }
      .custom-range::-moz-range-thumb {
        width: 1rem;
        height: 1rem;
        background-color: #007bff;
        border: 0;
        border-radius: 1rem;
        -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        -moz-appearance: none;
        appearance: none; }
        @media (prefers-reduced-motion: reduce) {
          .custom-range::-moz-range-thumb {
            -moz-transition: none;
            transition: none; } }
        .custom-range::-moz-range-thumb:active {
          background-color: #b3d7ff; }
      .custom-range::-moz-range-track {
        width: 100%;
        height: 0.5rem;
        color: transparent;
        cursor: pointer;
        background-color: #dee2e6;
        border-color: transparent;
        border-radius: 1rem; }
      .custom-range::-ms-thumb {
        width: 1rem;
        height: 1rem;
        margin-top: 0;
        margin-right: 0.2rem;
        margin-left: 0.2rem;
        background-color: #007bff;
        border: 0;
        border-radius: 1rem;
        -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        appearance: none; }
        @media (prefers-reduced-motion: reduce) {
          .custom-range::-ms-thumb {
            -ms-transition: none;
            transition: none; } }
        .custom-range::-ms-thumb:active {
          background-color: #b3d7ff; }
      .custom-range::-ms-track {
        width: 100%;
        height: 0.5rem;
        color: transparent;
        cursor: pointer;
        background-color: transparent;
        border-color: transparent;
        border-width: 0.5rem; }
      .custom-range::-ms-fill-lower {
        background-color: #dee2e6;
        border-radius: 1rem; }
      .custom-range::-ms-fill-upper {
        margin-right: 15px;
        background-color: #dee2e6;
        border-radius: 1rem; }
      .custom-range:disabled::-webkit-slider-thumb {
        background-color: #adb5bd; }
      .custom-range:disabled::-webkit-slider-runnable-track {
        cursor: default; }
      .custom-range:disabled::-moz-range-thumb {
        background-color: #adb5bd; }
      .custom-range:disabled::-moz-range-track {
        cursor: default; }
      .custom-range:disabled::-ms-thumb {
        background-color: #adb5bd; }
    
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
      transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
      transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
      @media (prefers-reduced-motion: reduce) {
        .custom-control-label::before,
        .custom-file-label,
        .custom-select {
          -webkit-transition: none;
          transition: none; } }
    
    
    .nav-link {
      display: block;
      padding: 0.5rem 1rem; }
      .nav-link:hover, .nav-link:focus {
        text-decoration: none; }
      .nav-link.disabled {
        color: #6c757d;
        pointer-events: none;
        cursor: default; }
    
    .nav-tabs {
      border-bottom: 1px solid #ebedf2; }
      .nav-tabs .nav-item {
        margin-bottom: -1px; }
      .nav-tabs .nav-link {
        border: 1px solid transparent;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem; }
        .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
          border-color: #ebedf2 #ebedf2 #ebedf2; }
        .nav-tabs .nav-link.disabled {
          color: #6c757d;
          background-color: transparent;
          border-color: transparent; }
      .nav-tabs .nav-link.active,
      .nav-tabs .nav-item.show .nav-link {
        color: #343a40;
        background-color: #ffffff;
        border-color: #ebedf2 #ebedf2 #ffffff; }
      .nav-tabs .dropdown-menu {
        margin-top: -1px;
        border-top-left-radius: 0;
        border-top-right-radius: 0; }
    
    .nav-pills .nav-link {
      border-radius: 0.25rem; }
    
    .nav-pills .nav-link.active,
    .nav-pills .show > .nav-link {
      color: #fff;
      background-color: #007bff; }
    
    .nav-fill > .nav-link,
    .nav-fill .nav-item {
      -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      text-align: center; }
    
    .nav-justified > .nav-link,
    .nav-justified .nav-item {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      text-align: center; }
    
    .tab-content > .tab-pane {
      display: none; }
    
    .tab-content > .active {
      display: block; }
    
    
    
    .navbar-toggler {
      padding: 0.25rem 0.75rem;
      font-size: 1.25rem;
      line-height: 1;
      background-color: transparent;
      border: 1px solid transparent;
      border-radius: 0.25rem; }
      .navbar-toggler:hover, .navbar-toggler:focus {
        text-decoration: none; }
    
    .navbar-toggler-icon {
      display: inline-block;
      width: 1.5em;
      height: 1.5em;
      vertical-align: middle;
      content: "";
      background: no-repeat center center;
      background-size: 100% 100%; }
    
    @media (max-width: 575.98px) {
      .navbar-expand-sm > .container,
      .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
        padding-right: 0;
        padding-left: 0; } }
    
    @media (min-width: 576px) {
      .navbar-expand-sm {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start; }
        .navbar-expand-sm .navbar-nav {
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-direction: row;
          flex-direction: row; }
          .navbar-expand-sm .navbar-nav .dropdown-menu {
            position: absolute; }
          .navbar-expand-sm .navbar-nav .nav-link {
            padding-right: 0.5rem;
            padding-left: 0.5rem; }
        .navbar-expand-sm > .container,
        .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
          -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
        .navbar-expand-sm .navbar-collapse {
          display: -webkit-box !important;
          display: -ms-flexbox !important;
          display: flex !important;
          -ms-flex-preferred-size: auto;
          flex-basis: auto; }
        .navbar-expand-sm .navbar-toggler {
          display: none; } }
    
    @media (max-width: 767.98px) {
      .navbar-expand-md > .container,
      .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
        padding-right: 0;
        padding-left: 0; } }
    
    @media (min-width: 768px) {
      .navbar-expand-md {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start; }
        .navbar-expand-md .navbar-nav {
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-direction: row;
          flex-direction: row; }
          .navbar-expand-md .navbar-nav .dropdown-menu {
            position: absolute; }
          .navbar-expand-md .navbar-nav .nav-link {
            padding-right: 0.5rem;
            padding-left: 0.5rem; }
        .navbar-expand-md > .container,
        .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
          -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
        .navbar-expand-md .navbar-collapse {
          display: -webkit-box !important;
          display: -ms-flexbox !important;
          display: flex !important;
          -ms-flex-preferred-size: auto;
          flex-basis: auto; }
        .navbar-expand-md .navbar-toggler {
          display: none; } }
    
    @media (max-width: 991.98px) {
      .navbar-expand-lg > .container,
      .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
        padding-right: 0;
        padding-left: 0; } }
    
    @media (min-width: 992px) {
      .navbar-expand-lg {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start; }
        .navbar-expand-lg .navbar-nav {
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-direction: row;
          flex-direction: row; }
          .navbar-expand-lg .navbar-nav .dropdown-menu {
            position: absolute; }
          .navbar-expand-lg .navbar-nav .nav-link {
            padding-right: 0.5rem;
            padding-left: 0.5rem; }
        .navbar-expand-lg > .container,
        .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
          -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
        .navbar-expand-lg .navbar-collapse {
          display: -webkit-box !important;
          display: -ms-flexbox !important;
          display: flex !important;
          -ms-flex-preferred-size: auto;
          flex-basis: auto; }
        .navbar-expand-lg .navbar-toggler {
          display: none; } }
    
    @media (max-width: 1199.98px) {
      .navbar-expand-xl > .container,
      .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
        padding-right: 0;
        padding-left: 0; } }
    
    @media (min-width: 1200px) {
      .navbar-expand-xl {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start; }
        .navbar-expand-xl .navbar-nav {
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-direction: row;
          flex-direction: row; }
          .navbar-expand-xl .navbar-nav .dropdown-menu {
            position: absolute; }
          .navbar-expand-xl .navbar-nav .nav-link {
            padding-right: 0.5rem;
            padding-left: 0.5rem; }
        .navbar-expand-xl > .container,
        .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
          -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
        .navbar-expand-xl .navbar-collapse {
          display: -webkit-box !important;
          display: -ms-flexbox !important;
          display: flex !important;
          -ms-flex-preferred-size: auto;
          flex-basis: auto; }
        .navbar-expand-xl .navbar-toggler {
          display: none; } }
    
    .navbar-expand {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start; }
      .navbar-expand > .container,
      .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
        padding-right: 0;
        padding-left: 0; }
      .navbar-expand .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row; }
        .navbar-expand .navbar-nav .dropdown-menu {
          position: absolute; }
        .navbar-expand .navbar-nav .nav-link {
          padding-right: 0.5rem;
          padding-left: 0.5rem; }
      .navbar-expand > .container,
      .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap; }
      .navbar-expand .navbar-collapse {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto; }
      .navbar-expand .navbar-toggler {
        display: none; }
    
    .navbar-light .navbar-brand {
      color: rgba(0, 0, 0, 0.9); }
      .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
        color: rgba(0, 0, 0, 0.9); }
    
    .navbar-light .navbar-nav .nav-link {
      color: rgba(0, 0, 0, 0.5); }
      .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
        color: rgba(0, 0, 0, 0.7); }
      .navbar-light .navbar-nav .nav-link.disabled {
        color: rgba(0, 0, 0, 0.3); }
    
    .navbar-light .navbar-nav .show > .nav-link,
    .navbar-light .navbar-nav .active > .nav-link,
    .navbar-light .navbar-nav .nav-link.show,
    .navbar-light .navbar-nav .nav-link.active {
      color: rgba(0, 0, 0, 0.9); }
    
    .navbar-light .navbar-toggler {
      color: rgba(0, 0, 0, 0.5);
      border-color: rgba(0, 0, 0, 0.1); }
    
    .navbar-light .navbar-toggler-icon {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }
    
    .navbar-light .navbar-text {
      color: rgba(0, 0, 0, 0.5); }
      .navbar-light .navbar-text a {
        color: rgba(0, 0, 0, 0.9); }
        .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
          color: rgba(0, 0, 0, 0.9); }
    
    .navbar-dark .navbar-brand {
      color: #fff; }
      .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
        color: #fff; }
    
    .navbar-dark .navbar-nav .nav-link {
      color: rgba(255, 255, 255, 0.5); }
      .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
        color: rgba(255, 255, 255, 0.75); }
      .navbar-dark .navbar-nav .nav-link.disabled {
        color: rgba(255, 255, 255, 0.25); }
    
    .navbar-dark .navbar-nav .show > .nav-link,
    .navbar-dark .navbar-nav .active > .nav-link,
    .navbar-dark .navbar-nav .nav-link.show,
    .navbar-dark .navbar-nav .nav-link.active {
      color: #fff; }
    
    .navbar-dark .navbar-toggler {
      color: rgba(255, 255, 255, 0.5);
      border-color: rgba(255, 255, 255, 0.1); }
    
    .navbar-dark .navbar-toggler-icon {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }
    
    .navbar-dark .navbar-text {
      color: rgba(255, 255, 255, 0.5); }
      .navbar-dark .navbar-text a {
        color: #fff; }
        .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
          color: #fff; }
    
    .card {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      min-width: 0;
      word-wrap: break-word;
      background-color: #fff;
      background-clip: border-box;
      border: 1px solid rgba(0, 0, 0, 0.125);
      border-radius: 0; }
      .card > hr {
        margin-right: 0;
        margin-left: 0; }
      .card > .list-group {
        border-top: inherit;
        border-bottom: inherit; }
        .card > .list-group:first-child {
          border-top-width: 0;
          border-top-left-radius: calc(0.25rem - 1px);
          border-top-right-radius: calc(0.25rem - 1px); }
        .card > .list-group:last-child {
          border-bottom-width: 0;
          border-bottom-right-radius: calc(0.25rem - 1px);
          border-bottom-left-radius: calc(0.25rem - 1px); }
      .card > .card-header + .list-group,
      .card > .list-group + .card-footer {
        border-top: 0; }
    
    .card-body {
      -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      min-height: 1px;
      padding: 1.25rem; }
    
    .card-title {
      margin-bottom: 0.75rem; }
    
    .card-subtitle {
      margin-top: -0.375rem;
      margin-bottom: 0; }
    
    .card-text:last-child {
      margin-bottom: 0; }
    
    .card-link:hover {
      text-decoration: none; }
    
    .card-link + .card-link {
      margin-left: 1.25rem; }
    
    .card-header {
      padding: 0.75rem 1.25rem;
      margin-bottom: 0;
      background-color: rgba(0, 0, 0, 0.03);
      border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
      .card-header:first-child {
        border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
    
    .card-footer {
      padding: 0.75rem 1.25rem;
      background-color: rgba(0, 0, 0, 0.03);
      border-top: 1px solid rgba(0, 0, 0, 0.125); }
      .card-footer:last-child {
        border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }
    
    .card-header-tabs {
      margin-right: -0.625rem;
      margin-bottom: -0.75rem;
      margin-left: -0.625rem;
      border-bottom: 0; }
    
    .card-header-pills {
      margin-right: -0.625rem;
      margin-left: -0.625rem; }
    
    .card-img-overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 1.25rem;
      border-radius: calc(0.25rem - 1px); }
    
    .card-img,
    .card-img-top,
    .card-img-bottom {
      -ms-flex-negative: 0;
      flex-shrink: 0;
      width: 100%; }
    
    .card-img,
    .card-img-top {
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    
    .card-img,
    .card-img-bottom {
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
    
    .card-deck .card {
      margin-bottom: 15px; }
    
    @media (min-width: 576px) {
      .card-deck {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        margin-right: -15px;
        margin-left: -15px; }
        .card-deck .card {
          -webkit-box-flex: 1;
          -ms-flex: 1 0 0%;
          flex: 1 0 0%;
          margin-right: 15px;
          margin-bottom: 0;
          margin-left: 15px; } }
    
    .card-group > .card {
      margin-bottom: 15px; }
    
    @media (min-width: 576px) {
      .card-group {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap; }
        .card-group > .card {
          -webkit-box-flex: 1;
          -ms-flex: 1 0 0%;
          flex: 1 0 0%;
          margin-bottom: 0; }
          .card-group > .card + .card {
            margin-left: 0;
            border-left: 0; }
          .card-group > .card:not(:last-child) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0; }
            .card-group > .card:not(:last-child) .card-img-top,
            .card-group > .card:not(:last-child) .card-header {
              border-top-right-radius: 0; }
            .card-group > .card:not(:last-child) .card-img-bottom,
            .card-group > .card:not(:last-child) .card-footer {
              border-bottom-right-radius: 0; }
          .card-group > .card:not(:first-child) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0; }
            .card-group > .card:not(:first-child) .card-img-top,
            .card-group > .card:not(:first-child) .card-header {
              border-top-left-radius: 0; }
            .card-group > .card:not(:first-child) .card-img-bottom,
            .card-group > .card:not(:first-child) .card-footer {
              border-bottom-left-radius: 0; } }
    
    .card-columns .card {
      margin-bottom: 0.75rem; }
    
    @media (min-width: 576px) {
      .card-columns {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
        -webkit-column-gap: 1.25rem;
        -moz-column-gap: 1.25rem;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1; }
        .card-columns .card {
          display: inline-block;
          width: 100%; } }
    
    .accordion {
      overflow-anchor: none; }
      .accordion > .card {
        overflow: hidden; }
        .accordion > .card:not(:last-of-type) {
          border-bottom: 0;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0; }
        .accordion > .card:not(:first-of-type) {
          border-top-left-radius: 0;
          border-top-right-radius: 0; }
        .accordion > .card > .card-header {
          border-radius: 0;
          margin-bottom: -1px; }
    
    .breadcrumb {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      padding: 0.56rem 1.13rem;
      margin-bottom: 1rem;
      font-size: 0.875rem;
      list-style: none;
      background-color: transparent;
      border-radius: 0.25rem; }
    
    .breadcrumb-item {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      .breadcrumb-item + .breadcrumb-item {
        padding-left: 0.5rem; }
        .breadcrumb-item + .breadcrumb-item::before {
          display: inline-block;
          padding-right: 0.5rem;
          color: #6c757d;
          content: "/"; }
      .breadcrumb-item + .breadcrumb-item:hover::before {
        text-decoration: underline; }
      .breadcrumb-item + .breadcrumb-item:hover::before {
        text-decoration: none; }
      .breadcrumb-item.active {
        color: #495057; }
    
    .pagination {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      padding-left: 0;
      list-style: none;
      border-radius: 0.25rem; }
    
    .page-link {
      position: relative;
      display: block;
      padding: 0.5rem 0.75rem;
      margin-left: -1px;
      line-height: 1.25;
      color: #007bff;
      background-color: #fff;
      border: 1px solid #dee2e6; }
      .page-link:hover {
        z-index: 2;
        color: #0056b3;
        text-decoration: none;
        background-color: #e9ecef;
        border-color: #dee2e6; }
      .page-link:focus {
        z-index: 3;
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    
    .page-item:first-child .page-link {
      margin-left: 0;
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem; }
    
    .page-item:last-child .page-link {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem; }
    
    .page-item.active .page-link {
      z-index: 3;
      color: #fff;
      background-color: #007bff;
      border-color: #007bff; }
    
    .page-item.disabled .page-link {
      color: #6c757d;
      pointer-events: none;
      cursor: auto;
      background-color: #fff;
      border-color: #dee2e6; }
    
    .pagination-lg .page-link {
      padding: 0.75rem 1.5rem;
      font-size: 1.25rem;
      line-height: 1.5; }
    
    .pagination-lg .page-item:first-child .page-link {
      border-top-left-radius: 0.3rem;
      border-bottom-left-radius: 0.3rem; }
    
    .pagination-lg .page-item:last-child .page-link {
      border-top-right-radius: 0.3rem;
      border-bottom-right-radius: 0.3rem; }
    
    .pagination-sm .page-link {
      padding: 0.25rem 0.5rem;
      font-size: 0.875rem;
      line-height: 1.5; }
    
    .pagination-sm .page-item:first-child .page-link {
      border-top-left-radius: 0.2rem;
      border-bottom-left-radius: 0.2rem; }
    
    .pagination-sm .page-item:last-child .page-link {
      border-top-right-radius: 0.2rem;
      border-bottom-right-radius: 0.2rem; }
    
    .badge {
      display: inline-block;
      padding: 0.25em 0.4em;
      font-size: 75%;
      font-weight: 700;
      line-height: 1;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      border-radius: 0.25rem;
      -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
      @media (prefers-reduced-motion: reduce) {
        .badge {
          -webkit-transition: none;
          transition: none; } }
      a.badge:hover, a.badge:focus {
        text-decoration: none; }
      .badge:empty {
        display: none; }
    
    .btn .badge {
      position: relative;
      top: -1px; }
    
    .badge-pill {
      padding-right: 0.6em;
      padding-left: 0.6em;
      border-radius: 10rem; }
    
    .badge-primary {
      color: #212529;
      background-color: #1bdbe0; }
      a.badge-primary:hover, a.badge-primary:focus {
        color: #212529;
        background-color: #16afb2; }
      a.badge-primary:focus, a.badge-primary.focus {
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(27, 219, 224, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(27, 219, 224, 0.5); }
    
    .badge-secondary {
      color: #212529;
      background-color: #d8d8d8; }
      a.badge-secondary:hover, a.badge-secondary:focus {
        color: #212529;
        background-color: #bfbfbf; }
      a.badge-secondary:focus, a.badge-secondary.focus {
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(216, 216, 216, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(216, 216, 216, 0.5); }
    
    .badge-success, .preview-list .preview-item .preview-thumbnail .badge.badge-online {
      color: #fff;
      background-color: #38ce3c; }
      a.badge-success:hover, .preview-list .preview-item .preview-thumbnail a.badge.badge-online:hover, a.badge-success:focus, .preview-list .preview-item .preview-thumbnail a.badge.badge-online:focus {
        color: #fff;
        background-color: #2aa92d; }
      a.badge-success:focus, .preview-list .preview-item .preview-thumbnail a.badge.badge-online:focus, a.badge-success.focus, .preview-list .preview-item .preview-thumbnail a.focus.badge.badge-online {
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(56, 206, 60, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(56, 206, 60, 0.5); }
    
    .badge-info, .preview-list .preview-item .preview-thumbnail .badge.badge-offline {
      color: #fff;
      background-color: #8e32e9; }
      a.badge-info:hover, .preview-list .preview-item .preview-thumbnail a.badge.badge-offline:hover, a.badge-info:focus, .preview-list .preview-item .preview-thumbnail a.badge.badge-offline:focus {
        color: #fff;
        background-color: #7516d2; }
      a.badge-info:focus, .preview-list .preview-item .preview-thumbnail a.badge.badge-offline:focus, a.badge-info.focus, .preview-list .preview-item .preview-thumbnail a.focus.badge.badge-offline {
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(142, 50, 233, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(142, 50, 233, 0.5); }
    
    .badge-warning, .preview-list .preview-item .preview-thumbnail .badge.badge-busy {
      color: #212529;
      background-color: #ffde73; }
      a.badge-warning:hover, .preview-list .preview-item .preview-thumbnail a.badge.badge-busy:hover, a.badge-warning:focus, .preview-list .preview-item .preview-thumbnail a.badge.badge-busy:focus {
        color: #212529;
        background-color: #ffd240; }
      a.badge-warning:focus, .preview-list .preview-item .preview-thumbnail a.badge.badge-busy:focus, a.badge-warning.focus, .preview-list .preview-item .preview-thumbnail a.focus.badge.badge-busy {
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 222, 115, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(255, 222, 115, 0.5); }
    
    .badge-danger {
      color: #fff;
      background-color: #ff4d6b; }
      a.badge-danger:hover, a.badge-danger:focus {
        color: #fff;
        background-color: #ff1a41; }
      a.badge-danger:focus, a.badge-danger.focus {
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 77, 107, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(255, 77, 107, 0.5); }
    
    .badge-light {
      color: #212529;
      background-color: #f8f9fa; }
      a.badge-light:hover, a.badge-light:focus {
        color: #212529;
        background-color: #dae0e5; }
      a.badge-light:focus, a.badge-light.focus {
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
    
    .badge-dark {
      color: #fff;
      background-color: #3e4b5b; }
      a.badge-dark:hover, a.badge-dark:focus {
        color: #fff;
        background-color: #29323d; }
      a.badge-dark:focus, a.badge-dark.focus {
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(62, 75, 91, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(62, 75, 91, 0.5); }
    
    .jumbotron {
      padding: 2rem 1rem;
      margin-bottom: 2rem;
      background-color: #e9ecef;
      border-radius: 0.3rem; }
      @media (min-width: 576px) {
        .jumbotron {
          padding: 4rem 2rem; } }
    
    .jumbotron-fluid {
      padding-right: 0;
      padding-left: 0;
      border-radius: 0; }
    
    .alert {
      position: relative;
      padding: 0.75rem 1.25rem;
      margin-bottom: 1rem;
      border: 1px solid transparent;
      border-radius: 0.25rem; }
    
    .alert-heading {
      color: inherit; }
    
    .alert-link {
      font-weight: 700; }
    
    .alert-dismissible {
      padding-right: 4rem; }
      .alert-dismissible .close {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        padding: 0.75rem 1.25rem;
        color: inherit; }
    
    .alert-primary {
      color: #0e7274;
      background-color: #d1f8f9;
      border-color: #bff5f6; }
      .alert-primary hr {
        border-top-color: #a9f2f3; }
      .alert-primary .alert-link {
        color: #094546; }
    
    .alert-secondary {
      color: #707070;
      background-color: #f7f7f7;
      border-color: #f4f4f4; }
      .alert-secondary hr {
        border-top-color: #e7e7e7; }
      .alert-secondary .alert-link {
        color: #575757; }
    
    .alert-success {
      color: #1d6b1f;
      background-color: #d7f5d8;
      border-color: #c7f1c8; }
      .alert-success hr {
        border-top-color: #b3ecb4; }
      .alert-success .alert-link {
        color: #124313; }
    
    .alert-info {
      color: #4a1a79;
      background-color: #e8d6fb;
      border-color: #dfc6f9; }
      .alert-info hr {
        border-top-color: #d2aff7; }
      .alert-info .alert-link {
        color: #30114f; }
    
    .alert-warning {
      color: #85733c;
      background-color: #fff8e3;
      border-color: #fff6d8; }
      .alert-warning hr {
        border-top-color: #fff0bf; }
      .alert-warning .alert-link {
        color: #62552c; }
    
    .alert-danger {
      color: #852838;
      background-color: #ffdbe1;
      border-color: #ffcdd6; }
      .alert-danger hr {
        border-top-color: #ffb4c1; }
      .alert-danger .alert-link {
        color: #5e1c27; }
    
    .alert-light {
      color: #818182;
      background-color: #fefefe;
      border-color: #fdfdfe; }
      .alert-light hr {
        border-top-color: #ececf6; }
      .alert-light .alert-link {
        color: #686868; }
    
    .alert-dark {
      color: #20272f;
      background-color: #d8dbde;
      border-color: #c9cdd1; }
      .alert-dark hr {
        border-top-color: #bbc0c5; }
      .alert-dark .alert-link {
        color: #0b0e11; }
    
    @-webkit-keyframes progress-bar-stripes {
      from {
        background-position: 1rem 0; }
      to {
        background-position: 0 0; } }
    
    @keyframes progress-bar-stripes {
      from {
        background-position: 1rem 0; }
      to {
        background-position: 0 0; } }
    
    .progress {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      height: 1rem;
      overflow: hidden;
      line-height: 0;
      font-size: 0.75rem;
      background-color: #e9ecef;
      border-radius: 0.25rem; }
    
    .progress-bar {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      overflow: hidden;
      color: #fff;
      text-align: center;
      white-space: nowrap;
      background-color: #007bff;
      -webkit-transition: width 0.6s ease;
      transition: width 0.6s ease; }
      @media (prefers-reduced-motion: reduce) {
        .progress-bar {
          -webkit-transition: none;
          transition: none; } }
    
    .progress-bar-striped {
      background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
      background-size: 1rem 1rem; }
    
    .progress-bar-animated {
      -webkit-animation: progress-bar-stripes 1s linear infinite;
      animation: progress-bar-stripes 1s linear infinite; }
      @media (prefers-reduced-motion: reduce) {
        .progress-bar-animated {
          -webkit-animation: none;
          animation: none; } }
    
    .media {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start; }
    
    .media-body {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1; }
    
    .list-group {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      padding-left: 0;
      margin-bottom: 0;
      border-radius: 0.25rem; }
    
    .list-group-item-action {
      width: 100%;
      color: #495057;
      text-align: inherit; }
      .list-group-item-action:hover, .list-group-item-action:focus {
        z-index: 1;
        color: #495057;
        text-decoration: none;
        background-color: #f8f9fa; }
      .list-group-item-action:active {
        color: #212529;
        background-color: #e9ecef; }
    
    .list-group-item {
      position: relative;
      display: block;
      padding: 0.75rem 1.25rem;
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.125); }
      .list-group-item:first-child {
        border-top-left-radius: inherit;
        border-top-right-radius: inherit; }
      .list-group-item:last-child {
        border-bottom-right-radius: inherit;
        border-bottom-left-radius: inherit; }
      .list-group-item.disabled, .list-group-item:disabled {
        color: #6c757d;
        pointer-events: none;
        background-color: #fff; }
      .list-group-item.active {
        z-index: 2;
        color: #fff;
        background-color: #007bff;
        border-color: #007bff; }
      .list-group-item + .list-group-item {
        border-top-width: 0; }
        .list-group-item + .list-group-item.active {
          margin-top: -1px;
          border-top-width: 1px; }
    
    .list-group-horizontal {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row; }
      .list-group-horizontal > .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal > .list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0; }
      .list-group-horizontal > .list-group-item.active {
        margin-top: 0; }
      .list-group-horizontal > .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0; }
        .list-group-horizontal > .list-group-item + .list-group-item.active {
          margin-left: -1px;
          border-left-width: 1px; }
    
    @media (min-width: 576px) {
      .list-group-horizontal-sm {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row; }
        .list-group-horizontal-sm > .list-group-item:first-child {
          border-bottom-left-radius: 0.25rem;
          border-top-right-radius: 0; }
        .list-group-horizontal-sm > .list-group-item:last-child {
          border-top-right-radius: 0.25rem;
          border-bottom-left-radius: 0; }
        .list-group-horizontal-sm > .list-group-item.active {
          margin-top: 0; }
        .list-group-horizontal-sm > .list-group-item + .list-group-item {
          border-top-width: 1px;
          border-left-width: 0; }
          .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
            margin-left: -1px;
            border-left-width: 1px; } }
    
    @media (min-width: 768px) {
      .list-group-horizontal-md {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row; }
        .list-group-horizontal-md > .list-group-item:first-child {
          border-bottom-left-radius: 0.25rem;
          border-top-right-radius: 0; }
        .list-group-horizontal-md > .list-group-item:last-child {
          border-top-right-radius: 0.25rem;
          border-bottom-left-radius: 0; }
        .list-group-horizontal-md > .list-group-item.active {
          margin-top: 0; }
        .list-group-horizontal-md > .list-group-item + .list-group-item {
          border-top-width: 1px;
          border-left-width: 0; }
          .list-group-horizontal-md > .list-group-item + .list-group-item.active {
            margin-left: -1px;
            border-left-width: 1px; } }
    
    @media (min-width: 992px) {
      .list-group-horizontal-lg {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row; }
        .list-group-horizontal-lg > .list-group-item:first-child {
          border-bottom-left-radius: 0.25rem;
          border-top-right-radius: 0; }
        .list-group-horizontal-lg > .list-group-item:last-child {
          border-top-right-radius: 0.25rem;
          border-bottom-left-radius: 0; }
        .list-group-horizontal-lg > .list-group-item.active {
          margin-top: 0; }
        .list-group-horizontal-lg > .list-group-item + .list-group-item {
          border-top-width: 1px;
          border-left-width: 0; }
          .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
            margin-left: -1px;
            border-left-width: 1px; } }
    
    @media (min-width: 1200px) {
      .list-group-horizontal-xl {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row; }
        .list-group-horizontal-xl > .list-group-item:first-child {
          border-bottom-left-radius: 0.25rem;
          border-top-right-radius: 0; }
        .list-group-horizontal-xl > .list-group-item:last-child {
          border-top-right-radius: 0.25rem;
          border-bottom-left-radius: 0; }
        .list-group-horizontal-xl > .list-group-item.active {
          margin-top: 0; }
        .list-group-horizontal-xl > .list-group-item + .list-group-item {
          border-top-width: 1px;
          border-left-width: 0; }
          .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
            margin-left: -1px;
            border-left-width: 1px; } }
    
    .list-group-flush {
      border-radius: 0; }
      .list-group-flush > .list-group-item {
        border-width: 0 0 1px; }
        .list-group-flush > .list-group-item:last-child {
          border-bottom-width: 0; }
    
    .list-group-item-primary {
      color: #0e7274;
      background-color: #bff5f6; }
      .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
        color: #0e7274;
        background-color: #a9f2f3; }
      .list-group-item-primary.list-group-item-action.active {
        color: #ffffff;
        background-color: #0e7274;
        border-color: #0e7274; }
    
    .list-group-item-secondary {
      color: #707070;
      background-color: #f4f4f4; }
      .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
        color: #707070;
        background-color: #e7e7e7; }
      .list-group-item-secondary.list-group-item-action.active {
        color: #ffffff;
        background-color: #707070;
        border-color: #707070; }
    
    .list-group-item-success {
      color: #1d6b1f;
      background-color: #c7f1c8; }
      .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
        color: #1d6b1f;
        background-color: #b3ecb4; }
      .list-group-item-success.list-group-item-action.active {
        color: #ffffff;
        background-color: #1d6b1f;
        border-color: #1d6b1f; }
    
    .list-group-item-info {
      color: #4a1a79;
      background-color: #dfc6f9; }
      .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
        color: #4a1a79;
        background-color: #d2aff7; }
      .list-group-item-info.list-group-item-action.active {
        color: #ffffff;
        background-color: #4a1a79;
        border-color: #4a1a79; }
    
    .list-group-item-warning {
      color: #85733c;
      background-color: #fff6d8; }
      .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
        color: #85733c;
        background-color: #fff0bf; }
      .list-group-item-warning.list-group-item-action.active {
        color: #ffffff;
        background-color: #85733c;
        border-color: #85733c; }
    
    .list-group-item-danger {
      color: #852838;
      background-color: #ffcdd6; }
      .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
        color: #852838;
        background-color: #ffb4c1; }
      .list-group-item-danger.list-group-item-action.active {
        color: #ffffff;
        background-color: #852838;
        border-color: #852838; }
    
    .list-group-item-light {
      color: #818182;
      background-color: #fdfdfe; }
      .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
        color: #818182;
        background-color: #ececf6; }
      .list-group-item-light.list-group-item-action.active {
        color: #ffffff;
        background-color: #818182;
        border-color: #818182; }
    
    .list-group-item-dark {
      color: #20272f;
      background-color: #c9cdd1; }
      .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
        color: #20272f;
        background-color: #bbc0c5; }
      .list-group-item-dark.list-group-item-action.active {
        color: #ffffff;
        background-color: #20272f;
        border-color: #20272f; }
    
    .close {
      float: right;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1;
      color: #000;
      text-shadow: 0 1px 0 #fff;
      opacity: .5; }
      .close:hover {
        color: #000;
        text-decoration: none; }
      .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
        opacity: .75; }
    
    button.close {
      padding: 0;
      background-color: transparent;
      border: 0; }
    
    a.close.disabled {
      pointer-events: none; }
    
    .toast {
      -ms-flex-preferred-size: 350px;
      flex-basis: 350px;
      max-width: 350px;
      font-size: 0.875rem;
      background-color: rgba(255, 255, 255, 0.85);
      background-clip: padding-box;
      border: 1px solid rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
      box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
      opacity: 0;
      border-radius: 0.25rem; }
      .toast:not(:last-child) {
        margin-bottom: 0.75rem; }
      .toast.showing {
        opacity: 1; }
      .toast.show {
        display: block;
        opacity: 1; }
      .toast.hide {
        display: none; }
    
    .toast-header {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      color: #6c757d;
      background-color: rgba(255, 255, 255, 0.85);
      background-clip: padding-box;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    
    .toast-body {
      padding: 0.75rem; }
    
    .modal-open {
      overflow: hidden; }
      .modal-open .modal {
        overflow-x: hidden;
        overflow-y: auto; }
    
    .modal {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1050;
      display: none;
      width: 100%;
      height: 100%;
      overflow: hidden;
      outline: 0; }
    
    .modal-dialog {
      position: relative;
      width: auto;
      margin: 10px;
      pointer-events: none; }
      .modal.fade .modal-dialog {
        -webkit-transition: -webkit-transform 0.4s ease;
        transition: -webkit-transform 0.4s ease;
        transition: transform 0.4s ease;
        transition: transform 0.4s ease, -webkit-transform 0.4s ease;
        -webkit-transform: translate(0, -50px);
        transform: translate(0, -50px); }
        @media (prefers-reduced-motion: reduce) {
          .modal.fade .modal-dialog {
            -webkit-transition: none;
            transition: none; } }
      .modal.show .modal-dialog {
        -webkit-transform: none;
        transform: none; }
      .modal.modal-static .modal-dialog {
        -webkit-transform: scale(1.02);
        transform: scale(1.02); }
    
    .modal-dialog-scrollable {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      max-height: calc(100% - 20px); }
      .modal-dialog-scrollable .modal-content {
        max-height: calc(100vh - 20px);
        overflow: hidden; }
      .modal-dialog-scrollable .modal-header,
      .modal-dialog-scrollable .modal-footer {
        -ms-flex-negative: 0;
        flex-shrink: 0; }
      .modal-dialog-scrollable .modal-body {
        overflow-y: auto; }
    
    .modal-dialog-centered {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      min-height: calc(100% - 20px); }
      .modal-dialog-centered::before {
        display: block;
        height: calc(100vh - 20px);
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content;
        content: ""; }
      .modal-dialog-centered.modal-dialog-scrollable {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        height: 100%; }
        .modal-dialog-centered.modal-dialog-scrollable .modal-content {
          max-height: none; }
        .modal-dialog-centered.modal-dialog-scrollable::before {
          content: none; }
    
    .modal-content {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      width: 100%;
      pointer-events: auto;
      background-color: #ecf0f4;
      background-clip: padding-box;
      border: 1px solid #e8ecf1;
      border-radius: 0.3rem;
      outline: 0; }
    
    .modal-backdrop {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1040;
      width: 100vw;
      height: 100vh;
      background-color: #000; }
      .modal-backdrop.fade {
        opacity: 0; }
      .modal-backdrop.show {
        opacity: 0.5; }
    
    .modal-header {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding: 1rem 1rem;
      border-bottom: 1px solid #e8ecf1;
      border-top-left-radius: calc(0.3rem - 1px);
      border-top-right-radius: calc(0.3rem - 1px); }
      .modal-header .close {
        padding: 1rem 1rem;
        margin: -25px -26px -25px auto; }
    
    .modal-title {
      margin-bottom: 0;
      line-height: 1.5; }
    
    .modal-body {
      position: relative;
      -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      padding: 0.9375rem; }
    
    .modal-footer {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      padding: 0.6875rem;
      border-top: 1px solid #e8ecf1;
      border-bottom-right-radius: calc(0.3rem - 1px);
      border-bottom-left-radius: calc(0.3rem - 1px); }
      .modal-footer > * {
        margin: 0.25rem; }
    
    .modal-scrollbar-measure {
      position: absolute;
      top: -9999px;
      width: 50px;
      height: 50px;
      overflow: scroll; }
    
    @media (min-width: 576px) {
      .modal-dialog {
        max-width: 500px;
        margin: 30px auto; }
      .modal-dialog-scrollable {
        max-height: calc(100% - 60px); }
        .modal-dialog-scrollable .modal-content {
          max-height: calc(100vh - 60px); }
      .modal-dialog-centered {
        min-height: calc(100% - 60px); }
        .modal-dialog-centered::before {
          height: calc(100vh - 60px);
          height: -webkit-min-content;
          height: -moz-min-content;
          height: min-content; }
      .modal-sm {
        max-width: 300px; } }
    
    @media (min-width: 992px) {
      .modal-lg,
      .modal-xl {
        max-width: 90%; } }
    
    @media (min-width: 1200px) {
      .modal-xl {
        max-width: 1140px; } }
    
    .tooltip {
      position: absolute;
      z-index: 1070;
      display: block;
      margin: 0;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-style: normal;
      font-weight: 400;
      line-height: 1.5;
      text-align: left;
      text-align: start;
      text-decoration: none;
      text-shadow: none;
      text-transform: none;
      letter-spacing: normal;
      word-break: normal;
      word-spacing: normal;
      white-space: normal;
      line-break: auto;
      font-size: 0.75rem;
      word-wrap: break-word;
      opacity: 0; }
      .tooltip.show {
        opacity: 0.9; }
      .tooltip .arrow {
        position: absolute;
        display: block;
        width: 0.8rem;
        height: 0.4rem; }
        .tooltip .arrow::before {
          position: absolute;
          content: "";
          border-color: transparent;
          border-style: solid; }
    
    .bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
      padding: 0.4rem 0; }
      .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
        bottom: 0; }
        .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
          top: 0;
          border-width: 0.4rem 0.4rem 0;
          border-top-color: #000; }
    
    .bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
      padding: 0 0.4rem; }
      .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
        left: 0;
        width: 0.4rem;
        height: 0.8rem; }
        .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
          right: 0;
          border-width: 0.4rem 0.4rem 0.4rem 0;
          border-right-color: #000; }
    
    .bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
      padding: 0.4rem 0; }
      .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
        top: 0; }
        .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
          bottom: 0;
          border-width: 0 0.4rem 0.4rem;
          border-bottom-color: #000; }
    
    .bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
      padding: 0 0.4rem; }
      .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
        right: 0;
        width: 0.4rem;
        height: 0.8rem; }
        .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
          left: 0;
          border-width: 0.4rem 0 0.4rem 0.4rem;
          border-left-color: #000; }
    
    .tooltip-inner {
      max-width: 200px;
      padding: 0.4rem 0.75rem;
      color: #fff;
      text-align: center;
      background-color: #000;
      border-radius: 0.375rem; }
    
    .popover {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1060;
      display: block;
      max-width: 276px;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-style: normal;
      font-weight: 400;
      line-height: 1.5;
      text-align: left;
      text-align: start;
      text-decoration: none;
      text-shadow: none;
      text-transform: none;
      letter-spacing: normal;
      word-break: normal;
      word-spacing: normal;
      white-space: normal;
      line-break: auto;
      font-size: 0.875rem;
      word-wrap: break-word;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 0.3rem; }
      .popover .arrow {
        position: absolute;
        display: block;
        width: 1rem;
        height: 0.5rem;
        margin: 0 0.3rem; }
        .popover .arrow::before, .popover .arrow::after {
          position: absolute;
          display: block;
          content: "";
          border-color: transparent;
          border-style: solid; }
    
    .bs-popover-top, .bs-popover-auto[x-placement^="top"] {
      margin-bottom: 0.5rem; }
      .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
        bottom: calc(-0.5rem - 1px); }
        .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
          bottom: 0;
          border-width: 0.5rem 0.5rem 0;
          border-top-color: rgba(0, 0, 0, 0.25); }
        .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
          bottom: 1px;
          border-width: 0.5rem 0.5rem 0;
          border-top-color: #fff; }
    
    .bs-popover-right, .bs-popover-auto[x-placement^="right"] {
      margin-left: 0.5rem; }
      .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
        left: calc(-0.5rem - 1px);
        width: 0.5rem;
        height: 1rem;
        margin: 0.3rem 0; }
        .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
          left: 0;
          border-width: 0.5rem 0.5rem 0.5rem 0;
          border-right-color: rgba(0, 0, 0, 0.25); }
        .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
          left: 1px;
          border-width: 0.5rem 0.5rem 0.5rem 0;
          border-right-color: #fff; }
    
    .bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
      margin-top: 0.5rem; }
      .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
        top: calc(-0.5rem - 1px); }
        .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
          top: 0;
          border-width: 0 0.5rem 0.5rem 0.5rem;
          border-bottom-color: rgba(0, 0, 0, 0.25); }
        .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
          top: 1px;
          border-width: 0 0.5rem 0.5rem 0.5rem;
          border-bottom-color: #fff; }
      .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
        position: absolute;
        top: 0;
        left: 50%;
        display: block;
        width: 1rem;
        margin-left: -0.5rem;
        content: "";
        border-bottom: 1px solid #f7f7f7; }
    
    .bs-popover-left, .bs-popover-auto[x-placement^="left"] {
      margin-right: 0.5rem; }
      .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
        right: calc(-0.5rem - 1px);
        width: 0.5rem;
        height: 1rem;
        margin: 0.3rem 0; }
        .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
          right: 0;
          border-width: 0.5rem 0 0.5rem 0.5rem;
          border-left-color: rgba(0, 0, 0, 0.25); }
        .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
          right: 1px;
          border-width: 0.5rem 0 0.5rem 0.5rem;
          border-left-color: #fff; }
    
    .popover-header {
      padding: 0.5rem 0.75rem;
      margin-bottom: 0;
      font-size: 1rem;
      background-color: #f7f7f7;
      border-bottom: 1px solid #ebebeb;
      border-top-left-radius: calc(0.3rem - 1px);
      border-top-right-radius: calc(0.3rem - 1px); }
      .popover-header:empty {
        display: none; }
    
    .popover-body {
      padding: 0.5rem 0.75rem;
      color: #212529; }
    
    .carousel {
      position: relative; }
    
    .carousel.pointer-event {
      -ms-touch-action: pan-y;
      touch-action: pan-y; }
    
    .carousel-inner {
      position: relative;
      width: 100%;
      overflow: hidden; }
      .carousel-inner::after {
        display: block;
        clear: both;
        content: ""; }
    
    .carousel-item {
      position: relative;
      display: none;
      float: left;
      width: 100%;
      margin-right: -100%;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      -webkit-transition: -webkit-transform 0.6s ease-in-out;
      transition: -webkit-transform 0.6s ease-in-out;
      transition: transform 0.6s ease-in-out;
      transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
      @media (prefers-reduced-motion: reduce) {
        .carousel-item {
          -webkit-transition: none;
          transition: none; } }
    
    .carousel-item.active,
    .carousel-item-next,
    .carousel-item-prev {
      display: block; }
    
    .carousel-item-next:not(.carousel-item-left),
    .active.carousel-item-right {
      -webkit-transform: translateX(100%);
      transform: translateX(100%); }
    
    .carousel-item-prev:not(.carousel-item-right),
    .active.carousel-item-left {
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%); }
    
    .carousel-fade .carousel-item {
      opacity: 0;
      -webkit-transition-property: opacity;
      transition-property: opacity;
      -webkit-transform: none;
      transform: none; }
    
    .carousel-fade .carousel-item.active,
    .carousel-fade .carousel-item-next.carousel-item-left,
    .carousel-fade .carousel-item-prev.carousel-item-right {
      z-index: 1;
      opacity: 1; }
    
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      z-index: 0;
      opacity: 0;
      -webkit-transition: opacity 0s 0.6s;
      transition: opacity 0s 0.6s; }
      @media (prefers-reduced-motion: reduce) {
        .carousel-fade .active.carousel-item-left,
        .carousel-fade .active.carousel-item-right {
          -webkit-transition: none;
          transition: none; } }
    
    .carousel-control-prev,
    .carousel-control-next {
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 1;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: 15%;
      color: #fff;
      text-align: center;
      opacity: 0.5;
      -webkit-transition: opacity 0.15s ease;
      transition: opacity 0.15s ease; }
      @media (prefers-reduced-motion: reduce) {
        .carousel-control-prev,
        .carousel-control-next {
          -webkit-transition: none;
          transition: none; } }
      .carousel-control-prev:hover, .carousel-control-prev:focus,
      .carousel-control-next:hover,
      .carousel-control-next:focus {
        color: #fff;
        text-decoration: none;
        outline: 0;
        opacity: 0.9; }
    
    .carousel-control-prev {
      left: 0; }
    
    .carousel-control-next {
      right: 0; }
    
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      display: inline-block;
      width: 20px;
      height: 20px;
      background: no-repeat 50% / 100% 100%; }
    
    .carousel-control-prev-icon {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }
    
    .carousel-control-next-icon {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }
    
    .carousel-indicators {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 15;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      padding-left: 0;
      margin-right: 15%;
      margin-left: 15%;
      list-style: none; }
      .carousel-indicators li {
        -webkit-box-sizing: content-box;
        box-sizing: content-box;
        -webkit-box-flex: 0;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        width: 30px;
        height: 3px;
        margin-right: 3px;
        margin-left: 3px;
        text-indent: -999px;
        cursor: pointer;
        background-color: #fff;
        background-clip: padding-box;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        opacity: .5;
        -webkit-transition: opacity 0.6s ease;
        transition: opacity 0.6s ease; }
        @media (prefers-reduced-motion: reduce) {
          .carousel-indicators li {
            -webkit-transition: none;
            transition: none; } }
      .carousel-indicators .active {
        opacity: 1; }
    
    .carousel-caption {
      position: absolute;
      right: 15%;
      bottom: 20px;
      left: 15%;
      z-index: 10;
      padding-top: 20px;
      padding-bottom: 20px;
      color: #fff;
      text-align: center; }
    
    @-webkit-keyframes spinner-border {
      to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg); } }
    
    @keyframes spinner-border {
      to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg); } }
    
    .spinner-border {
      display: inline-block;
      width: 2rem;
      height: 2rem;
      vertical-align: text-bottom;
      border: 0.25em solid currentColor;
      border-right-color: transparent;
      border-radius: 50%;
      -webkit-animation: spinner-border .75s linear infinite;
      animation: spinner-border .75s linear infinite; }
    
    .spinner-border-sm {
      width: 1rem;
      height: 1rem;
      border-width: 0.2em; }
    
    @-webkit-keyframes spinner-grow {
      0% {
        -webkit-transform: scale(0);
        transform: scale(0); }
      50% {
        opacity: 1;
        -webkit-transform: none;
        transform: none; } }
    
    @keyframes spinner-grow {
      0% {
        -webkit-transform: scale(0);
        transform: scale(0); }
      50% {
        opacity: 1;
        -webkit-transform: none;
        transform: none; } }
    
    .spinner-grow {
      display: inline-block;
      width: 2rem;
      height: 2rem;
      vertical-align: text-bottom;
      background-color: currentColor;
      border-radius: 50%;
      opacity: 0;
      -webkit-animation: spinner-grow .75s linear infinite;
      animation: spinner-grow .75s linear infinite; }
    
    .spinner-grow-sm {
      width: 1rem;
      height: 1rem; }
    
    .align-baseline {
      vertical-align: baseline !important; }
    
    .align-top {
      vertical-align: top !important; }
    
    .align-middle {
      vertical-align: middle !important; }
    
    .align-bottom {
      vertical-align: bottom !important; }
    
    .align-text-bottom {
      vertical-align: text-bottom !important; }
    
    .align-text-top {
      vertical-align: text-top !important; }
    
    .bg-primary {
      background-color: #1bdbe0 !important; }
    
    a.bg-primary:hover, a.bg-primary:focus,
    button.bg-primary:hover,
    button.bg-primary:focus {
      background-color: #16afb2 !important; }
    
    .bg-secondary {
      background-color: #d8d8d8 !important; }
    
    a.bg-secondary:hover, a.bg-secondary:focus,
    button.bg-secondary:hover,
    button.bg-secondary:focus {
      background-color: #bfbfbf !important; }
    
    .bg-success {
      background-color: #38ce3c !important; }
    
    a.bg-success:hover, a.bg-success:focus,
    button.bg-success:hover,
    button.bg-success:focus {
      background-color: #2aa92d !important; }
    
    .bg-info {
      background-color: #8e32e9 !important; }
    
    a.bg-info:hover, a.bg-info:focus,
    button.bg-info:hover,
    button.bg-info:focus {
      background-color: #7516d2 !important; }
    
    .bg-warning {
      background-color: #ffde73 !important; }
    
    a.bg-warning:hover, a.bg-warning:focus,
    button.bg-warning:hover,
    button.bg-warning:focus {
      background-color: #ffd240 !important; }
    
    .bg-danger {
      background-color: #ff4d6b !important; }
    
    a.bg-danger:hover, a.bg-danger:focus,
    button.bg-danger:hover,
    button.bg-danger:focus {
      background-color: #ff1a41 !important; }
    
    .bg-light {
      background-color: #f8f9fa !important; }
    
    a.bg-light:hover, a.bg-light:focus,
    button.bg-light:hover,
    button.bg-light:focus {
      background-color: #dae0e5 !important; }
    
    .bg-dark {
      background-color: #3e4b5b !important; }
    
    a.bg-dark:hover, a.bg-dark:focus,
    button.bg-dark:hover,
    button.bg-dark:focus {
      background-color: #29323d !important; }
    
    .bg-white {
      background-color: #ffffff !important; }
    
    .bg-transparent {
      background-color: transparent !important; }
    
    .border, .loader-demo-box {
      border: 1px solid #e8ecf1 !important; }
    
    .border-top {
      border-top: 1px solid #e8ecf1 !important; }
    
    .border-right {
      border-right: 1px solid #e8ecf1 !important; }
    
    .border-bottom {
      border-bottom: 1px solid #e8ecf1 !important; }
    
    .border-left {
      border-left: 1px solid #e8ecf1 !important; }
    
    .border-0 {
      border: 0 !important; }
    
    .border-top-0 {
      border-top: 0 !important; }
    
    .border-right-0 {
      border-right: 0 !important; }
    
    .border-bottom-0 {
      border-bottom: 0 !important; }
    
    .border-left-0 {
      border-left: 0 !important; }
    
    .border-primary {
      border-color: #1bdbe0 !important; }
    
    .border-secondary, .loader-demo-box {
      border-color: #d8d8d8 !important; }
    
    .border-success {
      border-color: #38ce3c !important; }
    
    .border-info {
      border-color: #8e32e9 !important; }
    
    .border-warning {
      border-color: #ffde73 !important; }
    
    .border-danger {
      border-color: #ff4d6b !important; }
    
    .border-light {
      border-color: #f8f9fa !important; }
    
    .border-dark {
      border-color: #3e4b5b !important; }
    
    .border-white {
      border-color: #ffffff !important; }
    
    .rounded-sm {
      border-radius: 0.2rem !important; }
    
    .rounded, .loader-demo-box {
      border-radius: 0.25rem !important; }
    
    .rounded-top {
      border-top-left-radius: 0.25rem !important;
      border-top-right-radius: 0.25rem !important; }
    
    .rounded-right {
      border-top-right-radius: 0.25rem !important;
      border-bottom-right-radius: 0.25rem !important; }
    
    .rounded-bottom {
      border-bottom-right-radius: 0.25rem !important;
      border-bottom-left-radius: 0.25rem !important; }
    
    .rounded-left {
      border-top-left-radius: 0.25rem !important;
      border-bottom-left-radius: 0.25rem !important; }
    
    .rounded-lg {
      border-radius: 0.3rem !important; }
    
    .rounded-circle {
      border-radius: 50% !important; }
    
    .rounded-pill {
      border-radius: 50rem !important; }
    
    .rounded-0 {
      border-radius: 0 !important; }
    
    .clearfix::after {
      display: block;
      clear: both;
      content: ""; }
    
    .d-none {
      display: none !important; }
    
    .d-inline {
      display: inline !important; }
    
    .d-inline-block {
      display: inline-block !important; }
    
    .d-block {
      display: block !important; }
    
    .d-table {
      display: table !important; }
    
    .d-table-row {
      display: table-row !important; }
    
    .d-table-cell {
      display: table-cell !important; }
    
    .d-flex, .page-header, .loader-demo-box, .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown .dropdown-item {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important; }
    
    .d-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important; }
    
    @media (min-width: 576px) {
      .d-sm-none {
        display: none !important; }
      .d-sm-inline {
        display: inline !important; }
      .d-sm-inline-block {
        display: inline-block !important; }
      .d-sm-block {
        display: block !important; }
      .d-sm-table {
        display: table !important; }
      .d-sm-table-row {
        display: table-row !important; }
      .d-sm-table-cell {
        display: table-cell !important; }
      .d-sm-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important; }
      .d-sm-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important; } }
    
    @media (min-width: 768px) {
      .d-md-none {
        display: none !important; }
      .d-md-inline {
        display: inline !important; }
      .d-md-inline-block {
        display: inline-block !important; }
      .d-md-block {
        display: block !important; }
      .d-md-table {
        display: table !important; }
      .d-md-table-row {
        display: table-row !important; }
      .d-md-table-cell {
        display: table-cell !important; }
      .d-md-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important; }
      .d-md-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important; } }
    
    @media (min-width: 992px) {
      .d-lg-none {
        display: none !important; }
      .d-lg-inline {
        display: inline !important; }
      .d-lg-inline-block {
        display: inline-block !important; }
      .d-lg-block {
        display: block !important; }
      .d-lg-table {
        display: table !important; }
      .d-lg-table-row {
        display: table-row !important; }
      .d-lg-table-cell {
        display: table-cell !important; }
      .d-lg-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important; }
      .d-lg-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important; } }
    
    @media (min-width: 1200px) {
      .d-xl-none {
        display: none !important; }
      .d-xl-inline {
        display: inline !important; }
      .d-xl-inline-block {
        display: inline-block !important; }
      .d-xl-block {
        display: block !important; }
      .d-xl-table {
        display: table !important; }
      .d-xl-table-row {
        display: table-row !important; }
      .d-xl-table-cell {
        display: table-cell !important; }
      .d-xl-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important; }
      .d-xl-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important; } }
    
    @media print {
      .d-print-none {
        display: none !important; }
      .d-print-inline {
        display: inline !important; }
      .d-print-inline-block {
        display: inline-block !important; }
      .d-print-block {
        display: block !important; }
      .d-print-table {
        display: table !important; }
      .d-print-table-row {
        display: table-row !important; }
      .d-print-table-cell {
        display: table-cell !important; }
      .d-print-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important; }
      .d-print-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important; } }
    
    .embed-responsive {
      position: relative;
      display: block;
      width: 100%;
      padding: 0;
      overflow: hidden; }
      .embed-responsive::before {
        display: block;
        content: ""; }
      .embed-responsive .embed-responsive-item,
      .embed-responsive iframe,
      .embed-responsive embed,
      .embed-responsive object,
      .embed-responsive video {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0; }
    
    .embed-responsive-21by9::before {
      padding-top: 42.85714%; }
    
    .embed-responsive-16by9::before {
      padding-top: 56.25%; }
    
    .embed-responsive-4by3::before {
      padding-top: 75%; }
    
    .embed-responsive-1by1::before {
      padding-top: 100%; }
    
    .embed-responsive-21by9::before {
      padding-top: 42.85714%; }
    
    .embed-responsive-16by9::before {
      padding-top: 56.25%; }
    
    .embed-responsive-4by3::before {
      padding-top: 75%; }
    
    .embed-responsive-1by1::before {
      padding-top: 100%; }
    
    .flex-row {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
      flex-direction: row !important; }
    
    .flex-column {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
      flex-direction: column !important; }
    
    .flex-row-reverse {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important; }
    
    .flex-column-reverse {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important; }
    
    .flex-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important; }
    
    .flex-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important; }
    
    .flex-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important; }
    
    .flex-fill {
      -webkit-box-flex: 1 !important;
      -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important; }
    
    .flex-grow-0 {
      -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
      flex-grow: 0 !important; }
    
    .flex-grow-1 {
      -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
      flex-grow: 1 !important; }
    
    .flex-shrink-0 {
      -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important; }
    
    .flex-shrink-1 {
      -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important; }
    
    .justify-content-start {
      -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
      justify-content: flex-start !important; }
    
    .justify-content-end {
      -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
      justify-content: flex-end !important; }
    
    .justify-content-center {
      -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
      justify-content: center !important; }
    
    .justify-content-between, .page-header {
      -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
      justify-content: space-between !important; }
    
    .justify-content-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important; }
    
    .align-items-start {
      -webkit-box-align: start !important;
      -ms-flex-align: start !important;
      align-items: flex-start !important; }
    
    .align-items-end {
      -webkit-box-align: end !important;
      -ms-flex-align: end !important;
      align-items: flex-end !important; }
    
    .align-items-center, .page-header, .loader-demo-box, .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown .dropdown-item {
      -webkit-box-align: center !important;
      -ms-flex-align: center !important;
      align-items: center !important; }
    
    .align-items-baseline {
      -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
      align-items: baseline !important; }
    
    .align-items-stretch {
      -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
      align-items: stretch !important; }
    
    .align-content-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important; }
    
    .align-content-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important; }
    
    .align-content-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important; }
    
    .align-content-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important; }
    
    .align-content-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important; }
    
    .align-content-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important; }
    
    .align-self-auto {
      -ms-flex-item-align: auto !important;
      align-self: auto !important; }
    
    .align-self-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important; }
    
    .align-self-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important; }
    
    .align-self-center {
      -ms-flex-item-align: center !important;
      align-self: center !important; }
    
    .align-self-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important; }
    
    .align-self-stretch {
      -ms-flex-item-align: stretch !important;
      align-self: stretch !important; }
    
    @media (min-width: 576px) {
      .flex-sm-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important; }
      .flex-sm-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important; }
      .flex-sm-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important; }
      .flex-sm-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important; }
      .flex-sm-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
      .flex-sm-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
      .flex-sm-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
      .flex-sm-fill {
        -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important; }
      .flex-sm-grow-0 {
        -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important; }
      .flex-sm-grow-1 {
        -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important; }
      .flex-sm-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
      .flex-sm-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
      .justify-content-sm-start {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important; }
      .justify-content-sm-end {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important; }
      .justify-content-sm-center {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important; }
      .justify-content-sm-between {
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important; }
      .justify-content-sm-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
      .align-items-sm-start {
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important; }
      .align-items-sm-end {
        -webkit-box-align: end !important;
        -ms-flex-align: end !important;
        align-items: flex-end !important; }
      .align-items-sm-center {
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important; }
      .align-items-sm-baseline {
        -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
        align-items: baseline !important; }
      .align-items-sm-stretch {
        -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important; }
      .align-content-sm-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
      .align-content-sm-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
      .align-content-sm-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important; }
      .align-content-sm-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
      .align-content-sm-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
      .align-content-sm-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
      .align-self-sm-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important; }
      .align-self-sm-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
      .align-self-sm-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
      .align-self-sm-center {
        -ms-flex-item-align: center !important;
        align-self: center !important; }
      .align-self-sm-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
      .align-self-sm-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }
    
    @media (min-width: 768px) {
      .flex-md-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important; }
      .flex-md-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important; }
      .flex-md-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important; }
      .flex-md-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important; }
      .flex-md-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
      .flex-md-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
      .flex-md-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
      .flex-md-fill {
        -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important; }
      .flex-md-grow-0 {
        -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important; }
      .flex-md-grow-1 {
        -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important; }
      .flex-md-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
      .flex-md-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
      .justify-content-md-start {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important; }
      .justify-content-md-end {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important; }
      .justify-content-md-center {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important; }
      .justify-content-md-between {
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important; }
      .justify-content-md-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
      .align-items-md-start {
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important; }
      .align-items-md-end {
        -webkit-box-align: end !important;
        -ms-flex-align: end !important;
        align-items: flex-end !important; }
      .align-items-md-center {
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important; }
      .align-items-md-baseline {
        -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
        align-items: baseline !important; }
      .align-items-md-stretch {
        -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important; }
      .align-content-md-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
      .align-content-md-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
      .align-content-md-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important; }
      .align-content-md-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
      .align-content-md-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
      .align-content-md-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
      .align-self-md-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important; }
      .align-self-md-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
      .align-self-md-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
      .align-self-md-center {
        -ms-flex-item-align: center !important;
        align-self: center !important; }
      .align-self-md-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
      .align-self-md-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }
    
    @media (min-width: 992px) {
      .flex-lg-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important; }
      .flex-lg-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important; }
      .flex-lg-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important; }
      .flex-lg-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important; }
      .flex-lg-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
      .flex-lg-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
      .flex-lg-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
      .flex-lg-fill {
        -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important; }
      .flex-lg-grow-0 {
        -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important; }
      .flex-lg-grow-1 {
        -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important; }
      .flex-lg-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
      .flex-lg-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
      .justify-content-lg-start {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important; }
      .justify-content-lg-end {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important; }
      .justify-content-lg-center {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important; }
      .justify-content-lg-between {
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important; }
      .justify-content-lg-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
      .align-items-lg-start {
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important; }
      .align-items-lg-end {
        -webkit-box-align: end !important;
        -ms-flex-align: end !important;
        align-items: flex-end !important; }
      .align-items-lg-center {
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important; }
      .align-items-lg-baseline {
        -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
        align-items: baseline !important; }
      .align-items-lg-stretch {
        -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important; }
      .align-content-lg-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
      .align-content-lg-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
      .align-content-lg-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important; }
      .align-content-lg-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
      .align-content-lg-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
      .align-content-lg-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
      .align-self-lg-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important; }
      .align-self-lg-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
      .align-self-lg-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
      .align-self-lg-center {
        -ms-flex-item-align: center !important;
        align-self: center !important; }
      .align-self-lg-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
      .align-self-lg-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }
    
    @media (min-width: 1200px) {
      .flex-xl-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important; }
      .flex-xl-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important; }
      .flex-xl-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important; }
      .flex-xl-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important; }
      .flex-xl-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
      .flex-xl-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
      .flex-xl-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
      .flex-xl-fill {
        -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important; }
      .flex-xl-grow-0 {
        -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important; }
      .flex-xl-grow-1 {
        -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important; }
      .flex-xl-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
      .flex-xl-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
      .justify-content-xl-start {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important; }
      .justify-content-xl-end {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important; }
      .justify-content-xl-center {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important; }
      .justify-content-xl-between {
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important; }
      .justify-content-xl-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
      .align-items-xl-start {
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important; }
      .align-items-xl-end {
        -webkit-box-align: end !important;
        -ms-flex-align: end !important;
        align-items: flex-end !important; }
      .align-items-xl-center {
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important; }
      .align-items-xl-baseline {
        -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
        align-items: baseline !important; }
      .align-items-xl-stretch {
        -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important; }
      .align-content-xl-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
      .align-content-xl-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
      .align-content-xl-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important; }
      .align-content-xl-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
      .align-content-xl-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
      .align-content-xl-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
      .align-self-xl-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important; }
      .align-self-xl-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
      .align-self-xl-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
      .align-self-xl-center {
        -ms-flex-item-align: center !important;
        align-self: center !important; }
      .align-self-xl-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
      .align-self-xl-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }
    
    .float-left {
      float: left !important; }
    
    .float-right {
      float: right !important; }
    
    .float-none {
      float: none !important; }
    
    @media (min-width: 576px) {
      .float-sm-left {
        float: left !important; }
      .float-sm-right {
        float: right !important; }
      .float-sm-none {
        float: none !important; } }
    
    @media (min-width: 768px) {
      .float-md-left {
        float: left !important; }
      .float-md-right {
        float: right !important; }
      .float-md-none {
        float: none !important; } }
    
    @media (min-width: 992px) {
      .float-lg-left {
        float: left !important; }
      .float-lg-right {
        float: right !important; }
      .float-lg-none {
        float: none !important; } }
    
    @media (min-width: 1200px) {
      .float-xl-left {
        float: left !important; }
      .float-xl-right {
        float: right !important; }
      .float-xl-none {
        float: none !important; } }
    
    .user-select-all {
      -webkit-user-select: all !important;
      -moz-user-select: all !important;
      -ms-user-select: all !important;
      user-select: all !important; }
    
    .user-select-auto {
      -webkit-user-select: auto !important;
      -moz-user-select: auto !important;
      -ms-user-select: auto !important;
      user-select: auto !important; }
    
    .user-select-none {
      -webkit-user-select: none !important;
      -moz-user-select: none !important;
      -ms-user-select: none !important;
      user-select: none !important; }
    
    .overflow-auto {
      overflow: auto !important; }
    
    .overflow-hidden {
      overflow: hidden !important; }
    
    .position-static {
      position: static !important; }
    
    .position-relative {
      position: relative !important; }
    
    .position-absolute {
      position: absolute !important; }
    
    .position-fixed {
      position: fixed !important; }
    
    .position-sticky {
      position: sticky !important; }
    
    .fixed-top {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1030; }
    
    .fixed-bottom {
      position: fixed;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1030; }
    
    @supports (position: sticky) {
      .sticky-top {
        position: sticky;
        top: 0;
        z-index: 1020; } }
    
    .sr-only {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border: 0; }
    
    .sr-only-focusable:active, .sr-only-focusable:focus {
      position: static;
      width: auto;
      height: auto;
      overflow: visible;
      clip: auto;
      white-space: normal; }
    
    .shadow-sm {
      -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }
    
    .shadow {
      -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }
    
    .shadow-lg {
      -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
      box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }
    
    .shadow-none {
      -webkit-box-shadow: none !important;
      box-shadow: none !important; }
    
    .w-25 {
      width: 25% !important; }
    
    .w-50 {
      width: 50% !important; }
    
    .w-75 {
      width: 75% !important; }
    
    .w-100 {
      width: 100% !important; }
    
    .w-auto {
      width: auto !important; }
    
    .h-25 {
      height: 25% !important; }
    
    .h-50 {
      height: 50% !important; }
    
    .h-75 {
      height: 75% !important; }
    
    .h-100 {
      height: 100% !important; }
    
    .h-auto {
      height: auto !important; }
    
    .mw-100 {
      max-width: 100% !important; }
    
    .mh-100 {
      max-height: 100% !important; }
    
    .min-vw-100 {
      min-width: 100vw !important; }
    
    .min-vh-100 {
      min-height: 100vh !important; }
    
    .vw-100 {
      width: 100vw !important; }
    
    .vh-100 {
      height: 100vh !important; }
    
    .m-0 {
      margin: 0 !important; }
    
    .mt-0, .demo-modal .modal-dialog,
    .my-0 {
      margin-top: 0 !important; }
    
    .mr-0,
    .mx-0 {
      margin-right: 0 !important; }
    
    .mb-0,
    .my-0 {
      margin-bottom: 0 !important; }
    
    .ml-0, .rtl .preview-list .preview-item .preview-thumbnail .preview-icon i,
    .mx-0 {
      margin-left: 0 !important; }
    
    .m-1 {
      margin: 0.25rem !important; }
    
    .mt-1,
    .my-1 {
      margin-top: 0.25rem !important; }
    
    .mr-1,
    .mx-1 {
      margin-right: 0.25rem !important; }
    
    .mb-1,
    .my-1 {
      margin-bottom: 0.25rem !important; }
    
    .ml-1,
    .mx-1 {
      margin-left: 0.25rem !important; }
    
    .m-2 {
      margin: 0.5rem !important; }
    
    .mt-2, .template-demo > .btn-group,
    .template-demo > .btn-group-vertical, .template-demo .circle-progress,
    .my-2 {
      margin-top: 0.5rem !important; }
    
    .mr-2, .template-demo > .btn-group,
    .template-demo > .btn-group-vertical, .template-demo > .dropdown,
    .mx-2 {
      margin-right: 0.5rem !important; }
    
    .mb-2,
    .my-2 {
      margin-bottom: 0.5rem !important; }
    
    .ml-2, .btn-toolbar .btn-group + .btn-group,
    .mx-2 {
      margin-left: 0.5rem !important; }
    
    .m-3 {
      margin: 1rem !important; }
    
    .mt-3, .template-demo > .btn, .template-demo > .btn-toolbar,
    .my-3 {
      margin-top: 1rem !important; }
    
    .mr-3, .template-demo > .btn, .template-demo > .btn-toolbar,
    .mx-3 {
      margin-right: 1rem !important; }
    
    .mb-3, .template-demo .circle-progress-block,
    .my-3 {
      margin-bottom: 1rem !important; }
    
    .ml-3,
    .mx-3 {
      margin-left: 1rem !important; }
    
    .m-4 {
      margin: 1.5rem !important; }
    
    .mt-4,
    .my-4 {
      margin-top: 1.5rem !important; }
    
    .mr-4,
    .mx-4 {
      margin-right: 1.5rem !important; }
    
    .mb-4,
    .my-4 {
      margin-bottom: 1.5rem !important; }
    
    .ml-4,
    .mx-4 {
      margin-left: 1.5rem !important; }
    
    .m-5 {
      margin: 3rem !important; }
    
    .mt-5,
    .my-5 {
      margin-top: 3rem !important; }
    
    .mr-5,
    .mx-5 {
      margin-right: 3rem !important; }
    
    .mb-5,
    .my-5 {
      margin-bottom: 3rem !important; }
    
    .ml-5,
    .mx-5 {
      margin-left: 3rem !important; }
    
    .p-0 {
      padding: 0 !important; }
    
    .pt-0,
    .py-0 {
      padding-top: 0 !important; }
    
    .pr-0,
    .px-0 {
      padding-right: 0 !important; }
    
    .pb-0,
    .py-0 {
      padding-bottom: 0 !important; }
    
    .pl-0,
    .px-0 {
      padding-left: 0 !important; }
    
    .p-1 {
      padding: 0.25rem !important; }
    
    .pt-1,
    .py-1 {
      padding-top: 0.25rem !important; }
    
    .pr-1,
    .px-1 {
      padding-right: 0.25rem !important; }
    
    .pb-1,
    .py-1 {
      padding-bottom: 0.25rem !important; }
    
    .pl-1,
    .px-1 {
      padding-left: 0.25rem !important; }
    
    .p-2 {
      padding: 0.5rem !important; }
    
    .pt-2,
    .py-2 {
      padding-top: 0.5rem !important; }
    
    .pr-2,
    .px-2,
    .template-demo .circle-progress-block {
      padding-right: 0.5rem !important; }
    
    .pb-2,
    .py-2 {
      padding-bottom: 0.5rem !important; }
    
    .pl-2,
    .px-2,
    .template-demo .circle-progress-block {
      padding-left: 0.5rem !important; }
    
    .p-3 {
      padding: 1rem !important; }
    
    .pt-3,
    .py-3 {
      padding-top: 1rem !important; }
    
    .pr-3,
    .px-3 {
      padding-right: 1rem !important; }
    
    .pb-3,
    .py-3 {
      padding-bottom: 1rem !important; }
    
    .pl-3,
    .px-3 {
      padding-left: 1rem !important; }
    
    .p-4 {
      padding: 1.5rem !important; }
    
    .pt-4,
    .py-4 {
      padding-top: 1.5rem !important; }
    
    .pr-4,
    .px-4 {
      padding-right: 1.5rem !important; }
    
    .pb-4,
    .py-4 {
      padding-bottom: 1.5rem !important; }
    
    .pl-4,
    .px-4 {
      padding-left: 1.5rem !important; }
    
    .p-5 {
      padding: 3rem !important; }
    
    .pt-5,
    .py-5 {
      padding-top: 3rem !important; }
    
    .pr-5,
    .px-5 {
      padding-right: 3rem !important; }
    
    .pb-5,
    .py-5 {
      padding-bottom: 3rem !important; }
    
    .pl-5,
    .px-5 {
      padding-left: 3rem !important; }
    
    .m-n1 {
      margin: -0.25rem !important; }
    
    .mt-n1,
    .my-n1 {
      margin-top: -0.25rem !important; }
    
    .mr-n1,
    .mx-n1 {
      margin-right: -0.25rem !important; }
    
    .mb-n1,
    .my-n1 {
      margin-bottom: -0.25rem !important; }
    
    .ml-n1,
    .mx-n1 {
      margin-left: -0.25rem !important; }
    
    .m-n2 {
      margin: -0.5rem !important; }
    
    .mt-n2,
    .my-n2 {
      margin-top: -0.5rem !important; }
    
    .mr-n2,
    .mx-n2 {
      margin-right: -0.5rem !important; }
    
    .mb-n2,
    .my-n2 {
      margin-bottom: -0.5rem !important; }
    
    .ml-n2,
    .mx-n2 {
      margin-left: -0.5rem !important; }
    
    .m-n3 {
      margin: -1rem !important; }
    
    .mt-n3,
    .my-n3 {
      margin-top: -1rem !important; }
    
    .mr-n3,
    .mx-n3 {
      margin-right: -1rem !important; }
    
    .mb-n3,
    .my-n3 {
      margin-bottom: -1rem !important; }
    
    .ml-n3,
    .mx-n3 {
      margin-left: -1rem !important; }
    
    .m-n4 {
      margin: -1.5rem !important; }
    
    .mt-n4,
    .my-n4 {
      margin-top: -1.5rem !important; }
    
    .mr-n4,
    .mx-n4 {
      margin-right: -1.5rem !important; }
    
    .mb-n4,
    .my-n4 {
      margin-bottom: -1.5rem !important; }
    
    .ml-n4,
    .mx-n4 {
      margin-left: -1.5rem !important; }
    
    .m-n5 {
      margin: -3rem !important; }
    
    .mt-n5,
    .my-n5 {
      margin-top: -3rem !important; }
    
    .mr-n5,
    .mx-n5 {
      margin-right: -3rem !important; }
    
    .mb-n5,
    .my-n5 {
      margin-bottom: -3rem !important; }
    
    .ml-n5,
    .mx-n5 {
      margin-left: -3rem !important; }
    
    .m-auto {
      margin: auto !important; }
    
    .mt-auto,
    .my-auto {
      margin-top: auto !important; }
    
    .mr-auto,
    .mx-auto {
      margin-right: auto !important; }
    
    .mb-auto,
    .my-auto {
      margin-bottom: auto !important; }
    
    .ml-auto,
    .mx-auto {
      margin-left: auto !important; }
    
    @media (min-width: 576px) {
      .m-sm-0 {
        margin: 0 !important; }
      .mt-sm-0,
      .my-sm-0 {
        margin-top: 0 !important; }
      .mr-sm-0,
      .mx-sm-0 {
        margin-right: 0 !important; }
      .mb-sm-0,
      .my-sm-0 {
        margin-bottom: 0 !important; }
      .ml-sm-0,
      .mx-sm-0 {
        margin-left: 0 !important; }
      .m-sm-1 {
        margin: 0.25rem !important; }
      .mt-sm-1,
      .my-sm-1 {
        margin-top: 0.25rem !important; }
      .mr-sm-1,
      .mx-sm-1 {
        margin-right: 0.25rem !important; }
      .mb-sm-1,
      .my-sm-1 {
        margin-bottom: 0.25rem !important; }
      .ml-sm-1,
      .mx-sm-1 {
        margin-left: 0.25rem !important; }
      .m-sm-2 {
        margin: 0.5rem !important; }
      .mt-sm-2,
      .my-sm-2 {
        margin-top: 0.5rem !important; }
      .mr-sm-2,
      .mx-sm-2 {
        margin-right: 0.5rem !important; }
      .mb-sm-2,
      .my-sm-2 {
        margin-bottom: 0.5rem !important; }
      .ml-sm-2,
      .mx-sm-2 {
        margin-left: 0.5rem !important; }
      .m-sm-3 {
        margin: 1rem !important; }
      .mt-sm-3,
      .my-sm-3 {
        margin-top: 1rem !important; }
      .mr-sm-3,
      .mx-sm-3 {
        margin-right: 1rem !important; }
      .mb-sm-3,
      .my-sm-3 {
        margin-bottom: 1rem !important; }
      .ml-sm-3,
      .mx-sm-3 {
        margin-left: 1rem !important; }
      .m-sm-4 {
        margin: 1.5rem !important; }
      .mt-sm-4,
      .my-sm-4 {
        margin-top: 1.5rem !important; }
      .mr-sm-4,
      .mx-sm-4 {
        margin-right: 1.5rem !important; }
      .mb-sm-4,
      .my-sm-4 {
        margin-bottom: 1.5rem !important; }
      .ml-sm-4,
      .mx-sm-4 {
        margin-left: 1.5rem !important; }
      .m-sm-5 {
        margin: 3rem !important; }
      .mt-sm-5,
      .my-sm-5 {
        margin-top: 3rem !important; }
      .mr-sm-5,
      .mx-sm-5 {
        margin-right: 3rem !important; }
      .mb-sm-5,
      .my-sm-5 {
        margin-bottom: 3rem !important; }
      .ml-sm-5,
      .mx-sm-5 {
        margin-left: 3rem !important; }
      .p-sm-0 {
        padding: 0 !important; }
      .pt-sm-0,
      .py-sm-0 {
        padding-top: 0 !important; }
      .pr-sm-0,
      .px-sm-0 {
        padding-right: 0 !important; }
      .pb-sm-0,
      .py-sm-0 {
        padding-bottom: 0 !important; }
      .pl-sm-0,
      .px-sm-0 {
        padding-left: 0 !important; }
      .p-sm-1 {
        padding: 0.25rem !important; }
      .pt-sm-1,
      .py-sm-1 {
        padding-top: 0.25rem !important; }
      .pr-sm-1,
      .px-sm-1 {
        padding-right: 0.25rem !important; }
      .pb-sm-1,
      .py-sm-1 {
        padding-bottom: 0.25rem !important; }
      .pl-sm-1,
      .px-sm-1 {
        padding-left: 0.25rem !important; }
      .p-sm-2 {
        padding: 0.5rem !important; }
      .pt-sm-2,
      .py-sm-2 {
        padding-top: 0.5rem !important; }
      .pr-sm-2,
      .px-sm-2 {
        padding-right: 0.5rem !important; }
      .pb-sm-2,
      .py-sm-2 {
        padding-bottom: 0.5rem !important; }
      .pl-sm-2,
      .px-sm-2 {
        padding-left: 0.5rem !important; }
      .p-sm-3 {
        padding: 1rem !important; }
      .pt-sm-3,
      .py-sm-3 {
        padding-top: 1rem !important; }
      .pr-sm-3,
      .px-sm-3 {
        padding-right: 1rem !important; }
      .pb-sm-3,
      .py-sm-3 {
        padding-bottom: 1rem !important; }
      .pl-sm-3,
      .px-sm-3 {
        padding-left: 1rem !important; }
      .p-sm-4 {
        padding: 1.5rem !important; }
      .pt-sm-4,
      .py-sm-4 {
        padding-top: 1.5rem !important; }
      .pr-sm-4,
      .px-sm-4 {
        padding-right: 1.5rem !important; }
      .pb-sm-4,
      .py-sm-4 {
        padding-bottom: 1.5rem !important; }
      .pl-sm-4,
      .px-sm-4 {
        padding-left: 1.5rem !important; }
      .p-sm-5 {
        padding: 3rem !important; }
      .pt-sm-5,
      .py-sm-5 {
        padding-top: 3rem !important; }
      .pr-sm-5,
      .px-sm-5 {
        padding-right: 3rem !important; }
      .pb-sm-5,
      .py-sm-5 {
        padding-bottom: 3rem !important; }
      .pl-sm-5,
      .px-sm-5 {
        padding-left: 3rem !important; }
      .m-sm-n1 {
        margin: -0.25rem !important; }
      .mt-sm-n1,
      .my-sm-n1 {
        margin-top: -0.25rem !important; }
      .mr-sm-n1,
      .mx-sm-n1 {
        margin-right: -0.25rem !important; }
      .mb-sm-n1,
      .my-sm-n1 {
        margin-bottom: -0.25rem !important; }
      .ml-sm-n1,
      .mx-sm-n1 {
        margin-left: -0.25rem !important; }
      .m-sm-n2 {
        margin: -0.5rem !important; }
      .mt-sm-n2,
      .my-sm-n2 {
        margin-top: -0.5rem !important; }
      .mr-sm-n2,
      .mx-sm-n2 {
        margin-right: -0.5rem !important; }
      .mb-sm-n2,
      .my-sm-n2 {
        margin-bottom: -0.5rem !important; }
      .ml-sm-n2,
      .mx-sm-n2 {
        margin-left: -0.5rem !important; }
      .m-sm-n3 {
        margin: -1rem !important; }
      .mt-sm-n3,
      .my-sm-n3 {
        margin-top: -1rem !important; }
      .mr-sm-n3,
      .mx-sm-n3 {
        margin-right: -1rem !important; }
      .mb-sm-n3,
      .my-sm-n3 {
        margin-bottom: -1rem !important; }
      .ml-sm-n3,
      .mx-sm-n3 {
        margin-left: -1rem !important; }
      .m-sm-n4 {
        margin: -1.5rem !important; }
      .mt-sm-n4,
      .my-sm-n4 {
        margin-top: -1.5rem !important; }
      .mr-sm-n4,
      .mx-sm-n4 {
        margin-right: -1.5rem !important; }
      .mb-sm-n4,
      .my-sm-n4 {
        margin-bottom: -1.5rem !important; }
      .ml-sm-n4,
      .mx-sm-n4 {
        margin-left: -1.5rem !important; }
      .m-sm-n5 {
        margin: -3rem !important; }
      .mt-sm-n5,
      .my-sm-n5 {
        margin-top: -3rem !important; }
      .mr-sm-n5,
      .mx-sm-n5 {
        margin-right: -3rem !important; }
      .mb-sm-n5,
      .my-sm-n5 {
        margin-bottom: -3rem !important; }
      .ml-sm-n5,
      .mx-sm-n5 {
        margin-left: -3rem !important; }
      .m-sm-auto {
        margin: auto !important; }
      .mt-sm-auto,
      .my-sm-auto {
        margin-top: auto !important; }
      .mr-sm-auto,
      .mx-sm-auto {
        margin-right: auto !important; }
      .mb-sm-auto,
      .my-sm-auto {
        margin-bottom: auto !important; }
      .ml-sm-auto,
      .mx-sm-auto {
        margin-left: auto !important; } }
    
    @media (min-width: 768px) {
      .m-md-0 {
        margin: 0 !important; }
      .mt-md-0,
      .my-md-0 {
        margin-top: 0 !important; }
      .mr-md-0,
      .mx-md-0 {
        margin-right: 0 !important; }
      .mb-md-0,
      .my-md-0 {
        margin-bottom: 0 !important; }
      .ml-md-0,
      .mx-md-0 {
        margin-left: 0 !important; }
      .m-md-1 {
        margin: 0.25rem !important; }
      .mt-md-1,
      .my-md-1 {
        margin-top: 0.25rem !important; }
      .mr-md-1,
      .mx-md-1 {
        margin-right: 0.25rem !important; }
      .mb-md-1,
      .my-md-1 {
        margin-bottom: 0.25rem !important; }
      .ml-md-1,
      .mx-md-1 {
        margin-left: 0.25rem !important; }
      .m-md-2 {
        margin: 0.5rem !important; }
      .mt-md-2,
      .my-md-2 {
        margin-top: 0.5rem !important; }
      .mr-md-2,
      .mx-md-2 {
        margin-right: 0.5rem !important; }
      .mb-md-2,
      .my-md-2 {
        margin-bottom: 0.5rem !important; }
      .ml-md-2,
      .mx-md-2 {
        margin-left: 0.5rem !important; }
      .m-md-3 {
        margin: 1rem !important; }
      .mt-md-3,
      .my-md-3 {
        margin-top: 1rem !important; }
      .mr-md-3,
      .mx-md-3 {
        margin-right: 1rem !important; }
      .mb-md-3,
      .my-md-3 {
        margin-bottom: 1rem !important; }
      .ml-md-3,
      .mx-md-3 {
        margin-left: 1rem !important; }
      .m-md-4 {
        margin: 1.5rem !important; }
      .mt-md-4,
      .my-md-4 {
        margin-top: 1.5rem !important; }
      .mr-md-4,
      .mx-md-4 {
        margin-right: 1.5rem !important; }
      .mb-md-4,
      .my-md-4 {
        margin-bottom: 1.5rem !important; }
      .ml-md-4,
      .mx-md-4 {
        margin-left: 1.5rem !important; }
      .m-md-5 {
        margin: 3rem !important; }
      .mt-md-5,
      .my-md-5 {
        margin-top: 3rem !important; }
      .mr-md-5,
      .mx-md-5 {
        margin-right: 3rem !important; }
      .mb-md-5,
      .my-md-5 {
        margin-bottom: 3rem !important; }
      .ml-md-5,
      .mx-md-5 {
        margin-left: 3rem !important; }
      .p-md-0 {
        padding: 0 !important; }
      .pt-md-0,
      .py-md-0 {
        padding-top: 0 !important; }
      .pr-md-0,
      .px-md-0 {
        padding-right: 0 !important; }
      .pb-md-0,
      .py-md-0 {
        padding-bottom: 0 !important; }
      .pl-md-0,
      .px-md-0 {
        padding-left: 0 !important; }
      .p-md-1 {
        padding: 0.25rem !important; }
      .pt-md-1,
      .py-md-1 {
        padding-top: 0.25rem !important; }
      .pr-md-1,
      .px-md-1 {
        padding-right: 0.25rem !important; }
      .pb-md-1,
      .py-md-1 {
        padding-bottom: 0.25rem !important; }
      .pl-md-1,
      .px-md-1 {
        padding-left: 0.25rem !important; }
      .p-md-2 {
        padding: 0.5rem !important; }
      .pt-md-2,
      .py-md-2 {
        padding-top: 0.5rem !important; }
      .pr-md-2,
      .px-md-2 {
        padding-right: 0.5rem !important; }
      .pb-md-2,
      .py-md-2 {
        padding-bottom: 0.5rem !important; }
      .pl-md-2,
      .px-md-2 {
        padding-left: 0.5rem !important; }
      .p-md-3 {
        padding: 1rem !important; }
      .pt-md-3,
      .py-md-3 {
        padding-top: 1rem !important; }
      .pr-md-3,
      .px-md-3 {
        padding-right: 1rem !important; }
      .pb-md-3,
      .py-md-3 {
        padding-bottom: 1rem !important; }
      .pl-md-3,
      .px-md-3 {
        padding-left: 1rem !important; }
      .p-md-4 {
        padding: 1.5rem !important; }
      .pt-md-4,
      .py-md-4 {
        padding-top: 1.5rem !important; }
      .pr-md-4,
      .px-md-4 {
        padding-right: 1.5rem !important; }
      .pb-md-4,
      .py-md-4 {
        padding-bottom: 1.5rem !important; }
      .pl-md-4,
      .px-md-4 {
        padding-left: 1.5rem !important; }
      .p-md-5 {
        padding: 3rem !important; }
      .pt-md-5,
      .py-md-5 {
        padding-top: 3rem !important; }
      .pr-md-5,
      .px-md-5 {
        padding-right: 3rem !important; }
      .pb-md-5,
      .py-md-5 {
        padding-bottom: 3rem !important; }
      .pl-md-5,
      .px-md-5 {
        padding-left: 3rem !important; }
      .m-md-n1 {
        margin: -0.25rem !important; }
      .mt-md-n1,
      .my-md-n1 {
        margin-top: -0.25rem !important; }
      .mr-md-n1,
      .mx-md-n1 {
        margin-right: -0.25rem !important; }
      .mb-md-n1,
      .my-md-n1 {
        margin-bottom: -0.25rem !important; }
      .ml-md-n1,
      .mx-md-n1 {
        margin-left: -0.25rem !important; }
      .m-md-n2 {
        margin: -0.5rem !important; }
      .mt-md-n2,
      .my-md-n2 {
        margin-top: -0.5rem !important; }
      .mr-md-n2,
      .mx-md-n2 {
        margin-right: -0.5rem !important; }
      .mb-md-n2,
      .my-md-n2 {
        margin-bottom: -0.5rem !important; }
      .ml-md-n2,
      .mx-md-n2 {
        margin-left: -0.5rem !important; }
      .m-md-n3 {
        margin: -1rem !important; }
      .mt-md-n3,
      .my-md-n3 {
        margin-top: -1rem !important; }
      .mr-md-n3,
      .mx-md-n3 {
        margin-right: -1rem !important; }
      .mb-md-n3,
      .my-md-n3 {
        margin-bottom: -1rem !important; }
      .ml-md-n3,
      .mx-md-n3 {
        margin-left: -1rem !important; }
      .m-md-n4 {
        margin: -1.5rem !important; }
      .mt-md-n4,
      .my-md-n4 {
        margin-top: -1.5rem !important; }
      .mr-md-n4,
      .mx-md-n4 {
        margin-right: -1.5rem !important; }
      .mb-md-n4,
      .my-md-n4 {
        margin-bottom: -1.5rem !important; }
      .ml-md-n4,
      .mx-md-n4 {
        margin-left: -1.5rem !important; }
      .m-md-n5 {
        margin: -3rem !important; }
      .mt-md-n5,
      .my-md-n5 {
        margin-top: -3rem !important; }
      .mr-md-n5,
      .mx-md-n5 {
        margin-right: -3rem !important; }
      .mb-md-n5,
      .my-md-n5 {
        margin-bottom: -3rem !important; }
      .ml-md-n5,
      .mx-md-n5 {
        margin-left: -3rem !important; }
      .m-md-auto {
        margin: auto !important; }
      .mt-md-auto,
      .my-md-auto {
        margin-top: auto !important; }
      .mr-md-auto,
      .mx-md-auto {
        margin-right: auto !important; }
      .mb-md-auto,
      .my-md-auto {
        margin-bottom: auto !important; }
      .ml-md-auto,
      .mx-md-auto {
        margin-left: auto !important; } }
    
    @media (min-width: 992px) {
      .m-lg-0 {
        margin: 0 !important; }
      .mt-lg-0,
      .my-lg-0 {
        margin-top: 0 !important; }
      .mr-lg-0,
      .mx-lg-0 {
        margin-right: 0 !important; }
      .mb-lg-0,
      .my-lg-0 {
        margin-bottom: 0 !important; }
      .ml-lg-0,
      .mx-lg-0 {
        margin-left: 0 !important; }
      .m-lg-1 {
        margin: 0.25rem !important; }
      .mt-lg-1,
      .my-lg-1 {
        margin-top: 0.25rem !important; }
      .mr-lg-1,
      .mx-lg-1 {
        margin-right: 0.25rem !important; }
      .mb-lg-1,
      .my-lg-1 {
        margin-bottom: 0.25rem !important; }
      .ml-lg-1,
      .mx-lg-1 {
        margin-left: 0.25rem !important; }
      .m-lg-2 {
        margin: 0.5rem !important; }
      .mt-lg-2,
      .my-lg-2 {
        margin-top: 0.5rem !important; }
      .mr-lg-2,
      .mx-lg-2 {
        margin-right: 0.5rem !important; }
      .mb-lg-2,
      .my-lg-2 {
        margin-bottom: 0.5rem !important; }
      .ml-lg-2,
      .mx-lg-2 {
        margin-left: 0.5rem !important; }
      .m-lg-3 {
        margin: 1rem !important; }
      .mt-lg-3,
      .my-lg-3 {
        margin-top: 1rem !important; }
      .mr-lg-3,
      .mx-lg-3 {
        margin-right: 1rem !important; }
      .mb-lg-3,
      .my-lg-3 {
        margin-bottom: 1rem !important; }
      .ml-lg-3,
      .mx-lg-3 {
        margin-left: 1rem !important; }
      .m-lg-4 {
        margin: 1.5rem !important; }
      .mt-lg-4,
      .my-lg-4 {
        margin-top: 1.5rem !important; }
      .mr-lg-4,
      .mx-lg-4 {
        margin-right: 1.5rem !important; }
      .mb-lg-4,
      .my-lg-4 {
        margin-bottom: 1.5rem !important; }
      .ml-lg-4,
      .mx-lg-4 {
        margin-left: 1.5rem !important; }
      .m-lg-5 {
        margin: 3rem !important; }
      .mt-lg-5,
      .my-lg-5 {
        margin-top: 3rem !important; }
      .mr-lg-5,
      .mx-lg-5 {
        margin-right: 3rem !important; }
      .mb-lg-5,
      .my-lg-5 {
        margin-bottom: 3rem !important; }
      .ml-lg-5,
      .mx-lg-5 {
        margin-left: 3rem !important; }
      .p-lg-0 {
        padding: 0 !important; }
      .pt-lg-0,
      .py-lg-0 {
        padding-top: 0 !important; }
      .pr-lg-0,
      .px-lg-0 {
        padding-right: 0 !important; }
      .pb-lg-0,
      .py-lg-0 {
        padding-bottom: 0 !important; }
      .pl-lg-0,
      .px-lg-0 {
        padding-left: 0 !important; }
      .p-lg-1 {
        padding: 0.25rem !important; }
      .pt-lg-1,
      .py-lg-1 {
        padding-top: 0.25rem !important; }
      .pr-lg-1,
      .px-lg-1 {
        padding-right: 0.25rem !important; }
      .pb-lg-1,
      .py-lg-1 {
        padding-bottom: 0.25rem !important; }
      .pl-lg-1,
      .px-lg-1 {
        padding-left: 0.25rem !important; }
      .p-lg-2 {
        padding: 0.5rem !important; }
      .pt-lg-2,
      .py-lg-2 {
        padding-top: 0.5rem !important; }
      .pr-lg-2,
      .px-lg-2 {
        padding-right: 0.5rem !important; }
      .pb-lg-2,
      .py-lg-2 {
        padding-bottom: 0.5rem !important; }
      .pl-lg-2,
      .px-lg-2 {
        padding-left: 0.5rem !important; }
      .p-lg-3 {
        padding: 1rem !important; }
      .pt-lg-3,
      .py-lg-3 {
        padding-top: 1rem !important; }
      .pr-lg-3,
      .px-lg-3 {
        padding-right: 1rem !important; }
      .pb-lg-3,
      .py-lg-3 {
        padding-bottom: 1rem !important; }
      .pl-lg-3,
      .px-lg-3 {
        padding-left: 1rem !important; }
      .p-lg-4 {
        padding: 1.5rem !important; }
      .pt-lg-4,
      .py-lg-4 {
        padding-top: 1.5rem !important; }
      .pr-lg-4,
      .px-lg-4 {
        padding-right: 1.5rem !important; }
      .pb-lg-4,
      .py-lg-4 {
        padding-bottom: 1.5rem !important; }
      .pl-lg-4,
      .px-lg-4 {
        padding-left: 1.5rem !important; }
      .p-lg-5 {
        padding: 3rem !important; }
      .pt-lg-5,
      .py-lg-5 {
        padding-top: 3rem !important; }
      .pr-lg-5,
      .px-lg-5 {
        padding-right: 3rem !important; }
      .pb-lg-5,
      .py-lg-5 {
        padding-bottom: 3rem !important; }
      .pl-lg-5,
      .px-lg-5 {
        padding-left: 3rem !important; }
      .m-lg-n1 {
        margin: -0.25rem !important; }
      .mt-lg-n1,
      .my-lg-n1 {
        margin-top: -0.25rem !important; }
      .mr-lg-n1,
      .mx-lg-n1 {
        margin-right: -0.25rem !important; }
      .mb-lg-n1,
      .my-lg-n1 {
        margin-bottom: -0.25rem !important; }
      .ml-lg-n1,
      .mx-lg-n1 {
        margin-left: -0.25rem !important; }
      .m-lg-n2 {
        margin: -0.5rem !important; }
      .mt-lg-n2,
      .my-lg-n2 {
        margin-top: -0.5rem !important; }
      .mr-lg-n2,
      .mx-lg-n2 {
        margin-right: -0.5rem !important; }
      .mb-lg-n2,
      .my-lg-n2 {
        margin-bottom: -0.5rem !important; }
      .ml-lg-n2,
      .mx-lg-n2 {
        margin-left: -0.5rem !important; }
      .m-lg-n3 {
        margin: -1rem !important; }
      .mt-lg-n3,
      .my-lg-n3 {
        margin-top: -1rem !important; }
      .mr-lg-n3,
      .mx-lg-n3 {
        margin-right: -1rem !important; }
      .mb-lg-n3,
      .my-lg-n3 {
        margin-bottom: -1rem !important; }
      .ml-lg-n3,
      .mx-lg-n3 {
        margin-left: -1rem !important; }
      .m-lg-n4 {
        margin: -1.5rem !important; }
      .mt-lg-n4,
      .my-lg-n4 {
        margin-top: -1.5rem !important; }
      .mr-lg-n4,
      .mx-lg-n4 {
        margin-right: -1.5rem !important; }
      .mb-lg-n4,
      .my-lg-n4 {
        margin-bottom: -1.5rem !important; }
      .ml-lg-n4,
      .mx-lg-n4 {
        margin-left: -1.5rem !important; }
      .m-lg-n5 {
        margin: -3rem !important; }
      .mt-lg-n5,
      .my-lg-n5 {
        margin-top: -3rem !important; }
      .mr-lg-n5,
      .mx-lg-n5 {
        margin-right: -3rem !important; }
      .mb-lg-n5,
      .my-lg-n5 {
        margin-bottom: -3rem !important; }
      .ml-lg-n5,
      .mx-lg-n5 {
        margin-left: -3rem !important; }
      .m-lg-auto {
        margin: auto !important; }
      .mt-lg-auto,
      .my-lg-auto {
        margin-top: auto !important; }
      .mr-lg-auto,
      .mx-lg-auto {
        margin-right: auto !important; }
      .mb-lg-auto,
      .my-lg-auto {
        margin-bottom: auto !important; }
      .ml-lg-auto,
      .mx-lg-auto {
        margin-left: auto !important; } }
    
    @media (min-width: 1200px) {
      .m-xl-0 {
        margin: 0 !important; }
      .mt-xl-0,
      .my-xl-0 {
        margin-top: 0 !important; }
      .mr-xl-0,
      .mx-xl-0 {
        margin-right: 0 !important; }
      .mb-xl-0,
      .my-xl-0 {
        margin-bottom: 0 !important; }
      .ml-xl-0,
      .mx-xl-0 {
        margin-left: 0 !important; }
      .m-xl-1 {
        margin: 0.25rem !important; }
      .mt-xl-1,
      .my-xl-1 {
        margin-top: 0.25rem !important; }
      .mr-xl-1,
      .mx-xl-1 {
        margin-right: 0.25rem !important; }
      .mb-xl-1,
      .my-xl-1 {
        margin-bottom: 0.25rem !important; }
      .ml-xl-1,
      .mx-xl-1 {
        margin-left: 0.25rem !important; }
      .m-xl-2 {
        margin: 0.5rem !important; }
      .mt-xl-2,
      .my-xl-2 {
        margin-top: 0.5rem !important; }
      .mr-xl-2,
      .mx-xl-2 {
        margin-right: 0.5rem !important; }
      .mb-xl-2,
      .my-xl-2 {
        margin-bottom: 0.5rem !important; }
      .ml-xl-2,
      .mx-xl-2 {
        margin-left: 0.5rem !important; }
      .m-xl-3 {
        margin: 1rem !important; }
      .mt-xl-3,
      .my-xl-3 {
        margin-top: 1rem !important; }
      .mr-xl-3,
      .mx-xl-3 {
        margin-right: 1rem !important; }
      .mb-xl-3,
      .my-xl-3 {
        margin-bottom: 1rem !important; }
      .ml-xl-3,
      .mx-xl-3 {
        margin-left: 1rem !important; }
      .m-xl-4 {
        margin: 1.5rem !important; }
      .mt-xl-4,
      .my-xl-4 {
        margin-top: 1.5rem !important; }
      .mr-xl-4,
      .mx-xl-4 {
        margin-right: 1.5rem !important; }
      .mb-xl-4,
      .my-xl-4 {
        margin-bottom: 1.5rem !important; }
      .ml-xl-4,
      .mx-xl-4 {
        margin-left: 1.5rem !important; }
      .m-xl-5 {
        margin: 3rem !important; }
      .mt-xl-5,
      .my-xl-5 {
        margin-top: 3rem !important; }
      .mr-xl-5,
      .mx-xl-5 {
        margin-right: 3rem !important; }
      .mb-xl-5,
      .my-xl-5 {
        margin-bottom: 3rem !important; }
      .ml-xl-5,
      .mx-xl-5 {
        margin-left: 3rem !important; }
      .p-xl-0 {
        padding: 0 !important; }
      .pt-xl-0,
      .py-xl-0 {
        padding-top: 0 !important; }
      .pr-xl-0,
      .px-xl-0 {
        padding-right: 0 !important; }
      .pb-xl-0,
      .py-xl-0 {
        padding-bottom: 0 !important; }
      .pl-xl-0,
      .px-xl-0 {
        padding-left: 0 !important; }
      .p-xl-1 {
        padding: 0.25rem !important; }
      .pt-xl-1,
      .py-xl-1 {
        padding-top: 0.25rem !important; }
      .pr-xl-1,
      .px-xl-1 {
        padding-right: 0.25rem !important; }
      .pb-xl-1,
      .py-xl-1 {
        padding-bottom: 0.25rem !important; }
      .pl-xl-1,
      .px-xl-1 {
        padding-left: 0.25rem !important; }
      .p-xl-2 {
        padding: 0.5rem !important; }
      .pt-xl-2,
      .py-xl-2 {
        padding-top: 0.5rem !important; }
      .pr-xl-2,
      .px-xl-2 {
        padding-right: 0.5rem !important; }
      .pb-xl-2,
      .py-xl-2 {
        padding-bottom: 0.5rem !important; }
      .pl-xl-2,
      .px-xl-2 {
        padding-left: 0.5rem !important; }
      .p-xl-3 {
        padding: 1rem !important; }
      .pt-xl-3,
      .py-xl-3 {
        padding-top: 1rem !important; }
      .pr-xl-3,
      .px-xl-3 {
        padding-right: 1rem !important; }
      .pb-xl-3,
      .py-xl-3 {
        padding-bottom: 1rem !important; }
      .pl-xl-3,
      .px-xl-3 {
        padding-left: 1rem !important; }
      .p-xl-4 {
        padding: 1.5rem !important; }
      .pt-xl-4,
      .py-xl-4 {
        padding-top: 1.5rem !important; }
      .pr-xl-4,
      .px-xl-4 {
        padding-right: 1.5rem !important; }
      .pb-xl-4,
      .py-xl-4 {
        padding-bottom: 1.5rem !important; }
      .pl-xl-4,
      .px-xl-4 {
        padding-left: 1.5rem !important; }
      .p-xl-5 {
        padding: 3rem !important; }
      .pt-xl-5,
      .py-xl-5 {
        padding-top: 3rem !important; }
      .pr-xl-5,
      .px-xl-5 {
        padding-right: 3rem !important; }
      .pb-xl-5,
      .py-xl-5 {
        padding-bottom: 3rem !important; }
      .pl-xl-5,
      .px-xl-5 {
        padding-left: 3rem !important; }
      .m-xl-n1 {
        margin: -0.25rem !important; }
      .mt-xl-n1,
      .my-xl-n1 {
        margin-top: -0.25rem !important; }
      .mr-xl-n1,
      .mx-xl-n1 {
        margin-right: -0.25rem !important; }
      .mb-xl-n1,
      .my-xl-n1 {
        margin-bottom: -0.25rem !important; }
      .ml-xl-n1,
      .mx-xl-n1 {
        margin-left: -0.25rem !important; }
      .m-xl-n2 {
        margin: -0.5rem !important; }
      .mt-xl-n2,
      .my-xl-n2 {
        margin-top: -0.5rem !important; }
      .mr-xl-n2,
      .mx-xl-n2 {
        margin-right: -0.5rem !important; }
      .mb-xl-n2,
      .my-xl-n2 {
        margin-bottom: -0.5rem !important; }
      .ml-xl-n2,
      .mx-xl-n2 {
        margin-left: -0.5rem !important; }
      .m-xl-n3 {
        margin: -1rem !important; }
      .mt-xl-n3,
      .my-xl-n3 {
        margin-top: -1rem !important; }
      .mr-xl-n3,
      .mx-xl-n3 {
        margin-right: -1rem !important; }
      .mb-xl-n3,
      .my-xl-n3 {
        margin-bottom: -1rem !important; }
      .ml-xl-n3,
      .mx-xl-n3 {
        margin-left: -1rem !important; }
      .m-xl-n4 {
        margin: -1.5rem !important; }
      .mt-xl-n4,
      .my-xl-n4 {
        margin-top: -1.5rem !important; }
      .mr-xl-n4,
      .mx-xl-n4 {
        margin-right: -1.5rem !important; }
      .mb-xl-n4,
      .my-xl-n4 {
        margin-bottom: -1.5rem !important; }
      .ml-xl-n4,
      .mx-xl-n4 {
        margin-left: -1.5rem !important; }
      .m-xl-n5 {
        margin: -3rem !important; }
      .mt-xl-n5,
      .my-xl-n5 {
        margin-top: -3rem !important; }
      .mr-xl-n5,
      .mx-xl-n5 {
        margin-right: -3rem !important; }
      .mb-xl-n5,
      .my-xl-n5 {
        margin-bottom: -3rem !important; }
      .ml-xl-n5,
      .mx-xl-n5 {
        margin-left: -3rem !important; }
      .m-xl-auto {
        margin: auto !important; }
      .mt-xl-auto,
      .my-xl-auto {
        margin-top: auto !important; }
      .mr-xl-auto,
      .mx-xl-auto {
        margin-right: auto !important; }
      .mb-xl-auto,
      .my-xl-auto {
        margin-bottom: auto !important; }
      .ml-xl-auto,
      .mx-xl-auto {
        margin-left: auto !important; } }
    
    .stretched-link::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      pointer-events: auto;
      content: "";
      background-color: rgba(0, 0, 0, 0); }
    
    .text-monospace {
      font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }
    
    .text-justify {
      text-align: justify !important; }
    
    .text-wrap {
      white-space: normal !important; }
    
    .text-nowrap {
      white-space: nowrap !important; }
    
    .text-truncate {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    
    .text-left {
      text-align: left !important; }
    
    .text-right {
      text-align: right !important; }
    
    .text-center {
      text-align: center !important; }
    
    @media (min-width: 576px) {
      .text-sm-left {
        text-align: left !important; }
      .text-sm-right {
        text-align: right !important; }
      .text-sm-center {
        text-align: center !important; } }
    
    @media (min-width: 768px) {
      .text-md-left {
        text-align: left !important; }
      .text-md-right {
        text-align: right !important; }
      .text-md-center {
        text-align: center !important; } }
    
    @media (min-width: 992px) {
      .text-lg-left {
        text-align: left !important; }
      .text-lg-right {
        text-align: right !important; }
      .text-lg-center {
        text-align: center !important; } }
    
    @media (min-width: 1200px) {
      .text-xl-left {
        text-align: left !important; }
      .text-xl-right {
        text-align: right !important; }
      .text-xl-center {
        text-align: center !important; } }
    
    .text-lowercase {
      text-transform: lowercase !important; }
    
    .text-uppercase {
      text-transform: uppercase !important; }
    
    .text-capitalize {
      text-transform: capitalize !important; }
    
    .font-weight-light {
      font-weight: 300 !important; }
    
    .font-weight-lighter {
      font-weight: lighter !important; }
    
    .font-weight-normal {
      font-weight: 400 !important; }
    
    .font-weight-bold {
      font-weight: 700 !important; }
    
    .font-weight-bolder {
      font-weight: bolder !important; }
    
    .font-italic {
      font-style: italic !important; }
    
    .text-white {
      color: #ffffff !important; }
    
    .text-primary {
      color: #1bdbe0 !important; }
    
    a.text-primary:hover, a.text-primary:focus {
      color: #13989c !important; }
    
    .text-secondary {
      color: #d8d8d8 !important; }
    
    a.text-secondary:hover, a.text-secondary:focus {
      color: #b2b2b2 !important; }
    
    .text-success {
      color: #38ce3c !important; }
    
    a.text-success:hover, a.text-success:focus {
      color: #259528 !important; }
    
    .text-info {
      color: #8e32e9 !important; }
    
    a.text-info:hover, a.text-info:focus {
      color: #6814ba !important; }
    
    .text-warning {
      color: #ffde73 !important; }
    
    a.text-warning:hover, a.text-warning:focus {
      color: #ffcc27 !important; }
    
    .text-danger {
      color: #ff4d6b !important; }
    
    a.text-danger:hover, a.text-danger:focus {
      color: #ff012b !important; }
    
    .text-light {
      color: #f8f9fa !important; }
    
    a.text-light:hover, a.text-light:focus {
      color: #cbd3da !important; }
    
    .text-dark {
      color: #3e4b5b !important; }
    
    a.text-dark:hover, a.text-dark:focus {
      color: #1f262e !important; }
    
    .text-body {
      color: #343a40 !important; }
    
    .text-muted, .preview-list .preview-item .preview-item-content p .content-category {
      color: #9c9fa6 !important; }
    
    .text-black-50 {
      color: rgba(0, 0, 0, 0.5) !important; }
    
    .text-white-50 {
      color: rgba(255, 255, 255, 0.5) !important; }
    
    .text-hide {
      font: 0/0 a;
      color: transparent;
      text-shadow: none;
      background-color: transparent;
      border: 0; }
    
    .text-decoration-none {
      text-decoration: none !important; }
    
    .text-break {
      word-break: break-word !important;
      word-wrap: break-word !important; }
    
    .text-reset {
      color: inherit !important; }
    
    .visible {
      visibility: visible !important; }
    
    .invisible {
      visibility: hidden !important; }
    
    @media print {
      *,
      *::before,
      *::after {
        text-shadow: none !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important; }
      a:not(.btn) {
        text-decoration: underline; }
      abbr[title]::after {
        content: " (" attr(title) ")"; }
      pre {
        white-space: pre-wrap !important; }
      pre,
      blockquote {
        border: 1px solid #adb5bd;
        page-break-inside: avoid; }
      thead {
        display: table-header-group; }
      tr,
      img {
        page-break-inside: avoid; }
      p,
      h2,
      h3 {
        orphans: 3;
        widows: 3; }
      h2,
      h3 {
        page-break-after: avoid; }
      @page {
        size: a3; }
      body {
        min-width: 992px !important; }
      .container {
        min-width: 992px !important; }
      .badge {
        border: 1px solid #000; }
      .table {
        border-collapse: collapse !important; }
        .table td,
        .table th {
          background-color: #ffffff !important; }
      .table-bordered th,
      .table-bordered td {
        border: 1px solid #dee2e6 !important; }
      .table-dark {
        color: inherit; }
        .table-dark th,
        .table-dark td,
        .table-dark thead th,
        .table-dark tbody + tbody {
          border-color: #e8ecf1; }
      .table .thead-dark th {
        color: inherit;
        border-color: #e8ecf1; } }
    
    /*-------------------------------------------------------------------*/
    /* === Template mixins === */
    /* Miscellaneous Mixins */
    /* Animation Mixins */
    @-webkit-keyframes dropdownAnimation {
      from {
        opacity: 0;
        -webkit-transform: translate3d(0, -30px, 0);
        transform: translate3d(0, -30px, 0); }
      to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
        -webkit-transform: translate3d(0, 0px, 0);
        transform: translate3d(0, 0px, 0); } }
    @keyframes dropdownAnimation {
      from {
        opacity: 0;
        -webkit-transform: translate3d(0, -30px, 0);
        transform: translate3d(0, -30px, 0); }
      to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
        -webkit-transform: translate3d(0, 0px, 0);
        transform: translate3d(0, 0px, 0); } }
    
    .dropdownAnimation, .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu {
      -webkit-animation-name: dropdownAnimation;
      animation-name: dropdownAnimation;
      -webkit-animation-duration: 0.25s;
      animation-duration: 0.25s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both; }
    
    @keyframes fadeOut {
      from {
        opacity: 1; }
      to {
        opacity: 0; } }
    
    .fadeOut {
      -webkit-animation-name: fadeOut;
      animation-name: fadeOut; }
    
    .infinite-spin {
      -webkit-animation-name: spin;
      animation-name: spin;
      -webkit-animation-duration: 3s;
      animation-duration: 3s;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear; }
    
    @-webkit-keyframes spin {
      from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg); }
      to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg); } }
    
    @keyframes spin {
      from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg); }
      to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg); } }
    
    @keyframes fadeInUp {
      from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0); }
      to {
        opacity: 1;
        -webkit-transform: none;
        transform: none; } }
    
    .fadeInUp {
      -webkit-animation-name: fadeInUp;
      animation-name: fadeInUp; }
    
    /*-------------------------------------------------------------------*/
    /* === Core Styles === */
    /* Reset Styles */
    body {
      padding: 0;
      margin: 0;
      overflow-x: hidden; }
    
    .form-control, .select2-container--default .select2-selection--single, .select2-container--default .select2-selection--single .select2-search__field, .typeahead,
    .tt-query,
    .tt-hint, .form-control:focus, .select2-container--default .select2-selection--single:focus, .select2-container--default .select2-selection--single .select2-search__field:focus, .typeahead:focus,
    .tt-query:focus,
    .tt-hint:focus {
      -webkit-box-shadow: none;
      -moz-box-shadow: none; }
    
    .form-control, .select2-container--default .select2-selection--single, .select2-container--default .select2-selection--single .select2-search__field, .typeahead,
    .tt-query,
    .tt-hint {
      -webkit-box-shadow: none;
      box-shadow: none; }
    
    .form-control:focus, .select2-container--default .select2-selection--single:focus, .select2-container--default .select2-selection--single .select2-search__field:focus, .typeahead:focus,
    .tt-query:focus,
    .tt-hint:focus {
      outline: 0;
      -webkit-box-shadow: none;
      box-shadow: none; }
    
    a,
    div,
    h1,
    h2,
    h3,
    h4,
    h5,
    p,
    span {
      text-shadow: none; }
    
    [type=button]:focus,
    a:active,
    a:focus,
    a:visited,
    button::-moz-focus-inner,
    input[type=reset]::-moz-focus-inner,
    input[type=button]::-moz-focus-inner,
    input[type=submit]::-moz-focus-inner,
    input[type=file] > input[type=button]::-moz-focus-inner,
    select::-moz-focus-inner {
      outline: 0; }
    
    input, .form-control:focus, .select2-container--default .select2-selection--single:focus, .select2-container--default .select2-selection--single .select2-search__field:focus, .typeahead:focus,
    .tt-query:focus,
    .tt-hint:focus,
    input:focus,
    select:focus,
    textarea:focus,
    button:focus {
      outline: none;
      outline-width: 0;
      outline-color: transparent;
      -webkit-box-shadow: none;
      box-shadow: none;
      outline-style: none; }
    
    textarea {
      resize: none;
      overflow-x: hidden; }
    
    .btn,
    .btn-group.open .dropdown-toggle,
    .btn:active,
    .btn:focus,
    .btn:hover,
    .btn:visited,
    a,
    a:active,
    a:checked,
    a:focus,
    a:hover,
    a:visited,
    body,
    button,
    button:active,
    button:hover,
    button:visited,
    div,
    input,
    input:active,
    input:focus,
    input:hover,
    input:visited,
    select,
    select:active,
    select:focus,
    select:visited,
    textarea,
    textarea:active,
    textarea:focus,
    textarea:hover,
    textarea:visited {
      -webkit-box-shadow: none;
      box-shadow: none; }
    
    .btn.active.focus,
    .btn.active:focus,
    .btn.focus,
    .btn:active.focus,
    .btn:active:focus,
    .btn:focus,
    button,
    button:active,
    button:checked,
    button:focus,
    button:hover,
    button:visited {
      outline: 0;
      outline-offset: 0; }
    
    .dropdown-menu > li > a:active,
    .dropdown-menu > li > a:focus,
    .dropdown-menu > li > a:hover,
    .dropdown-menu > li > a:visited {
      outline: 0; }
    
    a:focus,
    input:focus {
      border-color: transparent;
      outline: none; }
    
    /* Typography */
    body {
      font-size: 1rem;
      font-family: "Open Sans", sans-serif;
      font-weight: initial;
      line-height: normal;
      -webkit-font-smoothing: antialiased; }
    
    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
      font-family: "Open Sans", sans-serif;
      font-weight: 600; }
    
    p {
      font-size: 0.875rem; }
    
    h1, .h1 {
      font-size: 2.19rem; }
    
    h2, .h2 {
      font-size: 1.88rem; }
    
    h3, .h3 {
      font-size: 1.56rem; }
    
    h4, .h4 {
      font-size: 1.13rem; }
    
    h5, .h5 {
      font-size: 1rem; }
    
    h6, .h6 {
      font-size: .9375rem; }
    
    p {
      font-size: .9375rem;
      line-height: 1.5; }
    
    .display-1 {
      font-size: 3.75rem; }
      @media (max-width: 991px) {
        .display-1 {
          font-size: 3rem; } }
    
    .display-2 {
      font-size: 3.125rem; }
      @media (max-width: 991px) {
        .display-2 {
          font-size: 2.5rem; } }
    
    .display-3 {
      font-size: 2.5rem; }
      @media (max-width: 991px) {
        .display-3 {
          font-size: 2rem; } }
    
    .display-4 {
      font-size: 1.875rem; }
      @media (max-width: 991px) {
        .display-4 {
          font-size: 1.5rem; } }
    
    .display-5 {
      font-size: 1.25rem; }
      @media (max-width: 991px) {
        .display-5 {
          font-size: 1rem; } }
    
    .blockquote {
      padding: 1.25rem;
      border: 1px solid #e8ecf1; }
    
    address p {
      margin-bottom: 0; }
    
    .blockquote-primary {
      border-color: #1bdbe0; }
      .blockquote-primary .blockquote-footer {
        color: #1bdbe0; }
    
    .blockquote-secondary {
      border-color: #d8d8d8; }
      .blockquote-secondary .blockquote-footer {
        color: #d8d8d8; }
    
    .blockquote-success {
      border-color: #38ce3c; }
      .blockquote-success .blockquote-footer {
        color: #38ce3c; }
    
    .blockquote-info {
      border-color: #8e32e9; }
      .blockquote-info .blockquote-footer {
        color: #8e32e9; }
    
    .blockquote-warning {
      border-color: #ffde73; }
      .blockquote-warning .blockquote-footer {
        color: #ffde73; }
    
    .blockquote-danger {
      border-color: #ff4d6b; }
      .blockquote-danger .blockquote-footer {
        color: #ff4d6b; }
    
    .blockquote-light {
      border-color: #f8f9fa; }
      .blockquote-light .blockquote-footer {
        color: #f8f9fa; }
    
    .blockquote-dark {
      border-color: #3e4b5b; }
      .blockquote-dark .blockquote-footer {
        color: #3e4b5b; }
    
    .error-page h1 {
      font-size: 12rem; }
      @media (max-width: 991px) {
        .error-page h1 {
          font-size: 8rem; } }
    
    .icon-lg {
      font-size: 3.438rem; }
    
    .icon-md {
      font-size: 1.875rem; }
    
    .icon-sm {
      font-size: 1rem; }
    
    /* Miscellanoeous */
    body,
    html {
      overflow-x: hidden;
      padding-right: 0 !important; }
    
    *:-moz-full-screen,
    *:-webkit-full-screen,
    *:fullscreen *:-ms-fullscreen {
      overflow: auto; }
    
    .container-scroller {
      overflow: hidden; }
    
    pre {
      background: #e8eff4;
      padding: 15px;
      font-size: 14px; }
    
    code {
      padding: 5px;
      color: #ff4d6b;
      font-family: "Open Sans", sans-serif;
      font-weight: 300;
      font-size: 0.875rem;
      border-radius: 4px; }
    
    .page-header {
      margin: 0 0 1.5rem 0; }
      .page-header .breadcrumb {
        border: 0;
        margin-bottom: 0; }
    
    .page-title {
      color: #343a40;
      font-size: 1.125rem;
      margin-bottom: 0; }
      .page-title .page-title-icon {
        display: inline-block;
        width: 36px;
        height: 36px;
        border-radius: 4px;
        text-align: center;
        -webkit-box-shadow: 0px 3px 8.3px 0.7px rgba(163, 93, 255, 0.35);
        box-shadow: 0px 3px 8.3px 0.7px rgba(163, 93, 255, 0.35); }
        .page-title .page-title-icon i {
          font-size: .9375rem;
          line-height: 36px; }
    
    
    /* Utilities */
    .grid-margin {
      margin-bottom: 25px; }
    
    @media (min-width: 576px) {
      .grid-margin-sm-0 {
        margin-bottom: 0; } }
    
    @media (min-width: 768px) {
      .grid-margin-md-0 {
        margin-bottom: 0; } }
    
    @media (min-width: 992px) {
      .grid-margin-lg-0 {
        margin-bottom: 0; } }
    
    @media (min-width: 1200px) {
      .grid-margin-xl-0 {
        margin-bottom: 0; } }
    
    .img-lg {
      width: 92px;
      height: 92px; }
    
    .img-sm {
      width: 43px;
      height: 43px; }
    
    .img-xs {
      width: 37px;
      height: 37px; }
    
    .img-ss {
      width: 26px;
      height: 26px; }
    
    .stretch-card {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
      -webkit-box-pack: stretch;
      -ms-flex-pack: stretch;
      justify-content: stretch; }
      .stretch-card > .card {
        width: 100%;
        min-width: 100%; }
    
    @media (min-width: 576px) {
      .border-right-sm {
        border-right: 1px solid #e8ecf1; } }
    
    @media (min-width: 768px) {
      .border-right-md {
        border-right: 1px solid #e8ecf1; } }
    
    @media (min-width: 992px) {
      .border-right-lg {
        border-right: 1px solid #e8ecf1; } }
    
    @media (min-width: 576px) {
      .border-left-sm {
        border-left: 1px solid #e8ecf1; } }
    
    @media (min-width: 768px) {
      .border-left-md {
        border-left: 1px solid #e8ecf1; } }
    
    @media (min-width: 992px) {
      .border-left-lg {
        border-left: 1px solid #e8ecf1; } }
    
    .text-gray, .card .card-subtitle {
      color: #8c8c8c; }
    
    .text-black {
      color: #000; }
    
    .text-small {
      font-size: 12px; }
    
    .flex-grow {
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1; }
    
    .font-weight-light {
      font-family: "Open Sans", sans-serif;
      font-weight: 300; }
    
    .font-weight-medium {
      font-family: "Open Sans", sans-serif;
      font-weight: 600; }
    
    .font-weight-semibold {
      font-family: "Open Sans", sans-serif;
      font-weight: 700; }
    
    .font-weight-bold {
      font-family: "Open Sans", sans-serif;
      font-weight: bold; }
    
    .font-weight-normal {
      font-family: "Open Sans", sans-serif;
      font-weight: 400; }
    
    /* Demo Styles */
    .template-demo .slider-wrap {
      height: 100px; }
    
    .template-demo .progress {
      margin-top: 1.5rem; }
    
    .template-demo > h2,
    .template-demo > h3,
    .template-demo > h4,
    .template-demo > h5,
    .template-demo > h6,
    .template-demo > h1 {
      border-top: 1px solid #e8ecf1;
      padding: 0.5rem 0 0; }
    
    .template-demo .ul-slider.noUi-horizontal {
      margin-top: 2rem; }
    
    .template-demo .ul-slider.noUi-vertical {
      margin-right: 2rem; }
    
    .template-demo > .dropdown {
      display: inline-block;
      margin-bottom: 0.5rem; }
    
    .template-demo nav .breadcrumb {
      margin-bottom: 1.375rem; }
    
    .template-demo nav:last-child .breadcrumb {
      margin-bottom: 0; }
    
    .template-demo .editable-form > .form-group {
      border-bottom: 1px solid #e8ecf1;
      padding-bottom: 0.8rem;
      margin-bottom: 0.8rem; }
    
    .template-demo .circle-progress {
      padding: 15px; }
    
    .demo-modal {
      position: static;
      display: block; }
      .demo-modal .modal-dialog.modal-lg {
        max-width: 100%; }
    
    .loader-demo-box {
      width: 100%;
      height: 200px; }
    
    .dropdown-menu-static-demo {
      height: 250px;
      margin-bottom: 20px; }
    
    .rounded-legend ul li {
      list-style-type: none;
      color: #9c9fa6;
      font-size: .75rem; }
      .rounded-legend ul li .legend-dots {
        width: 1rem;
        height: 1rem;
        border-radius: 100%;
        display: inline-block;
        vertical-align: text-bottom;
        margin-right: .5rem; }
        .rtl .rounded-legend ul li .legend-dots {
          margin-left: .5rem; }
    
    .rounded-legend.legend-horizontal {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      .rounded-legend.legend-horizontal ul li {
        display: inline-block;
        margin-right: 1.5rem; }
        .rtl .rounded-legend.legend-horizontal ul li {
          margin-right: auto;
          margin-left: 1.5rem; }
    
    .rounded-legend.legend-top-right ul {
      float: right; }
      .rtl .rounded-legend.legend-top-right ul {
        float: left; }
    
    .rounded-legend.legend-vertical ul li {
      margin-top: 1rem; }
    
    /*-------------------------------------------------------------------*/
    /* === Components === */
    /* Buttons */
    .btn {
      font-size: 0.875rem;
      line-height: 1;
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
      /* Buttons with only icons */
      /* Buttons with icon and text */ }
      .btn i {
        font-size: 1rem; }
      .btn.btn-rounded {
        border-radius: 50px; }
      .btn.btn-fw {
        min-width: 150px; }
      .btn.btn-sm, .btn-group-sm > .btn {
        font-size: 0.875rem; }
      .btn.btn-lg, .btn-group-lg > .btn {
        font-size: 0.875rem; }
      .btn.btn-xs {
        padding: 0.5rem 0.75rem;
        font-size: 0.625rem; }
      .btn.btn-icon {
        width: 42px;
        height: 42px;
        padding: 0; }
      .btn.btn-icon-text .btn-icon-prepend {
        margin-right: .5rem; }
      .btn.btn-icon-text .btn-icon-append {
        margin-left: .5rem; }
      .btn.btn-social-icon {
        width: 50px;
        height: 50px;
        padding: 0; }
      .btn.btn-no-hover-bg:hover, .btn.btn-no-hover-bg:focus, .btn.btn-no-hover-bg:active {
        background: transparent;
        color: currentColor; }
    
    .btn-group .btn + .btn {
      border-left: 0; }
    
    /*social buttons*/
    .btn-twitter {
      background: #2caae1;
      color: #ffffff; }
      .btn-twitter:hover, .btn-twitter:focus {
        background: #1b8dbf;
        color: #ffffff; }
      .btn-twitter.btn-social-icon-text {
        padding: 0 1.5rem 0 0;
        background: #59bce7; }
        .btn-twitter.btn-social-icon-text i {
          background: #2caae1;
          padding: .75rem;
          display: inline-block;
          margin-right: 1.5rem; }
    
    .btn-outline-twitter {
      border: 1px solid #2caae1;
      color: #2caae1; }
      .btn-outline-twitter:hover {
        background: #2caae1;
        color: #ffffff; }
    
    .btn-facebook {
      background: #3b579d;
      color: #ffffff; }
      .btn-facebook:hover, .btn-facebook:focus {
        background: #2d4278;
        color: #ffffff; }
      .btn-facebook.btn-social-icon-text {
        padding: 0 1.5rem 0 0;
        background: #4e6ebd; }
        .btn-facebook.btn-social-icon-text i {
          background: #3b579d;
          padding: .75rem;
          display: inline-block;
          margin-right: 1.5rem; }
    
    .btn-outline-facebook {
      border: 1px solid #3b579d;
      color: #3b579d; }
      .btn-outline-facebook:hover {
        background: #3b579d;
        color: #ffffff; }
    
    .btn-google {
      background: #dc4a38;
      color: #ffffff; }
      .btn-google:hover, .btn-google:focus {
        background: #bf3322;
        color: #ffffff; }
      .btn-google.btn-social-icon-text {
        padding: 0 1.5rem 0 0;
        background: #e47163; }
        .btn-google.btn-social-icon-text i {
          background: #dc4a38;
          padding: .75rem;
          display: inline-block;
          margin-right: 1.5rem; }
    
    .btn-outline-google {
      border: 1px solid #dc4a38;
      color: #dc4a38; }
      .btn-outline-google:hover {
        background: #dc4a38;
        color: #ffffff; }
    
    .btn-linkedin {
      background: #0177b5;
      color: #ffffff; }
      .btn-linkedin:hover, .btn-linkedin:focus {
        background: #015682;
        color: #ffffff; }
      .btn-linkedin.btn-social-icon-text {
        padding: 0 1.5rem 0 0;
        background: #0198e8; }
        .btn-linkedin.btn-social-icon-text i {
          background: #0177b5;
          padding: .75rem;
          display: inline-block;
          margin-right: 1.5rem; }
    
    .btn-outline-linkedin {
      border: 1px solid #0177b5;
      color: #0177b5; }
      .btn-outline-linkedin:hover {
        background: #0177b5;
        color: #ffffff; }
    
    .btn-pinterest {
      background: #cc2127;
      color: #ffffff; }
      .btn-pinterest:hover, .btn-pinterest:focus {
        background: #a01a1f;
        color: #ffffff; }
      .btn-pinterest.btn-social-icon-text {
        padding: 0 1.5rem 0 0;
        background: #e04046; }
        .btn-pinterest.btn-social-icon-text i {
          background: #cc2127;
          padding: .75rem;
          display: inline-block;
          margin-right: 1.5rem; }
    
    .btn-outline-pinterest {
      border: 1px solid #cc2127;
      color: #cc2127; }
      .btn-outline-pinterest:hover {
        background: #cc2127;
        color: #ffffff; }
    
    .btn-youtube {
      background: #e52d27;
      color: #ffffff; }
      .btn-youtube:hover, .btn-youtube:focus {
        background: #c21d17;
        color: #ffffff; }
      .btn-youtube.btn-social-icon-text {
        padding: 0 1.5rem 0 0;
        background: #ea5955; }
        .btn-youtube.btn-social-icon-text i {
          background: #e52d27;
          padding: .75rem;
          display: inline-block;
          margin-right: 1.5rem; }
    
    .btn-outline-youtube {
      border: 1px solid #e52d27;
      color: #e52d27; }
      .btn-outline-youtube:hover {
        background: #e52d27;
        color: #ffffff; }
    
    .btn-github {
      background: #333333;
      color: #ffffff; }
      .btn-github:hover, .btn-github:focus {
        background: #1a1a1a;
        color: #ffffff; }
      .btn-github.btn-social-icon-text {
        padding: 0 1.5rem 0 0;
        background: #4d4d4d; }
        .btn-github.btn-social-icon-text i {
          background: #333333;
          padding: .75rem;
          display: inline-block;
          margin-right: 1.5rem; }
    
    .btn-outline-github {
      border: 1px solid #333333;
      color: #333333; }
      .btn-outline-github:hover {
        background: #333333;
        color: #ffffff; }
    
    .btn-behance {
      background: #1769ff;
      color: #ffffff; }
      .btn-behance:hover, .btn-behance:focus {
        background: #0050e3;
        color: #ffffff; }
      .btn-behance.btn-social-icon-text {
        padding: 0 1.5rem 0 0;
        background: #4a8aff; }
        .btn-behance.btn-social-icon-text i {
          background: #1769ff;
          padding: .75rem;
          display: inline-block;
          margin-right: 1.5rem; }
    
    .btn-outline-behance {
      border: 1px solid #1769ff;
      color: #1769ff; }
      .btn-outline-behance:hover {
        background: #1769ff;
        color: #ffffff; }
    
    .btn-dribbble {
      background: #ea4c89;
      color: #ffffff; }
      .btn-dribbble:hover, .btn-dribbble:focus {
        background: #e51e6b;
        color: #ffffff; }
      .btn-dribbble.btn-social-icon-text {
        padding: 0 1.5rem 0 0;
        background: #ef7aa7; }
        .btn-dribbble.btn-social-icon-text i {
          background: #ea4c89;
          padding: .75rem;
          display: inline-block;
          margin-right: 1.5rem; }
    
    .btn-outline-dribbble {
      border: 1px solid #ea4c89;
      color: #ea4c89; }
      .btn-outline-dribbble:hover {
        background: #ea4c89;
        color: #ffffff; }
    
    .btn-reddit {
      background: #ff4500;
      color: #ffffff; }
      .btn-reddit:hover, .btn-reddit:focus {
        background: #cc3700;
        color: #ffffff; }
      .btn-reddit.btn-social-icon-text {
        padding: 0 1.5rem 0 0;
        background: #ff6a33; }
        .btn-reddit.btn-social-icon-text i {
          background: #ff4500;
          padding: .75rem;
          display: inline-block;
          margin-right: 1.5rem; }
    
    .btn-outline-reddit {
      border: 1px solid #ff4500;
      color: #ff4500; }
      .btn-outline-reddit:hover {
        background: #ff4500;
        color: #ffffff; }
    
    /* inverse buttons */
    .btn-inverse-primary {
      background-color: rgba(27, 219, 224, 0.2);
      background-image: none;
      border-color: rgba(27, 219, 224, 0); }
      .btn-inverse-primary:not(.btn-inverse-light) {
        color: #1bdbe0; }
      .btn-inverse-primary:hover {
        color: #ffffff;
        background-color: #1bdbe0;
        border-color: #1bdbe0; }
      .btn-inverse-primary.focus, .btn-inverse-primary:focus {
        -webkit-box-shadow: 0 0 0 3px rgba(27, 219, 224, 0.5);
        box-shadow: 0 0 0 3px rgba(27, 219, 224, 0.5); }
      .btn-inverse-primary.disabled, .btn-inverse-primary:disabled {
        color: #1bdbe0;
        background-color: transparent; }
      .btn-inverse-primary.active, .btn-inverse-primary:active,
      .show > .btn-inverse-primary.dropdown-toggle {
        color: #ffffff;
        background-color: #1bdbe0;
        border-color: #1bdbe0; }
    
    .btn-primary:not(.btn-light) {
      color: #ffffff; }
      .btn-primary:not(.btn-light):hover, .btn-primary:not(.btn-light):focus, .btn-primary:not(.btn-light):active {
        color: #ffffff; }
      .btn-primary:not(.btn-light):focus, .btn-primary:not(.btn-light):active {
        background: #1bdbe0;
        border-color: #1bdbe0; }
    
    .btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active {
      background: #1bdbe0;
      color: #ffffff; }
    
    .btn-inverse-secondary {
      background-color: rgba(216, 216, 216, 0.2);
      background-image: none;
      border-color: rgba(216, 216, 216, 0); }
      .btn-inverse-secondary:not(.btn-inverse-light) {
        color: #d8d8d8; }
      .btn-inverse-secondary:hover {
        color: #ffffff;
        background-color: #d8d8d8;
        border-color: #d8d8d8; }
      .btn-inverse-secondary.focus, .btn-inverse-secondary:focus {
        -webkit-box-shadow: 0 0 0 3px rgba(216, 216, 216, 0.5);
        box-shadow: 0 0 0 3px rgba(216, 216, 216, 0.5); }
      .btn-inverse-secondary.disabled, .btn-inverse-secondary:disabled {
        color: #d8d8d8;
        background-color: transparent; }
      .btn-inverse-secondary.active, .btn-inverse-secondary:active,
      .show > .btn-inverse-secondary.dropdown-toggle {
        color: #ffffff;
        background-color: #d8d8d8;
        border-color: #d8d8d8; }
    
    .btn-secondary:not(.btn-light) {
      color: #ffffff; }
      .btn-secondary:not(.btn-light):hover, .btn-secondary:not(.btn-light):focus, .btn-secondary:not(.btn-light):active {
        color: #ffffff; }
      .btn-secondary:not(.btn-light):focus, .btn-secondary:not(.btn-light):active {
        background: #d8d8d8;
        border-color: #d8d8d8; }
    
    .btn-outline-secondary:hover, .btn-outline-secondary:focus, .btn-outline-secondary:active {
      background: #d8d8d8;
      color: #ffffff; }
    
    .btn-inverse-success {
      background-color: rgba(56, 206, 60, 0.2);
      background-image: none;
      border-color: rgba(56, 206, 60, 0); }
      .btn-inverse-success:not(.btn-inverse-light) {
        color: #38ce3c; }
      .btn-inverse-success:hover {
        color: #ffffff;
        background-color: #38ce3c;
        border-color: #38ce3c; }
      .btn-inverse-success.focus, .btn-inverse-success:focus {
        -webkit-box-shadow: 0 0 0 3px rgba(56, 206, 60, 0.5);
        box-shadow: 0 0 0 3px rgba(56, 206, 60, 0.5); }
      .btn-inverse-success.disabled, .btn-inverse-success:disabled {
        color: #38ce3c;
        background-color: transparent; }
      .btn-inverse-success.active, .btn-inverse-success:active,
      .show > .btn-inverse-success.dropdown-toggle {
        color: #ffffff;
        background-color: #38ce3c;
        border-color: #38ce3c; }
    
    .btn-success:not(.btn-light) {
      color: #ffffff; }
      .btn-success:not(.btn-light):hover, .btn-success:not(.btn-light):focus, .btn-success:not(.btn-light):active {
        color: #ffffff; }
      .btn-success:not(.btn-light):focus, .btn-success:not(.btn-light):active {
        background: #38ce3c;
        border-color: #38ce3c; }
    
    .btn-outline-success:hover, .btn-outline-success:focus, .btn-outline-success:active {
      background: #38ce3c;
      color: #ffffff; }
    
    .btn-inverse-info {
      background-color: rgba(142, 50, 233, 0.2);
      background-image: none;
      border-color: rgba(142, 50, 233, 0); }
      .btn-inverse-info:not(.btn-inverse-light) {
        color: #8e32e9; }
      .btn-inverse-info:hover {
        color: #ffffff;
        background-color: #8e32e9;
        border-color: #8e32e9; }
      .btn-inverse-info.focus, .btn-inverse-info:focus {
        -webkit-box-shadow: 0 0 0 3px rgba(142, 50, 233, 0.5);
        box-shadow: 0 0 0 3px rgba(142, 50, 233, 0.5); }
      .btn-inverse-info.disabled, .btn-inverse-info:disabled {
        color: #8e32e9;
        background-color: transparent; }
      .btn-inverse-info.active, .btn-inverse-info:active,
      .show > .btn-inverse-info.dropdown-toggle {
        color: #ffffff;
        background-color: #8e32e9;
        border-color: #8e32e9; }
    
    .btn-info:not(.btn-light) {
      color: #ffffff; }
      .btn-info:not(.btn-light):hover, .btn-info:not(.btn-light):focus, .btn-info:not(.btn-light):active {
        color: #ffffff; }
      .btn-info:not(.btn-light):focus, .btn-info:not(.btn-light):active {
        background: #8e32e9;
        border-color: #8e32e9; }
    
    .btn-outline-info:hover, .btn-outline-info:focus, .btn-outline-info:active {
      background: #8e32e9;
      color: #ffffff; }
    
    .btn-inverse-warning {
      background-color: rgba(255, 222, 115, 0.2);
      background-image: none;
      border-color: rgba(255, 222, 115, 0); }
      .btn-inverse-warning:not(.btn-inverse-light) {
        color: #ffde73; }
      .btn-inverse-warning:hover {
        color: #ffffff;
        background-color: #ffde73;
        border-color: #ffde73; }
      .btn-inverse-warning.focus, .btn-inverse-warning:focus {
        -webkit-box-shadow: 0 0 0 3px rgba(255, 222, 115, 0.5);
        box-shadow: 0 0 0 3px rgba(255, 222, 115, 0.5); }
      .btn-inverse-warning.disabled, .btn-inverse-warning:disabled {
        color: #ffde73;
        background-color: transparent; }
      .btn-inverse-warning.active, .btn-inverse-warning:active,
      .show > .btn-inverse-warning.dropdown-toggle {
        color: #ffffff;
        background-color: #ffde73;
        border-color: #ffde73; }
    
    .btn-warning:not(.btn-light) {
      color: #ffffff; }
      .btn-warning:not(.btn-light):hover, .btn-warning:not(.btn-light):focus, .btn-warning:not(.btn-light):active {
        color: #ffffff; }
      .btn-warning:not(.btn-light):focus, .btn-warning:not(.btn-light):active {
        background: #ffde73;
        border-color: #ffde73; }
    
    .btn-outline-warning:hover, .btn-outline-warning:focus, .btn-outline-warning:active {
      background: #ffde73;
      color: #ffffff; }
    
    .btn-inverse-danger {
      background-color: rgba(255, 77, 107, 0.2);
      background-image: none;
      border-color: rgba(255, 77, 107, 0); }
      .btn-inverse-danger:not(.btn-inverse-light) {
        color: #ff4d6b; }
      .btn-inverse-danger:hover {
        color: #ffffff;
        background-color: #ff4d6b;
        border-color: #ff4d6b; }
      .btn-inverse-danger.focus, .btn-inverse-danger:focus {
        -webkit-box-shadow: 0 0 0 3px rgba(255, 77, 107, 0.5);
        box-shadow: 0 0 0 3px rgba(255, 77, 107, 0.5); }
      .btn-inverse-danger.disabled, .btn-inverse-danger:disabled {
        color: #ff4d6b;
        background-color: transparent; }
      .btn-inverse-danger.active, .btn-inverse-danger:active,
      .show > .btn-inverse-danger.dropdown-toggle {
        color: #ffffff;
        background-color: #ff4d6b;
        border-color: #ff4d6b; }
    
    .btn-danger:not(.btn-light) {
      color: #ffffff; }
      .btn-danger:not(.btn-light):hover, .btn-danger:not(.btn-light):focus, .btn-danger:not(.btn-light):active {
        color: #ffffff; }
      .btn-danger:not(.btn-light):focus, .btn-danger:not(.btn-light):active {
        background: #ff4d6b;
        border-color: #ff4d6b; }
    
    .btn-outline-danger:hover, .btn-outline-danger:focus, .btn-outline-danger:active {
      background: #ff4d6b;
      color: #ffffff; }
    
    .btn-inverse-light {
      background-color: rgba(248, 249, 250, 0.2);
      background-image: none;
      border-color: rgba(248, 249, 250, 0); }
      .btn-inverse-light:not(.btn-inverse-light) {
        color: #f8f9fa; }
      .btn-inverse-light:hover {
        color: #ffffff;
        background-color: #f8f9fa;
        border-color: #f8f9fa; }
      .btn-inverse-light.focus, .btn-inverse-light:focus {
        -webkit-box-shadow: 0 0 0 3px rgba(248, 249, 250, 0.5);
        box-shadow: 0 0 0 3px rgba(248, 249, 250, 0.5); }
      .btn-inverse-light.disabled, .btn-inverse-light:disabled {
        color: #f8f9fa;
        background-color: transparent; }
      .btn-inverse-light.active, .btn-inverse-light:active,
      .show > .btn-inverse-light.dropdown-toggle {
        color: #ffffff;
        background-color: #f8f9fa;
        border-color: #f8f9fa; }
    
    .btn-light:not(.btn-light) {
      color: #ffffff; }
      .btn-light:not(.btn-light):hover, .btn-light:not(.btn-light):focus, .btn-light:not(.btn-light):active {
        color: #ffffff; }
      .btn-light:not(.btn-light):focus, .btn-light:not(.btn-light):active {
        background: #f8f9fa;
        border-color: #f8f9fa; }
    
    .btn-outline-light:hover, .btn-outline-light:focus, .btn-outline-light:active {
      background: #f8f9fa;
      color: #ffffff; }
    
    .btn-inverse-dark {
      background-color: rgba(62, 75, 91, 0.2);
      background-image: none;
      border-color: rgba(62, 75, 91, 0); }
      .btn-inverse-dark:not(.btn-inverse-light) {
        color: #3e4b5b; }
      .btn-inverse-dark:hover {
        color: #ffffff;
        background-color: #3e4b5b;
        border-color: #3e4b5b; }
      .btn-inverse-dark.focus, .btn-inverse-dark:focus {
        -webkit-box-shadow: 0 0 0 3px rgba(62, 75, 91, 0.5);
        box-shadow: 0 0 0 3px rgba(62, 75, 91, 0.5); }
      .btn-inverse-dark.disabled, .btn-inverse-dark:disabled {
        color: #3e4b5b;
        background-color: transparent; }
      .btn-inverse-dark.active, .btn-inverse-dark:active,
      .show > .btn-inverse-dark.dropdown-toggle {
        color: #ffffff;
        background-color: #3e4b5b;
        border-color: #3e4b5b; }
    
    .btn-dark:not(.btn-light) {
      color: #ffffff; }
      .btn-dark:not(.btn-light):hover, .btn-dark:not(.btn-light):focus, .btn-dark:not(.btn-light):active {
        color: #ffffff; }
      .btn-dark:not(.btn-light):focus, .btn-dark:not(.btn-light):active {
        background: #3e4b5b;
        border-color: #3e4b5b; }
    
    .btn-outline-dark:hover, .btn-outline-dark:focus, .btn-outline-dark:active {
      background: #3e4b5b;
      color: #ffffff; }
    
    /* Cards */
    .card {
      border: 0; }
      .card .card-body {
        padding: 1.875rem 1.875rem; }
        .card .card-body + .card-body {
          padding-top: 1rem; }
      .card .card-title {
        color: #343a40;
        margin-bottom: .75rem;
        text-transform: capitalize;
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
        font-size: 1.125rem; }
      .card .card-subtitle {
        font-family: "Open Sans", sans-serif;
        margin-top: 0.625rem;
        margin-bottom: 0.625rem; }
      .card .card-description {
        color: #76838f;
        margin-bottom: 1.5rem;
        font-family: "Open Sans", sans-serif; }
      .card.card-outline-success {
        border: 1px solid #38ce3c; }
      .card.card-outline-primary {
        border: 1px solid #1bdbe0; }
      .card.card-outline-warning {
        border: 1px solid #ffde73; }
      .card.card-outline-danger {
        border: 1px solid #ff4d6b; }
      .card.card-rounded {
        border-radius: 5px; }
      .card.card-faded {
        background: #b5b0b2;
        border-color: #b5b0b2; }
      .card.card-circle-progress {
        color: #ffffff;
        text-align: center; }
      .card.card-img-holder {
        position: relative; }
        .card.card-img-holder .card-img-absolute {
          position: absolute;
          top: 0;
          right: 0;
          height: 100%; }
    
    .card-inverse-primary {
      background: rgba(27, 219, 224, 0.2);
      border: 1px solid #19c9ce;
      color: #15a6aa; }
    
    .card-inverse-secondary {
      background: rgba(216, 216, 216, 0.2);
      border: 1px solid #c7c7c7;
      color: #a4a4a4; }
    
    .card-inverse-success {
      background: rgba(56, 206, 60, 0.2);
      border: 1px solid #34be37;
      color: #2b9d2e; }
    
    .card-inverse-info {
      background: rgba(142, 50, 233, 0.2);
      border: 1px solid #832ed6;
      color: #6c26b1; }
    
    .card-inverse-warning {
      background: rgba(255, 222, 115, 0.2);
      border: 1px solid #ebcc6a;
      color: #c2a957; }
    
    .card-inverse-danger {
      background: rgba(255, 77, 107, 0.2);
      border: 1px solid #eb4762;
      color: #c23b51; }
    
    .card-inverse-light {
      background: rgba(248, 249, 250, 0.2);
      border: 1px solid #e4e5e6;
      color: #bcbdbe; }
    
    .card-inverse-dark {
      background: rgba(62, 75, 91, 0.2);
      border: 1px solid #394554;
      color: #2f3945; }
    
    /* Checkboxes and Radios */
    .form-check {
      position: relative;
      display: block;
      margin-top: 15px;
      margin-bottom: 10px;
      padding-left: 0; }
      .form-check .form-check-label {
        display: block;
        margin-left: 1.75rem;
        font-size: 0.875rem;
        line-height: 1.5; }
        .rtl .form-check .form-check-label {
          margin-left: 0;
          margin-right: 1.75rem; }
        .form-check .form-check-label input {
          position: absolute;
          top: 0;
          left: 0;
          margin-left: 0;
          margin-top: 0;
          z-index: 1;
          cursor: pointer;
          opacity: 0;
          filter: alpha(opacity=0); }
          .rtl .form-check .form-check-label input {
            left: auto;
            right: 0; }
        .form-check .form-check-label input[type="checkbox"] + .input-helper:before, .form-check .form-check-label input[type="checkbox"] + .input-helper:after {
          position: absolute;
          top: 0;
          left: 0; }
          .rtl .form-check .form-check-label input[type="checkbox"] + .input-helper:before, .rtl .form-check .form-check-label input[type="checkbox"] + .input-helper:after {
            left: auto;
            right: 0; }
        .form-check .form-check-label input[type="checkbox"] + .input-helper:before {
          content: "";
          width: 18px;
          height: 18px;
          border-radius: 2px;
          border: solid #1bdbe0;
          border-width: 2px;
          -webkit-transition: all;
          transition: all;
          transition-duration: 0s;
          -webkit-transition-duration: 250ms;
          transition-duration: 250ms; }
        .form-check .form-check-label input[type="checkbox"] + .input-helper:after {
          -webkit-transition: all;
          transition: all;
          transition-duration: 0s;
          -webkit-transition-duration: 250ms;
          transition-duration: 250ms;
          opacity: 0;
          filter: alpha(opacity=0);
          content: "";
          height: 12px;
          width: 5px;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          border-style: solid;
          border-width: 2px;
          border-color: transparent #ffffff #ffffff transparent;
          left: 7px;
          top: 1px;
          font-weight: bold;
          color: #ffffff; }
        .form-check .form-check-label input[type="checkbox"]:checked + .input-helper:before {
          background: #1bdbe0;
          border-width: 0; }
        .form-check .form-check-label input[type="checkbox"]:checked + .input-helper:after {
          opacity: 1;
          line-height: 18px;
          filter: alpha(opacity=100); }
        .form-check .form-check-label input[type="checkbox"]:disabled + .input-helper:before {
          border-color: #e8ecf1; }
        .form-check .form-check-label input[type="checkbox"]:disabled:checked + .input-helper:after {
          border-color: transparent #e8ecf1 #e8ecf1 transparent; }
        .form-check .form-check-label input[type="radio"] + .input-helper:before {
          position: absolute;
          content: "";
          top: 0;
          left: 0;
          border: solid #1bdbe0;
          border-width: 2px;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          -webkit-transition: all;
          transition: all;
          transition-duration: 0s;
          -webkit-transition-duration: 250ms;
          transition-duration: 250ms; }
          .rtl .form-check .form-check-label input[type="radio"] + .input-helper:before {
            left: auto;
            right: 0; }
        .form-check .form-check-label input[type="radio"] + .input-helper:after {
          content: "";
          width: 8px;
          height: 8px;
          background: #ffffff;
          border-radius: 50%;
          top: 6px;
          left: 6px;
          -webkit-transition: all;
          transition: all;
          transition-duration: 0s;
          -webkit-transition-duration: 250ms;
          transition-duration: 250ms;
          opacity: 0;
          filter: alpha(opacity=0);
          -webkit-transform: scale(0);
          transform: scale(0);
          position: absolute; }
          .rtl .form-check .form-check-label input[type="radio"] + .input-helper:after {
            left: auto;
            right: 6px; }
        .form-check .form-check-label input[type="radio"]:checked + .input-helper:before {
          background: #1bdbe0;
          border-width: 0; }
        .form-check .form-check-label input[type="radio"]:checked + .input-helper:after {
          opacity: 1;
          line-height: 1.5;
          filter: alpha(opacity=100);
          -webkit-transform: scale(1);
          transform: scale(1); }
        .form-check .form-check-label input[type="radio"]:disabled + .input-helper:before {
          border-color: #e8ecf1; }
        .form-check .form-check-label input[type="radio"]:disabled:checked + .input-helper:before {
          background: #e8ecf1; }
        .form-check .form-check-label input[type="radio"]:disabled:checked + .input-helper:after {
          background: #ffffff; }
    
    .form-check-primary.form-check label input[type="checkbox"] + .input-helper:before,
    .form-check-primary.form-check label input[type="radio"] + .input-helper:before {
      border-color: #1bdbe0; }
    
    .form-check-primary.form-check label input[type="checkbox"]:checked + .input-helper:before,
    .form-check-primary.form-check label input[type="radio"]:checked + .input-helper:before {
      background: #1bdbe0; }
    
    .form-check-secondary.form-check label input[type="checkbox"] + .input-helper:before,
    .form-check-secondary.form-check label input[type="radio"] + .input-helper:before {
      border-color: #d8d8d8; }
    
    .form-check-secondary.form-check label input[type="checkbox"]:checked + .input-helper:before,
    .form-check-secondary.form-check label input[type="radio"]:checked + .input-helper:before {
      background: #d8d8d8; }
    
    .form-check-success.form-check label input[type="checkbox"] + .input-helper:before,
    .form-check-success.form-check label input[type="radio"] + .input-helper:before {
      border-color: #38ce3c; }
    
    .form-check-success.form-check label input[type="checkbox"]:checked + .input-helper:before,
    .form-check-success.form-check label input[type="radio"]:checked + .input-helper:before {
      background: #38ce3c; }
    
    .form-check-info.form-check label input[type="checkbox"] + .input-helper:before,
    .form-check-info.form-check label input[type="radio"] + .input-helper:before {
      border-color: #8e32e9; }
    
    .form-check-info.form-check label input[type="checkbox"]:checked + .input-helper:before,
    .form-check-info.form-check label input[type="radio"]:checked + .input-helper:before {
      background: #8e32e9; }
    
    .form-check-warning.form-check label input[type="checkbox"] + .input-helper:before,
    .form-check-warning.form-check label input[type="radio"] + .input-helper:before {
      border-color: #ffde73; }
    
    .form-check-warning.form-check label input[type="checkbox"]:checked + .input-helper:before,
    .form-check-warning.form-check label input[type="radio"]:checked + .input-helper:before {
      background: #ffde73; }
    
    .form-check-danger.form-check label input[type="checkbox"] + .input-helper:before,
    .form-check-danger.form-check label input[type="radio"] + .input-helper:before {
      border-color: #ff4d6b; }
    
    .form-check-danger.form-check label input[type="checkbox"]:checked + .input-helper:before,
    .form-check-danger.form-check label input[type="radio"]:checked + .input-helper:before {
      background: #ff4d6b; }
    
    .form-check-light.form-check label input[type="checkbox"] + .input-helper:before,
    .form-check-light.form-check label input[type="radio"] + .input-helper:before {
      border-color: #f8f9fa; }
    
    .form-check-light.form-check label input[type="checkbox"]:checked + .input-helper:before,
    .form-check-light.form-check label input[type="radio"]:checked + .input-helper:before {
      background: #f8f9fa; }
    
    .form-check-dark.form-check label input[type="checkbox"] + .input-helper:before,
    .form-check-dark.form-check label input[type="radio"] + .input-helper:before {
      border-color: #3e4b5b; }
    
    .form-check-dark.form-check label input[type="checkbox"]:checked + .input-helper:before,
    .form-check-dark.form-check label input[type="radio"]:checked + .input-helper:before {
      background: #3e4b5b; }
    
    /* Dropdowns */
    .dropdown .dropdown-toggle:after {
      border-top: 0;
      border-right: 0;
      border-left: 0;
      border-bottom: 0;
      font-family: "simple-line-icons";
      content: "\e604";
      width: auto;
      height: auto;
      vertical-align: baseline;
      font-size: .75rem; }
    
    .dropdown .dropdown-menu {
      margin-top: .75rem;
      font-size: 0.875rem;
      -webkit-box-shadow: 0px 1px 15px 1px rgba(230, 234, 236, 0.35);
      box-shadow: 0px 1px 15px 1px rgba(230, 234, 236, 0.35); }
      .dropdown .dropdown-menu .dropdown-item {
        font-size: 1rem;
        padding: .25rem 1.5rem; }
        .dropdown .dropdown-menu .dropdown-item:active {
          background: initial; }
    
    /* Forms */
    .form-group {
      margin-bottom: 1.5rem; }
    
    .input-group-append,
    .input-group-prepend {
      color: #c9c8c8;
      width: auto;
      border: none; }
      .input-group-append .input-group-text,
      .input-group-prepend .input-group-text {
        border-color: #e8ecf1;
        padding: 0.94rem 0.75rem;
        color: #c9c8c8; }
    
    .form-control, .select2-container--default .select2-selection--single, .select2-container--default .select2-selection--single .select2-search__field, .typeahead,
    .tt-query,
    .tt-hint {
      border: 1px solid #e8ecf1;
      font-family: "Open Sans", sans-serif;
      font-size: 0.8125rem; }
    
    select.form-control, .select2-container--default select.select2-selection--single, .select2-container--default .select2-selection--single select.select2-search__field, select.typeahead,
    select.tt-query,
    select.tt-hint {
      padding: .4375rem .75rem;
      border: 0;
      outline: 1px solid #e8ecf1;
      color: #c9c8c8; }
      select.form-control:focus, .select2-container--default select.select2-selection--single:focus, .select2-container--default .select2-selection--single select.select2-search__field:focus, select.typeahead:focus,
      select.tt-query:focus,
      select.tt-hint:focus {
        outline: 1px solid #e8ecf1; }
      select.form-control.border-primary, .select2-container--default select.border-primary.select2-selection--single, .select2-container--default .select2-selection--single select.border-primary.select2-search__field, select.border-primary.typeahead,
      select.border-primary.tt-query,
      select.border-primary.tt-hint {
        outline: 1px solid #1bdbe0; }
        select.form-control.border-primary:focus, .select2-container--default select.border-primary.select2-selection--single:focus, .select2-container--default .select2-selection--single select.border-primary.select2-search__field:focus, select.border-primary.typeahead:focus,
        select.border-primary.tt-query:focus,
        select.border-primary.tt-hint:focus {
          outline: 1px solid #1bdbe0; }
      select.form-control.border-secondary, select.form-control.loader-demo-box, .select2-container--default select.loader-demo-box.select2-selection--single, .select2-container--default .select2-selection--single select.loader-demo-box.select2-search__field, select.loader-demo-box.typeahead,
      select.loader-demo-box.tt-query,
      select.loader-demo-box.tt-hint, .select2-container--default select.border-secondary.select2-selection--single, .select2-container--default .select2-selection--single select.border-secondary.select2-search__field, select.border-secondary.typeahead,
      select.border-secondary.tt-query,
      select.border-secondary.tt-hint {
        outline: 1px solid #d8d8d8; }
        select.form-control.border-secondary:focus, select.form-control.loader-demo-box:focus, .select2-container--default select.loader-demo-box.select2-selection--single:focus, .select2-container--default .select2-selection--single select.loader-demo-box.select2-search__field:focus, select.loader-demo-box.typeahead:focus,
        select.loader-demo-box.tt-query:focus,
        select.loader-demo-box.tt-hint:focus, .select2-container--default select.border-secondary.select2-selection--single:focus, .select2-container--default .select2-selection--single select.border-secondary.select2-search__field:focus, select.border-secondary.typeahead:focus,
        select.border-secondary.tt-query:focus,
        select.border-secondary.tt-hint:focus {
          outline: 1px solid #d8d8d8; }
      select.form-control.border-success, .select2-container--default select.border-success.select2-selection--single, .select2-container--default .select2-selection--single select.border-success.select2-search__field, select.border-success.typeahead,
      select.border-success.tt-query,
      select.border-success.tt-hint {
        outline: 1px solid #38ce3c; }
        select.form-control.border-success:focus, .select2-container--default select.border-success.select2-selection--single:focus, .select2-container--default .select2-selection--single select.border-success.select2-search__field:focus, select.border-success.typeahead:focus,
        select.border-success.tt-query:focus,
        select.border-success.tt-hint:focus {
          outline: 1px solid #38ce3c; }
      select.form-control.border-info, .select2-container--default select.border-info.select2-selection--single, .select2-container--default .select2-selection--single select.border-info.select2-search__field, select.border-info.typeahead,
      select.border-info.tt-query,
      select.border-info.tt-hint {
        outline: 1px solid #8e32e9; }
        select.form-control.border-info:focus, .select2-container--default select.border-info.select2-selection--single:focus, .select2-container--default .select2-selection--single select.border-info.select2-search__field:focus, select.border-info.typeahead:focus,
        select.border-info.tt-query:focus,
        select.border-info.tt-hint:focus {
          outline: 1px solid #8e32e9; }
      select.form-control.border-warning, .select2-container--default select.border-warning.select2-selection--single, .select2-container--default .select2-selection--single select.border-warning.select2-search__field, select.border-warning.typeahead,
      select.border-warning.tt-query,
      select.border-warning.tt-hint {
        outline: 1px solid #ffde73; }
        select.form-control.border-warning:focus, .select2-container--default select.border-warning.select2-selection--single:focus, .select2-container--default .select2-selection--single select.border-warning.select2-search__field:focus, select.border-warning.typeahead:focus,
        select.border-warning.tt-query:focus,
        select.border-warning.tt-hint:focus {
          outline: 1px solid #ffde73; }
      select.form-control.border-danger, .select2-container--default select.border-danger.select2-selection--single, .select2-container--default .select2-selection--single select.border-danger.select2-search__field, select.border-danger.typeahead,
      select.border-danger.tt-query,
      select.border-danger.tt-hint {
        outline: 1px solid #ff4d6b; }
        select.form-control.border-danger:focus, .select2-container--default select.border-danger.select2-selection--single:focus, .select2-container--default .select2-selection--single select.border-danger.select2-search__field:focus, select.border-danger.typeahead:focus,
        select.border-danger.tt-query:focus,
        select.border-danger.tt-hint:focus {
          outline: 1px solid #ff4d6b; }
      select.form-control.border-light, .select2-container--default select.border-light.select2-selection--single, .select2-container--default .select2-selection--single select.border-light.select2-search__field, select.border-light.typeahead,
      select.border-light.tt-query,
      select.border-light.tt-hint {
        outline: 1px solid #f8f9fa; }
        select.form-control.border-light:focus, .select2-container--default select.border-light.select2-selection--single:focus, .select2-container--default .select2-selection--single select.border-light.select2-search__field:focus, select.border-light.typeahead:focus,
        select.border-light.tt-query:focus,
        select.border-light.tt-hint:focus {
          outline: 1px solid #f8f9fa; }
      select.form-control.border-dark, .select2-container--default select.border-dark.select2-selection--single, .select2-container--default .select2-selection--single select.border-dark.select2-search__field, select.border-dark.typeahead,
      select.border-dark.tt-query,
      select.border-dark.tt-hint {
        outline: 1px solid #3e4b5b; }
        select.form-control.border-dark:focus, .select2-container--default select.border-dark.select2-selection--single:focus, .select2-container--default .select2-selection--single select.border-dark.select2-search__field:focus, select.border-dark.typeahead:focus,
        select.border-dark.tt-query:focus,
        select.border-dark.tt-hint:focus {
          outline: 1px solid #3e4b5b; }
    
    .form-group label {
      font-size: 0.875rem;
      line-height: 1;
      vertical-align: top;
      margin-bottom: .5rem; }
    
    .form-group.has-danger .form-control, .form-group.has-danger .select2-container--default .select2-selection--single, .select2-container--default .form-group.has-danger .select2-selection--single, .form-group.has-danger .select2-container--default .select2-selection--single .select2-search__field, .select2-container--default .select2-selection--single .form-group.has-danger .select2-search__field, .form-group.has-danger .typeahead,
    .form-group.has-danger .tt-query,
    .form-group.has-danger .tt-hint {
      border-color: #ff4d6b; }
    
    .form-group .file-upload-default {
      visibility: hidden;
      position: absolute; }
    
    .form-group .file-upload-info {
      background: transparent; }
    
    /* Icons */
    .icons-list {
      border-left: 1px solid #e8ecf1;
      border-top: 1px solid #e8ecf1; }
      .icons-list > div {
        background: #fff;
        border-bottom: 1px solid #e8ecf1;
        border-right: 1px solid #e8ecf1;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 15px 15px;
        font-family: "Open Sans", sans-serif;
        font-size: 0.875rem; }
        .icons-list > div i {
          display: inline-block;
          font-size: 20px;
          width: 40px;
          text-align: left;
          color: #1bdbe0; }
    
    /* Lists */
    ul,
    ol,
    dl {
      padding-left: 1rem;
      font-size: 0.875rem; }
      ul li,
      ol li,
      dl li {
        line-height: 1.8; }
    
    .list-ticked,
    .list-arrow,
    .list-star {
      list-style: none;
      padding: 0; }
      .list-ticked li,
      .list-arrow li,
      .list-star li {
        padding-left: 1.5rem; }
        .list-ticked li:before,
        .list-arrow li:before,
        .list-star li:before {
          font-family: "simple-line-icons";
          margin-left: -1.5rem;
          width: 1.5rem;
          margin-right: .5rem; }
    
    .list-ticked li:before {
      content: '\e080';
      color: #ff4d6b; }
    
    .list-arrow li:before {
      content: '\e606';
      color: #38ce3c; }
    
    .list-star li:before {
      content: '\e09b';
      color: #ffde73; }
    
    .gradient-bullet-list {
      padding-left: 0; }
      .rtl .gradient-bullet-list {
        padding-right: 0; }
      .gradient-bullet-list li {
        position: relative;
        list-style-type: none;
        padding-left: 25px;
        line-height: 1;
        padding-bottom: 25px; }
        .gradient-bullet-list li:before, .gradient-bullet-list li:after {
          content: "";
          position: absolute; }
        .gradient-bullet-list li:before {
          top: 0;
          left: 0;
          width: 15px;
          height: 15px;
          border-radius: 100%; }
        .gradient-bullet-list li:after {
          width: 11px;
          height: 11px;
          top: 2px;
          left: 2px;
          background: #fff;
          border-radius: 100%; }
        .gradient-bullet-list li:nth-child(1):before {
          background: #ff4d6b; }
        .gradient-bullet-list li:nth-child(2):before {
          background: #8e32e9; }
        .gradient-bullet-list li:nth-child(3):before {
          background: #ffde73; }
        .gradient-bullet-list li:nth-child(4):before {
          background: #38ce3c; }
        .gradient-bullet-list li:nth-child(5):before {
          background: #1bdbe0; }
    
    /* Modals */
    .modal .modal-dialog {
      margin-top: 100px; }
      .modal .modal-dialog .modal-content .modal-header {
        padding: 25px 26px; }
        .modal .modal-dialog .modal-content .modal-header .close span {
          font-size: 20px;
          font-weight: 400;
          color: #6a6a6a; }
      .modal .modal-dialog .modal-content .modal-body {
        padding: 35px 26px; }
      .modal .modal-dialog .modal-content .modal-footer {
        padding: 15px 31px; }
    
    /* Pagination */
    .pagination .page-item .page-link {
      border-color: #e8ecf1;
      color: #343a40;
      font-size: .875rem;
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s; }
      .pagination .page-item .page-link:focus {
        background: inherit; }
      .pagination .page-item .page-link i:before {
        font-size: inherit;
        line-height: 1;
        vertical-align: middle; }
    
    .pagination .page-item.active .page-link, .pagination .page-item:hover .page-link, .pagination .page-item:focus .page-link, .pagination .page-item:active .page-link {
      background: #1bdbe0;
      border-color: #1bdbe0;
      color: #ffffff; }
    
    .pagination.flat .page-item .page-link {
      border: none;
      border-radius: 2px; }
    
    .pagination.separated .page-item {
      margin-left: 2px;
      margin-right: 2px; }
      .pagination.separated .page-item:first-child {
        margin-left: 0; }
      .pagination.separated .page-item:last-child {
        margin-right: 0; }
      .pagination.separated .page-item .page-link {
        border-radius: 2px; }
    
    .pagination.rounded .page-item:first-child .page-link, .pagination.loader-demo-box .page-item:first-child .page-link {
      border-radius: 25px 0 0 25px; }
    
    .pagination.rounded .page-item:last-child .page-link, .pagination.loader-demo-box .page-item:last-child .page-link {
      border-radius: 0 25px 25px 0; }
    
    .pagination.rounded-flat .page-item {
      margin-right: 3px;
      margin-left: 3px; }
      .pagination.rounded-flat .page-item .page-link {
        border: none;
        border-radius: 50px; }
    
    .pagination.rounded-separated .page-item {
      margin-left: 2px;
      margin-right: 2px; }
      .pagination.rounded-separated .page-item:first-child {
        margin-left: 0; }
        .pagination.rounded-separated .page-item:first-child .page-link {
          border-radius: 10px 0 0 10px; }
      .pagination.rounded-separated .page-item:last-child {
        margin-right: 0; }
        .pagination.rounded-separated .page-item:last-child .page-link {
          border-radius: 0 10px 10px 0; }
      .pagination.rounded-separated .page-item .page-link {
        border-radius: 2px; }
    
    /* pagination variations */
    .pagination-primary .page-item.active .page-link {
      background: #1bdbe0;
      border-color: #1bdbe0; }
    
    .pagination-primary .page-item .page-link:hover {
      background: #2fe1e6;
      border-color: #1bdbe0;
      color: #ffffff; }
    
    .pagination-secondary .page-item.active .page-link {
      background: #d8d8d8;
      border-color: #d8d8d8; }
    
    .pagination-secondary .page-item .page-link:hover {
      background: #e5e5e5;
      border-color: #d8d8d8;
      color: #ffffff; }
    
    .pagination-success .page-item.active .page-link {
      background: #38ce3c;
      border-color: #38ce3c; }
    
    .pagination-success .page-item .page-link:hover {
      background: #4cd350;
      border-color: #38ce3c;
      color: #ffffff; }
    
    .pagination-info .page-item.active .page-link {
      background: #8e32e9;
      border-color: #8e32e9; }
    
    .pagination-info .page-item .page-link:hover {
      background: #9b49eb;
      border-color: #8e32e9;
      color: #ffffff; }
    
    .pagination-warning .page-item.active .page-link {
      background: #ffde73;
      border-color: #ffde73; }
    
    .pagination-warning .page-item .page-link:hover {
      background: #ffe48d;
      border-color: #ffde73;
      color: #ffffff; }
    
    .pagination-danger .page-item.active .page-link {
      background: #ff4d6b;
      border-color: #ff4d6b; }
    
    .pagination-danger .page-item .page-link:hover {
      background: #ff6780;
      border-color: #ff4d6b;
      color: #ffffff; }
    
    .pagination-light .page-item.active .page-link {
      background: #f8f9fa;
      border-color: #f8f9fa; }
    
    .pagination-light .page-item .page-link:hover {
      background: white;
      border-color: #f8f9fa;
      color: #ffffff; }
    
    .pagination-dark .page-item.active .page-link {
      background: #3e4b5b;
      border-color: #3e4b5b; }
    
    .pagination-dark .page-item .page-link:hover {
      background: #48586a;
      border-color: #3e4b5b;
      color: #ffffff; }
    
    /* Tables */
    .table {
      margin-bottom: 0; }
      .table thead th {
        border-top: 0;
        border-bottom-width: 1px;
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
        font-weight: initial; }
        .table thead th i {
          margin-left: 0.325rem; }
      .table th,
      .table td {
        vertical-align: middle;
        font-size: 0.875rem;
        line-height: 1;
        white-space: nowrap; }
        .table th img,
        .table td img {
          width: 36px;
          height: 36px;
          border-radius: 100%; }
        .table th .badge,
        .table td .badge {
          margin-bottom: 0; }
      .table.table-borderless {
        border: none; }
        .table.table-borderless tr,
        .table.table-borderless td,
        .table.table-borderless th {
          border: none; }
    
    /* Preview */
    .preview-list .preview-item {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      padding: .75rem 1.5rem;
      font-size: .875rem; }
      .preview-list .preview-item:last-child {
        border-bottom: 0; }
      .preview-list .preview-item:hover {
        background: #f8f9fa; }
      .preview-list .preview-item .form-check {
        margin-top: 8px;
        margin-right: 1rem; }
      .preview-list .preview-item .preview-thumbnail {
        color: #ffffff;
        position: relative; }
        .preview-list .preview-item .preview-thumbnail img,
        .preview-list .preview-item .preview-thumbnail .preview-icon {
          width: 36px;
          height: 36px;
          border-radius: 100%; }
        .preview-list .preview-item .preview-thumbnail .preview-icon {
          padding: 6px;
          text-align: center;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center; }
          .preview-list .preview-item .preview-thumbnail .preview-icon i {
            font-size: 1.125rem;
            margin: 0; }
        .preview-list .preview-item .preview-thumbnail .badge {
          border: 2px solid #ffffff;
          border-radius: 100%;
          bottom: 5px;
          display: block;
          height: 14px;
          left: -5px;
          padding: 0;
          position: absolute;
          width: 14px; }
      .preview-list .preview-item .preview-item-content {
        line-height: 1;
        padding-left: 15px; }
        .preview-list .preview-item .preview-item-content:first-child {
          padding-left: 0; }
        .preview-list .preview-item .preview-item-content p {
          margin-bottom: 10px; }
          .preview-list .preview-item .preview-item-content p .content-category {
            font-family: 'source-sans-pro-semibold', sans-serif;
            padding-right: 15px;
            border-right: 1px solid #e8ecf1; }
        .rtl .preview-list .preview-item .preview-item-content {
          padding-left: 0;
          padding-right: 1rem;
          margin-right: 0;
          margin-left: auto; }
      .preview-list .preview-item .preview-actions {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row; }
        .preview-list .preview-item .preview-actions i {
          width: 29px;
          color: #e6e9ed;
          height: 29px;
          border: 2px solid #e6e9ed;
          border-radius: 100%;
          padding: 3px 6px;
          display: inline-block; }
          .preview-list .preview-item .preview-actions i:first-child {
            margin-right: 10px; }
    
    .preview-list.comment-preview .preview-item {
      padding: .87rem 0; }
      .preview-list.comment-preview .preview-item:first-child {
        padding-top: 0; }
      .preview-list.comment-preview .preview-item p {
        line-height: 27px; }
    
    .preview-list.bordered .preview-item {
      border-bottom: 1px solid #e8ecf1; }
      .preview-list.bordered .preview-item:last-child {
        border-bottom: 0; }
    
    /*-------------------------------------------------------------------*/
    /* === Plugin overrides === */
    /* Chartist */
    .ct-series-a .ct-line {
      stroke: #a29afc;
      stroke-width: 3px;
      stroke-dasharray: 10px 20px; }
    
    .ct-series-a .ct-point {
      stroke: #6355f9;
      stroke-width: 10px;
      stroke-linecap: round; }
    
    .ct-series-b .ct-line {
      stroke: #80d691;
      stroke-width: 3px;
      stroke-dasharray: 10px 20px; }
    
    .ct-series-b .ct-point {
      stroke: #4ac462;
      stroke-width: 10px;
      stroke-linecap: round; }
    
    .ct-series-c .ct-line {
      stroke: #f7cb9b;
      stroke-width: 3px;
      stroke-dasharray: 10px 20px; }
    
    .ct-series-c .ct-point {
      stroke: #f2a859;
      stroke-width: 10px;
      stroke-linecap: round; }
    
    .ct-series-a .ct-bar {
      stroke: #a29afc;
      stroke-width: 20px;
      stroke-dasharray: 0;
      stroke-linecap: squre; }
    
    .ct-series-b .ct-bar {
      stroke: #80d691;
      stroke-width: 20px;
      stroke-dasharray: 0;
      stroke-linecap: squre; }
    
    .ct-series-c .ct-bar {
      stroke: #f7cb9b;
      stroke-width: 20px;
      stroke-dasharray: 0;
      stroke-linecap: squre; }
    
    .ct-series-d .ct-bar {
      stroke: #fcb2b2;
      stroke-width: 20px;
      stroke-dasharray: 0;
      stroke-linecap: squre; }
    
    .ct-series-a .ct-slice-pie {
      fill: #a29afc;
      stroke-width: 4px; }
    
    .ct-series-b .ct-slice-pie {
      fill: #80d691;
      stroke-width: 4px; }
    
    .ct-series-c .ct-slice-pie {
      fill: #fcb2b2;
      stroke-width: 4px; }
    
    .ct-series-d .ct-slice-pie {
      fill: #f7cb9b;
      stroke-width: 4px; }
    
    .ct-series-a .ct-slice-donut-solid {
      fill: #a29afc; }
    
    .ct-series-b .ct-slice-donut-solid {
      fill: #80d691; }
    
    .ct-series-c .ct-slice-donut-solid {
      fill: #fcb2b2; }
    
    .ct-series-a .ct-slice-donut-solid {
      fill: #f7cb9b; }
    
    #ct-chart-dash-barChart .ct-series-a .ct-bar {
      stroke: #fb9999;
      stroke-width: 20px;
      stroke-dasharray: 0;
      stroke-linecap: squre; }
    
    #ct-chart-dash-barChart .ct-series-b .ct-bar {
      stroke: #8b81fb;
      stroke-width: 20px;
      stroke-dasharray: 0;
      stroke-linecap: squre; }
    
    #ct-chart-dash-barChart .ct-series-c .ct-bar {
      stroke: #a29afc;
      stroke-width: 20px;
      stroke-dasharray: 0;
      stroke-linecap: squre; }
    
    #ct-chart-dash-barChart .ct-series-d .ct-bar {
      stroke: #b9b3fc;
      stroke-width: 20px;
      stroke-dasharray: 0;
      stroke-linecap: squre; }
    
    /* Select2 */
    .select2-container--default .select2-results__option--highlighted[aria-selected] {
      background: #1bdbe0; }
    
    .select2-container--default .select2-selection--single,
    .select2-container--default .select2-dropdown,
    .select2-container--default .select2-selection--multiple {
      border-color: #e8ecf1; }
      .select2-container--default .select2-selection--single .select2-search__field,
      .select2-container--default .select2-dropdown .select2-search__field,
      .select2-container--default .select2-selection--multiple .select2-search__field {
        border-color: #e8ecf1; }
    
    .select2-container--default .select2-selection--single {
      height: auto; }
      .select2-container--default .select2-selection--single .select2-selection__rendered {
        line-height: 14px; }
    
    .select2-container--default .select2-dropdown {
      font-size: .8125rem; }
    
    .select2-container--default.select2-container--focus .select2-selection--multiple {
      border-color: #e8ecf1; }
    
    .select2-container--default .select2-selection--multiple .select2-selection__choice {
      color: #ffffff;
      border: 0;
      border-radius: 3px;
      padding: 6px;
      font-size: .625rem;
      font-family: inherit;
      line-height: 1; }
      .select2-container--default .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove {
        color: #ffffff; }
      .select2-container--default .select2-selection--multiple .select2-selection__choice:nth-child(5n+1) {
        background: #1bdbe0; }
      .select2-container--default .select2-selection--multiple .select2-selection__choice:nth-child(5n+2) {
        background: #38ce3c; }
      .select2-container--default .select2-selection--multiple .select2-selection__choice:nth-child(5n+3) {
        background: #8e32e9; }
      .select2-container--default .select2-selection--multiple .select2-selection__choice:nth-child(5n+4) {
        background: #ff4d6b; }
      .select2-container--default .select2-selection--multiple .select2-selection__choice:nth-child(5n+5) {
        background: #ffde73; }
    
    /* Typeahead */
    .tt-menu,
    .gist {
      text-align: left; }
    
    .twitter-typeahead {
      max-width: 100%; }
    
    .typeahead {
      background-color: #ffffff; }
      .typeahead:focus {
        border-color: #e8ecf1; }
    
    .tt-query {
      -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
    
    .tt-hint {
      color: #434a54; }
    
    .tt-menu {
      width: 100%;
      margin: 12px 0;
      padding: 8px 0;
      background-color: #ffffff;
      border: 1px solid #e8ecf1;
      border-radius: 8px;
      -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2); }
    
    .tt-suggestion {
      padding: 3px 20px;
      font-size: inherit; }
      .tt-suggestion:hover {
        cursor: pointer;
        color: #ffffff;
        background-color: #1bdbe0; }
      .tt-suggestion .tt-cursor {
        color: #ffffff;
        background-color: #1bdbe0; }
      .tt-suggestion p {
        margin: 0; }
    
    /*-------------------------------------------------------------------*/
    /* === Landing screens === */
    /* Auth */
    .auth .login-half-bg {
      background: url("../adminImages/auth/login-bg.jpg");
      background-size: cover; }
    
    .auth .register-half-bg {
      background: url("../adminImages/auth/register-bg.jpg");
      background-size: cover; }
    
    .auth.lock-full-bg {
      background: url("../adminImages/auth/lockscreen-bg.jpg");
      background-size: cover; }
    
    .auth .lock-profile-img {
      width: 90px;
      height: 90px;
      border-radius: 100%; }
    
    .auth .auth-form-dark {
      background: rgba(0, 0, 0, 0.6);
      color: #ffffff; }
      .auth .auth-form-dark .form-control, .auth .auth-form-dark .select2-container--default .select2-selection--single, .select2-container--default .auth .auth-form-dark .select2-selection--single, .auth .auth-form-dark .select2-container--default .select2-selection--single .select2-search__field, .select2-container--default .select2-selection--single .auth .auth-form-dark .select2-search__field, .auth .auth-form-dark .typeahead,
      .auth .auth-form-dark .tt-query,
      .auth .auth-form-dark .tt-hint {
        border-color: rgba(255, 255, 255, 0.2);
        color: #ffffff; }
        .auth .auth-form-dark .form-control:-ms-input-placeholder, .auth .auth-form-dark .select2-container--default .select2-selection--single:-ms-input-placeholder, .select2-container--default .auth .auth-form-dark .select2-selection--single:-ms-input-placeholder, .auth .auth-form-dark .select2-container--default .select2-selection--single .select2-search__field:-ms-input-placeholder, .select2-container--default .select2-selection--single .auth .auth-form-dark .select2-search__field:-ms-input-placeholder, .auth .auth-form-dark .typeahead:-ms-input-placeholder,
        .auth .auth-form-dark .tt-query:-ms-input-placeholder,
        .auth .auth-form-dark .tt-hint:-ms-input-placeholder {
          color: #ffffff; }
        .auth .auth-form-dark .form-control:-moz-placeholder, .auth .auth-form-dark .select2-container--default .select2-selection--single:-moz-placeholder, .select2-container--default .auth .auth-form-dark .select2-selection--single:-moz-placeholder, .auth .auth-form-dark .select2-container--default .select2-selection--single .select2-search__field:-moz-placeholder, .select2-container--default .select2-selection--single .auth .auth-form-dark .select2-search__field:-moz-placeholder, .auth .auth-form-dark .typeahead:-moz-placeholder,
        .auth .auth-form-dark .tt-query:-moz-placeholder,
        .auth .auth-form-dark .tt-hint:-moz-placeholder {
          color: #ffffff; }
        .auth .auth-form-dark .form-control::-moz-placeholder, .auth .auth-form-dark .select2-container--default .select2-selection--single::-moz-placeholder, .select2-container--default .auth .auth-form-dark .select2-selection--single::-moz-placeholder, .auth .auth-form-dark .select2-container--default .select2-selection--single .select2-search__field::-moz-placeholder, .select2-container--default .select2-selection--single .auth .auth-form-dark .select2-search__field::-moz-placeholder, .auth .auth-form-dark .typeahead::-moz-placeholder,
        .auth .auth-form-dark .tt-query::-moz-placeholder,
        .auth .auth-form-dark .tt-hint::-moz-placeholder {
          color: #ffffff; }
        .auth .auth-form-dark .form-control::-webkit-input-placeholder, .auth .auth-form-dark .select2-container--default .select2-selection--single::-webkit-input-placeholder, .select2-container--default .auth .auth-form-dark .select2-selection--single::-webkit-input-placeholder, .auth .auth-form-dark .select2-container--default .select2-selection--single .select2-search__field::-webkit-input-placeholder, .select2-container--default .select2-selection--single .auth .auth-form-dark .select2-search__field::-webkit-input-placeholder, .auth .auth-form-dark .typeahead::-webkit-input-placeholder,
        .auth .auth-form-dark .tt-query::-webkit-input-placeholder,
        .auth .auth-form-dark .tt-hint::-webkit-input-placeholder {
          color: #ffffff; }
    
    .auth .auth-form-light {
      background: #ffffff; }
      .auth .auth-form-light select {
        color: #c9c8c8; }
      .auth .auth-form-light .input-group .form-control:focus, .auth .auth-form-light .input-group .select2-container--default .select2-selection--single:focus, .select2-container--default .auth .auth-form-light .input-group .select2-selection--single:focus, .auth .auth-form-light .input-group .select2-container--default .select2-selection--single .select2-search__field:focus, .select2-container--default .select2-selection--single .auth .auth-form-light .input-group .select2-search__field:focus, .auth .auth-form-light .input-group .typeahead:focus,
      .auth .auth-form-light .input-group .tt-query:focus,
      .auth .auth-form-light .input-group .tt-hint:focus, .auth .auth-form-light .input-group .form-control:active, .auth .auth-form-light .input-group .select2-container--default .select2-selection--single:active, .select2-container--default .auth .auth-form-light .input-group .select2-selection--single:active, .auth .auth-form-light .input-group .select2-container--default .select2-selection--single .select2-search__field:active, .select2-container--default .select2-selection--single .auth .auth-form-light .input-group .select2-search__field:active, .auth .auth-form-light .input-group .typeahead:active,
      .auth .auth-form-light .input-group .tt-query:active,
      .auth .auth-form-light .input-group .tt-hint:active {
        border-color: #e8ecf1; }
    
    .auth .auth-form-transparent {
      background: transparent; }
      .auth .auth-form-transparent .form-control, .auth .auth-form-transparent .select2-container--default .select2-selection--single, .select2-container--default .auth .auth-form-transparent .select2-selection--single, .auth .auth-form-transparent .select2-container--default .select2-selection--single .select2-search__field, .select2-container--default .select2-selection--single .auth .auth-form-transparent .select2-search__field, .auth .auth-form-transparent .typeahead,
      .auth .auth-form-transparent .tt-query,
      .auth .auth-form-transparent .tt-hint,
      .auth .auth-form-transparent .input-group-text {
        border-color: #d8d8d8; }
        .auth .auth-form-transparent .form-control:focus, .auth .auth-form-transparent .select2-container--default .select2-selection--single:focus, .select2-container--default .auth .auth-form-transparent .select2-selection--single:focus, .auth .auth-form-transparent .select2-container--default .select2-selection--single .select2-search__field:focus, .select2-container--default .select2-selection--single .auth .auth-form-transparent .select2-search__field:focus, .auth .auth-form-transparent .typeahead:focus,
        .auth .auth-form-transparent .tt-query:focus,
        .auth .auth-form-transparent .tt-hint:focus, .auth .auth-form-transparent .form-control:active, .auth .auth-form-transparent .select2-container--default .select2-selection--single:active, .select2-container--default .auth .auth-form-transparent .select2-selection--single:active, .auth .auth-form-transparent .select2-container--default .select2-selection--single .select2-search__field:active, .select2-container--default .select2-selection--single .auth .auth-form-transparent .select2-search__field:active, .auth .auth-form-transparent .typeahead:active,
        .auth .auth-form-transparent .tt-query:active,
        .auth .auth-form-transparent .tt-hint:active,
        .auth .auth-form-transparent .input-group-text:focus,
        .auth .auth-form-transparent .input-group-text:active {
          border-color: #d8d8d8; }
      .auth .auth-form-transparent select {
        outline-color: #d8d8d8; }
    
    .auth.auth-img-bg {
      padding: 0; }
      @media (min-width: 768px) {
        .auth.auth-img-bg .auth-form-transparent {
          width: 55%;
          margin: auto; } }
    
    .auth .brand-logo {
      margin-bottom: 2rem; }
      .auth .brand-logo img {
        width: 150px; }
    
    .auth form .form-group {
      margin-bottom: 1.5rem; }
      .auth form .form-group label {
        font-size: .8125rem; }
      .auth form .form-group .form-control, .auth form .form-group .select2-container--default .select2-selection--single, .select2-container--default .auth form .form-group .select2-selection--single, .auth form .form-group .select2-container--default .select2-selection--single .select2-search__field, .select2-container--default .select2-selection--single .auth form .form-group .select2-search__field, .auth form .form-group .typeahead,
      .auth form .form-group .tt-query,
      .auth form .form-group .tt-hint {
        background: transparent;
        border-radius: 0;
        font-size: .9375rem; }
    
    .auth form .auth-form-btn {
      height: 50px;
      line-height: 1.5; }
    
    .auth form .auth-link {
      font-size: 0.875rem; }
      .auth form .auth-link:hover {
        color: initial; }
    
    /* Navbar */
    .navbar {
      font-family: "Open Sans", sans-serif;
      font-weight: 300;
      background: #fff;
      transition: background 0.25s ease;
      -webkit-transition: background 0.25s ease;
      -moz-transition: background 0.25s ease;
      -ms-transition: background 0.25s ease; }
      .navbar .navbar-brand {
        width: 110px; }
      .navbar .navbar-brand-wrapper {
        transition: width 0.25s ease, background 0.25s ease;
        -webkit-transition: width 0.25s ease, background 0.25s ease;
        -moz-transition: width 0.25s ease, background 0.25s ease;
        -ms-transition: width 0.25s ease, background 0.25s ease;
        background: #181824;
        width: 240px;
        height: 70px;
        padding: 0 1.75rem; }
        @media (max-width: 991px) {
          .navbar .navbar-brand-wrapper {
            width: 55px;
            padding-left: 15px;
            padding-right: 15px; } }
        .navbar .navbar-brand-wrapper .navbar-brand {
          color: #27367f;
          font-size: 1.5rem;
          line-height: 48px;
          margin-right: 0;
          padding: .25rem 0; }
          .navbar .navbar-brand-wrapper .navbar-brand:active, .navbar .navbar-brand-wrapper .navbar-brand:focus, .navbar .navbar-brand-wrapper .navbar-brand:hover {
            color: #1b2658; }
          .navbar .navbar-brand-wrapper .navbar-brand img {
            width: calc(240px - 120px);
            max-width: 100%;
            height: 28px;
            margin: auto;
            vertical-align: middle; }
          .navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini {
            display: none; }
            @media screen and (max-width: 991px) {
              .navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex; } }
            .navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini img {
              width: calc(70px - 35px);
              max-width: 100%;
              height: 28px;
              margin: auto;
              display: inline; }
        .navbar .navbar-brand-wrapper .navbar-toggler {
          color: #626262;
          padding: 0;
          margin-left: auto; }
          @media screen and (max-width: 991px) {
            .navbar .navbar-brand-wrapper .navbar-toggler {
              display: none; } }
          .sidebar-icon-only .navbar .navbar-brand-wrapper .navbar-toggler {
            margin-right: auto;
            margin-left: auto; }
      .navbar .navbar-menu-wrapper {
        transition: width 0.25s ease;
        -webkit-transition: width 0.25s ease;
        -moz-transition: width 0.25s ease;
        -ms-transition: width 0.25s ease;
        color: #111111;
        padding-left: 24px;
        padding-right: 24px;
        width: calc(100% - 240px);
        height: 70px;
        -webkit-box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.11);
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.11); }
        @media (max-width: 991px) {
          .navbar .navbar-menu-wrapper {
            width: auto;
            padding-left: 15px;
            padding-right: 15px; } }
        .navbar .navbar-menu-wrapper .navbar-toggler {
          border: 0;
          color: inherit;
          height: 70px;
          border-radius: 0px;
          padding-left: 5px;
          padding-right: 20px; }
          .navbar .navbar-menu-wrapper .navbar-toggler:not(.navbar-toggler-right) {
            font-size: 1.5rem; }
            @media (max-width: 991px) {
              .navbar .navbar-menu-wrapper .navbar-toggler:not(.navbar-toggler-right) {
                display: none; } }
          @media (max-width: 991px) {
            .navbar .navbar-menu-wrapper .navbar-toggler.navbar-toggler-right {
              padding-left: 15px;
              padding-right: 11px;
              border-right: none; } }
        .navbar .navbar-menu-wrapper .search-form {
          position: relative;
          margin-right: 15px; }
          .navbar .navbar-menu-wrapper .search-form i {
            font-size: 20px;
            position: absolute;
            left: 0;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            color: #626262; }
          .navbar .navbar-menu-wrapper .search-form input {
            background-color: transparent;
            border: none;
            cursor: pointer;
            width: 0;
            height: 35px;
            padding: 0 0 0 20px;
            position: relative;
            -webkit-transition: width 400ms ease, background 400ms ease;
            transition: width 400ms ease, background 400ms ease; }
            .navbar .navbar-menu-wrapper .search-form input:focus {
              background-color: transparent;
              border-bottom: 1px solid #626262;
              border-radius: 0;
              padding-left: 30px;
              background-position: 5px 7px;
              cursor: text;
              outline: 0;
              width: 140px; }
        .navbar .navbar-menu-wrapper .count-indicator {
          position: relative; }
          .navbar .navbar-menu-wrapper .count-indicator .count-symbol,
          .navbar .navbar-menu-wrapper .count-indicator .count-number {
            position: absolute;
            border-radius: 100%;
            border: 2px solid #ffffff; }
          .navbar .navbar-menu-wrapper .count-indicator .count-symbol {
            top: 17px;
            right: -3px;
            width: 10px;
            height: 10px; }
          .navbar .navbar-menu-wrapper .count-indicator .count-number {
            min-width: 14px;
            height: 14px;
            font-size: .5rem;
            color: #ffffff;
            bottom: 16px;
            right: -5px;
            line-height: 1;
            text-align: center; }
          .navbar .navbar-menu-wrapper .count-indicator:after {
            display: none; }
        .navbar .navbar-menu-wrapper .navbar-nav {
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center; }
          .navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link {
            color: inherit;
            font-size: 0.875rem;
            margin-left: 15px;
            margin-right: 15px;
            height: 35px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center; }
            @media (max-width: 767px) {
              .navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link {
                margin-left: .8rem;
                margin-right: .8rem; } }
            .navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link i {
              font-size: 1.25rem;
              color: #626262; }
          .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown {
            height: 70px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center; }
            .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-toggle {
              position: relative;
              padding: 0 20px; }
              .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-toggle:after {
                position: absolute;
                top: 50%;
                right: 0;
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
                font-size: 14px;
                color: grey; }
            .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu {
              border: none;
              border-radius: 5px;
              -webkit-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
              box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2); }
              .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown {
                position: absolute;
                font-size: 0.9rem;
                margin-top: 0;
                padding: 0; }
                .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown .dropdown-item {
                  margin-bottom: 0;
                  padding: 11px 13px;
                  cursor: pointer; }
                  .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown .dropdown-item i {
                    font-size: 17px; }
                  .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown .dropdown-item .ellipsis {
                    max-width: 200px;
                    overflow: hidden;
                    text-overflow: ellipsis; }
                .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown .dropdown-divider {
                  margin: 0; }
            @media (max-width: 991px) {
              .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown {
                position: static; }
                .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown {
                  left: 20px;
                  right: 20px;
                  top: 70px;
                  width: calc(100% - 40px); } }
          .navbar .navbar-menu-wrapper .navbar-nav .nav-item.user-dropdown {
            position: relative; }
            .navbar .navbar-menu-wrapper .navbar-nav .nav-item.user-dropdown .dropdown-menu {
              min-width: 250px;
              margin-top: 9px; }
              .navbar .navbar-menu-wrapper .navbar-nav .nav-item.user-dropdown .dropdown-menu .dropdown-header {
                padding: 20px; }
              .navbar .navbar-menu-wrapper .navbar-nav .nav-item.user-dropdown .dropdown-menu .dropdown-item {
                padding: 10px 15px; }
                .navbar .navbar-menu-wrapper .navbar-nav .nav-item.user-dropdown .dropdown-menu .dropdown-item .dropdown-item-icon {
                  margin-right: 15px; }
            .navbar .navbar-menu-wrapper .navbar-nav .nav-item.user-dropdown .dropdown-toggle img {
              margin-right: 14px; }
          .navbar .navbar-menu-wrapper .navbar-nav .nav-item.language-dropdown {
            margin-left: 24px; }
            .navbar .navbar-menu-wrapper .navbar-nav .nav-item.language-dropdown .dropdown-menu {
              width: 170px;
              left: -15px !important; }
              .navbar .navbar-menu-wrapper .navbar-nav .nav-item.language-dropdown .dropdown-menu .dropdown-item {
                padding-left: 22px;
                padding-right: 12px; }
                .navbar .navbar-menu-wrapper .navbar-nav .nav-item.language-dropdown .dropdown-menu .dropdown-item i {
                  margin-right: 15px; }
            .navbar .navbar-menu-wrapper .navbar-nav .nav-item.language-dropdown .dropdown-toggle {
              margin-right: 0;
              margin-left: 0;
              border-left: 1px solid #ecf0f4;
              padding-left: 44px; }
            .navbar .navbar-menu-wrapper .navbar-nav .nav-item.language-dropdown i {
              font-size: 14px; }
          .navbar .navbar-menu-wrapper .navbar-nav .nav-item .message-dropdown.count-indicator .count {
            -webkit-transform: translate(-50%);
            transform: translate(-50%);
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #ff4d6b;
            font-size: 6px;
            color: #ffffff;
            text-align: center;
            top: 4px;
            left: 50%; }
          @media (min-width: 992px) {
            .navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right {
              margin-left: auto; } }
    
    @media (max-width: 991px) {
      .navbar {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row; }
        .navbar .navbar-brand-wrapper {
          width: 75px; }
          .navbar .navbar-brand-wrapper .navbar-brand.brand-logo {
            display: none; }
          .navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini {
            display: inline-block; }
      .navbar-collapse {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-top: 0.5rem; } }
    
    @media (max-width: 480px) {
      .navbar .navbar-brand-wrapper {
        width: 55px; }
        .navbar .navbar-brand-wrapper .brand-logo-mini {
          padding-top: 0px; } }
    
    /* Sidebar */
    .sidebar {
      min-height: calc(100vh - 70px);
      background: #181824;
      font-family: "Open Sans", sans-serif;
      padding: 0;
      width: 240px;
      z-index: 11;
      transition: width 0.25s ease, background 0.25s ease;
      -webkit-transition: width 0.25s ease, background 0.25s ease;
      -moz-transition: width 0.25s ease, background 0.25s ease;
      -ms-transition: width 0.25s ease, background 0.25s ease; }
      .sidebar .nav {
        overflow: hidden;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-bottom: 60px; }
        .sidebar .nav .nav-item {
          padding: 0 1.75rem;
          -webkit-transition-duration: 0.25s;
          transition-duration: 0.25s;
          transition-property: background;
          -webkit-transition-property: background; }
          .sidebar .nav .nav-item .collapse {
            z-index: 999; }
          .sidebar .nav .nav-item .nav-link {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            white-space: nowrap;
            padding: 0.625rem 0 0.625rem 0;
            color: #ffffff;
            -webkit-transition-duration: 0.45s;
            transition-duration: 0.45s;
            transition-property: color;
            -webkit-transition-property: color; }
            :not(.sub-menu) .sidebar .nav .nav-item .nav-link {
              border-top: 1px solid rgba(255, 255, 255, 0.11); }
            .sidebar .nav .nav-item .nav-link i {
              color: inherit; }
              .sidebar .nav .nav-item .nav-link i.menu-icon {
                font-size: 1.125rem;
                line-height: 1;
                margin-left: auto;
                color: #626262; }
                .sidebar .nav .nav-item .nav-link i.menu-icon:before {
                  vertical-align: middle; }
            .sidebar .nav .nav-item .nav-link .menu-title {
              color: inherit;
              display: inline-block;
              font-size: 0.875rem;
              line-height: 1;
              vertical-align: middle; }
            .sidebar .nav .nav-item .nav-link .badge {
              margin-right: auto;
              margin-left: 1rem; }
            .sidebar .nav .nav-item .nav-link[aria-expanded="true"] .menu-arrow:before {
              content: "\f140"; }
          .sidebar .nav .nav-item.active:not(.navbar-brand-mini-wrapper) {
            background: #282828; }
            .sidebar .nav .nav-item.active:not(.navbar-brand-mini-wrapper) > .nav-link .menu-title {
              color: #b3b3b3;
              font-family: "Open Sans", sans-serif;
              font-weight: 600; }
            .sidebar .nav .nav-item.active:not(.navbar-brand-mini-wrapper) > .nav-link i {
              color: #38ce3c; }
          .sidebar .nav .nav-item:not(.nav-category):hover {
            background: #2c2c43; }
          .sidebar .nav .nav-item.nav-profile {
            max-width: 270px;
            margin-top: 10px;
            margin-bottom: 10px; }
            .sidebar .nav .nav-item.nav-profile .nav-link {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              border-top: none;
              padding: 10px 0; }
              .sidebar .nav .nav-item.nav-profile .nav-link .profile-image {
                margin-right: 15px;
                position: relative; }
                .rtl .sidebar .nav .nav-item.nav-profile .nav-link .profile-image {
                  margin-right: 0;
                  margin-left: 15px; }
              .sidebar .nav .nav-item.nav-profile .nav-link .dot-indicator {
                position: absolute;
                top: 50%;
                right: 0px;
                -webkit-transform: translate(50%, -50%);
                transform: translate(50%, -50%);
                width: 7px;
                height: 7px;
                border-radius: 50%; }
                .rtl .sidebar .nav .nav-item.nav-profile .nav-link .dot-indicator {
                  left: 0;
                  right: auto;
                  -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%); }
              .sidebar .nav .nav-item.nav-profile .nav-link .profile-name {
                margin-bottom: 5px;
                font-weight: 500;
                font-size: 15px; }
              .sidebar .nav .nav-item.nav-profile .nav-link .designation {
                margin-bottom: 0;
                font-weight: 400;
                color: #9c9fa6;
                font-size: 12px; }
              .sidebar .nav .nav-item.nav-profile .nav-link .icon-container {
                font-size: 16px;
                position: relative;
                color: #626262;
                margin-left: auto;
                -ms-flex-item-align: start;
                align-self: flex-start; }
                .rtl .sidebar .nav .nav-item.nav-profile .nav-link .icon-container {
                  margin-left: 0;
                  margin-right: auto; }
                .sidebar-mini .sidebar .nav .nav-item.nav-profile .nav-link .icon-container {
                  margin-right: auto; }
                .sidebar .nav .nav-item.nav-profile .nav-link .icon-container .dot-indicator {
                  top: 0;
                  -webkit-transform: none;
                  transform: none;
                  color: #626262; }
          .sidebar .nav .nav-item.nav-category {
            color: #38ce3c;
            font-size: 13px;
            font-weight: 700;
            text-transform: uppercase; }
            .sidebar .nav .nav-item.nav-category .nav-link {
              border: none; }
            .sidebar .nav .nav-item.nav-category ~ .nav-category .nav-link {
              border-top: 1px solid rgba(255, 255, 255, 0.11); }
            .sidebar .nav .nav-item.nav-category + .nav-item > .nav-link {
              border-top: 1px solid rgba(255, 255, 255, 0.11); }
            .sidebar .nav .nav-item.nav-category .nav-link {
              color: inherit;
              padding: 1rem 0 1.125rem; }
              .sidebar-dark .sidebar .nav .nav-item.nav-category .nav-link {
                color: inherit; }
          .sidebar .nav .nav-item.pro-upgrade .nav-link {
            padding-top: 2.25rem; }
            .sidebar .nav .nav-item.pro-upgrade .nav-link .btn-upgrade {
              background-image: -webkit-gradient(linear, right top, left top, from(#a25cff), to(#d78aff));
              background-image: linear-gradient(to left, #a25cff, #d78aff);
              background-color: #a25cff;
              color: #ffffff; }
          .sidebar .nav .nav-item.pro-upgrade:hover {
            background-color: transparent !important; }
            .sidebar-dark .sidebar .nav .nav-item.pro-upgrade:hover {
              background-color: transparent !important; }
          .sidebar .nav .nav-item.navbar-brand-mini-wrapper {
            display: none; }
            .sidebar .nav .nav-item.navbar-brand-mini-wrapper .nav-link {
              width: 100%; }
              .sidebar-icon-only .sidebar .nav .nav-item.navbar-brand-mini-wrapper .nav-link {
                margin: 0; }
            .sidebar-icon-only .sidebar .nav .nav-item.navbar-brand-mini-wrapper {
              display: block; }
        .sidebar .nav:not(.sub-menu) > .nav-item:hover:not(.nav-category):not(.nav-profile) > .nav-link {
          color: #ffffff; }
        .sidebar .nav.sub-menu {
          margin-bottom: 20px;
          margin-top: 0;
          list-style: none; }
          .sidebar .nav.sub-menu .nav-item {
            padding: 0; }
            .sidebar .nav.sub-menu .nav-item .nav-link {
              color: #ffffff;
              padding: 0.75rem 2rem 0.75rem 2rem;
              position: relative;
              font-size: 0.8125rem;
              line-height: 1;
              height: auto;
              border-top: 0; }
              .sidebar .nav.sub-menu .nav-item .nav-link:before {
                content: "\e606";
                font-family: "simple-line-icons";
                display: block;
                position: absolute;
                left: 0px;
                top: 50%;
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
                color: white;
                font-size: .75rem; }
              .sidebar .nav.sub-menu .nav-item .nav-link.active {
                color: #b3b3b3;
                background: transparent; }
              .sidebar .nav.sub-menu .nav-item .nav-link:hover {
                color: #b3b3b3; }
            .sidebar .nav.sub-menu .nav-item:hover {
              background: transparent; }
    
    /* style for off-canvas menu*/
    @media screen and (max-width: 991px) {
      .sidebar-offcanvas {
        max-height: calc(100vh - 70px);
        top: 70px;
        bottom: 0;
        overflow: auto;
        right: -240px;
        -webkit-transition: all 0.25s ease-out;
        transition: all 0.25s ease-out; }
        .sidebar-offcanvas.active {
          right: 0; } }
    
    .page-body-wrapper {
      min-height: calc(100vh - 70px);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      padding-left: 0;
      padding-right: 0; }
      .page-body-wrapper.full-page-wrapper {
        width: 100%;
        min-height: 100vh; }
    
    .navbar.fixed-top + .page-body-wrapper {
      padding-top: 70px; }
    
    .main-panel {
      -webkit-transition: width 0.25s ease, margin 0.25s ease;
      transition: width 0.25s ease, margin 0.25s ease;
      width: 100%;
      min-height: calc(100vh - 70px);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column; }
      @media (max-width: 991px) {
        .main-panel {
          margin-left: 0;
          width: 100%; } }
    
    .content-wrapper {
      background: #ecf0f4;
      padding: 2.75rem 1.5rem 0;
      width: 100%;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1; }
    
    .purchace-popup .close {
      color: inherit;
      opacity: 1; }
      @media (max-width: 991.98px) {
        .purchace-popup .close {
          position: absolute;
          top: 7px;
          right: 10px; } }
    
    .quick-action-toolbar .card .card-header {
      color: #ffffff;
      padding: 12px 30px;
      background-image: -webkit-gradient(linear, right top, left top, from(#fad961), to(#f76b1c));
      background-image: linear-gradient(to left, #fad961, #f76b1c);
      border: 0;
      border-radius: 0; }
      .quick-action-toolbar .card .card-header h5 {
        font-weight: 600;
        font-size: 18px; }
      .quick-action-toolbar .card .card-header p {
        color: inherit; }
      .quick-action-toolbar .card .card-header i {
        margin-left: 15px;
        -ms-flex-item-align: center;
        align-self: center; }
    
    .quick-action-toolbar .card .quick-action-btns .btn-light {
      background-color: transparent;
      border: none;
      font-size: 14px;
      color: #111111;
      font-weight: 600;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      .quick-action-toolbar .card .quick-action-btns .btn-light i {
        color: #626262;
        margin-right: 15px;
        font-size: 18px; }
        .rtl .quick-action-toolbar .card .quick-action-btns .btn-light i {
          margin-left: 15px;
          margin-right: 0; }
    
    .quick-action-toolbar .card .quick-action-btns .btn-wrapper {
      border-right: 1px solid #e8ecf1;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center; }
      .quick-action-toolbar .card .quick-action-btns .btn-wrapper button {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center; }
      @media screen and (max-width: 767px) {
        .quick-action-toolbar .card .quick-action-btns .btn-wrapper:first-child {
          border-bottom: 1px solid #e8ecf1; } }
      @media screen and (max-width: 576px) {
        .quick-action-toolbar .card .quick-action-btns .btn-wrapper:first-child {
          border-right: none; } }
      @media screen and (max-width: 767px) {
        .quick-action-toolbar .card .quick-action-btns .btn-wrapper:nth-child(2) {
          border-right: none;
          border-bottom: 1px solid #e8ecf1; } }
      @media screen and (max-width: 576px) {
        .quick-action-toolbar .card .quick-action-btns .btn-wrapper:nth-child(3) {
          border-right: none;
          border-bottom: 1px solid #e8ecf1; } }
      .quick-action-toolbar .card .quick-action-btns .btn-wrapper:last-child {
        border: none; }
    
    .income-expense-summary-chart-text h5 {
      font-size: 18px;
      font-weight: 600; }
    
    .income-expense-summary-chart-text h3 {
      font-size: 24px;
      font-weight: bold; }
    
    .income-expense-summary-chart-legend {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      .income-expense-summary-chart-legend span {
        display: inline-block;
        width: 14px;
        height: 14px;
        border: 2px solid transparent;
        margin-right: 10px; }
        .rtl .income-expense-summary-chart-legend span {
          margin-left: 10px;
          margin-right: 0; }
    
    #income-expense-summary-chart-daterange {
      width: 280px;
      border-radius: 4px;
      border: solid 1px #e8ecf1;
      background-color: #ffffff;
      color: #969696; }
      #income-expense-summary-chart-daterange .input-group-text {
        background-color: transparent;
        color: inherit;
        padding: 10px;
        border: 0; }
      #income-expense-summary-chart-daterange .form-control, #income-expense-summary-chart-daterange .select2-container--default .select2-selection--single, .select2-container--default #income-expense-summary-chart-daterange .select2-selection--single, #income-expense-summary-chart-daterange .select2-container--default .select2-selection--single .select2-search__field, .select2-container--default .select2-selection--single #income-expense-summary-chart-daterange .select2-search__field, #income-expense-summary-chart-daterange .typeahead,
      #income-expense-summary-chart-daterange .tt-query,
      #income-expense-summary-chart-daterange .tt-hint {
        border: 0;
        font-size: 12px;
        font-weight: 600;
        color: inherit;
        padding: 0; }
    
    .income-expense-summary-chart {
      direction: ltr; }
      .income-expense-summary-chart .ct-chart .ct-series .ct-line {
        stroke-width: 3px;
        stroke-dasharray: 0; }
      .income-expense-summary-chart .ct-chart .ct-series-a .ct-line {
        stroke: #6469df; }
      .income-expense-summary-chart .ct-chart .ct-series-a .ct-area {
        fill: #6469df; }
      .income-expense-summary-chart .ct-chart .ct-series-b .ct-line {
        stroke: #33c92d; }
      .income-expense-summary-chart .ct-chart .ct-series-b .ct-area {
        fill: #33c92d; }
      @media screen and (max-width: 480px) {
        .income-expense-summary-chart .ct-chart .ct-labels .ct-label.ct-horizontal {
          -webkit-transform: rotate(-90deg) translateY(-50%);
          transform: rotate(-90deg) translateY(-50%); } }
    
    .ct-chart .ct-line {
      stroke-dasharray: 0; }
    
    .report-summary-header {
      border-bottom: solid 1px #e8ecf1; }
    
    .report-inner-cards-wrapper {
      padding-top: 25px; }
      .report-inner-cards-wrapper .report-inner-card {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        border-right: solid 1px #e8ecf1;
        padding: 0 40px; }
        .rtl .report-inner-cards-wrapper .report-inner-card {
          border-right: none;
          border-left: solid 1px #e8ecf1; }
        @media screen and (max-width: 1199px) {
          .report-inner-cards-wrapper .report-inner-card {
            padding-top: 12.5px;
            padding-bottom: 12.5px; } }
        .report-inner-cards-wrapper .report-inner-card:first-child {
          padding-left: 15px; }
          .rtl .report-inner-cards-wrapper .report-inner-card:first-child {
            padding-right: 15px;
            padding-left: 40px; }
          @media screen and (max-width: 1199px) {
            .report-inner-cards-wrapper .report-inner-card:first-child {
              border-bottom: solid 1px #e8ecf1; } }
          @media screen and (max-width: 767px) {
            .report-inner-cards-wrapper .report-inner-card:first-child {
              border-right: none;
              padding-right: 12.5px; }
              .rtl .report-inner-cards-wrapper .report-inner-card:first-child {
                border-left: none;
                padding-left: 12.5px; } }
        @media screen and (max-width: 1199px) {
          .report-inner-cards-wrapper .report-inner-card:nth-child(2) {
            padding-right: 12.5px;
            border-bottom: solid 1px #e8ecf1;
            border-right: none; }
            .rtl .report-inner-cards-wrapper .report-inner-card:nth-child(2) {
              border-left: none;
              padding-left: 12.5px;
              padding-right: 40px; } }
        @media screen and (max-width: 767px) {
          .report-inner-cards-wrapper .report-inner-card:nth-child(2) {
            padding-left: 12.5px; }
            .rtl .report-inner-cards-wrapper .report-inner-card:nth-child(2) {
              padding-right: 12.5px; } }
        @media screen and (max-width: 1199px) {
          .report-inner-cards-wrapper .report-inner-card:nth-child(3) {
            padding-left: 12.5px; }
            .rtl .report-inner-cards-wrapper .report-inner-card:nth-child(3) {
              padding-left: 40px;
              padding-right: 12.5px; } }
        @media screen and (max-width: 767px) {
          .report-inner-cards-wrapper .report-inner-card:nth-child(3) {
            border-right: none;
            padding-right: 12.5px;
            border-bottom: solid 1px #e8ecf1; }
            .rtl .report-inner-cards-wrapper .report-inner-card:nth-child(3) {
              border-left: none;
              padding-left: 12.5px; } }
        .report-inner-cards-wrapper .report-inner-card:last-child {
          border-right: 0;
          padding-right: 15px; }
          .rtl .report-inner-cards-wrapper .report-inner-card:last-child {
            border-left: none;
            padding-left: 15px;
            padding-right: 40px; }
          @media screen and (max-width: 767px) {
            .report-inner-cards-wrapper .report-inner-card:last-child {
              border-right: none;
              padding-left: 12.5px; }
              .rtl .report-inner-cards-wrapper .report-inner-card:last-child {
                border-left: none;
                padding-right: 12.5px; } }
        .report-inner-cards-wrapper .report-inner-card .inner-card-text {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-pack: end;
          -ms-flex-pack: end;
          justify-content: flex-end; }
        .report-inner-cards-wrapper .report-inner-card .report-title {
          font-size: 14px;
          font-weight: 600;
          color: #111111; }
        .report-inner-cards-wrapper .report-inner-card h4 {
          font-weight: bold; }
        .report-inner-cards-wrapper .report-inner-card .report-count {
          font-size: 14px;
          color: #8e32e9; }
        .report-inner-cards-wrapper .report-inner-card .inner-card-icon {
          width: 62px;
          height: 62px;
          font-size: 25px;
          color: #ffffff;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          margin-left: auto; }
          .rtl .report-inner-cards-wrapper .report-inner-card .inner-card-icon {
            margin-left: 0;
            margin-right: auto; }
    
    .aligner-wrapper {
      position: relative; }
      .aligner-wrapper .wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); }
    
    .square-indicator {
      display: inline-block;
      width: 13px;
      height: 13px; }
      .square-indicator + p {
        font-size: 12px;
        font-weight: 600;
        color: #a7afb7; }
    
    #performance-indicator-chart .ct-series.ct-series-a line {
      stroke: #ff4d6b; }
    
    #performance-indicator-chart .ct-series.ct-series-b line {
      stroke: #8e32e9; }
    
    #performance-indicator-chart .ct-series.ct-series-c line {
      stroke: #1bdbe0; }
    
    #performance-indicator-chart .ct-series .ct-bar {
      stroke-width: 8px;
      stroke-linecap: round; }
      @media screen and (max-width: 480px) {
        #performance-indicator-chart .ct-series .ct-bar {
          stroke-width: 4px; } }
    
    @media screen and (max-width: 480px) {
      #performance-indicator-chart .ct-labels .ct-label.ct-horizontal {
        -webkit-transform: rotate(-90deg) translateY(20%);
        transform: rotate(-90deg) translateY(20%); } }
    
    .performane-indicator-card .data-time-range {
      color: #a7afb7;
      font-size: 12px;
      font-weight: 600;
      margin-left: 20px; }
      .performane-indicator-card .data-time-range.active {
        color: #111111; }
    
    .table td img.gateway-icon {
      width: 20px;
      height: 20px;
      min-width: 20px; }
    
    .pagination .page-item .page-link {
      font-size: 12px;
      font-weight: 600; }
      @media screen and (max-width: 576px) {
        .pagination .page-item .page-link {
          padding: 0.25rem 0.5rem; } }
        


/* style for off-canvas menu*/
@media screen and (max-width: 991px) {
  .sidebar-offcanvas {
    max-height: calc(100vh - 70px);
    top: 70px;
    bottom: 0;
    overflow: auto;
    right: -240px;
    -webkit-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out; }
    .sidebar-offcanvas.active {
      right: 0; } }

.page-body-wrapper {
  min-height: calc(100vh - 70px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  padding-left: 0;
  padding-right: 0; }
  .page-body-wrapper.full-page-wrapper {
    width: 100%;
    min-height: 100vh; }


.main-panel {
  -webkit-transition: width 0.25s ease, margin 0.25s ease;
  transition: width 0.25s ease, margin 0.25s ease;
  width: 100%;
  min-height: calc(100vh - 70px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column; }
  @media (max-width: 991px) {
    .main-panel {
      margin-left: 0;
      width: 100%; } }

.content-wrapper {
  background: #ecf0f4;
  padding: 2.75rem 1.5rem 0;
  width: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1; }

.purchace-popup .close {
  color: inherit;
  opacity: 1; }
  @media (max-width: 991.98px) {
    .purchace-popup .close {
      position: absolute;
      top: 7px;
      right: 10px; } }

.quick-action-toolbar .card .card-header {
  color: #ffffff;
  padding: 12px 30px;
  background-image: -webkit-gradient(linear, right top, left top, from(#fad961), to(#f76b1c));
  background-image: linear-gradient(to left, #fad961, #f76b1c);
  border: 0;
  border-radius: 0; }
  .quick-action-toolbar .card .card-header h5 {
    font-weight: 600;
    font-size: 18px; }
  .quick-action-toolbar .card .card-header p {
    color: inherit; }
  .quick-action-toolbar .card .card-header i {
    margin-left: 15px;
    -ms-flex-item-align: center;
    align-self: center; }

.quick-action-toolbar .card .quick-action-btns .btn-light {
  background-color: transparent;
  border: none;
  font-size: 14px;
  color: #111111;
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .quick-action-toolbar .card .quick-action-btns .btn-light i {
    color: #626262;
    margin-right: 15px;
    font-size: 18px; }
    .rtl .quick-action-toolbar .card .quick-action-btns .btn-light i {
      margin-left: 15px;
      margin-right: 0; }

.quick-action-toolbar .card .quick-action-btns .btn-wrapper {
  border-right: 1px solid #e8ecf1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }
  .quick-action-toolbar .card .quick-action-btns .btn-wrapper button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
  @media screen and (max-width: 767px) {
    .quick-action-toolbar .card .quick-action-btns .btn-wrapper:first-child {
      border-bottom: 1px solid #e8ecf1; } }
  @media screen and (max-width: 576px) {
    .quick-action-toolbar .card .quick-action-btns .btn-wrapper:first-child {
      border-right: none; } }
  @media screen and (max-width: 767px) {
    .quick-action-toolbar .card .quick-action-btns .btn-wrapper:nth-child(2) {
      border-right: none;
      border-bottom: 1px solid #e8ecf1; } }
  @media screen and (max-width: 576px) {
    .quick-action-toolbar .card .quick-action-btns .btn-wrapper:nth-child(3) {
      border-right: none;
      border-bottom: 1px solid #e8ecf1; } }
  .quick-action-toolbar .card .quick-action-btns .btn-wrapper:last-child {
    border: none; }

.income-expense-summary-chart-text h5 {
  font-size: 18px;
  font-weight: 600; }

.income-expense-summary-chart-text h3 {
  font-size: 24px;
  font-weight: bold; }

.income-expense-summary-chart-legend {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .income-expense-summary-chart-legend span {
    display: inline-block;
    width: 14px;
    height: 14px;
    border: 2px solid transparent;
    margin-right: 10px; }
    .rtl .income-expense-summary-chart-legend span {
      margin-left: 10px;
      margin-right: 0; }

#income-expense-summary-chart-daterange {
  width: 280px;
  border-radius: 4px;
  border: solid 1px #e8ecf1;
  background-color: #ffffff;
  color: #969696; }
  #income-expense-summary-chart-daterange .input-group-text {
    background-color: transparent;
    color: inherit;
    padding: 10px;
    border: 0; }
  #income-expense-summary-chart-daterange .form-control, #income-expense-summary-chart-daterange .select2-container--default .select2-selection--single, .select2-container--default #income-expense-summary-chart-daterange .select2-selection--single, #income-expense-summary-chart-daterange .select2-container--default .select2-selection--single .select2-search__field, .select2-container--default .select2-selection--single #income-expense-summary-chart-daterange .select2-search__field, #income-expense-summary-chart-daterange .typeahead,
  #income-expense-summary-chart-daterange .tt-query,
  #income-expense-summary-chart-daterange .tt-hint {
    border: 0;
    font-size: 12px;
    font-weight: 600;
    color: inherit;
    padding: 0; }

.income-expense-summary-chart {
  direction: ltr; }
  .income-expense-summary-chart .ct-chart .ct-series .ct-line {
    stroke-width: 3px;
    stroke-dasharray: 0; }
  .income-expense-summary-chart .ct-chart .ct-series-a .ct-line {
    stroke: #6469df; }
  .income-expense-summary-chart .ct-chart .ct-series-a .ct-area {
    fill: #6469df; }
  .income-expense-summary-chart .ct-chart .ct-series-b .ct-line {
    stroke: #33c92d; }
  .income-expense-summary-chart .ct-chart .ct-series-b .ct-area {
    fill: #33c92d; }
  @media screen and (max-width: 480px) {
    .income-expense-summary-chart .ct-chart .ct-labels .ct-label.ct-horizontal {
      -webkit-transform: rotate(-90deg) translateY(-50%);
      transform: rotate(-90deg) translateY(-50%); } }

.ct-chart .ct-line {
  stroke-dasharray: 0; }

.report-summary-header {
  border-bottom: solid 1px #e8ecf1; }

.report-inner-cards-wrapper {
  padding-top: 25px; }
  .report-inner-cards-wrapper .report-inner-card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-right: solid 1px #e8ecf1;
    padding: 0 40px; }
    .rtl .report-inner-cards-wrapper .report-inner-card {
      border-right: none;
      border-left: solid 1px #e8ecf1; }
    @media screen and (max-width: 1199px) {
      .report-inner-cards-wrapper .report-inner-card {
        padding-top: 12.5px;
        padding-bottom: 12.5px; } }
    .report-inner-cards-wrapper .report-inner-card:first-child {
      padding-left: 15px; }
      .rtl .report-inner-cards-wrapper .report-inner-card:first-child {
        padding-right: 15px;
        padding-left: 40px; }
      @media screen and (max-width: 1199px) {
        .report-inner-cards-wrapper .report-inner-card:first-child {
          border-bottom: solid 1px #e8ecf1; } }
      @media screen and (max-width: 767px) {
        .report-inner-cards-wrapper .report-inner-card:first-child {
          border-right: none;
          padding-right: 12.5px; }
          .rtl .report-inner-cards-wrapper .report-inner-card:first-child {
            border-left: none;
            padding-left: 12.5px; } }
    @media screen and (max-width: 1199px) {
      .report-inner-cards-wrapper .report-inner-card:nth-child(2) {
        padding-right: 12.5px;
        border-bottom: solid 1px #e8ecf1;
        border-right: none; }
        .rtl .report-inner-cards-wrapper .report-inner-card:nth-child(2) {
          border-left: none;
          padding-left: 12.5px;
          padding-right: 40px; } }
    @media screen and (max-width: 767px) {
      .report-inner-cards-wrapper .report-inner-card:nth-child(2) {
        padding-left: 12.5px; }
        .rtl .report-inner-cards-wrapper .report-inner-card:nth-child(2) {
          padding-right: 12.5px; } }
    @media screen and (max-width: 1199px) {
      .report-inner-cards-wrapper .report-inner-card:nth-child(3) {
        padding-left: 12.5px; }
        .rtl .report-inner-cards-wrapper .report-inner-card:nth-child(3) {
          padding-left: 40px;
          padding-right: 12.5px; } }
    @media screen and (max-width: 767px) {
      .report-inner-cards-wrapper .report-inner-card:nth-child(3) {
        border-right: none;
        padding-right: 12.5px;
        border-bottom: solid 1px #e8ecf1; }
        .rtl .report-inner-cards-wrapper .report-inner-card:nth-child(3) {
          border-left: none;
          padding-left: 12.5px; } }
    .report-inner-cards-wrapper .report-inner-card:last-child {
      border-right: 0;
      padding-right: 15px; }
      .rtl .report-inner-cards-wrapper .report-inner-card:last-child {
        border-left: none;
        padding-left: 15px;
        padding-right: 40px; }
      @media screen and (max-width: 767px) {
        .report-inner-cards-wrapper .report-inner-card:last-child {
          border-right: none;
          padding-left: 12.5px; }
          .rtl .report-inner-cards-wrapper .report-inner-card:last-child {
            border-left: none;
            padding-right: 12.5px; } }
    .report-inner-cards-wrapper .report-inner-card .inner-card-text {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end; }
    .report-inner-cards-wrapper .report-inner-card .report-title {
      font-size: 14px;
      font-weight: 600;
      color: #111111; }
    .report-inner-cards-wrapper .report-inner-card h4 {
      font-weight: bold; }
    .report-inner-cards-wrapper .report-inner-card .report-count {
      font-size: 14px;
      color: #8e32e9; }
    .report-inner-cards-wrapper .report-inner-card .inner-card-icon {
      width: 62px;
      height: 62px;
      font-size: 25px;
      color: #ffffff;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      margin-left: auto; }
      .rtl .report-inner-cards-wrapper .report-inner-card .inner-card-icon {
        margin-left: 0;
        margin-right: auto; }

.aligner-wrapper {
  position: relative; }
  .aligner-wrapper .wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }

.square-indicator {
  display: inline-block;
  width: 13px;
  height: 13px; }
  .square-indicator + p {
    font-size: 12px;
    font-weight: 600;
    color: #a7afb7; }

#performance-indicator-chart .ct-series.ct-series-a line {
  stroke: #ff4d6b; }

#performance-indicator-chart .ct-series.ct-series-b line {
  stroke: #8e32e9; }

#performance-indicator-chart .ct-series.ct-series-c line {
  stroke: #1bdbe0; }

#performance-indicator-chart .ct-series .ct-bar {
  stroke-width: 8px;
  stroke-linecap: round; }
  @media screen and (max-width: 480px) {
    #performance-indicator-chart .ct-series .ct-bar {
      stroke-width: 4px; } }

@media screen and (max-width: 480px) {
  #performance-indicator-chart .ct-labels .ct-label.ct-horizontal {
    -webkit-transform: rotate(-90deg) translateY(20%);
    transform: rotate(-90deg) translateY(20%); } }

.performane-indicator-card .data-time-range {
  color: #a7afb7;
  font-size: 12px;
  font-weight: 600;
  margin-left: 20px; }
  .performane-indicator-card .data-time-range.active {
    color: #111111; }

.table td img.gateway-icon {
  width: 20px;
  height: 20px;
  min-width: 20px; }

.pagination .page-item .page-link {
  font-size: 12px;
  font-weight: 600; }
  @media screen and (max-width: 576px) {
    .pagination .page-item .page-link {
      padding: 0.25rem 0.5rem; } }

